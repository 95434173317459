<div class="dialog-wrapper" [ngClass]="{'bad-request': data.statusCode >= 400, 'success': data.statusCode === 200, 'success_with_error': data.statusCode === 204}">
    <div class="head">
        <mat-icon *ngIf="data.statusCode === 200" svgIcon="ico-success" class="custom-mat-icon ico-size ico-std"></mat-icon>
        <mat-icon *ngIf="data.statusCode === 204" svgIcon="ico-warning" class="custom-mat-icon ico-size ico-std"></mat-icon>
        <mat-icon *ngIf="data.statusCode >= 500" svgIcon="ico-error-500" class="custom-mat-icon ico-size ico-std"></mat-icon>
        <mat-icon *ngIf="data.statusCode >= 400 && data.statusCode < 500" svgIcon="ico-error-400" class="custom-mat-icon ico-size ico-std"></mat-icon>
        <!-- ToDo: Remove after checking none showNotification function is using the param code. -->
        <mat-icon *ngIf="data.code === _keyName.form_submitted" svgIcon="ico-success" class="custom-mat-icon ico-size ico-std"></mat-icon>
    </div>
    <div class="body">
      <span>{{message}}</span>
    </div>
</div>
