//angular
import { NgModule } from '@angular/core';

//services
import { SharedCustomerService } from './shared-customer.service';
import { SharedGlobalService } from './shared-global.service';
import { SharedProductService } from './shared-product.service';
import { SharedProductRatePlanService } from './shared-product-rate-plan.service';
import { SharedIntegrationService } from './shared-integration.service';


const PROVIDERS: any[] = [
  SharedCustomerService,
  SharedIntegrationService,
  SharedGlobalService,
  SharedProductService,
  SharedProductRatePlanService,
]


@NgModule({
  declarations: [],
  imports: [],
  providers: PROVIDERS,
})
export class CrossServiceModule {
  constructor() {
    console.log(`cross-service.module.ts was loaded.`)
  }
 }
