<ng-template #dialogHead>
  <label class="label-title">Customer Address</label>
  <button mat-icon-button (click)="onClose()" class="custom-mat-icon-button button-close-panel">
    <mat-icon matSuffix svgIcon="ico-close" class="custom-mat-icon ico-size ico-2xs"></mat-icon>
  </button>
</ng-template>

<!-- Body -->
<ng-template #dialogBody>
  <form class="form-wrapper">
    <div class="form-content">
      <div class="form-section">
        <div class="form-section-container padding nopadding">
          <app-account-shipping-address
              [saveNewShippingAddress] = _data.saveAddress
              [saveAddressDisabled] = _data.saveAddressDisabled
              [customerId] = _data.customerId
              [defaultCountryAlpha3Code] = defaultCountryAlpha3Code
              (_emitBodyParams)="onGetDetailsBodyParams($event)">  
            </app-account-shipping-address>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<!-- Nav -->
<ng-template #dialogNav>
  <div class="nav margin nomargin">
    <button mat-flat-button (click)="onSubmit()" color="primary" class="mat-button custom-mat-button-primary">Save Changes</button>
  </div>
</ng-template>

<app-dialog
  [dialogHead]="dialogHead"
  [dialogBody]="dialogBody"
  [dialogNav]="dialogNav">
</app-dialog>