import { Deserializable } from '../interfaces/deserialize.interface';

export class Gateway implements Deserializable<Gateway> {
  constructor(
    public apiVersion?: string,
    public card?: Array<any>,
    public code?: string,
    public credentialsLastUpdate?: string,
    public dateAdded?: string,
    public id?: number,
    public isActive?: boolean,
    public isDefault?: boolean,
    public isEnabled?: boolean,
    public logo?: string,
    public method?: string,
    public name?: string,
    public status?: string,
    public type?: string,
    public urlSandbox?: string,
    public urlProduction?: string,
  ){}

    deserialize(input: any): Gateway {
        Object.assign(this, input);
        return this;
    }
}

export class GatewayCarddModel implements Deserializable<GatewayCarddModel> {
  constructor(
    public id?: string,
    public isActive?: string,
    public name?: string
  ) {}

  deserialize(input: any): GatewayCarddModel {
    Object.assign(this, input);
    return this;
  }
}

export class GatewayClientToken implements Deserializable<GatewayClientToken>{
    constructor(
        public publicKey?: string,
        public merchantId?: string,
    ){}

    deserialize(input: any): GatewayClientToken {
        Object.assign(this, input);
        return this;
    }
}

export class GatewayConfig implements Deserializable<GatewayConfig> {
  constructor(
    public card?: Array<any>,
    public connectionType?: string,
    public createdAt?: Date,
    public credentialsLastUpdate?: string,
    public dateAdded?: string,
    public enabled?: boolean,
    public id?: number,
    public keyName?: string,
    public logo?: string,
    public name?: string,
    public setting?: Array<GatewaySettingsField>,
    public status?: string,
    public type?: string,
    public updatedAt?: Date
  ) {}

  deserialize(input: any): GatewayConfig {
    Object.assign(this, input);
    return this;
  }
}

export class GatewaySettingsField implements Deserializable<GatewaySettingsField>{
  constructor(
    public displayName?: string,
    public keyName?: string,
    public value?: string,
  ) {}

  deserialize(input: any): GatewaySettingsField {
    Object.assign(this, input);
    return this;
  }
}

export class GatewayNonceResponse implements Deserializable<GatewayNonceResponse>{
    constructor(
        public accountType?: string,
        public accountKind?: string,
        public accountName?: string,
        public nonce?: string,
    ) { }

    deserialize(input: any): GatewayNonceResponse {
        Object.assign(this, input);
        return this;
    }
}

export class GatewayBraintreePaymentResponse implements Deserializable<GatewayBraintreePaymentResponse>{
    constructor(
        public description?: string, //ending in 11
        public details?: {
            cardType: string, //Visa
            expirationMonth: string, //12
            expirationYear: string, //2020
            lastFour: string, //1111
            lastTwo: string, //11
        },
        public nonce?: string, //tokencc_bd_59gjw4_kypsvk_q28pbb_2hk9bc_87y
        public type?: string, //CreditCard
    ) { }

    deserialize(input: any): GatewayBraintreePaymentResponse {
        Object.assign(this, input);
        return this;
    }
}

export class GatewaySquarePaymentResponse implements Deserializable<GatewaySquarePaymentResponse>{
  constructor(
    public cardData?: {
      billing_postal_code: string, //12345
      card_brand: string, //VISA
      digital_wallet_type: string, //NONE
      exp_month: string, //12
      exp_year: string, //2023
      last_4: string, //1111
    },
    public nonce?: string, //cnon:CBASEDcig-9KTUk50bgV69zldJE
  ){}

  deserialize(input: any): GatewaySquarePaymentResponse {
    Object.assign(this, input);
    return this;
  }
}

export class POST_GatewayModel implements Deserializable<POST_GatewayModel>{
  constructor(
    public card?: Array<any>,
    public displayName?: string,
    public gblGatewayId?: number,
    public setting: Object = {},
  ){}

  deserialize(input: any): POST_GatewayModel {
    Object.assign(this, input);
    return this;
  }
}

