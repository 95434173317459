import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddressTypeValues, responseCode } from 'src/app/app.data';
import { KeyName } from 'src/app/app.keynames';
import { SharedCustomerService, SharedGlobalService } from 'src/app/_core/services/api';
import { MetaService } from 'src/app/_core/services/meta.service';
import { Country, POST_AddressInterface, RestResponse } from '../../models';
import { CustomerAddressBook } from '../../models/customer.model';
import { Unsubscriber } from '../../unsubscriber/unsubscriber';
import { ValidatorService } from '../../validators/validator.service';

@Component({
  selector: 'app-account-shipping-address',
  templateUrl: './account-shipping-address.component.html'
})
export class AccountShippingAddressComponent implements OnInit, OnDestroy {

  form: FormGroup;
  collectionOfCountry: Array<Country> = [];
  /** indicates If We want to save the address */
  @Input() saveNewShippingAddress: boolean = false;
/** accountId to asociate with the address */
  @Input() customerId: number = null;
  /** It's the default country option when the new address is selected */
  @Input() defaultCountryAlpha3Code: string = null;
  /** It is used to indicate if the 'save button' is disabled */
  @Input() saveAddressDisabled?: boolean = true;

  @Output() _emitBodyParams: EventEmitter<any> = new EventEmitter<any>();
  private subs = new Unsubscriber();

  constructor(
    private _fb: FormBuilder,
    public _meta: MetaService,
    private sharedCustomerService: SharedCustomerService,
    private sharedGlobalService: SharedGlobalService,
    private _validatorService: ValidatorService,
  ) { }

  ngOnInit(): void {
    this.setUpForm();
    //Get list of countries.
    this.subs.addSub = this.sharedGlobalService.getCountries()
      .subscribe(
        (res: RestResponse) => {
          this.collectionOfCountry = <Array<Country>>res.data;

          if(this.defaultCountryAlpha3Code){
            const defaultValue = this.collectionOfCountry.find(country => country.alpha3Code == this.defaultCountryAlpha3Code);
            this.form.get('countryId').setValue(defaultValue.id);
          }
        },
        (err: RestResponse) => {
          this._meta.showNotification(err);
        }
    );

    this.form.get('saveNewShippingAddress').setValue(this.saveNewShippingAddress);
  }

  setUpForm() {
    this.form = this._fb.group({
      saveNewShippingAddress: false,
      city: ['', [Validators.required, this._validatorService.emptySpace, Validators.minLength(3), Validators.maxLength(255)]],
      contactName: ['', [Validators.required, this._validatorService.emptySpace, Validators.minLength(3), Validators.maxLength(255)]],
      countryId: ['', [Validators.required]],
      id: [''],
      street1: ['', [Validators.required, this._validatorService.emptySpace, Validators.minLength(3), Validators.maxLength(255)]],
      street2: ['', [this._validatorService.emptySpace, Validators.minLength(1), Validators.maxLength(255)]],
      state: ['', [Validators.required, this._validatorService.emptySpace, Validators.minLength(2), Validators.maxLength(255)]],
      zip: [null, [Validators.required, this._validatorService.emptySpace, Validators.minLength(3), Validators.maxLength(255)]],
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onSubmit() {
    //Check if form is valid.
    if (this.form.invalid) {

      this.form.markAllAsTouched();
      this.form.markAsDirty();
      this._meta.showNotificationCustomMessage(responseCode.errorBadRequestCode, KeyName.form_invalid);

      let bodyParamsEmit = { code: KeyName.request_error, body: 'form invalid'};
      this._emitBodyParams.emit(bodyParamsEmit);
      return;
    }

    const attrCustomerAddress = this.form.value;

    const saveAddress = this.form.get('saveNewShippingAddress').value;

    if(saveAddress) {

      let address: POST_AddressInterface ={
        contactName: attrCustomerAddress.contactName,
        countryId: attrCustomerAddress.countryId,
        city: attrCustomerAddress.city,
        state: attrCustomerAddress.state,
        street1: attrCustomerAddress.street1,
        street2: attrCustomerAddress.street2,
        type: AddressTypeValues.residential,
        zip: attrCustomerAddress.zip
      }

      this.subs.addSub = this.sharedCustomerService.createAddressBookByCustomerId(this.customerId, address).subscribe(
        (res: any) => {

          let bodyParams = {
            code: responseCode.successCodeWithError,
            body: res.data,
            isStored: true};

          this._emitBodyParams.emit(bodyParams);

          this._meta.showNotification(res);
        },
        (err: RestResponse) => {
          this._meta.showNotification(err);
          let bodyParams = {
            code: KeyName.request_error ,
            body: err};
          this._emitBodyParams.emit(bodyParams);
        }
      );


    } else {

      const countryId = this.form.get('countryId').value,
            countrySelected = this.collectionOfCountry.find(country => country.id === countryId);

      let addressNoStored = new CustomerAddressBook();

      addressNoStored.contactName = attrCustomerAddress.contactName;
      addressNoStored.street1 = attrCustomerAddress.street1;
      addressNoStored.street2 = attrCustomerAddress.street2;
      addressNoStored.city = attrCustomerAddress.city;
      addressNoStored.state = attrCustomerAddress.state;
      addressNoStored.zip = attrCustomerAddress.zip;
      addressNoStored.country = countrySelected;

      let bodyParams = {
        code: responseCode.successCode,
        body: addressNoStored,
        isStored: false};

      this._emitBodyParams.emit(bodyParams);

    }

  }

}
