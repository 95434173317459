<app-global-header [companyName]="companyName" [userFirstName]="userFirstName" [userLastName]="userLastName" [userRole]="userRole">
  <div id="notifications">
    <reb-ngx-mat-menu-icon *ngIf="hasNotifications" matTooltipPosition="below" matTooltipClass="mat-tooltip" matTooltip='Internal Notifications' class="notifications" icon="ico-bell-notification" overlayPanelClass="rn-panel-notifications" kind="text" mood="none" (click)="getUnreadNotifications()">
      <div class="header">
        <reb-ngx-label size="14" weight="600" mood="default">NOTIFICATIONS</reb-ngx-label>
        <!-- TODO: it needs an endpoint to be implemented -->
        <!-- <reb-ngx-mat-button iconLeft="ico-check" kind="text" (clickHandler)="markNotificationAsRead($event)">Mark all as read</reb-ngx-mat-button> -->
      </div>
      <reb-ngx-divider marginVertical="8" marginHorizontal="16"></reb-ngx-divider>
      <ng-container *ngIf="isNotificationsReady">
        <reb-ngx-mat-menu-item class="rn-mat-menu-item" *ngFor="let notification of collectionOfNotifications | slice:0:7; let i = index">
          <div id="mat-menu-item-label">
            <label>{{notification.message}}</label>
            <label>{{notification.createdAt | dateTz}}</label>
          </div>
          <div id="mat-menu-item-action">
            <reb-ngx-mat-button-icon
              icon="ico-delete"
              kind="text"
              mood="none"
              size="medium"º
              (clickHandler)="updateNotificationStatus($event, notification.id)"
            >
            </reb-ngx-mat-button-icon>
          </div>
        </reb-ngx-mat-menu-item>
      </ng-container>
      <ng-container *ngIf="!isNotificationsReady">
        <reb-ngx-mat-menu-item class="rn-mat-menu-item" *ngFor="let fake of skeleton.generateFake(7)">
          <div id="mat-menu-item-label">
            <label>
              <skeleton-loader [theme]="{'width': '140px'}"></skeleton-loader>
            </label>
            <label>
              <skeleton-loader [theme]="{'width': '120px'}"></skeleton-loader>
            </label>
          </div>
          <div id="mat-menu-item-action">
            <p matLine>
              <skeleton-loader [theme]="{'width': '20px', 'margin': '13px'}"></skeleton-loader>
            </p>
          </div>
        </reb-ngx-mat-menu-item>
      </ng-container>
    </reb-ngx-mat-menu-icon>
    <reb-ngx-mat-menu-icon *ngIf="!hasNotifications" class="notifications" icon="ico-bell" kind="text" mood="none">
      <reb-ngx-mat-menu-item class="rn-mat-menu-item">
        <div id="mat-menu-item-label">
          <label>You are up to date with your notifications!</label>
        </div>
      </reb-ngx-mat-menu-item>
    </reb-ngx-mat-menu-icon>
  </div>
  <div id="company-menu-items-middle">
    <reb-ngx-mat-menu-button-item class="rn-mat-menu-button-item" leftIcon="ico-transfer" [rightIcon]="false" (click)="goToSwitch()">
      <label>Switch Company</label>
    </reb-ngx-mat-menu-button-item>
    <reb-ngx-mat-menu-button-item class="rn-mat-menu-button-item" leftIcon="ico-user" [rightIcon]="false" [routerLink]="['/account']">
      <label>Account</label>
    </reb-ngx-mat-menu-button-item>
    <reb-ngx-mat-menu-button-item class="rn-mat-menu-button-item" leftIcon="ico-settings" [rightIcon]="false" [routerLink]="['/main/settings/advanced']">
      <label>Advanced Settings</label>
    </reb-ngx-mat-menu-button-item>
  </div>
  <div id="company-menu-items-bottom">
    <reb-ngx-mat-menu-button-item class="rn-mat-menu-button-item" leftIcon="ico-log-out" [rightIcon]="false" (click)="signOut()">
      <label>Log Out</label>
    </reb-ngx-mat-menu-button-item>
    <div *ngIf="supportPin" (click)="$event.stopPropagation();$event.preventDefault()" class="support-pin"><span>Support Pin:</span> {{supportPin}}</div>
  </div>
  <div id="company-menu-items-helpdesk">
    <reb-ngx-mat-menu-button-item class="rn-mat-menu-button-item" leftIcon="ico-plus" [rightIcon]="false" (click)="openExternalLink('https://support.rebillia.com/tickets-view/new')">
      <label>Open a Ticket</label>
    </reb-ngx-mat-menu-button-item>
    <reb-ngx-mat-menu-button-item class="rn-mat-menu-button-item" leftIcon="ico-search" [rightIcon]="false" (click)="openExternalLink('https://support.rebillia.com/tickets-view')">
      <label>Check ticket Status</label>
    </reb-ngx-mat-menu-button-item>
    <!--<reb-ngx-mat-menu-button-item class="rn-mat-menu-button-item" leftIcon="ico-customize" [rightIcon]="false" (click)="openExternalLink('https://rebillia.featurebase.app/')">
      <label>Feature Suggestion</label>
    </reb-ngx-mat-menu-button-item>-->
    <reb-ngx-mat-menu-button-item class="rn-mat-menu-button-item" leftIcon="ico-book" [rightIcon]="false" (click)="openExternalLink('https://rebillia.com/resources')">
      <label>Knowledge Base</label>
    </reb-ngx-mat-menu-button-item>
  </div>
</app-global-header>
