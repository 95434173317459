import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharedCustomerService } from 'src/app/_core/services/api';
import { MetaService } from 'src/app/_core/services/meta.service';
import { DialogShippingAddressFormComponent } from '../../dialog/dialog-shipping-address-form/dialog-shipping-address-form.component';
import { RestResponse } from '../../models';
import { CustomerAddressBook } from '../../models/customer.model';
import { Unsubscriber } from '../../unsubscriber/unsubscriber';



@Component({
  selector: 'app-shipping-address-dropdown',
  templateUrl: './shipping-address.component.html',
})
export class ShippingAddressComponent implements OnInit, OnDestroy {

  dialogShippingForm: MatDialogRef<DialogShippingAddressFormComponent>;
  /** It's used to 'save' the previous selected value */
  selectedAddressBkup: any;
  
  private subs = new Unsubscriber();

  /** Shows the option 'add new address'  */
  @Input() allowToCreateShipping?: boolean = false;
  /** All account addresses */
  @Input() collectionOfAddressBook: Array<CustomerAddressBook> = [];
  /** the current Address */
  @Input() selectedAddress: any;
  /** Mandatory If createNewAddress == true */
  @Input() customerId: number = null;
  /** It is used to indicate if the new address will stored */
  @Input() saveNewShippingAddress?: boolean = true;
  /** It is used to indicate if the 'save button' is disabled */
  @Input() saveAddressDisabled?: boolean = true;
  /** emmitter to implement response data on the parent component */
  @Output() emitAddressSelection: EventEmitter<any> = new EventEmitter<any>();
  
  constructor(
    private _dialog: MatDialog,
    private sharedCustomerService: SharedCustomerService,
    public _meta: MetaService,
    ) { }

  onChangeShipToAddress(_selectedValue: any) {
    this.selectedAddress = _selectedValue;

    if (this.selectedAddress === '0') {
      this.showDialogAccountShipping();
    } else {
      this.emitAddressSelection.emit(this.selectedAddress);
    }
  }

  showDialogAccountShipping() {
    this.dialogShippingForm = this._dialog.open(DialogShippingAddressFormComponent, {
      panelClass: ['custom-mat-dialog', 'mat-dialog-new-payment-method'],
      data: {
        isEdit: false,
        customerId: this.customerId,
        saveAddress: this.saveNewShippingAddress,
        saveAddressDisabled: this.saveAddressDisabled,
      },
    });

    this.dialogShippingForm.afterClosed().subscribe((res) => {
      if(!res) {
        this.onChangeShipToAddress(this.selectedAddressBkup);
      } else {
        const addressReturned: CustomerAddressBook = new CustomerAddressBook().deserialize(res?.bodyParams);
        if(res?.isStored) {
          this.getCustomerAddressbook(addressReturned);
        } else {
          this.collectionOfAddressBook.push(addressReturned);
          this.onChangeShipToAddress(addressReturned);
        }
      }
    });
  }

  getAddressFormData() {
    return this.selectedAddress;
  }

  getCustomerAddressbook(_selectedValue) {
    this.subs.addSub = this.sharedCustomerService.getAddressBookByCustomerId(this.customerId)
      .subscribe(
        (res: RestResponse) => {
          this.collectionOfAddressBook =[];
          this.collectionOfAddressBook = <Array<CustomerAddressBook>>res.data;
          const addressSelected = this.collectionOfAddressBook.find(add => add.id == _selectedValue.id);
          this.onChangeShipToAddress(addressSelected);
        },
        (err: RestResponse) => {
          this._meta.showNotification(err);
        }
    );
  }

  ngOnInit() {
    this.selectedAddressBkup = this.selectedAddress;
  }
  
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
