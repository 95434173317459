//angular
import { Injectable } from '@angular/core';
//app
import { DropDown } from './_shared/interfaces/dropdown.interface';

export const DEFAULT_VALUE_CUSTOM_TAX = 'P000000';

export const PaginatorList = [5, 10, 25, 45, 100]

//Global Enum
export enum AddressTypeValues {
  commercial = 'commercial',
  residential = 'residential'
}

export enum AdjustersTypes {
  percentage = 'percentage',
  relative = 'relative'
}

export enum ApiKeyStatus {
    active = 'active',
    disabled = 'disabled'
}
export enum AppClient {
  sandbox= '81o9vat7ipodmcg8qi0joyp22kxabwu',
  production= '7oxpiocnr0owlh35qwio361qsdvd9it',
}
export enum AvalaraSettingKeyNames {
  code_default_taxable = 'codeDefaultTaxable',
  code_gift_wrapping = 'codeGiftWrapping',
  code_handling = 'codeHandling',
  code_nt = 'codeNoTaxable',
  code_shipping = 'codeShipping',
  commit_invoices = 'useTaxReportingAndReturns'
}
export enum AvalaraSettingKeyValues {
  code_non_taxable = 'NT',
}
export enum AvalaraTaxExemptCodes {
  none = 'None',
  federalGovernment = 'A',
  stateGovernment = 'B',
  tribalGovernment = 'C',
  foreignGovernment = 'D',
  charitableOrganization = 'E',
  religiousOrganization = 'F',
  resale = 'G',
  agriculture = 'H',
  industrialManufactrurers = 'I',
  directPay = 'J',
  directMail = 'K',
  educationalOrganization = 'M',
  localGovernment = 'N',
  commercialAquaculture = 'P',
  commercialFishery = 'Q',
  nonResident = 'R',
  taxable = 'Taxable'
}
export enum BigcommerceOrderStatusValues {
  incomplete = 'Incomplete',
  pending = 'Pending',
  awaitingPayment = 'Awaiting Payment',
  awaitingFulfillment = 'Awaiting Fulfillment',
  awaitingShipment = 'Awaiting Shipment',
  awaitingPickup = 'Awaiting Pickup',
  partiallyShipped = 'Partially Shipped',
  completed = 'Completed',
  shipped = 'Shipped',
  cancelled = 'Cancelled',
  declined = 'Declined',
  refunded = 'Refunded',
  disputed = 'Disputed',
  manualVerificationRequired = 'Manual Verification Required',
  partiallyRefunded = 'Partially Refunded'
}
export enum BigcommerceSettingKeyname {
  allowToggleShippingProtection = 'allowToggleShippingProtection',
  allowCustomerCancelSubscription = 'allowCustomerCancelSubscription',
  allowCustomerChangeChargeProduct = 'allowCustomerChangeChargeProduct',
  allowCustomerChangeChargeProductQuantity = 'allowCustomerChangeChargeProductQuantity',
  allowCustomerChangeSubscriptionRatePlan = 'allowCustomerChangeSubscriptionRatePlan',
  allowCustomerCreateSubscriptionBag = 'allowCustomerCreateSubscriptionBag',
  allowCustomerListPaymentMethods = 'allowCustomerListPaymentMethods',
  allowCustomerListSubscription = 'allowCustomerListSubscription',
  allowCustomerListUpcomingCharges = 'allowCustomerListUpcomingCharges',
  allowCustomerManageSubscriptionBag = 'allowCustomerManageSubscriptionBag',
  allowCustomerReScheduleSubscription = 'allowCustomerReScheduleSubscription',
  allowCustomerReScheduleSubscriptionToBack = 'allowCustomerReScheduleSubscriptionToBack',
  allowCustomerSkipUpcomingCharge = 'allowCustomerSkipUpcomingCharge',
  allowCustomerUpdateSubscriptionDetail = 'allowCustomerUpdateSubscriptionDetail',
  allowCustomerUpdateSubscriptionPaymentMethod = 'allowCustomerUpdateSubscriptionPaymentMethod',
  allowCustomerUpdateSubscriptionShippingMethod = 'allowCustomerUpdateSubscriptionShippingMethod',
  allowCustomerUpdateSubscriptionShipTo = 'allowCustomerUpdateSubscriptionShipTo',
  allowCustomerToPauseSubscription = 'allowCustomerToPauseSubscription',
  apiVersion = 'apiVersion',
  appClientId = 'appClientId',
  autoArrangeCustomerAccountNavigation = 'autoArrangeCustomerAccountNavigation',
  autoRemoveOtherPaymentOptions = 'autoRemoveOtherPaymentOptions',
  cartHasSubscriptionMessage = 'cartHasSubscriptionMessage',
  createCustomer = 'createCustomer',
  customerGroups = 'customerGroups',
  customerPaymentMethodDisplayName = 'customerPaymentMethodDisplayName',
  customerSubscriptionDisplayName = 'customerSubscriptionDisplayName',
  customerUpcomingDisplayName = 'customerUpcomingDisplayName',
  enableCheckout = 'enableCheckout',
  enableCheckoutForAllTransactions = 'enableCheckoutForAllTransactions',
  enableCustomerAddToMySubscriptionButton = 'enableCustomerAddToMySubscriptionButton',
  enableCustomerPaymentMethodNavigation = 'enableCustomerPaymentMethodNavigation',
  enableCustomerSubscriptionNavigation = 'enableCustomerSubscriptionNavigation',
  enableCustomerUpcomingNavigation = 'enableCustomerUpcomingNavigation',
  environment = 'environment',
  firstCycleBillForOneTimeOnCheckout = 'firstCycleBillForOneTimeOnCheckout',
  firstCycleBillForRecurringOnCheckout = 'firstCycleBillForRecurringOnCheckout',
  generateOrder = 'generateOrder',
  orderDetail = 'orderDetail',
  orderStatusCanceled = 'canceled',
  orderStatusPaid = 'paid',
  orderStatusPartialPaid = 'partialPaid',
  orderStatusAuthorized = 'authorized',
  orderStatusPartialRefund = 'partialRefund',
  orderStatusPaymentDue = 'paymentDue',
  orderStatusPosted = 'posted',
  orderStatusRefund = 'refund',
  orderStatusRequestPayment = 'requestPayment',
  orderStatusRequiredAuthorization = 'requiredAuthorization',
  orderStatusVoided = 'voided',
  paymentGateways = 'paymentGateways',
  shippingAmount = 'shippingAmount',
  shippingCompanyIntegrationId = 'shippingCompanyIntegrationId',
  shippingDefault = 'shippingDefault',
  staffMessage = 'staffMessage',
  staffMessageText = 'staffMessageText',
  taxAmount = 'taxAmount',
  taxCompanyIntegrationId = 'taxCompanyIntegrationId',
  taxDefault = 'taxDefault',
  displayChargeRulePrepaidLoyaltyCycleData = 'displayChargeRulePrepaidLoyaltyCycleData',
  displayChargeRulePrepaidLoyaltyRenewalData = 'displayChargeRulePrepaidLoyaltyRenewalData',
  displayChargeNow = 'displayChargeNow',
  allowCustomerCancelPlanCharge = 'allowCustomerCancelPlanCharge',
  openedViewPlanDetailsByDefault = 'openedViewPlanDetailsByDefault',
  groupRateplansInOneSubscription = 'groupRateplansInOneSubscription',
  taxInclusive = 'taxInclusive',
  displayLatestSubscriptionAtTheTop = 'displayLatestSubscriptionAtTheTop',
  allowToAddProductsToSubscriptionBag = 'allowToAddProductsToSubscriptionBag',
  enableGiftItemControls = 'enableGiftItemControls',
  paidWhenSourceIsCheckout = 'paidWhenSourceIsCheckout',
  paidWhenSourceIsRecurring = 'paidWhenSourceIsRecurring',
  useSalePrice = 'useSalePrice',
  useGroupRateplansForCheckout = 'useGroupRateplansForCheckout',
  shippingZonePaymentMethods = 'shippingZonePaymentMethods'

}
export enum BillCycleTypeValues {
  subscriptionStartDay = 'subscriptionStartDay',
  specificDayOfMonth = 'specificDayOfMonth',
  specificDayOfWeek = 'specificDayOfWeek',
  specificDayOfYear = 'specificDayOfYear',
  specificMonthOfYear = 'specificMonthOfYear',
  subscriptionFreeTrial = "subscriptionFreeTrial"
}
export enum UpcomingRateplanChargeActions {
  skip = 'skip',
  price = 'price',
  payment_method = 'payment_method',
  shipping_address = 'shipping_address',
  shipping_price = "shipping_price"
}
export enum BillingPeriodAlignmentValues {
  alignToCharge = 'alignToCharge',
  alignToSubscriptionStart = 'alignToSubscriptionStart',
  alignToTermStart = 'alignToTermStart'
}
export enum BillingPeriodValues {
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  annual = 'annual',
  semiAnnual = 'semiAnnual',
  specificMonths = 'specificMonths',
  specificWeeks = 'specificWeeks',
  custom = 'custom'
}
export enum BillingRunStatus {
  pending = 'pending',
  processing = 'processing',
  completed = 'completed',
  completedWithErrors = 'completedWithErrors',
  error = 'error'
}
export enum BillingRunRepeater {
  repeaterDaily = 'daily',
  repeaterWeekly = 'weekly',
  repeaterMonthly = 'monthly',
  repeaterYearly = 'yearly'
}
export enum BillingPeriodsValues {
  days = 'day',
  months = 'month',
  weeks = 'week',
  years = 'year'
}
export enum BillingTimingValues {
  inAdvance = 'inAdvance',
  inArrears = 'inArrears'
}
export enum ChargeStartingDateOptions {
  immediately = 'immediately',
  schedule = 'schedule',
  custom = 'custom',
  subscriptionFreeTrial = 'subscriptionFreeTrial'
}
export enum CustomerGroupTypeValues {
  all = 'all',
  custom = 'custom'
}
export enum CustomerType {
  customer = 'customer',
  partner = 'partner',
  prospect = 'prospect',
  reseller = 'reseller'
}
export enum ChargeCreditType {
    charge = 'charge',
    credit = 'credit'
}
export enum ChargeModelValues {
  discountFixedAmount = 'discountFixedAmount',
  discountPercentage = 'discountPercentage',
  flatFeePricing = 'flatFeePricing',
  perUnitPricing = 'perUnitPricing',
  overagePricing = 'overagePricing',
  tieredPricing = 'tieredPricing',
  tieredWithOveragePricing = 'tieredWithOveragePricing',
  volumePricing = 'volumePricing'
}
export enum ChargeCreditStatus {
    assigned = 'assigned',
    unassigned = 'unassigned'
}
export enum ChargeType {
  oneTimeCharge = 'oneTime',
  recurringCharge = 'recurring',
  usageCharge = 'usage'
}
export enum ChargeRulesPrepaidAction {
  add = 'add',
  remove = 'remove',
  fixed = 'fixed'
}
export enum ChargePrepaidRulesAmountTypes {
  price = 'price',
  percentage = 'percent'
}
export enum ChargeCutOffRulesType {
  date = 'date',
  day = 'day'
}
export enum ChargePrepaidRulesEvents {
  invoicePosted = 'invoices_posted'
}
export enum ChargePrepaidRulesCountType {
  every = 'every',
}
export enum ChargeRulesLoyaltyAction {
  remove = 'remove',
  fixed = 'fixed'
}
export enum ChargeLoyaltyRulesAmountTypes {
  price = 'price',
  percentage = 'percent'
}
export enum ChargeLoyaltyRulesEvents {
  invoicePosted = 'invoices_posted'
}
export enum ChargeLoyaltyRulesCountType {
  after ='after',
  every = 'every',
}
export enum CheckboxState {
    checked = 'checked',
    uncheck = 'uncheck'
}

export interface RblOrder {
  id: number;
  company: Company;
  dateFrom: string;
  dateTo: string;
  rblOrderDetails: RblOrderDetail[];
  status: string;
  total: any;
  createdAt: string;
  updatedAt: string;
}

export interface RblOrderDetail {
  id: number;
  dateFrom: string;
  dateTo: string;
  description: string;
  price: any;
  overridePrice: any;
  quantity: number;
  rblPricingConcept: RblPricingConcept;
  subTotal: number;
  createdAt: string;
  updatedAt: string;
}

interface RblPricingConcept {
  id: number;
  calculatorKeyName: string;
  description: string;
  frequency: string;
  name: string;
  price: string;
  quantity: number;
  status: string;
}

interface Company {
  address: Address;
  supportPin: string;
  cancelledAt?: any;
  email: string;
  estimatedAnnualRevenue: string;
  id: number;
  industry: Industry;
  name: string;
  phoneExt: string;
  phoneNum: string;
  taxId: string;
  website: string;
  workerSize: number;
}

interface Industry {
  id: number;
  name: string;
}

interface Address {
  city: string;
  country: Country;
  state: string;
  street1: string;
  street2?: any;
  zip: string;
}

interface Country {
  id: number;
  name: string;
  alpha2Code: string;
  alpha3Code: string;
  numericCode: number;
  flag: string;
}

export enum CompanyStatus {
  active = 'active',
  pending = 'pending',
  closed = 'closed',
  deactivated = 'deactivated',
  uninstalled = 'uninstalled',
  blocked = 'blocked',
}

export enum CompanySettingsKey {
  capturePayment = 'capturePayment',
  currencyDefault = 'currencyDefault',
  decimalPlaces = 'decimalPlaces',
  decimalToken = 'decimalToken',
  displayDateFormat = 'displayDateFormat',
  dstCorrection = 'dstCorrection',
  dunningConfigAuto = 'dunningConfigAuto',
  dunningConfigManual = 'dunningConfigManual',
  environment = 'environment',
  extendedDisplayDateFormat = 'extendedDisplayDateFormat',
  factoringDimension = 'factoringDimension',
  invoiceNumber = 'invoiceNumber',
  lengthMeasurement = 'lengthMeasurement',
  paymentMethodDefault = 'paymentMethodDefault',
  paymentMethodBackup = 'paymentMethodBackup',
  shipping = 'shipping',
  smtpServerAddress = 'smtpServerAddress',
  smtpUserName = 'smtpUserName',
  smtpPassword = 'smtpPassword',
  smtpPortNumber = 'smtpPortNumber',
  smtpEncryptionType = 'smtpEncryptionType',
  smtpAuthMode = 'smtpAuthMode',
  tax = 'tax',
  thousandsToken = 'thousandsToken',
  timezone = 'timezone',
  weightMeasurement = 'weightMeasurement'
}
export enum CompanyNotificationChannelStatus {
  active = 'active',
  disabled = 'disabled',
}

export enum CompanyInvoiceStatus {
  active = 'active',
  close = 'close',
  pending = 'pending',
}

export enum CompanyNotificationChannelType {
  html = 'html',
  text = 'text'
}
export enum CustomerStatus {
  active = 'active',
  disabled = 'disabled',
  archived = 'archived',
}
export enum LeadsStatus {
  active = 'active',
  pending = 'pending',
  closed = 'closed',
}
export enum PurchaseHistoryStatus {
  active = 'active',
  pending = 'pending',
  closed = 'closed',
}
export enum DateFormatValues {
  YYYY_MM_DD = 'YYYY-MM-DD',
  MMM_DD_YYYY = 'MMM DD, YYYY',
  ddd_DD_MMM_YYYY = 'ddd DD MMM, YYYY',
  dddd_DD_MMM_YYYY = 'dddd DD MMM, YYYY',
  ddd_DD_MMM_YYYY_HH_mm_ss = 'ddd DD MMM, YYYY HH:mm:ss z ZZ',
  HH_mm = 'HH:mm',
  hh_mm_ss_a = 'hh:mm:ss A',
  hh_mm_ss_a_z_zz = 'hh:mm:ss A z ZZ',
  hh_mm_ss_a_zz = 'hh:mm:ss A ZZ',
}
export enum DayOfWeek {
  sunday = 0,
  monday = 1,
  tuesday = 2,
  wednesday = 3,
  thursday = 4,
  friday = 5,
  saturday = 6
}
export enum DunningCycleActions {
  stop_cancel = 'stop_cancel',
  stop = 'stop',
  retry = 'retry',
}
export enum EmailNotificationType {
  customers = 'customers',
  invoices = 'invoices',
  revenue_recovery = 'revenue_recovery',
  subscription = 'subscription'
}
export enum EmailNotificationStatus {
  active = 'active',
  disabled = 'disabled',
}
export enum EmptyCollectionMessage {
  emptyChargeAndCreditMessage = 'No manual charges found.',
  emptyExternalCharges = 'No External charges found.',
  emptyInvoicesMessage = 'No Invoices found.',
  emptyTransactionMessage = 'No Transactions found.',
}
export enum EndDateConditionValues {
  fixedPeriod = 'fixedPeriod',
  subscriptionEnd = 'subscriptionEnd'
}
export enum EnvironmentValues {
  dev = 'dev',
  production = 'production',
  sandbox = 'sandbox'
}
export enum EnvironmentNames {
  dev = 'DEV',
  local = 'LOCAL',
  prod = 'PROD',
  qa = 'QA',
  sandbox= 'SANDBOX'
}
export enum HashToCreateAccount {
  hashUsed = '7E994F890F9AB11BC7787A9AD5C5E4A5348B7DA4'
}
export enum responseCode {
  errorBadRequestCode = 400,
  errorServerCode = 500,
  successCode = 200,
  successCodeWithError = 204
}
export enum ExternalInvoicesStatus {
  error = 'error',
  pending = 'pending',
  success = 'success'
}
export enum EventsGridColumns {
  eventsInfo = 'eventsInfo',
  eventsUser = 'eventsUser',
  eventsCreatedAt = 'eventsCreatedAt',
  eventsSource = 'eventsSource',
  eventsLevel = 'eventsLevel',
}
export enum FactoringDimension {
  productDepth = 'productDepth',
  productHeight = 'productHeight',
  productWidth = 'productWidth'
}
export enum FieldTypes {
  checkbox = 'checkbox',
  country = 'country',
  dropdown = 'dropdown',
  input = 'input',
  multiSelect = 'multiSelect',
  select = 'select'
}
export enum FilterSections {
  creditNotes = 'creditNotes',
  companyLogs = 'companyLogs',
  customers = 'customers',
  customerLogs = 'customerLogs',
  externalInvoices = 'externalInvoices',
  invoices = 'invoices',
  transactions = 'transactions',
  orders = 'orders',
  products = 'products',
  plans = 'plans',
  subscriptions = 'subscriptions',
  internalCompanyRequests = 'internalCompanyRequests',
  upsCapitalShipping = 'upsCapitalShipping'
}
export enum FrequencyUnitValues {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
  quarter = 'quarter',
  semiAnnual = 'semiAnnual',
  custom = 'custom'
}
export enum GatewayType {
  offlinePaymentProvider = 'offlinePaymentProvider',
  requestPayment = 'requestPayment',
  thirdPartyPaymentProvider = 'thirdPartyPaymentProvider',
  walletPaymentProvider = 'walletPaymentProvider',
}
export enum GlobalSettings {
    currencyDefault = 'currencyDefault',
    paymentMethodPrimary = 'paymentMethodPrimary',
    paymentMethodBackup = 'paymentMethodBackup',
}
export enum GatewayCode {
    authorize = 'authorize',
    braintree = 'braintree',
    square = 'square',
    stripe = 'stripe',
    usaepay = 'usaepay',
    cybersource = 'cybersource',
    paypal = 'paypal'
}
export enum GatewayUrls {
  urlAuthorizeAccept = 'https://jstest.authorize.net/v1/Accept.js',
  urlBraintreeClient = 'https://js.braintreegateway.com/web/3.81.0/js/client.min.js',
  urlBraintreeHostedFields = 'https://js.braintreegateway.com/web/3.81.0/js/hosted-fields.min.js',
  urlBraintreeDataCollector = 'https://js.braintreegateway.com/web/3.81.0/js/data-collector.min.js',
  urlStripe = 'https://js.stripe.com/v3/',
  urlSquare = 'https://sandbox.web.squarecdn.com/v1/square.js',
  urlUsaepay = 'https://sandbox.usaepay.com/js/v2/pay.js',

  urlAuthorizeAcceptProd = 'https://js.authorize.net/v1/Accept.js',
  urlBraintreeClientProd = 'https://js.braintreegateway.com/web/3.81.0/js/client.min.js',
  urlBraintreeHostedFieldsProd = 'https://js.braintreegateway.com/web/3.81.0/js/hosted-fields.min.js',
  urlCybersourceHostedFields = 'https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js',
  urlCybersourceHostedFieldsProd = 'https://flex.cybersource.com/microform/bundle/v2/flex-microform.min.js"',
  urlBraintreeDataCollectorProd = 'https://js.braintreegateway.com/web/3.81.0/js/data-collector.min.js',
  urlStripeProd = 'https://js.stripe.com/v3/',
  urlSquareProd = 'https://web.squarecdn.com/v1/square.js',
  urlUsaepayProd = 'https://www.usaepay.com/js/v2/pay.js',

}
export enum GatewayConnectionType {
    credentials = 'credentials',
    oauth = 'oauth',
}
export enum GatewayStatus {
  active = 'active',
  archive = 'archive',
  disabled = 'disabled',
  error = 'error'
}
export enum GridColumnsBillingOperations {
  billingOperationsCompletedOn = 'billingOperationsCompletedOn',
  billingOperationsCreatedAt = 'billingOperationsCreatedAt',
  billingOperationsExecutedOn = 'billingOperationsExecutedOn',
  billingOperationsInvoiceCount = 'billingOperationsInvoiceCount',
  billingOperationsRepeatType = 'billingOperationsRepeatType',
  billingOperationsSubscriptionCount = 'billingOperationsSubscriptionCount',
  billingOperationsStatus = 'billingOperationsStatus',
  billingOperationsType = 'billingOperationsType',
  billingOperationsUpdatedAt = 'billingOperationsUpdatedAt'
}
export enum GridColumnsCustomer {
  customerInfo = 'customerInfo',
  customerLastInvoice = 'customerLastInvoice',
  customerSubscriptions = 'customerSubscriptions',
  customerBalanceDue = 'customerBalanceDue',
  customerPaymentNet = 'customerPaymentNet',
  customerAddress = 'customerAddress',
  customerPayments = 'customerPayments',
  customerChannel = 'customerChannel',
  customerBusinessName = 'customerBusinessName',
  customerSupportPin = 'customerSupportPin',
}
export enum GridColumnsLeads {
  internalCompanyRequestsName = 'internalCompanyRequestsName',
  internalCompanyRequestsEmail = 'internalCompanyRequestsEmail',
  internalCompanyRequestsPhone = 'internalCompanyRequestsPhone',
  internalCompanyRequestsWebsite = 'internalCompanyRequestsWebsite',
  internalCompanyRequestsCreatedAt = 'internalCompanyRequestsCreatedAt',
}
export enum GridColumnsPurchaseHistory {
  upsCapitalShippingInvoiceId = 'upsCapitalShippingInvoiceId',
  upsCapitalShippingCustomer = 'upsCapitalShippingCustomer',
  upsCapitalShippingPrice = 'upsCapitalShippingPrice',
  upsCapitalShippingCreatedAt = 'upsCapitalShippingCreatedAt',
}
export enum GridColumnsCustomerCharges {
  customerChargesAmount = 'customerChargesAmount',
  customerChargesCategory = 'customerChargesCategory',
  customerChargesCreatedAt = 'customerChargesCreatedAt',
  customerChargesDescription = 'customerChargesDescription',
  customerChargesQty = 'customerChargesQty',
  customerChargesTaxable = 'customerChargesTaxable',
  customerChargesTotal = 'customerChargesTotal',
}
export enum GridColumnsCustomerSubscriptions {
  customerSubscriptionInfo = 'customerSubscriptionInfo',
  customerSubscriptionCreatedAt = 'customerSubscriptionCreatedAt',
  customerSubscriptionLastCharge = 'customerSubscriptionLastCharge',
  customerSubscriptionNextCharge = 'customerSubscriptionNextCharge',
  customerSubscriptionPayment = 'customerSubscriptionPayment',
  customerSubscriptionShippingAddress = 'customerSubscriptionShippingAddress',
  customerSubscriptionSource = 'customerSubscriptionSource',
  customerSubscriptionLinkedWith = 'customerSubscriptionLinkedWith'
}
export enum GridColumnExternalOrders {
  externalOrdersAddress = 'externalOrdersAddress',
  externalOrdersAssignTo = 'externalOrdersAssignTo',
  externalOrdersCustomerInfo = 'externalOrdersCustomerInfo',
  externalOrdersOrderInfo = 'externalOrdersOrderInfo',
  externalOrdersStatus = 'externalOrdersStatus'
}
export enum GridColumnExternalInvoices {
  externalInvoiceInvoice = 'externalInvoiceInvoice',
  externalInvoiceOrder = 'externalInvoiceOrder',
  externalInvoiceCustomer = 'externalInvoiceCustomer',
  externalInvoiceTotal = 'externalInvoiceTotal',
  externalInvoiceSubscriptionBags = 'externalInvoiceSubscriptionBags'
}
export enum GridColumnsInvoices {
  invoiceInfo = 'invoiceInfo',
  invoiceCustomer = 'invoiceCustomer',
  invoiceDueOn = 'invoiceDueOn',
  invoiceShipping = 'invoiceShipping',
  invoiceTax = 'invoiceTax',
  invoiceTotal = 'invoiceTotal',
  invoiceTransactions = 'invoiceTransactions',
  invoicesBusinessName = 'invoicesBusinessName'
}
export enum GridColumnsProducts {
  productChannel = 'productChannel',
  productEffective = 'productEffective',
  productInfo = 'productInfo',
  productSKU = 'productSKU'
}
export enum GridColumnsProductsRatePlans {
  productRatePlanName = 'productRatePlanName',
  productRatePlanCharges = 'productRatePlanCharges',
  productRatePlanSKU = 'productRatePlanSKU',
  productRatePlanEffective = 'productRatePlanEffective',
  productRatePlanOneTimeCharges = 'productRatePlanOneTimeCharges',
  productRatePlanMRR = 'productRatePlanMRR',
  productRatePlanUsageCharges = 'productRatePlanUsageCharges'
}

export enum GridColumnsSection {
  customers = 'customers',
  internalCompanyRequests = 'internalCompanyRequests',
  customerCharges = 'customerCharges',
  externalInvoices = 'externalInvoices',
  invoices = 'invoices',
  product = 'products',
  productRatePlans = 'productRatePlans',
  subscriptions = 'subscriptions',
  subscriptionRatePlans = 'subscriptionRatePlans',
  transactions = 'transactions',
  importerProductTask = 'importerProductTask',
  importerProductSubTask = 'importerProductSubTask',
  historyRatePlan = 'historyRatePlan',
  upsCapitalShipping = 'upsCapitalShipping'
}
export enum GridColumnsSubscriptions {
  subscriptionsSubscriptionInfo = 'subscriptionsSubscriptionInfo',
  subscriptionsCustomerInfo = 'subscriptionsCustomerInfo',
  subscriptionsCreatedAt = 'subscriptionsCreatedAt',
  subscriptionsLastCharge = 'subscriptionsLastCharge',
  subscriptionsNextCharge = 'subscriptionsNextCharge',
  subscriptionsPayment = 'subscriptionsPayment',
  subscriptionsShippingAddress = 'subscriptionsShippingAddress',
  subscriptionsSource = 'subscriptionsSource',
  subscriptionsLinkedWith = 'subscriptionsLinkedWith',
  subscriptionsBusinessName = 'subscriptionsBusinessName'
}

export enum GridColumnsListProductSubscriptions {
  subscriptionsSubscriptionInfo = 'subscriptionsSubscriptionInfo',
  subscriptionsCustomerInfo = 'subscriptionsCustomerInfo',
  subscriptionsCreatedAt = 'subscriptionsCreatedAt',
  subscriptionsLastCharge = 'subscriptionsLastCharge',
  subscriptionsNextCharge = 'subscriptionsNextCharge',
  subscriptionsPayment = 'subscriptionsPayment',
  subscriptionsShippingAddress = 'subscriptionsShippingAddress',
  subscriptionsSource = 'subscriptionsSource',
  subscriptionsLinkedWith = 'subscriptionsLinkedWith',
  subscriptionsLtv = 'subscriptionsLtv',
  subscriptionsPaidInvoices = 'subscriptionsPaidInvoices',
  subscriptionsBusinessName = 'subscriptionsBusinessName'
}

export enum GridColumnsCompanyInvoices {
  companyInvoiceTotal = 'companyInvoiceTotal',
  companyInvoiceNumber = 'companyInvoiceNumber',
  companyInvoicePayment = 'companyInvoicePayment',
  companyInvoiceDueDate = 'companyInvoiceDueDate',
  companyInvoiceStatus = 'companyInvoiceStatus',

}

export enum GridColumnsHistoryRatePlans {
  historyRatePlanName = 'historyRatePlanName',
  historyRatePlanStartedAt = 'historyRatePlanStartedAt',
  historyRatePlanEndedAt = 'historyRatePlanEndedAt',
  historyRatePlanUserId = 'historyRatePlanUserId'
}

export enum GridColumnsSubscriptionRatePlans {
  subscriptionRatePlansName = 'subscriptionRatePlansName',
  subscriptionRatePlansCharges = 'subscriptionRatePlansName',
  subscriptionRatePlansSKU = 'subscriptionRatePlansSKU',
  subscriptionRatePlansOneTimeCharges = 'subscriptionRatePlansOneTimeCharges',
  subscriptionRatePlansMRR = 'subscriptionRatePlansMRR',
  subscriptionRatePlansUsageCharges = 'subscriptionRatePlansUsageCharges'
}
export enum GridColumnsSubscriptionInvoices {
  subscriptionInvoicesInfo = 'subscriptionInvoicesInfo',
  subscriptionInvoicesAutoCollection = 'subscriptionInvoicesAutoCollection',
  subscriptionInvoicesBillTo = 'subscriptionInvoicesBillTo',
  subscriptionInvoicesCustomerInfo = 'subscriptionInvoicesCustomerInfo',
  subscriptionInvoicesDateDue = 'subscriptionInvoicesDateDue',
  subscriptionInvoicesIssuedOn = 'subscriptionInvoicesIssuedOn',
  subscriptionInvoicesPaidOn = 'subscriptionInvoicesPaidOn',
  subscriptionInvoicesPaymentRetry = 'subscriptionInvoicesPaymentRetry',
  subscriptionInvoicesShipTo = 'subscriptionInvoicesShipTo',
  subscriptionInvoicesStatus = 'subscriptionInvoicesStatus',
  subscriptionInvoicesVoidedOn = 'subscriptionInvoicesVoidedOn'
}
export enum GridColumnsSubscriptionLogs {
  subscriptionLogsInfo = 'subscriptionLogsInfo',
  subscriptionLogsUser = 'subscriptionLogsUser',
  subscriptionLogsCreatedAt = 'subscriptionLogsCreatedAt',
  subscriptionLogsSource = 'subscriptionLogsSource',
  subscriptionLogsLevel = 'subscriptionLogsLevel',
  subscriptionLogsType = 'subscriptionLogsType',
}
export enum GridColumnsSubscriptionTimeLine {
  subscriptionTimelineDate = 'subscriptionTimelineDate',
  subscriptionTimelineRatePlans = 'subscriptionTimelineRatePlans',
  subscriptionTimelinePaymentMethod = 'subscriptionTimelinePaymentMethod',
  subscriptionTimelineSubTotal = 'subscriptionTimelineSubTotal',
  subscriptionTimelineShipping = 'subscriptionTimelineShipping',
  subscriptionTimelineTotal = 'subscriptionTimelineTotal',
}
export enum GridColumnsTransactions {
  transactionTransactionInfo = 'transactionTransactionInfo',
  transactionCreatedAt = 'transactionCreatedAt',
  transactionKind = 'transactionKind',
  transactionType = 'transactionType',
  transactionPayment = 'transactionPayment',
  transactionAccount = 'transactionAccount',
  transactionGateway = 'transactionGateway',
  transactionsBusinessName = 'transactionsBusinessName'
}
export enum GridColumnsUpcomingCharges {
  upcomingChargeName = 'upcomingChargeName',
  upcomingChargeProductName = 'upcomingChargeProductName',
  upcomingChargeThroughtDate = 'upcomingChargeThroughtDate',
  upcomingChargePrice = 'upcomingChargePrice',
  upcomingChargeExternalProduct = 'upcomingChargeExternalProduct',
  upcomingChargeQuantity = 'upcomingChargeQuantity',
}

export enum GridColumnsImporterProductTask {
  importerProductTaskIntegration = 'importerProductTaskIntegration',
  importerProductTaskCreatedAt = 'importerProductTaskCreatedAt',
  importerProductTaskStatus = 'importerProductTaskStatus',
  importerProductTaskUpdatedAt = 'importerProductTaskUpdatedAt',
  importerProductTaskSubtask = 'importerProductTaskSubtask',
  importerProductTaskSuccess = 'importerProductTaskSuccess',
  importerProductTaskError = 'importerProductTaskError',
  importerProductTaskPending = 'importerProductTaskPending',
}
export enum GridColumnsImporterProductSubTask {
  importerProductSubTaskStatus = 'importerProductSubTaskStatus',
  importerProductSubTaskCreatedAt = 'importerProductSubTaskCreatedAt',
  importerProductSubTaskUpdatedAt = 'importerProductSubTaskUpdatedAt',
  importerProductSubTaskExternalProductId = 'importerProductSubTaskExternalProductId',
}
export enum GridFiltersSection {
  billRuns = 'billRuns',
  companyInbox = 'companyInbox',
  companyLogs = 'companyLogs',
  companyNotifications = 'companyNotifications',
  creditNotes = 'creditNotes',
  customers = 'customers',
  externalInvoices = 'externalInvoices',
  externalOrders = 'externalOrders',
  invoices = 'invoices',
  orders = 'orders',
  paymentRuns = 'paymentRuns',
  products = 'products',
  productRatePlans = 'productRatePlans',
  subscriptions = 'subscriptions',
  subscriptionRatePlan = 'subscriptionRatePlan',
  transactions = 'transactions',
  importerProductTask = 'importerProductTask',
  historyRatePlan = 'historyRatePlan',
}
export enum HoverState {
    in = 'in',
    out = 'out'
}
export enum IdleUserTimes {
  idleTime = 1800, //idle/Inactive Time in seconds
  timeOut = 60 //Amount of time (in seconds) that idle dialog is shown until logout the user if not response is made.
}
export enum ImportStatuses {
  archived = 'archived',
  excluded = 'excluded',
  forReview = 'forReview',
  matched = 'matched'
}
export enum IncludeParam {
  addressbook = 'addressbook',
  chargeTier = 'chargeTier',
  detail = 'detail',
  externalCustomers = 'externalCustomers',
  externalProduct = 'externalProduct',
  logs = 'logs',
  lastBillRun = 'lastBillRun',
  lastInvoice = 'lastInvoice',
  paymentmethod = 'paymentmethod',
  ratePlan = 'ratePlan',
  salesChannels = 'salesChannels',
  subscriptions = 'subscriptions',
  transactions = 'transactions',
  unpaidInvoices = 'unpaidInvoices',
  section = 'section',
  externalRatePlanCharges = 'externalRatePlanCharges',
  ratePlanCharge = 'ratePlanCharge',
  companyIntegrationId = 'companyIntegrationId',
  startDate = 'startDate',
  endDate = 'endDate',
  currency_ISO3 = 'currency_ISO3',
  productRateplan = 'productRateplan',
  ratePlansCounts = 'ratePlansCounts',
  productRatePlanCharge = 'productRatePlanCharge'

}
export enum IntegrationKey {
  authToken = 'authToken',
  domain = 'domain',
  clientId = 'clientId',
  clientSecret = 'clientSecret',
  controlPanelBaseUrl = 'controlPanelBaseUrl',
  logo = 'logo',
  storeHash = 'storeHash',
  secureUrl = 'secureUrl'
}
export enum IntegrationKeyName {
  avalara = 'avalara',
  bigcommerce = 'bigcommerce',
  oneSource = 'oneSource',
  salesforce = 'salesforce',
  shopify = 'shopify',
  shipstation = 'shipstation',
  taxamo = 'taxamo',
  ups = 'ups',
  vertex = 'vertex',
  google = 'google',
  smtp = 'smtp',
  slack = 'slack',
  shipperHq = 'shipperHq'
}
export enum IntegrationStatus {
  active = 'active',
  disabled = 'disabled',
  pending = 'pending',
  archived = 'archived'
}
export enum IntegrationFields {
  accountNumber = 'accountNumber'
}
export enum InvoiceStatus {
  canceled = 'canceled',
  draft = 'draft',
  paid = 'paid',
  partialPaid = 'partialPaid',
  partialRefund = 'partialRefund',
  paymentDue = 'paymentDue',
  pending = 'pending',
  posted = 'posted',
  refund = 'refund',
  requestPayment = 'requestPayment',
  requiredAuthorization = 'requiredAuthorization',
  voided = 'voided',
  awaitingForSettlement = 'awaitingForSettlement',
  authorized = 'authorized'
}
export enum InvoiceTerms {
    onReceipt = 'onReceipt',
    net10 = 'net10',
    net30 = 'net30',
    net60 = 'net60',
    custom = 'custom'
}
export enum JSVersions {
  v6 = '6.0.0',
  v601 = '6.0.1',
  v610 = '6.1.0'
}
export enum LengthMeasurament {
  centimeters = 'centimeters',
  inches = 'inches'
}
export enum LoadingState {
    isLoading = 'isLoading',
    isLoaded = 'isLoaded'
}
export enum LogSourceValues {
  bigcommerceCheckout = 'bigcommerceCheckout',
  billRun = 'BillRun',
  paymentRun = 'PaymentRun'
}
export enum LogStatus {
  error = 'error',
  info = 'info',
  warning = 'warning'
}
export enum NotificationChannels {
  customerGoogle = 'customerGoogle',
  merchantEmail = 'merchantEmail',
  customerSmtp = 'customerSmtp',
  merchantSmtp = 'merchantSmtp',
  technicalSlack = 'technicalSlack',
  companyNotificationInbox = 'companyNotificationInbox',
}
export enum NotificationSectionValues {
  companyNotifications = 'company',
  customNotifications = 'customNotifications',
  customerOnBoardingEmails = 'onBoarding',
  customerPaymentMethods = 'paymentMethod',
  onBoardingEmails = 'onboardingEmails',
  dunningForOnlinePayments = 'onlineDunning',
  dunningForOfflinePayments = 'offlineDunning',
  futureSubscriptionReminders = 'futureSubscriptionReminders',
  invalidPaymentMethods = 'invalidPaymentMethods',
  invoiceReceipt = 'invoices',
  onBoardingNotifications = 'onBoardingNotifications',
  paymentsAndCredits = 'payments',
  paymentMethod = 'paymentMethod',
  paymentCollect = 'paymentCollect',
  selfServePortalAccountManagement = 'selfServePortalAccountManagement',
  standardNotifications = 'standardNotifications',
  subscriptionActivation = 'subscriptionActivation',
  subscriptionCancellations = 'subscriptionCancellations',
  subscriptionChanges = 'subscriptionChanges',
  subscriptionRenewalReminders = 'subscriptionRenewalReminders',
  automaticProcess = 'automaticProcess',
}
export enum NotificationSectionType {
  synchronous = 'synchronous'
}
export enum OpacityState {
    isHidden = 'isHidden',
    isVisible = 'isVisible'
}
export enum PanelStatusState {
    isHidden = 'isHidden',
    isVisible = 'isVisible'
}
export enum PaymentAccountKind {
    business = 'business',
    credit = 'credit',
    debit = 'debit',
    personal = 'personal',
    unknown = 'unknown'
}
export enum PaymentAccountType {
  card = 'card',
  checking = 'checking',
  savings = 'savings',
  unknown = 'unknown',
  alipay = 'alipay',
  americanexpress = 'americanexpress',
  americanexpress2 = 'american express',
  american_express = 'american_express',
  AmericanExpress = 'AmericanExpress',
  amex = 'amex',
  diners = 'diners',
  discover = 'discover',
  elo = 'elo',
  hypercard = 'hypercard',
  jcb = 'jcb',
  maestro = 'maestro',
  master = 'master',
  mastercard = 'mastercard',
  paypal = 'paypal',
  unionpay = 'unionpay',
  verve = 'verve',
  visa = 'visa',
}
export enum PaymentRunStatus {
  completed = 'completed',
  completedWithErrors = 'completedWithErrors',
  dunning = 'dunning',
  error = 'error',
  pending = 'pending',
  processing = 'processing',
  declined = 'declined'
}
export enum PaymentRunRepeater {
  repeaterDaily = 'daily',
  repeaterWeekly = 'weekly',
  repeaterMonthly = 'monthly',
  repeaterYearly = 'yearly'
}
export enum PaymentSource {
  ach = 'ach',
  amazon = 'amazon',
  applePay = 'applePay',
  bacs = 'bacs',
  check = 'check',
  creditCard = 'card',
  directEBanking = 'directEBanking',
  eft = 'eft',
  ideal = 'ideal',
  moneyOrder = 'moneyOrder',
  nonce = 'nonce',
  offline = 'offline',
  other = 'other',
  paypal = 'paypal',
  qiwiWallet = 'qiwi_wallet',
  roku = 'roku',
  sepa = 'sepa',
  sepaDirectDebit = 'sepaDirectDebit',
  token = 'token',
  wire_transfer = 'wireTransfer',
}
export enum PaymentSourceOffline {
  amazon = 'amazon',
  applePay = 'applePay',
  bacs = 'bacs',
  check = 'check',
  eft = 'eft',
  moneyOrder = 'moneyOrder',
  other = 'other',
  paypal = 'paypal',
  qiwiWallet = 'qiwi_wallet',
  roku = 'roku',
  sepa = 'sepa',
  sepaDirectDebit = 'sepaDirectDebit',
  directEBanking = 'directEBanking',
  token = 'token',
  wire_transfer = 'wireTransfer',
  ideal = 'ideal'
}
export enum PriceTypes {
  currentPrice = 'currentPrice',
  standard = 'standard',
  variant = 'variant'
}
export enum PriceUnitValues {
  flatFee = 'flatFee',
  perUnit = 'perUnit'
}
export enum ProductCategory {
  addOn = 'addOn',
  baseProducts = 'baseProducts',
  bundleProduct = 'bundleProduct',
  miscellaneous = 'miscellaneous',
  service = 'service',
}
export enum ProductStatus {
  archived = 'archived',
  disabled = 'disabled',
  draft = 'draft',
  published = 'published',
  enabled = 'enabled',
}
export enum ProductStatusActions {
  archive = 'Archive',
  disable = 'Disable',
  publish = 'Publish',
}
export enum ImporterProductTaskStatus {
  pending = 'pending',
  processing = 'processing',
  ready = 'ready',
  running = 'running',
  done = 'done',
}
export enum ImporterProductSubTaskStatus {
  pending = 'pending',
  error = 'error',
  success = 'success',
}
export enum ImporterProductTaskActions {
  startImportTask = 'Start Import Task',
  doneImportTask = 'Done Import Task',
  restartImportTask = 'Restart Import Task',
}
export enum ImporterProductTaskOptions {
  all = 'all',
  custom = 'custom',
  brand = 'brand',
  category = 'category',
  createdAt = 'createdAt',
}
export enum ProductRatePlanName {
  oneTimePurchase = 'One Time Purchase'
}
export enum ProductRatePlanChargesCategory {
  physical = 'physical',
  digital = 'digital'
}
export enum ProductRatePlanStatus {
  active = 'active',
  archived = 'archived',
  disabled = 'disabled',
  draft = 'draft',
  published = 'published', // this is changed to enabled
  enabled = 'enabled',
  discontinue = 'discontinue'
}
export enum ExternalIntervalStatus {
  archived = 'archived',
  fail = 'fail',
  active = 'active',
}
export enum ProductRatePlanStatusActions {
  archive = 'Archive',
  disable = 'Disable',
  enable = 'Enable',
}
export enum RatePlanStatus {
  active = 'active',
  archived = 'archived',
  cancel = 'cancel',
  disabled = 'disabled',
  pause = 'pause',
}
export enum RatePlanType {
  prepaid = 'prepaid',
  ongoing = 'ongoing',
  contract = 'contract'
}
export enum RangeType {
  amount = 'amount',
  percent = 'percent'
}
export enum RefundableOptionsValues {
  individualRefund = 'individualRefund',
  fullRefund = 'fullRefund',
  partialRefund = 'partialRefund'
}
export enum SideNavMenuOptions {
  advancedSettings = 'advanced-settings',
  analytics = 'analytics',
  dashboard = 'dashboard',
  customer = 'customers',
  invoices = 'invoices',
  integrations = 'integrations',
  notificationChannels = 'notification-channels',
  products = 'products',
  salesChannels = 'sales-channels',
  settings = 'settings',
  store = 'store',
  subscriptions = 'subscriptions',
  transactions = 'transactions',
}
export enum SettingsType {
  billing = 'billing',
  dateFormat = 'dateFormat',
  dunning = 'dunning',
  metrics = 'metrics',
  settings = 'settings',
  smtp = 'smtp',
  paginator = 'paginator',
  notification = 'notification'
}
export enum ShippingRealTimeProviders {
  fedEx = 'fedEx',
  ups = 'ups',
  usps = 'usps'
}
export enum ShippingRealTimeProvidersStatus {
  active = 'active',
  disabled = 'disabled'
}
export enum ShippingStaticValues {
  flatRateOneDay = 'flatRateOneDay',
  flatRateThreeDay = 'flatRateThreeDay',
  freeShipping = 'freeShipping',
  pickupInStore = 'pickupInStore',
  shipBy = 'shipBy'
}
export enum ShippingCustomFreeShippingStaticValues {
  shippingCustomKey = 'customRate',
  shippingFreeKey = 'freeRate'
}
export enum SubmitActions {
  continue = 'continue',
  submit = 'submit',
}
export enum SubscriptionSource {
  desktop = 'desktop',
  publicAPI = 'publicAPI',
  salesChannel = 'salesChannel'
}
export enum SubscriptionStatus {
  active = 'active',
  archived = 'archived',
  cancel = 'cancel',
  paused = 'paused',
  requestPayment = 'requestPayment'
}
export enum SubscriptionStatusActions {
  activate = 'Activate',
  archive = 'Archive',
  pause = 'Pause',
  requestPayment = 'Request Payment'
}
export enum SubscriptionRatePlanChargeStatus {
  active = 'active',
  archived = 'archived',
  disabled = 'disabled',
  ended = 'ended',
  error = 'error',
  paused = 'pause'
}
export enum SubscriptionRatePlanChargeStatusActions {
  end = 'End',
  archive = 'Archive',
}
export enum TaxClassValues {
  codeDefaultTaxable = 'codeDefaultTaxable',
  codeGiftWrapping = 'codeGiftWrapping',
  nonTaxable = 'nonTaxable', // harcoded
  codeShipping = 'codeShipping',
  codeHandling = 'codeHandling',
  custom = 'custom', // harcoded
  manualTax  = 'manualTax' // harcoded
}
export enum TimeFrameOptions {
  specificDate = 'specificDate',
  dateRange = 'dateRange',
}
export enum StartDateOptions {
  immediately = 'immediately',
  schedule = 'schedule',
}
export enum ShippingDefaultValues {
  custom = 'custom',
  freeShipping = 'freeShipping',
  integration = 'integration',
  byShippingZone = 'byShippingZone'
}
export enum StartingDateValues {
  alignedWithFirstCharge = 'alignedWithFirstCharge',
  cycleBased = 'cycleBased',
  dayBased = 'dayBased',
  immediately = 'immediately',
}
export enum StatusValues {
  active = 'active',
  archived = 'archived',
  disabled = 'disabled',
  error = 'error',
  ended = 'ended'
}
export enum SubscriptionRuleCountTypes {
  after = 'after',
}
export enum SubscriptionRuleTypes {
  cutoff = 'rateplan_cutoff',
  expirationRule = 'rateplan_expiration',
  loyaltyRule = 'rateplan_loyalty',
  prepaidRule = 'rateplan_prepaid',
}
export enum SubscriptionRuleStatus {
  active = 'active',
  disabled = 'disabled',
}
export enum TaxDefaultValues {
  externalAmount = 'externalAmount',
  fixedAmount = 'fixedAmount',
  fixedRate = 'fixedRate',
  freeTax = 'freeTax',
  integration = 'integration',
}
export enum ToggleState {
  on = 'on',
  off = 'off',
}
export enum TransactionStatus {
  authorized = 'authorized',
  awaitingForSettlement = 'awaitingForSettlement',
  error = 'error',
  refund = 'refund',
  settled = 'settled',
  void = 'void',
  declined = 'declined',
}
export enum TransactionType {
  sale = 'sale',
  refund = 'refund'
}
export enum TransactionKind {
  checkout = 'checkout',
  cron = 'cron',
  desktop = 'desktop',
  retry = 'retry',
}
export enum TypeOfShippingMethod {
  isFreeShipping = 'isFree',
  isCustom = 'isCustom',
  isIntegration = 'isIntegration'
}
export enum UpToPeriodsTypeValues {
  billingPeriods = 'billingPeriods',
  days = 'days',
  weeks = 'weeks',
  months = 'months',
  years = 'years'
}
export enum VisibleState {
  isHidden = 'isHidden',
  isVisible = 'isVisible',
}
export enum WeightMeasuraments {
  grams = 'grams',
  kilograms = 'kilograms',
  ounces = 'ounces',
  pounds = 'pounds',
  tonnes = 'tonnes'
}
export enum WeekDay {
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday'
}
//CONST
export const collectionOfAddressType: DropDown[] = [
  {value:AddressTypeValues.residential,label:'Residential'},
  {value:AddressTypeValues.commercial,label:'Commercial'},
];
export const collectionOfAnnualRevenue: DropDown[] = [
  { value: '0', label: '< $1M' },
  { value: '1', label: '$1 - 10M'},
  { value: '2', label: '$10 - 50M' },
  { value: '3', label: '$50 - 100M' },
  { value: '4', label: '$100M - 500M' },
  { value: '5', label: '$500M - 1B' },
  { value: '6', label: '+ $1B' }
]
export const collectionOfAppClient: DropDown[] = [
  { value: AppClient.sandbox, label: 'Bigcommerce Sandbox App' },
  { value: AppClient.production, label: 'Bigcommerce Production App' },
];
export const collectionAPIVersion: DropDown[] = [
  { value: JSVersions.v6, label: JSVersions.v6 },
  { value: JSVersions.v601, label: JSVersions.v601 },
  { value: JSVersions.v610, label: JSVersions.v610 },
];
export const collectionOfAvalaraTaxExemptValues: DropDown[] = [
  { value: AvalaraTaxExemptCodes.none, label: 'None' },
  { value: AvalaraTaxExemptCodes.federalGovernment, label: 'A - Federal Government' },
  { value: AvalaraTaxExemptCodes.stateGovernment, label: 'B - State Government' },
  { value: AvalaraTaxExemptCodes.tribalGovernment, label: 'C - Tribal Government' },
  { value: AvalaraTaxExemptCodes.foreignGovernment, label: 'D - Foreign Government' },
  { value: AvalaraTaxExemptCodes.charitableOrganization, label: 'E - Charitable/Exempt Organization' },
  { value: AvalaraTaxExemptCodes.religiousOrganization, label: 'F - Religious Organization' },
  { value: AvalaraTaxExemptCodes.resale, label: 'G - Resale' },
  { value: AvalaraTaxExemptCodes.agriculture, label: 'H - Agriculture' },
  { value: AvalaraTaxExemptCodes.industrialManufactrurers, label: 'I - Industrial Prod/Manufacturers' },
  { value: AvalaraTaxExemptCodes.directPay, label: 'J - Direct Pay' },
  { value: AvalaraTaxExemptCodes.directMail, label: 'K - Direct Mail' },
  { value: AvalaraTaxExemptCodes.educationalOrganization, label: 'M - Educational Organization' },
  { value: AvalaraTaxExemptCodes.localGovernment, label: 'N - Local Government' },
  { value: AvalaraTaxExemptCodes.commercialAquaculture, label: 'P - Commercial Aquaculture' },
  { value: AvalaraTaxExemptCodes.commercialFishery, label: 'Q - Commercial Fishery' },
  { value: AvalaraTaxExemptCodes.nonResident, label: 'R - Non-Resident' },
  { value: AvalaraTaxExemptCodes.taxable, label: 'Taxable - Override Exemption' }
]
export const collectionOfBigcommerceOrderStatus: DropDown[] = [
  { value: 'incomplete', label: 'Incomplete' },
  { value: 'pending', label: 'Pending' },
  { value: 'awaitingPayment ', label: 'Awaiting Payment' },
  { value: 'awaitingFulfillment', label: 'Awaiting Fulfillment' },
  { value: 'awaitingShipment', label: 'Awaiting Shipment' },
  { value: 'awaitingPickup', label: 'Awaiting Pickup' },
  { value: 'partiallyShipped', label: 'Partially Shipped' },
  { value: 'completed', label: 'Completed' },
  { value: 'shipped', label: 'Shipped' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'declined', label: 'Declined' },
  { value: 'refunded', label: 'Refunded' },
  { value: 'disputed', label: 'Disputed' },
  { value: 'manualVerificationRequired', label: 'Manual Verification Required' },
  { value: 'partiallyRefunded', label: 'Partially Refunded' }
];
export const collectionOfBillCycleType: DropDown[] = [
  { value: BillCycleTypeValues.subscriptionStartDay, label: 'Subscription Start Day' },
  { value: BillCycleTypeValues.specificDayOfMonth, label: 'Specific Day Of Month' },
  { value: BillCycleTypeValues.specificDayOfWeek, label: 'Specific Day Of Week' },
  { value: BillCycleTypeValues.specificMonthOfYear, label: 'Specific Day Of Year' }
];
export const collectionOfBillingPeriod: DropDown[] = [
  { value: BillingPeriodValues.custom, label: 'Custom' },
  { value: BillingPeriodValues.week, label: 'Week' },
  { value: BillingPeriodValues.month, label: 'Month' },
  { value: BillingPeriodValues.quarter, label: 'Quarter' },
  { value: BillingPeriodValues.annual, label: 'Annual' },
  { value: BillingPeriodValues.semiAnnual, label: 'Semi-Annual' },
  // { value: BillingPeriodValues.specificWeeks, label: 'Specific Weeks' },
  // { value: BillingPeriodValues.specificMonths, label: 'Specific Months' },
];
export const collectionOfBillingPeriods: DropDown[] = [
  { value: BillingPeriodsValues.weeks, label: 'Weeks' },
  { value: BillingPeriodsValues.months, label: 'Months' },
  { value: BillingPeriodsValues.years, label: 'Years' }
];
export const collectionOfBillingPeriodAlignment: DropDown[] = [
  { value: BillingPeriodAlignmentValues.alignToCharge, label: 'Align to Purchase Day', status: StatusValues.active },
  { value: BillingPeriodAlignmentValues.alignToSubscriptionStart, label: 'Align To Subscription Start', status: StatusValues.active },
  { value: BillingPeriodAlignmentValues.alignToTermStart, label: 'Align To Term Start', status: StatusValues.active },
];
export const collectionOfBillingTiming: DropDown[] = [
  { value: BillingTimingValues.inAdvance, label: 'In Advance', status: StatusValues.active },
  { value: BillingTimingValues.inArrears, label: 'In Arrears', status: StatusValues.disabled }
];
export const collectionOfBillingFrequencyStartRule: DropDown[] = [
  { value: '0', label: 'Subscribe immediately' },
  { value: '1', label: 'On a fixed date' },
  { value: '2', label: 'Scheduled' },
];
export const collectionOfBillingPeriodType: DropDown[] = [
  { value: 'frequency', label: 'By Frequency' },
  { value: 'dates', label: 'By Dates' },
];
export const collectionOfChargeCreditType: DropDown[] = [
  { value: ChargeCreditType.charge, label: ChargeCreditType.charge, status: StatusValues.active },
  { value: ChargeCreditType.credit, label: ChargeCreditType.credit, status: StatusValues.disabled },
];
export const collectionOfRatePlanTypes: DropDown[] = [
  { value: RatePlanType.contract, label: 'Contract'},
  { value: RatePlanType.ongoing, label: 'Ongoing'},
  { value: RatePlanType.prepaid, label: 'Prepaid'},
];
export const collectionOfChargePrepaidRulesAction: DropDown[] = [
  { value: ChargeRulesPrepaidAction.add, label: 'Add'},
];
export const collectionOfChargePrepaidRulesAmountTypes: DropDown[] = [
  { value: ChargePrepaidRulesAmountTypes.price, label: '$ (Price)'},
];
export const collectionOfChargeCutOffRuleType: DropDown[] = [
  { value: ChargeCutOffRulesType.date, label: 'By Date' },
  { value: ChargeCutOffRulesType.day, label: 'By Day' },
];
export const collectionOfChargesPrepaidRulesEvents: DropDown[] = [
  { value: ChargePrepaidRulesEvents.invoicePosted, label: 'Invoice is Posted'},
];
export const collectionOfChargeLoyaltyRulesAction: DropDown[] = [
  { value: ChargeRulesLoyaltyAction.remove, label: 'Remove'},
  { value: ChargeRulesLoyaltyAction.fixed, label: 'Fixed'},
];
export const collectionOfChargeLoyaltyRulesAmountTypes: DropDown[] = [
  { value: ChargeLoyaltyRulesAmountTypes.price, label: '$ (Price)'},
  { value: ChargeLoyaltyRulesAmountTypes.percentage, label: '% (Percentage)'},
];
export const collectionOfChargeLoyaltyRulesEvents: DropDown[] = [
  { value: ChargeLoyaltyRulesEvents.invoicePosted, label: 'Invoice is Posted'},
];
export const collectionOfChargeLoyaltyRulesCountType: DropDown[] = [
  { value: ChargeLoyaltyRulesCountType.every, label: 'Every'},
];
export const collectionOfChargeModel: DropDown[] = [
  { value: ChargeModelValues.flatFeePricing, label: 'Flat Fee Pricing', status: StatusValues.active  },
  { value: ChargeModelValues.perUnitPricing, label: 'Per Unit Pricing', status: StatusValues.active  },
  { value: ChargeModelValues.tieredPricing, label: 'Tiered Pricing', status: StatusValues.active  },
  { value: ChargeModelValues.volumePricing, label: 'Volume Pricing', status: StatusValues.active  },
  { value: ChargeModelValues.discountFixedAmount, label: 'Discount Fixed Amount', status: StatusValues.disabled },
  { value: ChargeModelValues.discountPercentage, label: 'Discount Percentage', status: StatusValues.disabled },
  { value: ChargeModelValues.overagePricing, label: 'Overage Pricing', status: StatusValues.disabled },
  { value: ChargeModelValues.tieredWithOveragePricing, label: 'Tiered With Overage Pricing', status: StatusValues.disabled }
];
export const collectionOfChargeType: DropDown[] = [
  { value: ChargeType.oneTimeCharge, label: 'One Time Charge' },
  { value: ChargeType.recurringCharge, label: 'Recurring Charge' },
  { value: ChargeType.usageCharge, label: 'Usage Charge' },
];

export const collectionOfCustomerGroupTypes: DropDown[] = [
  {value: CustomerGroupTypeValues.all, label:'All'},
  {value: CustomerGroupTypeValues.custom, label:'Custom'},
];

export const collectionOfCustomerType: DropDown[] = [
  { value: CustomerType.customer, label: 'Customer' },
  { value: CustomerType.partner, label: 'Partner' },
  { value: CustomerType.prospect, label: 'Prospect' },
  { value: CustomerType.reseller, label: 'Reseller' }
];
export const collectionOfDateFormat: DropDown[] = [
  { value: DateFormatValues.YYYY_MM_DD, label: 'YYYY-MM-DD' },
  { value: DateFormatValues.MMM_DD_YYYY, label: 'MMM DD, YYYY' },
  { value: DateFormatValues.ddd_DD_MMM_YYYY, label: 'ddd DD MMM, YYYY' },
  { value: DateFormatValues.dddd_DD_MMM_YYYY, label: 'dddd DD MMM, YYYY' },
];
export const collectionOfDecimalFields: String[] = ['amount', 'price', 'weight', 'width', 'height', 'depth', 'total', 'subtotal', 'shipping', 'oneTimeChargeAmount', 'recurringChargeAmount', 'usageChargeAmount', 'totalTaxExc', 'subtotalTaxExc', 'taxAmount', 'balanceDue', 'invoiceAmount', 'shippingAmount', 'totalTaxCalculated', 'paidAmount', 'grandTotal', 'refundAmount', 'fixedShippingPrice', 'orderAmount'];

export const collectionOfDecimalPlaces: DropDown[] = [
  {value:'1', label:'1'},
  {value:'2', label:'2'},
  {value:'3', label:'3'},
  {value:'4', label:'4'},
];
export const collectionOfDeliveryService: DropDown[] = [
  {value:'01', label:'UPS Next Day Air'},
  {value:'02', label:'UPS 2nd Day Air'},
  {value:'03', label:'UPS Ground'},
  {value:'07', label:'UPS Worldwide Express'},
  {value:'08', label:'UPS Worldwide Expedited'},
  {value:'11', label:'UPS Today Standard'},
  {value:'12', label:'UPS 3-Day Select'},
  {value:'13', label:'UPS Next Day Air Saver'},
  {value:'14', label:'UPS Next Day Air Early A.M.'},
  {value:'54', label:'UPS Worldwide Express Plus'},
  {value:'59', label:'UPS 2nd Day Air A.M.'},
  {value:'65', label:'UPS Express Saver'},
  {value:'71', label:'UPS Worldwide Express Freight Midday'},
  {value:'96', label:'UPS Worldwide Express Freight'},
  // {value:'Expedited', label:'UPS Expedited®'},
  // {value:'Express', label:'UPS Express®'},
  // {value:'Express_Plus', label:'UPS Express Plus®'},
  // {value:'Express_Saver', label:'UPS Express Saver®'},
  // {value:'Express_Early_AM', label:'UPS Express Early A.M.®'},
  // {value:'Standard', label:'UPS Standard℠'},
  // {value:'Today_Dedicated_Courier', label:'UPS Today Dedicated Courier℠'},
  // {value:'Today_Express', label:'UPS Today Express℠'},
  // {value:'Today_Express_Saver', label:'UPS Today Express Saver℠'},
  // {value:'Today_Intercity', label:'UPS Today Intercity℠'},
  // {value:'Worldwide_Express_Saver', label:'UPS Worldwide Express Saver®'},
  // {value:'Worldwide_Saver', label:'UPS Worldwide Saver®'}
];
export const collectionOfEndDateCondition: DropDown[] = [
  { value: EndDateConditionValues.subscriptionEnd, label: 'Subscription End' },
  { value: EndDateConditionValues.fixedPeriod, label: 'Fixed Period' },
];
export const collectionOfEnvironment: DropDown[] = [
  { value: EnvironmentValues.dev, label: 'Dev' },
  { value: EnvironmentValues.sandbox, label: 'Sandbox' },
  { value: EnvironmentValues.production, label: 'Production' }
];
export const collectionOfFactoringDimension: DropDown[] = [
  { value: FactoringDimension.productDepth, label: 'Product Depth' },
  { value: FactoringDimension.productHeight, label: 'Product Height' },
  { value: FactoringDimension.productWidth, label: 'Product Width' },
];
export const collectionOfFrequencyMonthOption: DropDown[] = [
  { value: '0', label: 'Based on customer checkout date' },
  { value: '1', label: 'On each of the following days in the month' },
  { value: '2', label: 'At the following date in the month' }
];
export const collectionOfFrequencyUnit: DropDown[] = [
  { value: FrequencyUnitValues.day, label: 'Day' },
  { value: FrequencyUnitValues.week, label: 'Week' },
  { value: FrequencyUnitValues.month, label: 'Month' },
  { value: FrequencyUnitValues.year, label: 'Year' }
];
export const collectionOfFrequencyWeekOption: DropDown[] = [
  { value: '0', label: 'Based on customer checkout date' },
  { value: '1', label: 'On each of the following days in the week' },
];
export const collectionOfFrequencyYearOption: DropDown[] = [
  { value: '0', label: 'Based on customer checkout date' },
  { value: '1', label: 'On each of the following months in the year' },
  { value: '2', label: 'At the following date in the year' }
];
export const collectionOfInvoiceTerms: DropDown[] = [
  { value: InvoiceTerms.onReceipt, label: 'On-Receipt' },
  { value: InvoiceTerms.net10, label: 'Net-10 ' },
  { value: InvoiceTerms.net30, label: 'Net-30' },
  { value: InvoiceTerms.net60, label: 'Net-60' },
  { value: InvoiceTerms.custom, label: 'Custom' },
];

export const CollectionOfInvoicePaymentMethods: DropDown[] = [
  { value: GatewayType.thirdPartyPaymentProvider, label: 'Credit Card' },
  { value: GatewayType.offlinePaymentProvider, label: 'Offline Payment' },
  { value: GatewayType.requestPayment, label: 'Request new payment method' },
];

export const collectionOfLengthMeasurament: DropDown[] = [
{ value: LengthMeasurament.inches, label: 'Inches' },
{ value: LengthMeasurament.centimeters, label: 'Centimeters' },
];
export const collectionOfListPriceBase: DropDown[] = [
  { value: 'perMonth', label: 'Per Month' },
  { value: 'perBillingPeriod', label: 'Per Billing Period' },
  { value: 'perWeek', label: 'Per Week' }
];
export const collectionOfMonth: DropDown[] = [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
];
export const collectionOfMonthDay: any[] = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
];
export const collectionOfPackingMethod: DropDown[] = [
  {value: '1', label: 'All items in the order in one package'},
  {value: '2', label: 'Each item in the order in its own package'}
];
export const collectionOfPackagingType: DropDown[] = [
  {value: '21',label: 'Express Box'},
  {value: '24',label: 'UPS® 25KG Box'},
  {value: '25',label: 'UPS® 10KG Box'},
  {value: '30',label: 'Pallet'},
  {value: '01',label: 'UPS® Letter'},
  {value: '02',label: 'Customer Supplied Package'},
  {value: '03',label: 'Tube'},
  {value: '04',label: 'Pak'},
  {value: '2a',label: 'Small Express Box'},
  {value: '2b',label: 'Medium Express Box'},
  {value: '2c',label: 'Large Express Box'}
];
export const collectionOfPaymentSource: DropDown[] = [
  { value: PaymentSource.creditCard, label: 'Credit Card' },
  { value: PaymentSource.other, label: 'Other' },
];
export const collectionOfPaymentSourceOffline: DropDown[] = [
  { value: PaymentSourceOffline.amazon, label: 'Amazon Pay'},
  { value: PaymentSourceOffline.applePay, label: 'Apple Pay'},
  { value: PaymentSourceOffline.bacs, label: 'Bacs'},
  { value: PaymentSourceOffline.check, label: 'Check'},
  { value: PaymentSourceOffline.eft, label: 'EFT'},
  { value: PaymentSourceOffline.moneyOrder, label: 'Money Order'},
  { value: PaymentSourceOffline.other, label: 'Other'},
  { value: PaymentSourceOffline.paypal, label: 'PayPal'},
  { value: PaymentSourceOffline.qiwiWallet, label: 'Qiwi Wallet'},
  { value: PaymentSourceOffline.roku, label: 'Roku'},
  { value: PaymentSourceOffline.sepa, label: 'SEPA'},
  { value: PaymentSourceOffline.sepaDirectDebit, label: 'SEPA Direct Debit'},
  { value: PaymentSourceOffline.directEBanking, label: 'Sofort'},
  { value: PaymentSourceOffline.token, label: 'Token'},
  { value: PaymentSourceOffline.wire_transfer, label: 'Wire Transfer'},
  { value: PaymentSourceOffline.ideal, label: 'iDEAL'}
];
export const collectionOfPriceChangeOption: DropDown[] = [
  { value: 'NoChange', label: 'NoChange' },
  { value: 'SpecificPercentageValue', label: 'SpecificPercentageValue' },
  { value: 'UseLatestProductCatalogPricing', label: 'UseLatestProductCatalogPricing' }
];
export const collectionOfProductCategory: DropDown[] = [
  { value: ProductCategory.baseProducts, label: 'Base Product' },
  { value: ProductCategory.bundleProduct, label: 'Bundle Product' },
  { value: ProductCategory.service, label: 'Service' },
  { value: ProductCategory.addOn, label: 'Add-On' },
  { value: ProductCategory.miscellaneous, label: 'Miscellaneous' }
];
export const collectionOfProductRatePlanChargesCategory: DropDown[] = [
  { value: ProductRatePlanChargesCategory.physical, label: 'Physical' },
  { value: ProductRatePlanChargesCategory.digital, label: 'Digital' },
];
export const collectionOfProductSetupPrice: DropDown[] = [
  { value: '1', label: 'No Setup Fee' },
  { value: '2', label: 'Charge One-Time Setup Fee' }
];
export const collectionOfRangeType: DropDown[] = [
  { value: RangeType.amount, label: '$' },
  { value: RangeType.percent, label: '%' }
];
export const collectionOfRefundableOptions: DropDown[] = [
  { value: RefundableOptionsValues.fullRefund, label: 'Full Refund' },
  { value: RefundableOptionsValues.individualRefund, label: 'By Transaction' },
  { value: RefundableOptionsValues.partialRefund, label: 'Partial Refund' },
];
export const collectionOfShippingStaticClasses: any[] = [
  {
    description: 'Use free shipping to improve checkout conversion, increase average order value and reduce abandoned carts.',
    label: 'Free Shipping',
    logo: 'free-shipping',
    value: ShippingStaticValues.freeShipping
  },
  {
    description: 'Charge a fixed shipping cost per order or per item.',
    label: 'Flat Rate (One Day)',
    logo: 'flat-rate',
    value: ShippingStaticValues.flatRateOneDay
  },
  {
    description: 'Charge a fixed shipping cost per order or per item.',
    label: 'Flat Rate (Three Day)',
    logo: 'flat-rate',
    value: ShippingStaticValues.flatRateThreeDay
  },
  {
    description: 'Calculate shipping cost based on order value or the total weight of items.',
    label: 'Ship By',
    logo: 'ship-by',
    value: ShippingStaticValues.shipBy
  },
  {
    description: 'Your customers can pickup / collect their orders from your store’s physical retail location.',
    label: 'Pickup In Store',
    logo: 'pickup-in-store',
    value: ShippingStaticValues.pickupInStore
  }
];
export const collectionOfShippingDefaultValues: DropDown[] = [
  { value: ShippingDefaultValues.freeShipping, label: 'Free Shipping' },
  { value: ShippingDefaultValues.custom, label: 'Custom' },
  { value: ShippingDefaultValues.integration, label: 'Integration' },
  { value: ShippingDefaultValues.byShippingZone, label: 'By Shipping Zone' }
];
export const collectionOfShippingRealTimeProviders: any[] = [
  {
    enabled: true,
    description: 'UPS® (United Parcel Service®) is the world’s largest express carrier and package delivery company. UPS® provides world-wide delivery services originating from 58 countries.',
    label: 'UPS®',
    logo: 'logo-ups-01',
    status: 'active',
    value: ShippingRealTimeProviders.ups
  },
  {
    enabled: false,
    description: 'FedEx Express provides delivery services to every U.S. address and more than 220 countries and territories around the world.',
    label: 'FedEx',
    logo: 'logo-fedex-01',
    status: 'disabled',
    value: ShippingRealTimeProviders.fedEx
  },
  {
    enabled: false,
    description: 'US Postal Service provides domestic shipping services within the USA and internationally with FedEx Express.',
    label: 'USPS',
    logo: 'logo-endicia-01',
    status: 'disabled',
    value: ShippingRealTimeProviders.usps
  }
];
export const collectionOfStartingDateOption: DropDown[] = [
  { value: StartingDateValues.immediately, label: 'Immediately' },
  { value: StartingDateValues.cycleBased, label: 'After N cycles' },
  { value: StartingDateValues.dayBased, label: 'After N Days' },
  { value: StartingDateValues.alignedWithFirstCharge, label: 'Aligned with first charge' }
];
export const collectionOfStartDateSubscription: DropDown[] = [
  { value: StartDateOptions.immediately, label: 'Immediately' },
  { value: StartDateOptions.schedule, label: 'Schedule' },
];

export const collectionOfStartDateSubscriptionRatePlan: DropDown[] = [
  { value: StartDateOptions.immediately, label: 'Immediately' },
  { value: StartDateOptions.schedule, label: 'Schedule' },

];

export const collectionOfSubscribeOn: DropDown[] = [
  { value: 'first', label: 'First' },
  { value: 'second', label: 'Second' },
  { value: 'third', label: 'Third' },
  { value: 'fourth', label: 'Fourth' },
  { value: 'fifth', label: 'Fifth' },
  { value: 'last', label: 'Last' },
  { value: 'custom', label: 'Custom' }
];
export const collectionOfSubscribeOnOptions: DropDown[] = [
  { value: WeekDay.sunday, label: 'Sunday' },
  { value: WeekDay.monday, label: 'Monday' },
  { value: WeekDay.tuesday, label: 'Tuesday' },
  { value: WeekDay.wednesday, label: 'Wednesday' },
  { value: WeekDay.thursday, label: 'Thursday' },
  { value: WeekDay.friday, label: 'Friday' },
  { value: WeekDay.saturday, label: 'Saturday' },
  { value: 'day', label: 'Day' },
  { value: 'weekday', label: 'Weekday' },
  { value: 'weekend', label: 'Weekend' },
];
export const collectionOfRatePlanOptions: DropDown[] = [
  { value: 'stop_cancel', label: 'Cancel Plan' },
  { value: 'stop', label: 'Cancel Invoice & Keep Plan Active' },
  { value: 'retry', label: 'Reset Dunning' },
  { value: 'stop_retry', label: 'Pause Plan' },
];
export const collectionOfSubscriptionStartingDateOptions: DropDown[] = [
  { value: ChargeStartingDateOptions.immediately, label: 'Immediately' },
  { value: ChargeStartingDateOptions.schedule, label: 'Schedule' },
  { value: ChargeStartingDateOptions.custom, label: 'Custom' }
];
export const collectionOfChargeStartingDateOptions: DropDown[] = [
  { value: ChargeStartingDateOptions.immediately, label: 'Immediately' },
  { value: ChargeStartingDateOptions.schedule, label: 'Schedule' },
  { value: ChargeStartingDateOptions.subscriptionFreeTrial, label: 'Free Trial' },
];
export const collectionOfTaxClass: DropDown[] = [
  { value: TaxClassValues.nonTaxable, label: 'Non-Taxable' },
  { value: TaxClassValues.codeDefaultTaxable, label: 'Default Tax Class' },
  { value: TaxClassValues.codeShipping, label: 'Shipping' },
  { value: TaxClassValues.codeGiftWrapping, label: 'Gift Wrapping' },
  { value: TaxClassValues.codeHandling, label: 'Handling' },
  { value: TaxClassValues.custom, label: 'Custom Tax Item Code' },
  { value: TaxClassValues.manualTax, label: 'Manual Tax Rate' }
];

export const collectionOfTaxClassAux: DropDown[] = [
  { value: TaxClassValues.nonTaxable, label: 'Non-Taxable' },
  { value: TaxClassValues.custom, label: 'Custom Tax Item Code' },
  { value: TaxClassValues.manualTax, label: 'Manual Tax Rate' }
];

export const collectionOfTaxMode: DropDown[] = [
  { value: 'taxExclusive', label: 'Tax Exclusive' },
  { value: 'taxInclusive', label: 'Tax Inclusive' },
];
export const collectionOfTaxPartner: any[] = [
  {
    description: 'Avalara helps businesses of all sizes achieve compliance with transactional taxes, including sales and use, VAT, excise, communications, and other tax types. The company delivers comprehensive, automated, cloud-based solutions that are designed to be fast, accurate, and easy to use.',
    label: 'Avalara',
    logo: 'logo-avalara',
    value: IntegrationKeyName.avalara
  },
  {
    description: 'Realize your business growth ambitions for global digital service sales with Taxamo services. Taxamo offers end-to-end services from automated software for tax rate calculation tax registering, filing, and settlement through our Taxamo technology platform. Partner with Taxamo, enable your digital sales journey, now and into the future.',
    label: 'Taxamo',
    logo: 'logo-taxamo',
    value: IntegrationKeyName.taxamo
  },
  {
    description: 'Vertex helps companies realize the full financial and strategic potential of corporate tax, offering a variety of solutions that streamline, integrate and automate corporate tax processes. Vertex solutions serve multinational corporations in every major line of business tax including; income, sales, consumer use, value added, communications and payroll.',
    label: 'Vertex',
    logo: 'logo-vertex',
    value: IntegrationKeyName.vertex
  },
  {
    description: 'Get accurate tax calculation with effortless filing and remittance. Thomson Reuters ONESOURCE™ is a smarter end-to-end tax solution for US and global sales tax, use tax, GST, and VAT for 193 countries regardless of your corporation`s size or geographical research. Automate the entire transactional tax lifecycle — from rate calculation to compliance. Say goodbye to manual work and focus your time on growing your business. Learn more.',
    label: 'Thomson Reuters',
    logo: 'logo-oneSource',
    value: IntegrationKeyName.oneSource
  }
];
export enum ProductSourceImage {
  productLink = 'product_link',
  directLink = 'direct_link'
}

export const collectionOfTaxDefaultValues: DropDown[] = [
  { value: TaxDefaultValues.freeTax, label: 'Tax Free' },
  { value: TaxDefaultValues.fixedAmount, label: 'Fixed Amount' },
  { value: TaxDefaultValues.fixedRate, label: 'Fixed Rate' },
  { value: TaxDefaultValues.integration, label: 'Integration' },
  { value: TaxDefaultValues.externalAmount, label: 'External Amount' }
];
export const collectionOfTermLength: DropDown[] = [
  { value: '0', label: 'No contract' },
  { value: '1', label: 'Custom' }
];
export const collectionOfTermRenewalRule: DropDown[] = [
  { value: '0', label: 'Auto renew subscription with Term' },
  { value: '1', label: 'Auto renew subscription without Term' },
  { value: '2', label: 'Expire subscription' }
];

export const commitmentLengths = {
  [FrequencyUnitValues.day]: [...Array(365).keys()].map(i=>i+1),
  [FrequencyUnitValues.week]: [...Array(52).keys()].map(i=>i+1),
  [FrequencyUnitValues.month]: [...Array(12).keys()].map(i=>i+1),
  [FrequencyUnitValues.year]: [...Array(10).keys()].map(i=>i+1),
};
export const collectionOfTime: DropDown[] = [
  { value: '00', label: '00:00'},
  { value: '01', label: '01:00'},
  { value: '02', label: '02:00'},
  { value: '03', label: '03:00'},
  { value: '04', label: '04:00'},
  { value: '05', label: '05:00'},
  { value: '06', label: '06:00'},
  { value: '07', label: '07:00'},
  { value: '08', label: '08:00'},
  { value: '09', label: '09:00'},
  { value: '10', label: '10:00'},
  { value: '11', label: '11:00'},
  { value: '12', label: '12:00'},
  { value: '13', label: '13:00'},
  { value: '14', label: '14:00'},
  { value: '15', label: '15:00'},
  { value: '16', label: '16:00'},
  { value: '17', label: '17:00'},
  { value: '18', label: '18:00'},
  { value: '19', label: '19:00'},
  { value: '20', label: '20:00'},
  { value: '21', label: '21:00'},
  { value: '22', label: '22:00'},
  { value: '23', label: '23:00'}
];
export const collectionOfTimeFrame: DropDown[] = [
  { value: TimeFrameOptions.specificDate, label: 'Specific Date', status: StatusValues.active },
  { value: TimeFrameOptions.dateRange, label: 'Date Range', status: StatusValues.disabled  },
];
export const collectionOfTrialLength: DropDown[] = [
  { value: '0', label: 'No trial' },
  { value: '7', label: '7 Days' },
  { value: '14', label: '14 Days' },
  { value: '30', label: '1 Month' },
  { value: 'custom', label: 'Custom' }
];
export const collectionOfUpToPeriodsType: DropDown[] = [
  { value: UpToPeriodsTypeValues.billingPeriods, label: 'Billing Periods' },
  { value: UpToPeriodsTypeValues.days, label: 'Days' },
  { value: UpToPeriodsTypeValues.weeks, label: 'Weeks' },
  { value: UpToPeriodsTypeValues.months, label: 'Months' },
  { value: UpToPeriodsTypeValues.years, label: 'Years' }
];
export const collectionOfWeeklyBillCycleDay: DropDown[] = [
  { value: WeekDay.sunday, label: 'Sunday' },
  { value: WeekDay.monday, label: 'Monday' },
  { value: WeekDay.tuesday, label: 'Tuesday' },
  { value: WeekDay.wednesday, label: 'Wednesday' },
  { value: WeekDay.thursday, label: 'Thursday' },
  { value: WeekDay.friday, label: 'Friday' },
  { value: WeekDay.saturday, label: 'Saturday' }
];
export const collectionOfWeightMeasurament: DropDown[] = [
  { value: WeightMeasuraments.pounds, label: 'Pounds' },
  { value: WeightMeasuraments.ounces, label: 'Ounces' },
  { value: WeightMeasuraments.kilograms, label: 'Kilograms' },
  { value: WeightMeasuraments.grams, label: 'Grams' },
  { value: WeightMeasuraments.tonnes, label: 'Tonnes' },
];

export const collectionOfEnvironmentGateways: DropDown[] = [
  { value: 'sandbox', label: 'Sandbox' },
  { value: 'production', label: 'Production' },
];

export const collectionOfTransactionTypeGateways: DropDown[] = [
  { value: 'authorizeOnly', label: 'Authorize Only' },
  { value: 'authorizeCapture', label: 'Authorize & Capture' },
];

export const collectionOfWorkSize: DropDown[] = [
  { value: '1', label: 'Just You' },
  { value: '2', label: '2 - 9' },
  { value: '3', label: '10 - 99' },
  { value: '4', label: '100 - 299' },
  { value: '5', label: '300+' }
]
export const collectionOfFlatRateTypes: DropDown[] = [
  { value: 'item', label: 'Per item' },
  { value: 'order', label: 'Per order' },
]
export const collectionOfhipByChargeShipping: DropDown[] = [
  { value: 'byWeight', label: 'By weight' },
  { value: 'byOrderTotal', label: 'By order total' },
  { value: 'byOrderQty', label: 'By order quantity' },
]
export const collectionOfShipByTypes: DropDown[] = [
  { value: '$', label: '$' },
  { value: '%', label: '% of total orders' },
]
export const collectionOfImporterProductTaskOptions : DropDown[] = [
  { value: 'all', label: 'All' },
  { value: 'custom', label: 'Custom' },
  { value: 'brand', label: 'By Brand' },
  { value: 'category', label: 'By Category' },
  // { value: 'createdAt', label: 'By Created At' },
]

export const collectionOfModifierDisplayStyleOptions : DropDown[] = [
  { value: 'dropdown', label: 'Dropdown' },
  { value: 'radio_buttons', label: 'Radio Buttons' },
  { value: 'rectangles', label: 'Rectangles' },
]

//Main Navigation
export const collectionOfNavOption: Array<any> = [
  // Quick Access
  {
    id: 1,
    title: 'Quick Access',
    iconPrefix: 'ico-quick-access',
    hasAccess: true,
    module: 'quickAccess',
    options: [
      {
        id: 11,
        displayName: 'Create Customer',
        hasAccess: true,
        url: '/main/customers/add',
      },
      {
        id: 12,
        displayName: 'Create Product',
        hasAccess: true,
        url: '/main/products/add',
      },
      {
        id: 13,
        displayName: 'Create Subscription',
        hasAccess: true,
        url: '/main/subscriptions/add',
      },
      {
        id: 14,
        displayName: 'Create Invoice',
        hasAccess: true,
        url: '/main/invoices/add',
      }
    ],
  },
  // Subscriptions
  {
    id: 2,
    title: 'Subscriptions',
    iconPrefix: 'ico-subscriptions',
    hasAccess: true,
    module: 'subscriptions',
    options: [
      {
        id: 21,
        displayName: 'Manage',
        hasAccess: true,
        url: '/main/subscriptions/list',
      },
      {
        id: 22,
        displayName: 'New Subscription',
        hasAccess: true,
        url: '/main/subscriptions/add',
      },
      {
        id: 23,
        displayName: 'Import Orders',
        hasAccess: true,
        url: '/main/subscriptions/import',
      },
    ],
  },
  // Product Catalog
  {
    id: 10,
    title: 'Product Catalog',
    iconPrefix: 'ico-products',
    hasAccess: true,
    module: 'products',
    options: [
      {
        id: 101,
        displayName: 'Manage',
        hasAccess: true,
        url: '/main/products/list',
      },
      {
        id: 102,
        displayName: 'New Product',
        hasAccess: true,
        url: '/main/products/add',
      },
      {
        id: 103,
        displayName: 'Import / Export',
        hasAccess: true,
        url: '/main/products/import',
      },
    ],
  },
  // Customers
  {
    id: 3,
    title: 'Customers',
    iconPrefix: 'ico-customers',
    hasAccess: true,
    module: 'customers',
    options: [
      {
        id: 31,
        displayName: 'Manage',
        hasAccess: true,
        url: '/main/customers/list',
      },
      {
        id: 32,
        displayName: 'New Customer',
        hasAccess: true,
        url: '/main/customers/add',
      },
      {
        id: 33,
        displayName: 'Import / Export',
        hasAccess: true,
        url: '/main/customers/import',
      },
    ],
  },
  // Invoices & Credits
  {
    id: 4,
    title: 'Invoices & Credits',
    iconPrefix: 'ico-invoices',
    hasAccess: true,
    module: 'invoices',
    options: [
      {
        id: 41,
        displayName: 'Invoices',
        hasAccess: true,
        url: '/main/invoices/list',
      },
      {
        id: 42,
        displayName: 'Credits',
        hasAccess: false,
        url: '/main/invoices/credit',
      },
    ],
  },
  // Analytics
  {
    id: 5,
    title: 'Analytics',
    iconPrefix: 'ico-analytics',
    hasAccess: true,
    module: 'analytics',
    options: [
      {
        id: 51,
        displayName: 'Dashboard',
        hasAccess: true,
        url: '/analytics/dashboard',
      },
      {
        id: 52,
        displayName: 'Subscribers',
        hasAccess: true,
        url: '/analytics/subscribers',
      },
      {
        id: 53,
        displayName: 'Plans',
        hasAccess: true,
        url: '/analytics/plans',
      },
      {
        id: 54,
        displayName: 'Trial Performance',
        hasAccess: true,
        url: '/analytics/trial-performance',
      },
      {
        id: 55,
        displayName: 'Monthly Recurring Revenue',
        hasAccess: true,
        url: '/analytics/monthly-recurring-revenue',
      },
      {
        id: 56,
        displayName: 'Subscribers Retention',
        hasAccess: true,
        url: '/analytics/subscribers-retention',
      },
      {
        id: 57,
        displayName: 'Billings',
        hasAccess: true,
        url: '/analytics/billings',
      },
      {
        id: 57,
        displayName: 'Churn Analysis',
        hasAccess: true,
        url: '/analytics/churn-analysis',
      },
      {
        id: 58,
        displayName: 'Plan Performance',
        hasAccess: true,
        url: '/analytics/plan-performance',
      },
      {
        id: 59,
        displayName: 'Recovered Revenue',
        hasAccess: true,
        url: '/analytics/recovered-revenue',
      },
      {
        id: 60,
        displayName: 'Dunning Effectiveness',
        hasAccess: true,
        url: '/analytics/dunning-effectiveness',
      },
      {
        id: 61,
        displayName: 'Export',
        hasAccess: true,
        url: '/analytics/export',
      },
    ],
  },
  // Transactions
  {
    id: 7,
    title: 'Transactions',
    iconPrefix: 'ico-logs',
    hasAccess: true,
    module: 'transactions',
    options: [
      {
        id: 71,
        displayName: 'Transactions',
        hasAccess: true,
        url: '/main/transactions/list',
      }
    ],
  },
  // Sales Channels
  {
    id: 8,
    title: 'Sales Channels',
    iconPrefix: 'ico-channels',
    hasAccess: true,
    module: 'integrations',
    options: [
      {
        id: 81,
        displayName: 'Sales Channels',
        hasAccess: true,
        url: '/main/integrations/sales-channels/list',
      }
      // {
      //   id: 82,
      //   displayName: 'Shopify',
      //   hasAccess: true,
      //   url: '/main/integrations/sales-channels/shopify',
      // },
      // {
      //   id: 83,
      //   displayName: 'Salesforce',
      //   hasAccess: false,
      //   url: '/main/integrations/sales-channels/salesforce',
      // },
      // {
      //   id: 84,
      //   displayName: 'Amazon',
      //   hasAccess: false,
      //   url: '/main/integrations/sales-channels/amazon',
      // },
      // {
      //   id: 85,
      //   displayName: 'eBay',
      //   hasAccess: false,
      //   url: '/main/integrations/sales-channels/ebay',
      // },
      // {
      //   id: 86,
      //   displayName: 'Store Front',
      //   hasAccess: false,
      //   url: '/main/integrations/sales-channels/shipstation',
      // }
    ],
  },
  // Settings
  {
    id: 9,
    title: 'Settings',
    iconPrefix: 'ico-settings',
    hasAccess: true,
    module: 'settings',
    options: [
      {
        id: 91,
        displayName: 'Store Setup',
        hasAccess: true,
        url: '/main/settings/store/store-profile',
      }
      // {
      //   id: 92,
      //   displayName: 'Advance Settings',
      //   hasAccess: true,
      //   url: '/main/settings/advanced',
      // }
      // {
      //   id: 91,
      //   displayName: 'Account Settings',
      //   hasAccess: true,
      //   url: '/account',
      // },
      // {
      //   id: 92,
      //   displayName: 'Billing',
      //   hasAccess: true,
      //   url: '/main/settings/billing',
      // },
      // {
      //   id: 93,
      //   displayName: 'Users',
      //   hasAccess: true,
      //   url: '/main/settings/users',
      // },
      // {
      //   id: 97,
      //   displayName: 'Notifications',
      //   hasAccess: true,
      //   url: '/main/settings/notifications',
      // },
      // {
      //   id: 105,
      //   displayName: 'API Credentials',
      //   hasAccess: false,
      //   url: '/main/integrations/settings',
      // },
      // {
      //   id: 99,
      //   displayName: 'Hosted Pages',
      //   hasAccess: false,
      //   url: '/main/settings/hosted-pages',
      // },
      // {
      //   id: 100,
      //   displayName: 'Custom Fields',
      //   hasAccess: false,
      //   url: '/main/settings/custom-fields',
      // },
    ],
  },
];

export const SCOPE_RULE = {
  additional_currency: [
    {
      scopes: [
        { name: "SCOPE_BUTTON_DISABLE" },
        {
          name: "SCOPE_HIDE_WITHMESSAGE",
          message: "This feature is not available in your current plan",
        },
      ],
    },
  ],
  payment_gateways: [
    {
      name: "limit",
      value: 1,
      scopes: [
        {
          name: "SCOPE_LIMIT",
          message:
            "Sorry Boss! Your plan allows connecting up to {limit} payment gateways",
        },
      ],
    },
  ],
  payment_wallets: [
    {
      name: "limit",
      value: 1,
      scopes: [
        {
          name: "SCOPE_LIMIT",
          message:
            "Sorry Boss! Your plan allows connecting up to {limit} online wallet”",
        },
      ],
    },
  ],
  payment_offline: [
    {
      name: "limit",
      value: 1,
      scopes: [
        {
          name: "SCOPE_LIMIT",
          message:
            "Sorry Boss! Your plan allows connecting up to {limit} offline payment methods",
        },
      ],
    },
  ],
  accounting_integrations: [
    { scopes: [{ name: "SCOPE_HIDE" }, { name: "SCOPE_NOPAGE" }] },
    {
      name: "limit",
      value: 1,
      scopes: [
        {
          name: "SCOPE_LIMIT",
          message:
            "Sorry Boss! Your plan allows connecting up to {limit} accounting integrations",
        },
      ],
    },
  ],
  tax_integrations: [
    { scopes: [{ name: "SCOPE_HIDE" }, { name: "SCOPE_NOPAGE" }] },
    {
      name: "limit",
      value: 1,
      scopes: [
        {
          name: "SCOPE_LIMIT",
          message:
            "Sorry Boss! Your plan allows connecting up to {limit} tax integrations",
        },
      ],
    },
  ],
  shipping_integrations: [
    { scopes: [{ name: "SCOPE_HIDE" }, { name: "SCOPE_NOPAGE" }] },
    {
      name: "limit",
      value: 69,
      scopes: [
        {
          name: "SCOPE_LIMIT",
          message:
            "Sorry Boss! Your plan allows connecting up to {limit} shipping integrations",
        },
      ],
    },
  ],
  publicapi_account: [
    { scopes: [{ name: "SCOPE_HIDE" }, { name: "SCOPE_NOPAGE" }] },
  ],
  feature_dunning: [
    { scopes: [{ name: "SCOPE_HIDE" }, { name: "SCOPE_NOPAGE" }] },
  ],
  feature_inbox_notifications: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  feature_email_notifications: [
    { scopes: [{ name: "SCOPE_HIDE" }] },
    {
      name: "limit",
      value: 3,
      scopes: [
        {
          name: "SCOPE_LIMIT",
          message:
            "Sorry Boss! Your plan allows connecting up to {limit} Email account(s)",
        },
      ],
    },
  ],
  feature_billrun_runnow: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  feature_payrun_runnow: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  feature_saleschannels: [{ scopes: [{ name: "SCOPE_BUTTON_DISABLE" }] }],
  saleschannel_bigcommerce: [
    { scopes: [{ name: "SCOPE_BUTTON_DISABLE" }] },
    {
      name: "limit",
      value: 3,
      scopes: [
        {
          name: "SCOPE_LIMIT",
          message:
            "Sorry Boss! Your plan allows connecting up to {limit} BigCommerce stores",
        },
      ],
    },
  ],
  feature_childaccount: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  view_billing_plan: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  view_billing_order: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  view_billing_invoices1: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  feature_analytics: [
    {
      scopes: [
        {
          name: "SCOPE_NOPAGE_WITHMESSAGE",
          message:
            "Analytics are not a part of your current plan. To access analytics, upgrade your plan or contact your account manager",
        },
      ],
    },
  ],
  analytics_indepth: [{ scopes: [{ name: "SCOPE_BUTTON_DISABLE" }] }],
  analytics_cohorts: [{ scopes: [{ name: "SCOPE_BUTTON_DISABLE" }] }],
  analytics_reports: [{ scopes: [{ name: "SCOPE_BUTTON_DISABLE" }] }],
  feature_loyaltydiscount: [
    {
      scopes: [
        { name: "SCOPE_SET_VALUE", value: false },
        { name: "SCOPE_INPUT_DISABLE" },
      ],
    },
  ],
  feature_usagebased: [
    {
      scopes: [
        { name: "SCOPE_HIDE" },
        { name: "SCOPE_SET_VALUE", value: false },
      ],
    },
  ],
  view_sub_alignment: [
    {
      scopes: [
        { name: "SCOPE_HIDE" },
        { name: "SCOPE_SET_VALUE", value: "Align to charge" },
      ],
    },
  ],
  view_sub_billday: [
    {
      scopes: [
        { name: "SCOPE_HIDE" },
        { name: "SCOPE_SET_VALUE", value: "Subscription Start Day" },
      ],
    },
  ],
  view_sub_billtiming: [
    {
      scopes: [
        { name: "SCOPE_HIDE" },
        { name: "SCOPE_SET_VALUE", value: "In Advance" },
      ],
    },
  ],
  view_sub_endcondition: [
    {
      scopes: [
        { name: "SCOPE_HIDE" },
        { name: "SCOPE_SET_VALUE", value: "Subscription End" },
      ],
    },
  ],
  view_sub_startdate: [
    {
      scopes: [
        { name: "SCOPE_HIDE" },
        { name: "SCOPE_SET_VALUE", value: "Immediately" },
      ],
    },
  ],
  feature_rptype_prepaid: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  feature_rptype_contract: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  feature_effectivedates: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  multi_prpc: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  multi_rpc: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  feature_gridexport: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  feature_advanced_upcoming: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  saleschannel_checkoutAllTransactions: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  saleschannel_enableCustomerGroups: [
    { scopes: [{ name: "SCOPE_BUTTON_DISABLE" }] },
  ],
  feature_billrun_changeTime: [{ scopes: [{ name: "SCOPE_BUTTON_DISABLE" }] }],
  feature_payrun_changeTime: [{ scopes: [{ name: "SCOPE_BUTTON_DISABLE" }] }],
  saleschannel_customer_import: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  feature_angular_chargenow: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  shipping_shipperhq: [{ scopes: [{ name: "SCOPE_BUTTON_DISABLE" }] }],
  feature_storecredit: [
    {
      scopes: [
        { name: "SCOPE_SET_VALUE", value: false },
        { name: "SCOPE_INPUT_DISABLE" },
      ],
    },
  ],
  feature_setasgift: [
    {
      scopes: [
        { name: "SCOPE_SET_VALUE", value: false },
        { name: "SCOPE_INPUT_DISABLE" },
      ],
    },
  ],
  feature_dailyop_notification: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  salechannel_addprodtobag: [
    {
      scopes: [
        { name: "SCOPE_SET_VALUE", value: false },
        { name: "SCOPE_INPUT_DISABLE" },
      ],
    },
  ],
  feature_PRP_minimum_commit: [
    {
      scopes: [
        { name: "SCOPE_SET_VALUE", value: false },
        { name: "SCOPE_INPUT_DISABLE" },
      ],
    },
  ],
  feature_PRP_templates: [
    { scopes: [{ name: "SCOPE_HIDE" }, { name: "SCOPE_NOPAGE" }] },
  ],
  feature_mergeCustomer: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
  feature_reportAnIssue: [{ scopes: [{ name: "SCOPE_HIDE" }] }],
};
@Injectable()

export class AppData {
  constructor() {}
}

// af-ZA
// am-ET
// ar-AE
// ar-BH
// ar-DZ
// ar-EG
// ar-IQ
// ar-JO
// ar-KW
// ar-LB
// ar-LY
// ar-MA
// arn-CL
// ar-OM
// ar-QA
// ar-SA
// ar-SY
// ar-TN
// ar-YE
// as-IN
// az-Cyrl-AZ
// az-Latn-AZ
// ba-RU
// be-BY
// bg-BG
// bn-BD
// bn-IN
// bo-CN
// br-FR
// bs-Cyrl-BA
// bs-Latn-BA
// ca-ES
// co-FR
// cs-CZ
// cy-GB
// da-DK
// de-AT
// de-CH
// de-DE
// de-LI
// de-LU
// dsb-DE
// dv-MV
// el-GR
// en-029
// en-AU
// en-BZ
// en-CA
// en-GB
// en-IE
// en-IN
// en-JM
// en-MY
// en-NZ
// en-PH
// en-SG
// en-TT
// en-US
// en-ZA
// en-ZW
// es-AR
// es-BO
// es-CL
// es-CO
// es-CR
// es-DO
// es-EC
// es-ES
// es-GT
// es-HN
// es-MX
// es-NI
// es-PA
// es-PE
// es-PR
// es-PY
// es-SV
// es-US
// es-UY
// es-VE
// et-EE
// eu-ES
// fa-IR
// fi-FI
// fil-PH
// fo-FO
// fr-BE
// fr-CA
// fr-CH
// fr-FR
// fr-LU
// fr-MC
// fy-NL
// ga-IE
// gd-GB
// gl-ES
// gsw-FR
// gu-IN
// ha-Latn-NG
// he-IL
// hi-IN
// hr-BA
// hr-HR
// hsb-DE
// hu-HU
// hy-AM
// id-ID
// ig-NG
// ii-CN
// is-IS
// it-CH
// it-IT
// iu-Cans-CA
// iu-Latn-CA
// ja-JP
// ka-GE
// kk-KZ
// kl-GL
// km-KH
// kn-IN
// kok-IN
// ko-KR
// ky-KG
// lb-LU
// lo-LA
// lt-LT
// lv-LV
// mi-NZ
// mk-MK
// ml-IN
// mn-MN
// mn-Mong-CN
// moh-CA
// mr-IN
// ms-BN
// ms-MY
// mt-MT
// nb-NO
// ne-NP
// nl-BE
// nl-NL
// nn-NO
// nso-ZA
// oc-FR
// or-IN
// pa-IN
// pl-PL
// prs-AF
// ps-AF
// pt-BR
// pt-PT
// qut-GT
// quz-BO
// quz-EC
// quz-PE
// rm-CH
// ro-RO
// ru-RU
// rw-RW
// sah-RU
// sa-IN
// se-FI
// se-NO
// se-SE
// si-LK
// sk-SK
// sl-SI
// sma-NO
// sma-SE
// smj-NO
// smj-SE
// smn-FI
// sms-FI
// sq-AL
// sr-Cyrl-BA
// sr-Cyrl-CS
// sr-Cyrl-ME
// sr-Cyrl-RS
// sr-Latn-BA
// sr-Latn-CS
// sr-Latn-ME
// sr-Latn-RS
// sv-FI
// sv-SE
// sw-KE
// syr-SY
// ta-IN
// te-IN
// tg-Cyrl-TJ
// th-TH
// tk-TM
// tn-ZA
// tr-TR
// tt-RU
// tzm-Latn-DZ
// ug-CN
// uk-UA
// ur-PK
// uz-Cyrl-UZ
// uz-Latn-UZ
// vi-VN
// wo-SN
