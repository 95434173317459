
export interface Errors {
    code: number,
    message: string
}

export interface ErrorInterface {
    code: number, //HTTP response code
    message: string, //A descriptive information about the error. This is for developer(/merchant) consumption and should not be used for showing errors to your customers.
    errors: Array<Errors>
    help: string
}

export class RestResponse<T = object> {
  code: number;
  currentPageNumber: number;
  itemsPerPage: number;
  isSuccess: boolean;
  data: T;
  error: ErrorInterface;
  totalItems: number;
  totalPages: number;

  constructor() {}

  onFail(_status:number, _code: number, _message: string, _errors: any, _help: any) {
    this.code = _status;
    this.isSuccess = false;
    this.error = {
      code: _code,
      message: _message,
      errors: _errors,
      help: _help
    };
    return this;
  }

  onSuccess(_code: number, _data: any = null) {
    this.currentPageNumber = (_data.currentPageNumber ? _data.currentPageNumber : 1);
    this.itemsPerPage = (_data.itemsPerPage ? _data.itemsPerPage : 0);
    this.totalItems = (_data.totalItems ? _data.totalItems : 0);
    this.totalPages = (_data.totalPages ? _data.totalPages : 0);
    this.code = _code;
    this.data = (_data.data ? _data.data : _data);
    this.isSuccess = true;
    return this;
  }

  onSuccessNoData(_code: number) {
    this.code = _code;
    this.data = null;
    this.isSuccess = true;
    return this;
  }
}
