//angular
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_core/services/auth.service';
//app

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html'
})

export class CallbackComponent implements OnInit {
  color = 'primary';
  mode = 'query';
  value = 50;
  bufferValue = 75;

  constructor(
    private _auth: AuthService
  ) {}

  ngOnInit() {
    console.log('callback.component.ts - ngOnInit()');
    this._auth.handleAuthentication();
  }

}
