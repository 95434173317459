//angular
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../_core/services/auth.service';
//app

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(
        public _auth: AuthService,
        public _router: Router
    ) {
        console.log(`auth.guard.ts was loaded.`)
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this._auth.isAuthenticated()) {
            this._router.navigate(['/login']);
            return false;

        } else {
            
            const userRole = this._auth.getRole();

            // check if route is restricted by role
            if (route.data.roles && route.data.roles.indexOf(userRole) === -1) {
                // role not authorised so redirect to switch page
                this._router.navigate(['/login/switch']);
                return false;
            }

            // authorised so return true
            return true;
        }
    }

}
