import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { catchError, map, of } from "rxjs";
import { SharedGlobalService } from "src/app/_core/services/api";

@Injectable({ providedIn: "root" })
export class PermissionManagerService {
  private scopes: any[];
  constructor(private sharedGlobal: SharedGlobalService) {
    this.scopes = JSON.parse(localStorage.getItem('scopes'))
  }

  /** Get config from db and stores it in localstorage */
  getConfig(): any {
    return new Promise((resolve, reject)=>{

      return this.sharedGlobal.getCompanyScope().pipe(
        map((res: any) => {
          this.scopes = res.data;
          localStorage.setItem('scopes', JSON.stringify(this.scopes))
          resolve(this.scopes);
        })
      )
      .subscribe(
        (resp) => {
            console.log(resp);
        },
        (error) => {
          this.scopes = [];
          resolve(this.scopes);
          return of()
        }
    );
    })

  }

  /**
   * Finds if restriction exist in database
   * @param keyName
   * @param type
   * @param action
   * @returns
   */
  hasRestriction(keyName: string, type: string, action: string): boolean | any {
    const globalScope = this.scopes.find((item) => item.planScopeKey.keyName === keyName);
    if(!globalScope || !globalScope.enabled) return false;

    // const rule = globalScope.value?.find(item=>item.name === type);
    // if(!rule || !rule.scopes) return false;
    const rule = globalScope.value[0];

    const restriction = rule.scopes?.find((value)=>value.name === action);
    if (restriction) {
      if(restriction.message) {
        restriction.message = restriction.message.replace('{limit}', rule.value)
      }
      return restriction;
    }
    return false;
  }

  /**
   * This function should be used to disable or do logic in forms based on restriction
   * @param permission
   * @param form
   * @param formControlName
   * @param overideLocalValue
   * @param cb
   * @returns
   */
  checkInput(permission, form, formControlName, overideLocalValue? : any, cb?: any) {
    const [keyName, type, action] = permission.split("|");
    const restriction = this.hasRestriction(keyName, type, action);
    if (restriction) {
      if(cb){
        form.get(formControlName).disable();
        return cb();
      }
      form.get(formControlName).setValue( overideLocalValue !== undefined ?  overideLocalValue : restriction.value);
      form.get(formControlName).disable();
      form.get(formControlName).enable = () => {};
      form.get(formControlName).setValue = () => {};
    }
  }

  /**
   *
   * @param permission
   * @param cb
   * @returns
   */
  isRestricted(permission, cb?: any) {
    const [keyName, type, action] = permission.split("|");
    const restriction = this.hasRestriction(keyName, type, action);
    if(cb){
      cb( restriction);
    }
    return restriction
  }
}
