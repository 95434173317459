//angular
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
//app component
import { ButtonSecondaryComponent } from './elements/button/button-secondary/button-secondary.component';
import { CardComponent } from './elements/card/card.component';
import { DialogComponent } from './dialog/dialog.component';
import { DialogPaymentFormComponent } from './payment-form/dialog-payment-form/dialog-payment-form.component';
import { DialogShippingAddressFormComponent } from './dialog/dialog-shipping-address-form/dialog-shipping-address-form.component';
import { DialogUserIdleComponent } from './idle-user/dialog-user-idle.component';
import { ErrorMessagesComponent } from './error-messages/error-messages.component';
import { FormatTimePipe, FormatDatePipe, FormatDateTimePipe, FormatFullTime, SetTimezonePipe } from './pipes/dateTime.pipe';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { CreditCardComponent } from './elements/credit-card/credit-card.component';
import { PaymentCardComponent } from './elements/payment-card/payment-card.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { GridChargesComponent } from './elements/app-grid-charges/app-grid-charges.component';
import { AccountShippingAddressComponent } from './elements/account-shipping-address/account-shipping-address.component';
import { ShippingAddressComponent } from './elements/shipping-address/shipping-address.component';
import { ShippingMethodComponent } from './elements/shipping-method/shipping-method.component';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';
import { SkeletonLoaderFormComponent } from './skeleton-loader/skeleton-loader-form/skeleton-loader-form.component';
import { SkeletonLoaderGatewayComponent } from './skeleton-loader/skeleton-loader-gateway/skeleton-loader-gateway.component';
import { SkeletonLoaderGridHeaderComponent } from './skeleton-loader/skeleton-loader-grid/skeleton-loader-grid-header/skeleton-loader-grid-header.component';
import { SkeletonLoaderGridBodyComponent } from './skeleton-loader/skeleton-loader-grid/skeleton-loader-grid-body/skeleton-loader-grid-body.component';

import { FormControlErrorMessageComponent } from './error-messages/form-control-error-message.component';

//app module
import { AppMaterialModule } from '../app.material';

import {ValidatorService} from './validators/validator.service';

import { NgxCleaveDirectiveModule} from 'ngx-cleave-directive';
import { ShippingProtectionComponent } from './dialog/shipping-protection/shipping-protection.component';
import { MatSelectSearchModule } from './elements/mat-select-search/mat-select-search.module';

const COMPONENTS: any[] = [
  AccountShippingAddressComponent,
  ButtonSecondaryComponent,
  CardComponent,
  CreditCardComponent,
  DialogPaymentFormComponent,
  DialogComponent,
  DialogShippingAddressFormComponent,
  PageLoaderComponent,
  DialogUserIdleComponent,
  ErrorMessagesComponent,
  FormatDatePipe,
  FormatFullTime,
  FormatDateTimePipe,
  SetTimezonePipe,
  FormatTimePipe,
  PaymentCardComponent,
  PaymentFormComponent,
  GridChargesComponent,
  ShippingAddressComponent,
  ShippingMethodComponent,
  SkeletonLoaderComponent,
  SkeletonLoaderFormComponent,
  SkeletonLoaderGatewayComponent,
  SkeletonLoaderGridHeaderComponent,
  SkeletonLoaderGridBodyComponent,

  FormControlErrorMessageComponent
]

const MODULES: any[] = [
  AppMaterialModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NgxCleaveDirectiveModule,
  MatSelectSearchModule
]

const PROVIDERS: any[] = [
  ValidatorService
]

@NgModule({
  exports: [ ...COMPONENTS, MatSelectSearchModule ],
  declarations: [ COMPONENTS, ShippingProtectionComponent ],
  imports: [ MODULES ],
  providers: [ PROVIDERS ]
})

export class AppSharedModule { }
