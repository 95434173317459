import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { PermissionManagerService } from "./permissions.service";
import { Observable } from "rxjs";


  @Injectable({ providedIn: "root" })
  export class RestrictionResolver implements Resolve<any> {
    constructor(private service: PermissionManagerService) {}

    resolve(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {

      return this.service.getConfig();
    }
  }