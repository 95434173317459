import { ChargeCreditType, ProductRatePlanChargesCategory } from 'src/app/app.data';
import { User } from '.';
import { Deserializable } from '../interfaces/deserialize.interface';
import { RatePlanCharge } from '../interfaces/subscription.interface';
import { Address } from './address.model';
import { CompanyCurrency, CompanyGateway } from './company.model';
import { CustomerPaymentSource, CustomerProfile } from './customer.model';
import { CompanyIntegrationModel } from './integration.model';
import { ProductCatalogModel, ProductRatePlanCharge } from './product.model';
import { Subscription } from './subscriptions.model';
import {CustomerStoreChargeCredit, InvoiceTransaction} from './transaction.model';

export class InvoiceObject implements Deserializable<InvoiceObject>{
  constructor(
    public autoCollection?: boolean,
    public balanceDue?: string,
    public billingAddress?: Address,
    public comments?: string,
    public createdAt?: string,
    public currency?: CompanyCurrency,
    public customer?: CustomerProfile,
    public customerEmail?: string,
    public customerName?: string,
    public customerPhone?: string,
    public dateDue?: string,
    public dateFrom?: string,
    public datePaid?: string,
    public dateRefund?: string,
    public dateTo?: string,
    public dateVoid?: string,
    public detail?: Array<InvoiceDetailObject>,
    public id?: number,
    public invoiceNo?: string,
    public shippingAddress?: Address,
    public shippingAmount?: string,
    public status?: string,
    public type?: string,
    public subtotalTaxExc?: number,
    public subtotalTaxInc?: number,
    public taxAmount?: number,
    public taxRate?: number,
    public terms?: string,
    public total?: string,
    public transaction?: Array<InvoiceTransaction>,
    public updatedAt?: string,
  ){}

  deserialize(input: any): InvoiceObject {
    Object.assign(this, input);
    return this;
  }
}

export class InvoiceByIdObject implements Deserializable<InvoiceByIdObject> {
  constructor(
    public showSetExternalOrderAction?: boolean,
    public autoCollection?: boolean,
    public balanceDue?: number,
    public billingAddress?: Address,
    public comments?: string,
    public companyGateway?: CompanyGateway,
    public createdAt?: string,
    public currency?: CompanyCurrency,
    public customer?: CustomerProfile,
    public customerEmail?: string,
    public customerName?: string,
    public customerPaymentMethod?: CustomerPaymentSource,
    public customerPhone?: string,
    public dateDue?: string,
    public dateFrom?: string,
    public datePaid?: string,
    public dateRefund?: string,
    public dateTo?: string,
    public dateVoid?: string,
    public detail?: Array<InvoiceDetailObject>,
    public id?: number,
    public invoiceNo?: string,
    public logs?: Array<InvoiceLogs>,
    public offlinePaymentId?: string,
    public paymentType?: string,
    public refundAmount?: number,
    public shippingAddress?: Address,
    public shippingAmount?: number,
    public shippingCompanyIntegration?: CompanyIntegrationModel,
    public shippingServiceId?: number,
    public shippingServiceName?: string,
    public status?: string,
    public type?: string,
    public subtotalTaxExc?: number,
    public subtotalTaxInc?: number,
    public taxAmount?: number,
    public taxCompanyIntegration?: CompanyIntegrationModel,
    public taxShippingAmount?: number,
    public taxTotal?: number,
    public taxRate?: number,
    public terms?: string,
    public total?: string,
    public transaction?: Array<InvoiceTransaction>,
    public updatedAt?: string,
    public customerStoreChargeCredits?: CustomerStoreChargeCredit,
    public hasAnExternalOrder?: string,
  ){}

  deserialize(input: any): InvoiceByIdObject {
      Object.assign(this, input);
      return this;
  }
}

export class InvoiceDetailObject implements Deserializable<InvoiceDetailObject>{
  constructor(
    public amount?: string,
    public category?: ProductRatePlanChargesCategory,
    public chargeCredit?: ChargeCreditModel,
    public description?: string,
    public id?: number,
    public product?: ProductCatalogModel,
    public productRatePlanCharge?: ProductRatePlanCharge,
    public qty?: number,
    public ratePlanCharge?: RatePlanCharge,
    public subscription?: Subscription,
    public taxRate?: string,
    public totalTaxExc?: string,
    public totalTaxInc?: string,
  ){}

  deserialize(input: any): InvoiceDetailObject {
    Object.assign(this, input);
    return this;
  }
}

export class ChargeCreditModel implements Deserializable<ChargeCreditModel> {
    constructor(
      public amount?: number,
      public category?: ProductRatePlanChargesCategory,
      public companyCurrency?: CompanyCurrency,
      public createdAt?: Date,
      public depth?: string,
      public dimensionalRule?: string,
      public description?: string,
      public height?: string,
      public id?: number,
      public isFreeShipping?: boolean,
      public originLocation?: string,
      public qty?: number,
      public shippingGroup?: number,
      public taxable?: boolean,
      public taxClass?: string,
      public total?: number,
      public type?: ChargeCreditType,
      public weight?: number,
      public width?: string
    ) { }

    deserialize(input: any): ChargeCreditModel {
        Object.assign(this, input);
        return this;
    }
}


export class InvoiceLogs implements Deserializable<InvoiceLogs> {
  constructor(
    public createdAt?: string,
    public id?: number,
    public jsonContent?: Array<any>,
    public level?: string,
    public message?: string,
    public name?: string,
    public source?: string,
    public updatedAt?: string,
    public user?: User,

  ){}
    deserialize(input: any): InvoiceLogs {
      Object.assign(this, input);
      return this;
    }
  }
