import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from 'src/environments/environment';

import { DataSharingService, RestService, StorageService } from 'src/app/_core/services';
import { HttpParamService } from 'src/app/_core/services/api/http-param.service';
import { Observable } from 'rxjs';
import { PUT_CompanyIntegrationModel, RestResponse } from 'src/app/_shared/models';
import { HttpParamsInterface } from 'src/app/_shared/interfaces/http-params.interface';
import { POST_IntegrationSetting } from 'src/app/_shared/interfaces/integration.interface';
import { POST_OrdersImportInterface } from 'src/app/_shared/interfaces/order.interface';
import { IntegrationKeyName } from 'src/app/app.data';


const APIEndpoint: string = environment.APIEndpoint;
const APIVersion: string = environment.APIVersion;

const APIResourceCompanies: string = '/companies';
const APIResourceImport: string = '/import';
const APIResourceIntegrations: string = '/integrations';
const APIResourceOrders: string = '/orders';

@Injectable({
  providedIn: 'root'
})
export class SharedIntegrationService {

  private subscription: Subscription;
  private serviceUrl_Integrations: string;
  private serviceUrl_OrdersImport: string;

  constructor(private httpParamService: HttpParamService,
    private _shareData: DataSharingService,
    private _storage: StorageService,
    private _rest: RestService) {

      let entityId: string;

      this.subscription = this._shareData.currentCompany.subscribe(data => {
        if (data.companyId){
          entityId = `/${data.companyId}`;
        } else {
          entityId = `/${this._storage.readLocalStorage('entityId')}`;
        }

        // INTEGRATIONS
        // /companies/{{companyId}}/integrations
        this.serviceUrl_Integrations = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceIntegrations;

        // ORDERS
        // /companies/{{companyId}}/orders/import
        this.serviceUrl_OrdersImport = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceOrders + APIResourceImport;

    });
  }

  public createBigCommerceOrder(bodyParams: POST_OrdersImportInterface): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_OrdersImport}`, bodyParams);
  }

  public createIntegrationSettingByIntegrationId(integrationId: number, bodyParams: Array<POST_IntegrationSetting>): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_Integrations}/${integrationId}/settings`, bodyParams);
  }

  public getExternalProductImages(integrationId: number, externalProductId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Integrations}/${integrationId}/products/${externalProductId}/images`);
  }

  public createIntegrationByIntegrationId(integrationId: number, bodyParams: any): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_Integrations}/${integrationId}`, bodyParams);
  }

  public deleteCompanyIntegrationByIntegrationId(integrationId: number): Observable<RestResponse> {
    return this._rest.delete(`${this.serviceUrl_Integrations}/${integrationId}`);
  }

  public getBigcommerceOrdersByExternalId(companyIntegrationId: number, externalId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Integrations}/${companyIntegrationId}/orders/${externalId}`);
  }

  public getCompanyIntegrationsByChannelName(saleChannelName :string): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Integrations}/${saleChannelName}`);
  }

  public getCompanyIntegraionSettingsByIntegrationId(integrationId :number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Integrations}/${integrationId}/config`);
  }

  public getCustomerGroupSettings(companyIntegrationId: number): Observable<any> {
    return this._rest.get(`${this.serviceUrl_Integrations}/${companyIntegrationId}/customer-groups`);
  }

  public getExternalOrdersByIntegrationId(integrationId: number, pathParams?: HttpParamsInterface): Observable<RestResponse> {
    let params = pathParams ? this.httpParamService.buildPathParams(pathParams) : null;
    return this._rest.get(`${this.serviceUrl_Integrations}/${integrationId}/external-invoices`, { params: params });
  }

  public getGlobalIntegrationsByIntegrationName(integrationKeyName: string): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Integrations}/${integrationKeyName}/global-settings`);
  }

  public getIntegrationByIntegrationType(integrationType: string): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Integrations}?type=${integrationType}`);
  }

  public getIntegrationInfoByIntegrationId(integrationId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Integrations}/${integrationId}/info`);
  }

  public getIntegrationByIntegrationName(integrationKeyName: string): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Integrations}/${integrationKeyName}`);
  }

  public getIntegrationSettingsByIntegrationId(integrationId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Integrations}/${integrationId}/config`);
  }

  public getIntegrationStatusesByIntegrationId(integrationId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Integrations}/${integrationId}/orders/statuses`);
  }

  public testConfiguration(integrationId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Integrations}/${integrationId}/test-credentials`);
  }

  public updateCompanyIntegration(integrationId: number, bodyParams: PUT_CompanyIntegrationModel): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Integrations}/${integrationId}`, bodyParams);
  }

  public validateIntegration(companyIntegrationId: number): Observable<any> {
    return this._rest.get(`${this.serviceUrl_Integrations}/${companyIntegrationId}/check-delete`);
  }
}
