<div class="dialog-wrapper">
  <!-- Head -->
  <div class="head">
    <label class="label-title">Enter a New Payment Method</label>
    <button mat-icon-button (click)="onClose()" class="custom-mat-icon-button button-close-panel">
      <mat-icon svgIcon="ico-close" class="custom-mat-icon ico-size ico-2xs"></mat-icon>
    </button>
  </div>
  <div class="body">
    <form class="form-wrapper">
      <div class="form-content">
        <div class="form-section">
          <div class="form-section-container padding nopadding">
            <app-payment-form
              [_collectionOfCustomerAddressBook]=_data.collectionOfCustomerAddressBook
              [_billingAddress]=_data.billingAddress
              [_companyGateways]=_data.companyGateways
              (_emitPaymentForm)="onGetData($event)"
            ></app-payment-form>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="nav">
    <div class="inner">
      <button mat-flat-button (click)="onSubmit()" [disabled]="formSubmitted" color="primary" class="mat-button custom-mat-button-primary">Add Payment Method</button>
    </div>
  </div>
</div>
