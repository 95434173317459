//angular
import { Injectable, EventEmitter, ElementRef, Inject } from "@angular/core";
import { DOCUMENT } from '@angular/common';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';
//others
declare var Accept: any;
declare var Stripe: any;
declare var SqPaymentForm: any;
declare var Square: any;
//app
import { StorageService } from './storage.service';
import { RestResponse, GatewayBraintreePaymentResponse, GatewaySquarePaymentResponse, GatewayNonceResponse, POST_AddressInterface } from "src/app/_shared/models";
import { PaymentAccountKind } from "src/app/app.data";
import { KeyName } from "src/app/app.keynames";
import { SharedGlobalService } from "./api";
declare const usaepay: any;
declare const Flex: any;
export const enum PaymentFormInputSelectorsForAuthorize {
  cardCVV = 'authorize_CardCVV',
  cardExp = 'authorize_CardExp',
  cardNumber = 'authorize_CardNumber',
  cardZip = 'authorize_CardZip'
}

export const enum PaymentFormInputSelectorsForBraintree {
  cardCVV = 'braintree_CardCVV',
  cardExp = 'braintree_CardExp',
  cardNumber = 'braintree_CardNumber',
  cardZip = 'braintree_CardZip'
}

export const enum PaymentFormInputSelectorsForSquare {
  cardItem = 'square_Card'
}

export const enum PaymentFormInputSelectorsForStripe {
  cardNumber = 'stripe_CardNumber',
  cardExp = 'stripe_CardExp',
  cardCVV = 'stripe_CardCVV',
}

export interface AuthorizeNetData {
  clientKey: string;
  apiLoginID: string;
}

export interface AuthorizeNetModel {
  clientKey: string
  apiLoginID: string
}

export interface AuthorizeNetCardData {
  cardNumber: string;
  month: string;
  year: string;
  fullName: string;
  cardCode: string;
}

export interface AuthorizeNetSecureData {
  authData: AuthorizeNetData
  cardData: AuthorizeNetCardData
}

export const SQUARE_SUCCESS = 'OK';
@Injectable()
export class GatewayService {
  // used in campany-gateway.component
  isGatewayReady: boolean = false;
  _elemCardHolderName: ElementRef;
  _elemCardNumber: ElementRef;
  _elemCardExp: ElementRef;
  _elemCardCVV: ElementRef;
  _elemCardZip: ElementRef;
  paymentFormElements: any;
  paymentFormInstance: any;
  SqEmiter: EventEmitter<any> = new EventEmitter();
  squareCard: any;
  private window: any;

  constructor(
    private _http: HttpClient,
    private _storage: StorageService,
    private sharedGlobalService: SharedGlobalService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
  }

  public getNonce(_gateway, _fields): Observable<any>{
    return new Observable((observer) => {
      switch (_gateway.gatewayId) {
        case 1: // Braintree
        case 'braintree':
          this.getNonceWithBraintree(_fields).subscribe(
            (res: GatewayBraintreePaymentResponse) => {
              let data: GatewayNonceResponse = new GatewayNonceResponse;
              data.accountKind = res.type;
              data.accountType = res.details.cardType;
              data.accountName = _fields;
              data.nonce = res.nonce;
              observer.next(data);
            },
            (err: any) => {
              observer.error(err);
            }
          )
          break;
        case 2: // AuthorizeNET
        case 'authorizenet':
          // this._gateway.getClientToken(`${this._apiCustomer.serviceUrl_CompanyGateways}/${this.selectedCompanyGateway.id}/client_token`).subscribe(
          //   (res: RestResponse) => {
          //     let merchantId = res.data['merchantId'];
          //     let publicKey = res.data['publicKey'];
          //     this._gateway.getNonceWithAuthorize(publicKey, merchantId).subscribe(
          //       (res: RestResponse) => {
          //         //Load form values
          //         this.form.controls.accountName.setValue(this._elemCardHolderName.nativeElement.value);
          //         this.form.controls.paymentMethod.get('nonce').setValue(res['opaqueData'].dataValue);
          //         this.form.controls.accountKind.setValue(PaymentAccountKind.credit);
          //         //Add Customer Payment Method
          //         this.addPaymentSource();
          //       },
          //       (err: RestResponse) => {
          //         console.log(err);
          //         this._message.showMessage({ 'errCode': err.code })
          //       }
          //     )
          //   },
          //   (err: RestResponse) => {
          //     this._loader.stopLoader();
          //     this._message.showMessage({ 'errCode': err.code })
          //   }
          // );
          break;
        case 3: // Stripe
        case 'stripe':
          // this._gateway.getNonceWithStripe(this._elemCardHolderName.nativeElement.value, this.selectedBillingAddress).subscribe(
          //   (res: any) => {
          //     //Load form values
          //     this.form.controls.accountName.setValue(this._elemCardHolderName.nativeElement.value);
          //     this.form.controls.paymentMethod.get('nonce').setValue(res.token.id);
          //     this.form.controls.accountKind.setValue(res.type);
          //     //Add Customer Payment Method
          //     this.addPaymentSource();
          //   },
          //   (err: any) => {
          //     console.log(err);
          //   }
          // )
          break;
        case 4: // Square
        case 'square':
          this.getNonceWithSquare().subscribe(
            (res: GatewaySquarePaymentResponse) => {
              let data: GatewayNonceResponse = new GatewayNonceResponse;
              data.accountName = _fields;
              data.accountKind = PaymentAccountKind.credit;
              data.accountType = res.cardData.card_brand;
              data.nonce = res.nonce;
              observer.next(data);
            },
            (err: any) => {
              console.log(`err. ${err}`);
              observer.next(err);
            }
          )
          break;
      }
      return { unsubscribe() { } };
    })
  }

  public getNonceWithUsaepay(){
    return new Observable((observer) => {
      this.paymentFormInstance.getPaymentKey(this.paymentFormElements).then(res => {
        if (res.error) {
          observer.error(res.error);
        } else {
          observer.next(res);
        }
      }).catch( err => {
        observer.error(err);
      });
      })
    return;
  }

  public getNonceWithCybersource(cardData = {}){
    return new Observable((observer) => {
      this.paymentFormElements.createToken(cardData, function (err, token) {
        if (err) {
          // handle error
          observer.error(err.message);
        } else {
          observer.next(token);
        }
      });
    })
    return;
  }

  public getNonceWithAuthorize(_clientKey, _apiLoginID, _cardData): Observable<any> {
    let authData = {} as AuthorizeNetModel;
    let cardData = {} as AuthorizeNetCardData;

    authData.clientKey = _clientKey;
    authData.apiLoginID = _apiLoginID;

    let expData = _cardData.cardExp.split('/');

    cardData.cardCode = _cardData.cardCode;
    cardData.cardNumber = _cardData.cardNumber.replace(/ /g,'');
    cardData.fullName = _cardData.fullName;
    cardData.month = expData[0];
    cardData.year = expData[1];

    let secureData = {
      authData: authData,
      cardData: cardData
    };

    return new Observable((observer) => {
      Accept.dispatchData(secureData, (res) => {
        if (res.messages.resultCode == 'Error') {
          let errorMessage = this.handleErrorsMessagesAuthorize(res);
          observer.error(errorMessage);
        }else {
          observer.next(res);
        }
      });

      return { unsubscribe() { } };
    });
  }

  public getNonceWithBraintree(_cardHolderName:string): Observable<any> {
    return new Observable((observer) => {
      this.paymentFormInstance.tokenize({ cardholderName: _cardHolderName })
        .then(
          (res) => {
            observer.next(res);
          }
        ).catch(
          (err) => {
            observer.error(err);
          }
        );
    });
  }

  public getNonceWithSquare(): Observable<any> {
    return new Observable((observer) => {
      this.squareCard.tokenize().then((tokenResult) => {
        if (tokenResult.status === SQUARE_SUCCESS) {
          observer.next({ nonce: tokenResult.token, cardData: tokenResult.details });
        } else {
          const errorMessage = this.handleErrorsMessagesSquare(tokenResult)
          observer.error(errorMessage);
        }
      });
    })
  }

  public getNonceWithStripe(_cardHolderName: string, _address: POST_AddressInterface, customerId: any, companyGatewayId: any): Observable<any> {
    let extraDetails = {
      name: _cardHolderName,
      address_line1: (_address.street1 ? _address.street1 : ''),
      address_line2: (_address.street2 ? _address.street2 : ''),
      address_city: (_address.city ? _address.city : ''),
      address_state: (_address.state ? _address.state : ''),
      address_zip: (_address.zip ? _address.zip : ''),
      address_country: (_address.countryCode ? _address.countryCode : '')
    };

    return new Observable((observer) => {
      this.sharedGlobalService.customerSetupStripeIntent(companyGatewayId, customerId).subscribe(({data}: any)=>{
        this.paymentFormInstance.confirmCardSetup(
          data.clientSecret,
          {
            payment_method: {
              card: this.paymentFormInstance.gatewayPaymentInstanceCardFieldNo,
              billing_details: {name: extraDetails.name}
            }
          }
        ).then(function(result) {
          if (result.error) {
            let errorMessage = this.handleErrorsMessagesStripe(result);
            observer.error(errorMessage);
          } else {
            // The SetupIntent was successful!
            observer.next(result.setupIntent);
          }
        });
      }, ((err) => {
        observer.error(err);
      }))
    })
  }

  public getClientToken(_serviceUrl, _existCompanyId): Observable<RestResponse> {
    if (!_existCompanyId) {
      _serviceUrl = this.sharedGlobalService.getServiceUrlSystemGateways();
    }
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${this._storage.readLocalStorage('access_token')}`)
      .set("Accept", "application/json")
    return this._http
      .get(_serviceUrl, { headers, responseType: 'json', observe: 'response' })
      .pipe(
        map((data: any) => this.handleSuccess(data))
      )
  }

  handleErrorsMessagesAuthorize(err) {

    let firstErrorToShow = err?.messages?.message ? err.messages.message[0].text : KeyName.request_error;

    let errorMessage = {
      code: KeyName.request_error,
      message: firstErrorToShow
    };

    return errorMessage;
  }

  handleErrorsMessagesBraintree(err) {
    let errorMessage = {
      code: KeyName.request_error,
      message: err.message
    };

    return errorMessage;
  }

  handleErrorsMessagesSquare(tokenResult) {
    let errorMessageText = `Tokenization failed with status: ${tokenResult.status}`;
    // if (tokenResult.errors) {
    //   errorMessageText += ` and errors: ${JSON.stringify(
    //     tokenResult.errors
    //   )}`;
    // }
    let errorMessage = {
      code: KeyName.request_error,
      message: errorMessageText
    };

    return errorMessage;
  }

  handleErrorsMessagesStripe(err) {
    let errorMessageFromGateway = err ? err["error"].message : KeyName.request_error;

    let errorMessage = {
      code: KeyName.request_error ,
      message: errorMessageFromGateway
    };

    return errorMessage;
  }

  public doTokenizeAchFormWithBraintree(): Observable<any> {
    return new Observable((observer) => {
      let bankDetails = {
        // accountNumber: this.form.value.accountNumber,
        // routingNumber: this.form.value.routingNumber,
        // accountType: this.form.value.accountType,
        // ownershipType: this.form.value.ownershipType,
        // billingAddress: {
        //   streetAddress: this.form.value.billingAddress.streetAddress,
        //   extendedAddress: this.form.value.billingAddress.extendedAddress,
        //   locality: this.form.value.billingAddress.extendedAddress,
        //   region: 'CA',
        //   postalCode: this.form.value.billingAddress.postalCode
        // }
      };
      // if (this.ownershipType === 'business') {
      //   bankDetails['businessName'] = this.form.value.businessName;
      // } else {
      //   bankDetails['firstName'] = this.form.value.firstName;
      //   bankDetails['lastName'] = this.form.value.lastName;
      // }

      // this.usBankAccountInstance.tokenize({
      //   bankDetails: bankDetails,
      //   mandateText: 'By clicking ["Checkout"], I authorize Braintree, a service of PayPal, on behalf of Rebillia LLC (i) to verify my bank account information using bank information and consumer reports and (ii) to debit my bank account.'
      // }, (tokenizeErr, tokenizedPayload) => {
      //   if (tokenizedPayload) {
      //     console.log(tokenizedPayload);
      //     observer.next(tokenizedPayload);
      //   }
      //   if (tokenizeErr) {
      //     console.log(tokenizeErr);
      //     observer.next(tokenizeErr);
      //   }
      // });
    });
  }

  public doTokenizeCardFormWithBraintree(_clientId: string): Observable<any> {
    return new Observable((observer) => {
      this.window.braintree.client.create({
        authorization: _clientId
      }).then((clientInstance) => {
        this.window.braintree.hostedFields.create({
          client: clientInstance,
          fields: {
            number: { selector: `#${PaymentFormInputSelectorsForBraintree.cardNumber}`, placeholder: '4111 1111 1111 1111' },
            cvv: { selector: `#${PaymentFormInputSelectorsForBraintree.cardCVV}`, placeholder: '•••' },
            expirationDate: { selector: `#${PaymentFormInputSelectorsForBraintree.cardExp}`, placeholder: 'MM/YYYY' },
            postalCode: { selector: `#${PaymentFormInputSelectorsForBraintree.cardZip}`, placeholder: '12345' }
          },
          styles: {
            'input': {
              'font-size': '1em',
              'font-family': '"Roboto Condensed", sans-serif',
              'font-weight': '400',
              'color': 'black'
            },
            '::placeholder': {
              'color': '#AFB3B7',
              'font-family': '"Roboto Condensed", sans-serif',
            },
            ':focus': {
              'color': 'black'
            },
            '.valid': {
              'color': 'black'
            },
            '.invalid': {
              'color': '#ff1744'
            }
          }
        }).then((hostedFieldsInstance) => {
          this.paymentFormInstance = hostedFieldsInstance;
          observer.next(hostedFieldsInstance );
        }).catch((error) => {
          let errorMessage = this.handleErrorsMessagesBraintree(error);
          observer.error(errorMessage);
        });
      }).catch((error) => {
        observer.error(error);
      });
    });
  }

  public doTokenizeCardFormWithSquare(_clientToken: string, _zipCode: string = ''): Observable<any> {
    return new Observable((observer) => {
      this.paymentFormInstance = Square.payments(_clientToken);
      if (this.squareCard) {
        this.squareCard.destroy().then(() => {
          this._attachSquareCardToHtml(observer, _zipCode)
        })
      } else {
        this._attachSquareCardToHtml(observer, _zipCode)
      }
    })
  }

  private _attachSquareCardToHtml(observer, _zipCode) {
    this.paymentFormInstance.card({
      postalCode: _zipCode
    }).then((card: any) => {
      observer.next(this.paymentFormInstance);
      card.attach("#" + PaymentFormInputSelectorsForSquare.cardItem);
      this.squareCard = card;
    })
  }

  public doTokenizeCardFormWithUsaepay(_clientToken: string): Observable<any> {
    return new Observable((observer) => {
      // Instantiate Client with Public API Key
      this.paymentFormInstance = new usaepay.Client(_clientToken);

      // Instantiate Payment Card Entry
      this.paymentFormElements =  this.paymentFormInstance.createPaymentCardEntry();
      const styles = {
            'container': {
              "width": "100%",
              "height": "40px",
              "border-radius": "4px",
              "border": "1px solid #ddd"
          }
        }

        this.paymentFormElements.generateHTML({styles});

        this.paymentFormElements.addHTML('paymentCardContainer');
        this.paymentFormElements.addEventListener('error', err => {
          err = JSON.parse(err);
          console.log(err);
      })

      observer.next(this.paymentFormInstance);
    })
  }

  public doTokenizeCardFormWithCybersource(_clientToken: string): Observable<any> {
    return new Observable((observer) => {
      try {
        this.paymentFormInstance = new Flex(_clientToken);
        const style = {
          base: {
            color: '#212121',
            fontFamily: '"Roboto Condensed",sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '1.0875em',
            '::placeholder': { color: '#616161' }
          },
          invalid: {
            color: '#ff1744',
            iconColor: '#ff1744'
          }
        };
        this.paymentFormElements = this.paymentFormInstance.microform({styles: style});
        const number = this.paymentFormElements.createField('number', {placeholder: 'Enter card number'});
        const securityCode = this.paymentFormElements.createField('securityCode', {placeholder: '•••'});
        number.load('#number-container');
        securityCode.load('#securityCode-container');
        observer.next(this.paymentFormInstance);
      } catch (e) {
        console.log('e', e)
      }

    })
  }

  public doTokenizeCardFormWithStripe(_clientToken: string): Observable<any> {
    return new Observable((observer) => {
      this.paymentFormInstance = Stripe(_clientToken);
      this.paymentFormElements = this.paymentFormInstance.elements();
      const style = {
        base: {
          color: '#212121',
          fontFamily: '"Roboto Condensed",sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '1.0875em',
          '::placeholder': { color: '#616161' }
        },
        invalid: {
          color: '#ff1744',
          iconColor: '#ff1744'
        }
      };

      this.paymentFormInstance.gatewayPaymentInstanceCardFieldNo = this.paymentFormElements.create('cardNumber', { style: style });
      this.paymentFormInstance.gatewayPaymentInstanceCardFieldNo.mount(`#${PaymentFormInputSelectorsForStripe.cardNumber}`);
      this.paymentFormInstance.gatewayPaymentInstanceCardFieldExp = this.paymentFormElements.create('cardExpiry', { style: style });
      this.paymentFormInstance.gatewayPaymentInstanceCardFieldExp.mount(`#${PaymentFormInputSelectorsForStripe.cardExp}`);
      this.paymentFormInstance.gatewayPaymentInstanceCardFieldCVV = this.paymentFormElements.create('cardCvc', { style: style });
      this.paymentFormInstance.gatewayPaymentInstanceCardFieldCVV.mount(`#${PaymentFormInputSelectorsForStripe.cardCVV}`);

      observer.next(this.paymentFormInstance);
    })
  }

  public setCardPaymentForm(_gateway: any): Observable<any> {
    return new Observable((observer) => {
      switch (_gateway.gatewayId) {
        case 1:
        case 'braintree':
          //Braintree
          this.doTokenizeCardFormWithBraintree(_gateway.setting.publicKey).subscribe(
            (res: any) => {
              observer.next(res);
            },
            (err: any) => {
              observer.error(err)
            }
          );
          break;
        case 2:
        case 'authorizenet':
          //AuthorizeNET
          observer.next();
          break;
        case 3:
        case 'stripe':
          //Stripe
          // this.doTokenizeCardFormWithStripe(data.publicKey).subscribe(
          //   (res: any) => {
          //   },
          //   (err: any) => {
          //   }
          // );
          break;
        case 4:
        case 'square':
          //Square
          this.doTokenizeCardFormWithSquare(_gateway.setting.publicKey).subscribe(
            (res: any) => {
              observer.next(res);
            },
            (err: any) => {
              observer.error(err)
            }
          );
          break;
      }
    })
  }

  private handleSuccess(_data: any) {
    return new RestResponse().onSuccess(_data.status, _data.body);
  }


}
