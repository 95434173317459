export class TaxRegion {
    id: string;
    isEnabled?: boolean
    regionName: string;
}

export class TaxLocale {
    id: string;
    regionName: string;
    isEnabled?: boolean
}