export enum KeyName {
  archive_integration = 'archive_integration',
  archive_subscription = 'archive_subscription',
  bigcommerce_settings = 'bigcommerce_settings',
  account_closed = 'account_closed',
  cancel_account = 'cancel_account',
  cancel_invite = 'cancel_invite',
  cancel_invoice = 'cancel_invoice',
  change_access_level = 'change_access_level',
  change_auto_collection_invoice = 'change_auto_collection_invoice',
  change_charge = 'change_charge',
  new_sync_active_plans = 'new_sync_active_plans',
  change_customer_status = 'change_customer_status',
  enforce_customer_gdpr = 'enforce_customer_gdpr',
  block_account = 'block_account',
  unblock_account = 'unblock_account',
  merge_customers = 'merge_customers',
  change_gateway_status_to_active = 'change_gateway_status_to_active',
  change_gateway_status_to_archive = 'change_gateway_status_to_archive',
  change_gateway_status_to_disabled = 'change_gateway_status_to_disabled',
  change_importer_product_task_status = 'change_importer_product_task_status',
  change_product_rateplan_status = 'change_product_rateplan_status',
  product_rateplan_discontinue = 'product_rateplan_discontinue',
  set_order_action = 'set_order_action',
  change_product_status = 'change_product_status',
  change_products_status = 'change_products_status',
  change_subscription_status = 'change_subscription_status',
  change_subscription_rate_plan_status = 'change_subscription_rate_plan_status',
  skip_rate_plan_charge = 'skip_rate_plan_charge',
  change_price_for_rate_plan_charge = 'change_price_for_rate_plan_charge',
  change_shipping_price_for_rate_plan_charge = 'change_shipping_price_for_rate_plan_charge',
  change_subscription_rate_plan_charge_status = 'change_subscription_rate_plan_charge_status',
  activate_lead = 'activate_lead',
  channel_notification_already_exist = 'channel_notification_already_exist',
  close_Modal = 'close_Modal',
  create_customer_address = 'create_customer_address',
  delete_integration = 'delete_integration',
  delete_charge = 'delete_charge',
  delete_customer_group = 'delete_customer_group',
  delete_integration_shipping = 'delete_integration_shipping',
  delete_integration_tax = 'delete_integration_tax',
  delete_tax_region = 'delete_tax_region',
  delete_currency = 'delete_currency',
  delete_external_product = 'delete_external_product',
  delete_external_charge = 'delete_external_charge',
  sync_modifiers = 'sync_modifiers',
  delete_external_rate_plan_charge = 'delete_external_rate_plan_charge',
  subscription_unlink_sale_channel = 'subscription_unlink_sale_channel',
  delete_gateway = 'delete_gateway',
  delete_invoice = 'delete_invoice',
  delete_notification = 'delete_notification',
  delete_plan = 'delete_plan',
  delete_product = 'delete_product',
  bulk_pause_product = 'bulk_pause_product',
  delete_subscription = 'delete_subscription',
  delete_rate_plan = 'delete_rate_plan',
  delete_rate_plan_charge = 'delete_rate_plan_charge',
  sync_active_plans = 'sync_active_plans',
  mark_as_template = 'mark_as_template',
  sync_with_template = 'sync_with_template',
  apply_template = 'apply_template',
  disable_SMTP_integration = 'disable_SMTP_integration',
  disengage_company_gateway = 'disengage_company_gateway',
  disengage_company_gateway_success = 'disengage_company_gateway_success',
  do_payment_with_credit_card = 'do_payment_with_credit_card',
  do_payment_with_ach = 'do_payment_with_ach',
  error_auth0 = 'error_auth0',
  error_bad_request = 'error_bad_request',
  error_bad_request_change_role_owner = 'error_bad_request_change_role_owner',
  // error_bad_request_change_role_yourself = 'error_bad_request_change_role_yourself',
  // error_bad_request_company_currency = 'error_bad_request_company_currency',
  error_bad_request_company_integration_action_Disconnect = 'error_bad_request_company_integration_action_Disconnect',
  error_bad_request_currency_default = 'error_bad_request_currency_default',
  error_bad_request_currency_in_use = 'error_bad_request_currency_in_use',
  // error_bad_request_email_setting = 'error_bad_request_email_setting',
  error_bad_request_gateway_delete = 'error_bad_request_gateway_delete',
  // error_bad_request_integration_action = 'error_bad_request_integration_action',
  // error_bad_request_invite_user_company = 'error_bad_request_invite_user_company',
  // error_bad_request_invite_user_company_duplicated = 'error_bad_request_invite_user_company_duplicated',
  // error_bad_request_invite_yourself = 'error_bad_request_invite_yourself',
  // error_bad_request_jwt_validation = 'error_bad_request_jwt_validation',
  error_bad_request_payment_method_has_active_subscriptions = 'error_bad_request_payment_method_has_active_subscriptions',
  error_bad_request_big_commerce = 'error_bad_request_big_commerce',
  // error_bad_request_product_action = 'error_bad_request_product_action',
  // error_bad_request_role_owner = 'error_bad_request_role_owner',
  error_bad_request_route = 'error_bad_request_route',
  // error_bad_request_set_role_owner = 'error_bad_request_set_role_owner',
  // error_bad_request_timezone_convertion = 'error_bad_request_timezone_convertion',
  // error_balance = 'error_balance',
  // error_bigcommerce_login_email = 'error_bigcommerce_login_email',
  // error_create_transaction = 'error_create_transaction',
  error_duplicated_rule_filter = 'error_duplicated_rule_filter',
  error_exchange_rates = 'error_exchange_rates',
  // error_forbidden = 'error_forbidden',
  // error_forbidden_company = 'error_forbidden_company',
  error_forbidden_company_action = 'error_forbidden_company_action',
  // error_forbidden_payment_method = 'error_forbidden_payment_method',
  // error_forbidden_payment_method_action = 'error_forbidden_payment_method_action',
  // error_forbidden_plan_limitations = 'error_forbidden_plan_limitations',
  error_form_attributes = 'error_form_attributes',
  error_gateway = 'error_gateway',
  error_gateway_not_found = 'error_gateway_not_found',
  error_gateway_doesnt_belong_rebillia_gateway = 'error_gateway_doesnt_belong_rebillia_gateway',
  error_integration = 'error_integration',
  error_incomplete_subscription = 'error_incomplete_subscription',
  // error_integration_authentication = 'error_integration_authentication',
  // error_integration_disabled = 'error_integration_disabled',
  error_malformed_request = 'error_malformed_request',
  error_not_active_gateway_found ='error_not_active_gateway_found',
  error_not_countries_found ='error_not_countries_found',
  error_not_currencies_found = 'error_not_currencies_found',
  error_not_found = 'error_not_found',
  // error_not_found_company = 'error_not_found_company',
  // error_not_found_company_bill_run = 'error_not_found_company_bill_run',
  // error_not_found_company_currency = 'error_not_found_company_currency',
  // error_not_found_company_gateway = 'error_not_found_company_gateway',
  error_not_found_company_integration = 'error_not_found_company_integration',
  error_external_interval_failed_for_product_status = 'error_external_interval_failed_for_product_status',
  // error_not_found_company_payment_run = 'error_not_found_company_payment_run',
  // error_not_found_currency = 'error_not_found_currency',
  error_not_found_customer = 'error_not_found_customer',
  error_not_found_customer_address_book = 'error_not_found_customer_address_book',
  error_not_found_customer_group = 'error_not_found_customer_group',
  // error_not_found_customer_payment_method = 'error_not_found_customer_payment_method',
  error_not_found_email_template = 'error_not_found_email_template',
  // error_not_found_external_customer_address_book = 'error_not_found_external_customer_address_book',
  // error_not_found_external_order = 'error_not_found_external_order',
  // error_not_found_external_product = 'error_not_found_external_product',
  // error_not_found_integration = 'error_not_found_integration',
  // error_not_found_integration_key = 'error_not_found_integration_key',
  // error_not_found_integration_setting = 'error_not_found_integration_setting',
  // error_not_found_integration_value = 'error_not_found_integration_value',
  // error_not_found_product = 'error_not_found_product',
  // error_not_found_product_rateplan = 'error_not_found_product_rateplan',
  // error_not_found_product_rateplan_charge = 'error_not_found_product_rateplan_charge',
  // error_not_found_rate_plan = 'error_not_found_rate_plan',
  // error_not_found_rate_plan_charge = 'error_not_found_rate_plan_charge',
  // error_not_found_setting_type = 'error_not_found_setting_type',
  // error_not_found_subscription = 'error_not_found_subscription',
  error_not_gateway_associated = 'error_not_gateway_associated',
  error_external_interval_failed = 'error_external_interval_failed',
  error_rebillia_connector = 'error_rebillia_connector',
  // error_sales_channel_service = 'error_sales_channel_service',
  error_server_api = 'error_server_api',
  // error_server_api_analytics_billing_activity = 'error_server_api_analytics_billing_activity',
  // error_server_api_analytics_get_totals = 'error_server_api_analytics_get_totals',
  error_server_api_analytics_list_by_sales_channel = 'error_server_api_analytics_list_by_sales_channel',
  error_product_not_published_due_to_not_active_rateplan = 'error_product_not_published_due_to_not_active_rateplan',
  // error_server_api_analytics_list_invoices = 'error_server_api_analytics_list_invoices',
  // error_server_api_analytics_payment_activity = 'error_server_api_analytics_payment_activity',
  // error_server_api_bill_run_get = 'error_server_api_bill_run_get',
  // error_server_api_bill_run_invoices = 'error_server_api_bill_run_invoices',
  // error_server_api_bill_run_list = 'error_server_api_bill_run_list',
  // error_server_api_bill_run_update = 'error_server_api_bill_run_update',
  // error_server_api_company_integration_create = 'error_server_api_company_integration_create',
  // error_server_api_company_integration_delete = 'error_server_api_company_integration_delete',
  // error_server_api_company_integration_keys = 'error_server_api_company_integration_keys',
  // error_server_api_company_integration_keys_set = 'error_server_api_company_integration_keys_set',
  // error_server_api_company_integration_list = 'error_server_api_company_integration_list',
  // error_server_api_company_integration_list_by_key_name = 'error_server_api_company_integration_list_by_key_name',
  error_server_api_company_integration_settings = 'error_server_api_company_integration_settings',
  // error_server_api_company_integration_settings_set = 'error_server_api_company_integration_settings_set',
  // error_server_api_company_integration_update = 'error_server_api_company_integration_update',
  // error_server_api_customer_address_book_create = 'error_server_api_customer_address_book_create',
  // error_server_api_customer_address_book_list = 'error_server_api_customer_address_book_list',
  // error_server_api_customer_address_book_update = 'error_server_api_customer_address_book_update',
  // error_server_api_customer_payment_method_by_id = 'error_server_api_customer_payment_method_by_id',
  // error_server_api_customer_payment_method_list = 'error_server_api_customer_payment_method_list',
  // error_server_api_customer_payment_method_remove = 'error_server_api_customer_payment_method_remove',
  // error_server_api_customer_payment_method_update = 'error_server_api_customer_payment_method_update',
  // error_server_api_database = 'error_server_api_database',
  // error_server_api_database_user = 'error_server_api_database_user',
  // error_server_api_external_customer_address_book_create = 'error_server_api_external_customer_address_book_create',
  // error_server_api_external_invoice_list = 'error_server_api_external_invoice_list',
  error_server_api_external_product_delete = 'error_server_api_external_product_delete',
  error_server_api_external_product_duplicate = 'error_server_api_external_product_duplicate',
  // error_server_api_gateway_get_client_token = 'error_server_api_gateway_get_client_token',
  // error_server_api_integration_get_by_key_name = 'error_server_api_integration_get_by_key_name',
  // error_server_api_integration_list = 'error_server_api_integration_list',
  // error_server_api_payment_run_get = 'error_server_api_payment_run_get',
  // error_server_api_payment_run_list = 'error_server_api_payment_run_list',
  // error_server_api_payment_run_transactions = 'error_server_api_payment_run_transactions',
  // error_server_api_payment_run_update = 'error_server_api_payment_run_update',
  // error_server_api_product_create = 'error_server_api_product_create',
  // error_server_api_product_delete = 'error_server_api_product_delete',
  // error_server_api_product_get = 'error_server_api_product_get',
  // error_server_api_product_list = 'error_server_api_product_list',
  // error_server_api_product_rateplan_charge_calculate_price = 'error_server_api_product_rateplan_charge_calculate_price',
  // error_server_api_product_rateplan_charge_create = 'error_server_api_product_rateplan_charge_create',
  // error_server_api_product_rateplan_charge_delete = 'error_server_api_product_rateplan_charge_delete',
  // error_server_api_product_rateplan_charge_get = 'error_server_api_product_rateplan_charge_list',
  // error_server_api_product_rateplan_charge_list = 'error_server_api_product_rateplan_charge_list',
  // error_server_api_product_rateplan_charge_update = 'error_server_api_product_rateplan_charge_update',
  // error_server_api_product_rateplan_create = 'error_server_api_product_rateplan_create',
  // error_server_api_product_rateplan_delete = 'error_server_api_product_rateplan_delete',
  // error_server_api_product_rateplan_external_product_create = 'error_server_api_product_rateplan_external_product_create',
  // error_server_api_product_rateplan_external_product_delete = 'error_server_api_product_rateplan_external_product_delete',
  // error_server_api_product_rateplan_external_product_list = 'error_server_api_product_rateplan_external_product_list',
  // error_server_api_product_rateplan_get = 'error_server_api_product_rateplan_get',
  // error_server_api_product_rateplan_list = 'error_server_api_product_rateplan_list',
  // error_server_api_product_rateplan_status = 'error_server_api_product_rateplan_status',
  // error_server_api_product_rateplan_sync = 'error_server_api_product_rateplan_sync',
  // error_server_api_product_rateplan_update = 'error_server_api_product_rateplan_update',
  // error_server_api_product_status = 'error_server_api_product_status',
  error_server_api_product_update = 'error_server_api_product_update',
  // error_server_api_rateplan_charge_schedule_next_charge = 'error_server_api_rateplan_charge_schedule_next_charge',
  // error_server_api_subscription_change_rate_plan_charge_status = 'error_server_api_subscription_change_rate_plan_charge_status',
  // error_server_api_subscription_change_status = 'error_server_api_subscription_change_status',
  // error_server_api_subscription_create = 'error_server_api_subscription_create',
  // error_server_api_subscription_create_rate_plan = 'error_server_api_subscription_create_rate_plan',
  // error_server_api_subscription_create_rate_plan_charge = 'error_server_api_subscription_create_rate_plan_charge',
  // error_server_api_subscription_delete = 'error_server_api_subscription_delete',
  // error_server_api_subscription_delete_rate_plan = 'error_server_api_subscription_delete_rate_plan',
  // error_server_api_subscription_delete_rate_plan_charge = 'error_server_api_subscription_delete_rate_plan_charge',
  // error_server_api_subscription_get_external_product = 'error_server_api_subscription_get_external_product',
  // error_server_api_subscription_list = 'error_server_api_subscription_list',
  // error_server_api_subscription_list_by_customer = 'error_server_api_subscription_list_by_customer',
  // error_server_api_subscription_list_by_id = 'error_server_api_subscription_list_by_id',
  // error_server_api_subscription_list_external_invoices = 'error_server_api_subscription_list_external_invoices',
  // error_server_api_subscription_list_external_invoices_by_company_integration = 'error_server_api_subscription_list_external_invoices_by_company_integration',
  // error_server_api_subscription_list_invoices = 'error_server_api_subscription_list_invoices',
  // error_server_api_subscription_list_logs = 'error_server_api_subscription_list_logs',
  // error_server_api_subscription_list_rate_plan = 'error_server_api_subscription_list_rate_plan',
  // error_server_api_subscription_list_rate_plan_charge = 'error_server_api_subscription_list_rate_plan_charge',
  // error_server_api_subscription_list_upcoming = 'error_server_api_subscription_list_upcoming',
  // error_server_api_subscription_next_bill = 'error_server_api_subscription_next_bill',
  // error_server_api_subscription_next_bill_not_found = 'error_server_api_subscription_next_bill_not_found',
  // error_server_api_subscription_update = 'error_server_api_subscription_update',
  // error_server_api_subscription_update_payment_method = 'error_server_api_subscription_update_payment_method',
  // error_server_api_subscription_update_rate_plan = 'error_server_api_subscription_update_rate_plan',
  // error_server_api_subscription_update_rate_plan_charge = 'error_server_api_subscription_update_rate_plan_charge',
  // error_server_api_subscription_update_shipping_address = 'error_server_api_subscription_update_shipping_address',
  // error_shipping_rates_get = 'error_shipping_rates_get',
  // error_shipping_rates_list = 'error_shipping_rates_list',
  // error_shipping_services = 'error_shipping_services',
  // error_status_invoice = 'error_status_invoice',
  // error_tax_account_create = 'error_tax_account_create',
  // error_tax_calculate = 'error_tax_calculate',
  // error_tax_code = 'error_tax_code',
  // error_tax_create_transaction = 'error_tax_create_transaction',
  // error_tax_region_add = 'error_tax_region_add',
  // error_tax_region_list_by_company = 'error_tax_region_list_by_company',
  // error_tax_region_list_by_country = 'error_tax_region_list_by_country',
  // error_tax_region_remove = 'error_tax_region_remove',
  // error_update_invoice = 'error_update_invoice',
  email_status_disabled = 'email_status_disabled',
  email_status_enabled = 'email_status_enabled',
  enable_integration = 'enable_integration',
  execute_billing_run_now = 'execute_billing_run_now',
  save_rbl_scope_key_list = 'save_rbl_scope_key_list',
  save_experimental_features = 'save_experimental_features',
  execute_payment_run_now = 'execute_payment_run_now',
  form_invalid = 'form_invalid',
  form_submitted = 'form_submitted',
  message_removed = 'message_removed',
  no_product_rate_plan_charges = 'no_product_rate_plan_charges',
  plan_core = 'core',
  plan_professional = 'professional',
  plan_enterprise = 'enterprise',
  reconnect_gateway = 'reconnect_gateway',
  refund_invoice = 'refund_invoice',
  remove_address = 'remove_address',
  remove_charge_credit = 'remove_charge_credit',
  remove_charge = 'remove_charge',
  remove_charge_inLine = 'remove_charge_inLine',
  remove_integration = 'remove_integration',
  remove_gateway = 'remove_gateway',
  remove_payment = 'remove_payment',
  request_error = 'request_error',
  request_error_transaction_unsuccessful = 'request_error_transaction_unsuccessful',
  request_success = 'request_success',
  revoke_access_user = 'revoke_access_user',
  switch_product_rate_plan = 'switch_product_rate_plan',
  sales_channel_order = 'sales_channel_order',
  sales_channel_checkout = 'sales_channel_checkout',
  set_as_default_payment_method = 'set_as_default_payment_method',
  set_as_default_smart_routing = 'set_as_default_smart_routing',
  test_Avalara_configuration = 'test_Avalara_configuration',
  subscription_shipping = 'subscription_shipping',
  system_message_removed = 'message_removed',
  test_gateway = 'test_gateway',
  remove_customer_address_book_error_billing = 'remove_customer_address_book_error_billing',
  remove_customer_address_book_error_shipping = 'remove_customer_address_book_error_shipping',
  remove_customer_address_book_error_payment  = 'remove_customer_address_book_error_payment',
  export_csv = 'export_csv',
  manually_rerun_payment_run = 'manually_rerun_payment_run',
  limit_reached = 'limit_reached',
  switch_company_for_closed = 'switch_company_for_closed',
  delete_webhook = 'delete_webhook',
  remove_shipping_protection = 'remove_shipping_protection',
  remove_shipping_zone = 'remove_shipping_zone',

}

export interface messageIterface {
  value: string;
  message: string;
  messageWithVariables?: string[];
}

export const collectionOfKeyNamesMessages: messageIterface[] = [

  { value: KeyName.channel_notification_already_exist, message: 'Channel notification already exist.'},
  { value: KeyName.disengage_company_gateway, message: '', messageWithVariables:['',' account mapping for Card Payments in ','', 'has been removed.']},
  { value: KeyName.disengage_company_gateway_success, message: 'Success. Your gateway is disengage now.', messageWithVariables:['',' account mapping for Card Payments in ','', 'has been removed.']},
  { value: KeyName.error_auth0, message: 'Token expired.'},
  { value: KeyName.error_bad_request, message: 'Error in your request.'},
  { value: KeyName.error_bad_request_change_role_owner, message: 'Error in your request. It is not possible invite a user with role Owner.'},
  { value: KeyName.error_bad_request_company_integration_action_Disconnect, message: 'Error in your request. It is not possible disconnect this integration due to there are transactions related.'},
  { value: KeyName.error_bad_request_currency_default, message: 'Sorry but something went wrong while trying to execute your request. The currency you are trying to delete is being used.'},
  { value: KeyName.error_bad_request_currency_in_use, message: 'Error in your request. Looks like we cannot remove the currency from your account. The currency is in use.'},
  { value: KeyName.error_bad_request_gateway_delete, message: 'Error in your request. Looks like we cannot remove the payment gateway from your account. There are customer payment methods associate with this payment gateway.'},
  { value: KeyName.error_bad_request_route, message: 'Sorry but the element you are trying to access does not exist anymore.'},
  { value: KeyName.error_duplicated_rule_filter, message: 'The rule created already exist.'},
  { value: KeyName.error_exchange_rates, message: 'Error in your request. An error occurred when trying to connect with service Exchange Rates.'},
  { value: KeyName.error_forbidden_company_action, message: 'Looks like you don’t have permissions to do this action'},
  { value: KeyName.error_form_attributes, message: 'Error in your request. Looks like the information you entered is not valid but, what do we know?'},
  { value: KeyName.error_gateway, message: 'We were not able to connect with the payment proccesor. Please make sure your credentials are correct.'},
  { value: KeyName.error_server_api_company_integration_settings, message: 'Settings should be completed.'},
  { value: KeyName.error_gateway_not_found, message: 'We were not found a gateway. '},
  { value: KeyName.error_gateway_doesnt_belong_rebillia_gateway, message: '', messageWithVariables:['Error in your request. The selected gateway doesn\'t belong to a Rebillia Gateway','']},
  { value: KeyName.error_integration, message: 'We were not able to connect with a third party API. Please check your credentials and try again.'},
  { value: KeyName.error_incomplete_subscription, message: 'We were not able to create a subscription. Please select a correct payment method.'},
  { value: KeyName.error_malformed_request, message: 'Error in your request. The request is malformed.'},
  { value: KeyName.error_not_active_gateway_found, message: 'Error in your request. An error occurred when trying to get the list of active Company Gateways.'},
  { value: KeyName.error_not_countries_found, message: 'Error in your request. An error occurred when trying to get the list of Countries.'},
  { value: KeyName.error_not_currencies_found, message: 'Error in your request. An error occurred when trying to get the list of company currencies.'},
  { value: KeyName.error_not_found, message: 'Sorry but the element you are trying to access does not exist anymore.'},
  { value: KeyName.error_not_found_company_integration, message: 'Error in your request. The integration that try to access not exist in this company.'},
  { value: KeyName.error_not_found_customer, message: 'Error in your request. Looks like we cannot create the address because We cannot found the customer selected.'},
  { value: KeyName.error_not_found_customer_address_book, message: 'Error in your request. The address book that you try to access does not exist for this customer.'},
  { value: KeyName.error_not_found_customer_group, message: 'Error in your request. Customer group settings is not activated on the Bigcommerce\'s settings.'},
  { value: KeyName.error_not_found_email_template, message: 'Error in your request.  The email template that you try to access does not exist in this company.'},
  { value: KeyName.error_not_gateway_associated, message: 'Error in your request. There are no company Gateways associated.'},
  { value: KeyName.error_external_interval_failed, message: 'Sorry Boss! Looks like we were not able to connect one ore more plans to your sale channel'},
  { value: KeyName.error_rebillia_connector, message: 'Error in your request. Please contact us in order to enable Avalara integration.'},
  { value: KeyName.error_server_api, message: 'In this moment we can not process your request.'},
  { value: KeyName.error_server_api_analytics_list_by_sales_channel, message: 'In this moment we can not process your request. It Was not possible list subscriptions by sales channels.'},
  { value: KeyName.error_server_api_external_product_delete, message: 'The external product cannot remove because there are at least one charge related.'},
  { value: KeyName.error_server_api_external_product_duplicate, message: 'The external product already exist for this product.'},
  { value: KeyName.error_server_api_product_update, message: 'In this moment we can not process your request.'},
  { value: KeyName.form_invalid, message: 'Error in your request. Looks like the information you entered is not valid but, what do we know?'},
  { value: KeyName.form_submitted, message: 'Success'},
  { value: KeyName.message_removed, message: 'Ok, got it.'},
  { value: KeyName.no_product_rate_plan_charges, message: 'Make sure you have created at least one product rate plan charge for each rate plan.'},
  { value: KeyName.request_error, message: 'Error in your request.'},
  { value: KeyName.request_error_transaction_unsuccessful, message: 'The transaction was unsuccessful.'},
  { value: KeyName.request_success, message: 'Nice! Everything looks good from here boss.'},
  { value: KeyName.subscription_shipping, message: 'If you proceed with this action you won\'t be able to calculate the shipping amount in some subscriptions.'},
  { value: KeyName.bigcommerce_settings, message: 'If you proceed with this action yours Bigcommerce\'s settings will affected and might cause errors when Rebillia creates subscriptions.'},
  { value: KeyName.sales_channel_order, message: 'If you proceed with this action you won\'t able to create Bigcommerce\'s orders.'},
  { value: KeyName.sales_channel_checkout, message: 'If you proceed with this action your Bigcommerce\'s checkout will be deactivated.'},
  { value: KeyName.remove_customer_address_book_error_billing, message: 'Sorry Boss, no can do! This address is being used as the billing address of one or more subscriptions.'},
  { value: KeyName.remove_customer_address_book_error_shipping, message: 'Sorry Boss, no can do! This address is being used as the shipping address of one or more subscriptions.'},
  { value: KeyName.remove_customer_address_book_error_payment, message: 'Sorry Boss, no can do! This address is being used as the billing address of one or more cards on file.'},
  { value: KeyName.error_bad_request_payment_method_has_active_subscriptions, message: 'Sorry boss, Can’t do that! This card is attached to an active subscription bag.'},
  { value: KeyName.error_product_not_published_due_to_not_active_rateplan, message: 'Sorry Boss! You must have at least one enabled rate plan to publish the product'},
  { value: KeyName.error_bad_request_big_commerce, message: 'Sorry Boss! We can’t find this product in your sale channel. It may have been removed' },
  { value: KeyName.error_external_interval_failed_for_product_status, message: 'Sorry Boss! We could not publish. Please make sure the product does not already have an option with the same name' }
  //{ value: KeyName.message<span *ngIf='data.message'>{{data.message}}</span>
  ];

export class AppKeyNames {
  constructor() {}
}
