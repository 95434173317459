import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSharingService, LoaderService, RestService, StorageService} from 'src/app/_core/services';
import {RestResponse, Role} from 'src/app/_shared/models';
import {CompanyStatus, DateFormatValues} from 'src/app/app.data';
import {environment} from 'src/environments/environment';
import {DatePipe} from '@angular/common';
import * as dayjs from 'dayjs';
import {MatDialog} from '@angular/material/dialog';
import {DialogConfirmationComponent} from '../../dialog/confirmation/confirmation.component';
import {KeyName} from 'src/app/app.keynames';
import {AuthService} from 'src/app/_core/services/auth.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

const APIEndpoint: string = environment.APIEndpoint;
const APIVersion: string = environment.APIVersion;
const APIResourceSystem: string = '/system';
const APIResourceMessages: string = '/messages';
const APIResourceSearch: string = '/search';
const APIResourceSupport: string = '/support';
const APICompanies: string = '/companies';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  private serviceUrl_SystemMessage: string;
  private serviceUrl_Search: string;
  private serviceUrl_Support: string;
  private companyStatus: string;
  private cancelledAt: string;
  _dateFormatValues = DateFormatValues;

  constructor(
    private _shareData: DataSharingService,
    private _storage: StorageService,
    private _rest: RestService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private auth: AuthService,
    private loader: LoaderService,
    private _dialogRef: MatDialog,
    public _router: Router,
  ) {
    let entityId: string;

    this._shareData.currentCompany.subscribe(data => {
      this.checkIfItsDeactivated()
      console.log('data', data)
      if (data.companyId){
        entityId = `/${data.companyId}`;
      } else {
        entityId = `/${this._storage.readLocalStorage('entityId')}`;
      }

      this.companyStatus = data.status;
      this.cancelledAt = data.cancelledAt && data.cancelledAt.date ? data.cancelledAt.date : null

      // SYSTEM MESSAGES
      // /system/messages
      this.serviceUrl_SystemMessage = APIEndpoint + APIVersion + APIResourceSystem + APIResourceMessages;

      // Agregar la siguiente ruta
      this.serviceUrl_Search = APIEndpoint + APIVersion + APIResourceSearch + ".json";

      // Agregar la siguiente ruta
      this.serviceUrl_Support = APIEndpoint + APIVersion +APICompanies + entityId + APIResourceSupport;
    });
  }

  public submitSupport(data: any)
  {
    const httpOptions = {
      context:"ignoreContentOverride",
      reportProgress: false,
      observe: 'events'
    }

    return this._rest.postUpload(this.serviceUrl_Support, data, httpOptions)
  }

  public getSystemMessages(): Observable<RestResponse> {
    return this._rest.get(this.serviceUrl_SystemMessage);
  }

  public updateSystemMessages(messageId: number): Observable<any> {
    return this._rest.put(`${this.serviceUrl_SystemMessage}/${messageId}`)
  }

  public getLocalCompanyMessages() {
    const messages = [];
    const companyId = localStorage.getItem('entityId');
    const isDeactivated = (this.companyStatus ? this.companyStatus : localStorage.getItem('status')) == CompanyStatus.deactivated && !localStorage.getItem(companyId + '-hideStatusDeactivatedMessage');
    const isBlocked = (this.companyStatus ? this.companyStatus : localStorage.getItem('status')) == CompanyStatus.blocked && !localStorage.getItem(companyId + '-hideStatusDeactivatedMessage');
    const cancelledAt = this.cancelledAt ? this.cancelledAt : localStorage.getItem('cancelledAt');
    const role = localStorage.getItem('userRole');
    const dateAfter14 = dayjs(cancelledAt, "DD-MM-YYYY").add(15, 'days');
    const days = dateAfter14.diff(new Date(), 'days') // 20214000000 default milliseconds

    if(isBlocked){
      this._router.navigate(['/setup/blocked']);
    }

    if(isDeactivated && role !=  Role.owner){
      this.showDeactivatedDialog();
    }

    if (isDeactivated && days>0) {
      const message = {
        "id": 0,
        "message": `Account cancelled since <strong>${this.datePipe.transform(cancelledAt)}</strong>.
        Access to the account will be revoked in <strong>${days} ${days == 1? 'Day' : 'Days'}</strong>.
        To reactivate the account, please reach out to your account manager,
        contact our support through support@rebillia.com or call the office at (404) 900-6858. Note - Only account owners can reactivate the account.`,
        "status": "local",
        "hideDismiss":true,
        "type": "info",
        "level": 2,
        "keyName": "company_deactivated",
        "createdAt": {
          "date": new Date(),
          "timezone_type": 3,
          "timezone": "UTC"
        },
        "updatedAt": {
          "date": new Date(),
          "timezone_type": 3,
          "timezone": "UTC"
        }
      };

      messages.push(message);
    }

    return messages;
  }

  isAccountDeactivated(cancelledAt, role): boolean {
    if(!cancelledAt) return false;
    const dateAfter14 = dayjs(cancelledAt, "DD-MM-YYYY").add(14, 'days').toDate()
    const cancelledDateDue = dayjs().isAfter(dateAfter14);
    return cancelledDateDue;
  }

  checkIfItsDeactivated() {
    const role = localStorage.getItem('userRole');
    const cancelledAt = this.cancelledAt ? this.cancelledAt : localStorage.getItem('cancelledAt');
    if (this.isAccountDeactivated(cancelledAt, role)) {
      this.showDeactivatedDialog();
    }
  }

  showDeactivatedDialog(){
    const dialogRef = this.dialog.open(DialogConfirmationComponent, {
      panelClass: 'custom-mat-dialog',
      disableClose: true,
      data: {
        keyName: KeyName.account_closed,
        code: KeyName.account_closed,
        hideCancel: true,
        switchCompany: KeyName.switch_company_for_closed
      }
    });

    dialogRef.afterClosed().subscribe(res=>{
      if (res === KeyName.account_closed) {
        this.auth.logout();
        this.loader.stopPageLoader();
      } else if (res === KeyName.switch_company_for_closed) {
        if(this._dialogRef) {
          this._dialogRef.closeAll();
        }
        this.auth.clearCompanyStorage();
        this.loader.stopPageLoader();
      }
    })
  }
}
