//angular
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermissionManagerService } from './permissions.service';
//app

@Injectable()
export class PermissionsGuardService implements CanActivate {

    constructor(
        public _permissionManagerService: PermissionManagerService,
        public _router: Router
    ) {
        console.log(`auth.guard.ts was loaded.`)
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if(!route.data.restriction) return true;
        
        const [keyName, type, action] = route.data.restriction.split("|");

        const restriction = this._permissionManagerService.hasRestriction(keyName, type, action);
        if (restriction) {
          this._router.navigate(["/main/dashboard"]);
          return false;
        } else {
          return true;
        }
    }

}
