import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { RestResponse } from '../../_shared/models';
import { Injectable } from "@angular/core";
import { ErrorService } from '../services/error.service';
import { KeyName } from 'src/app/app.keynames';
import { AuthService } from '../services/auth.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorService: ErrorService, private authService: AuthService){
  }
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(httpRequest).pipe(
      map((event: HttpEvent<any>) => {
          return event;
      }),
      catchError((res: HttpErrorResponse) => {
        if (res.error instanceof ErrorEvent) {
          // client-side error
          console.log(`client-side error`);
          this.errorService.push({
            message: res.error.message,
            status: res.status,
            data: []
          })
        } else {
          console.log(res.status);
          console.log(res);
          console.log(`server-side error`);
          this.errorService.push({
            message: res.error.message,
            status: res.status,
            data: res.error
          })

          if(res.error.code === KeyName.error_auth0) {
              this.authService.logout();
          }

          return throwError(new RestResponse().onFail(res.status, res.error.code, res.error.message, res.error.errors, res.error.help));
        }
      })
    );
  }
}
