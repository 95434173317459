import { Component, Input } from '@angular/core';
import { AppAnimations } from '../../../animations';
import { SkeletonLoaderService  } from '../../../../_core/services';

@Component({
  selector: 'skeleton-loader-grid-body',
  templateUrl: './skeleton-loader-grid-body.component.html',
  animations: [ AppAnimations ],
  host: { 'class': 'skeleton-loader-grid-body' }
})
export class SkeletonLoaderGridBodyComponent {
  // use this input when you have a grid within a grid
  @Input() includeInnerGrid: boolean = false;
  @Input() gridBulkSection: boolean = true;
  @Input() numberOfColumns: number = 5;
  @Input() numberOfRows: number = 5;

  constructor(
    public _skeleton: SkeletonLoaderService,
  ) {}
}
