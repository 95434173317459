import { Component, Input } from '@angular/core';
import { AppAnimations } from '../../animations';
import { SkeletonLoaderService  } from '../../../_core/services';

@Component({
  selector: 'skeleton-loader-form',
  templateUrl: './skeleton-loader-form.component.html',
  styleUrls: ['./skeleton-loader-form.component.scss'],
  animations: [ AppAnimations ],
})
export class SkeletonLoaderFormComponent {
  // If is a section doesn't have a title add [hasTitle]=false
  @Input() hasTitle: boolean = true;
  // If is a section that is loaded individually and is inside a form add [insideSection]=true
  @Input() insideSection: boolean = false;
  // If is the first section in the page add [top]=true
  @Input() top: boolean = false;
  @Input() numberOfInputs: number = 2;

  constructor(
    public _skeleton: SkeletonLoaderService,
  ) {}
}
