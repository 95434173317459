import { Injectable, Optional } from '@angular/core';
import * as dayjs from 'dayjs';
import { DateFormatValues, collectionOfDecimalFields } from 'src/app/app.data';
import { DataSharingService } from './data.sharing.service';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable()
export class UtilsService {
  private collectionOfDecimalFields: String[] = collectionOfDecimalFields;
  private displayDateFormat: string;
  private decimalPlaces: string;
  private thousandsToken: string;
  private timezoneSettings: any;
  
  constructor(
    private _shareData: DataSharingService
  ) {
    this._shareData.getDisplayDateFormat().subscribe((res) => {
      this.displayDateFormat = res;
    });

    this._shareData.getCompanySettingsTimezone().subscribe((res) => {
      this.timezoneSettings = res;
    });

    this._shareData.getDecimalPlaces().subscribe((res) => {
      this.decimalPlaces = res;
    });

    this._shareData.getThousandsToken().subscribe((res) => {
      this.thousandsToken = res;
    });
  }

  // used with cleave mask
  public convertToNumber(value: any): number {
    if (typeof value === 'string') {
      return Number((value).split(this.thousandsToken).join(''));
    }
    return value;
  }

  public isEmpty(object: any): boolean {
    return object && Object.keys(object).length === 0 && object.constructor === Object;
  }

  // used with GET
  public isDateStringValid(body: any, key: string): boolean {
    const matchRegex = (value: string) => {
      // const dateTimeRegex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/,
      const dateTimeRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\+(\d{2}):(\d{2})$/,
            match = value.match(dateTimeRegex);
      return !!match;
    };

    const value: string = body[key];
    if (value === null || value === undefined) {
      return false;
    }

    if (typeof value === 'string') {
      return matchRegex(value);
    }
    return false;
  };

  // used with POST, PUT
  public isDateValid(body: any, key: string): boolean {
    const value = body[key];
    if (value === null || value === undefined) {
      return false;
    }

    if (value instanceof Date) {
      return true;
    } else if (typeof value === 'string') {
      return dayjs(value, DateFormatValues.YYYY_MM_DD).format(DateFormatValues.YYYY_MM_DD) === value;
    }
    return false;
  };

  public formatDateToSend(value: string) {
    return dayjs(value).format(DateFormatValues.YYYY_MM_DD);
  }

  public formatDateToReceive(value: string) {
    // timezoneSettings
return value;
    // return !this.isEmpty(this.displayDateFormat) ? dayjs.tz(value, "America/Toronto").format(this.displayDateFormat + " HH:mm:ssZ") : value;
  }

  public isDecimalField(body: any, key: string): boolean {
    return this.collectionOfDecimalFields.some(item => {
      if (item == key) {
        const value = body[key];
        if (value === null || value === undefined || typeof value === 'object') {
          return false;
        }
        return true;
      }
    });
  };

  private exponencyBy(value: number): number {
    return value >= 0 ? 10**value : null;
  }

  public formatDecimalNumberToSend(value: number): number {
    return Number((value * this.exponencyBy(Number(this.decimalPlaces))).toFixed());
  }

  public formatDecimalNumberToReceive(value: number): string {
    return (Number(value)).toFixed(Number(this.decimalPlaces));
  }

  public formatDecimalNumberToReceiveInline(value: number): number {
    return Number((value / this.exponencyBy(Number(this.decimalPlaces))));
  }

  public iterateBody(body: any, validation: any, action: any) {
    if (body === null || body === undefined || typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (validation(body, key)) {
        body[key] = action(value);
      } else if (typeof value === 'object') {
        this.iterateBody(value, validation, action);
      }
    }
    return body;
  }

  isOverdueDate(dateOrigin) {
    const invoiceDateDue = dayjs(dateOrigin);
    const today = dayjs();
    return invoiceDateDue.isBefore(today);
  }

  findVariant(options, collectionOfVariants): any {
    let filteredCollection = [];
    filteredCollection = collectionOfVariants.filter(({option_values}) => {
      return option_values.every(ov => {
        return options.some(o => o.value_id === ov.id)
      });
    });
    console.log('@@@ findVariant array', filteredCollection)
    console.log('@@@ findVariant', filteredCollection.length > 0 ? filteredCollection[0] : 'no hay variante')
    return filteredCollection.length > 0 ? filteredCollection[0] : null;
  }

  findOptionVariant(options, collectionOfOptions): Array<any> {
    let concatedCollection = [];
    collectionOfOptions.forEach((variant)=> {
        const value = variant.values;
        if(value.length > 0) {
          value.forEach((item) => {
            concatedCollection.push(item);
          })
        }
      });

    let filteredCollection = [];
    filteredCollection = concatedCollection.filter((variantValue)=>{return options.some((optionValue)=>{ return optionValue.value_id === variantValue.id }) });
    return filteredCollection.length > 0 ? filteredCollection : null;
  }

  convertToUtcNoOffset(date, time?: any, timezoneOffset?: any) {
    let dateDayJs = dayjs(date).utcOffset(timezoneOffset).set('hour', time)
    dateDayJs = dateDayJs.utcOffset(0)
    return dayjs(dateDayJs).format()
  }

  dhm(ms) {
    const oneSecMs = 1000;
    const oneMinutesMs = 60 * 1000;
    const oneHourMs = 60 * 60 * 1000;
    const oneDayMs = 24 * 60 * 60 * 1000;

    const days = Math.floor(ms / oneDayMs);
    const daysms = ms % oneDayMs;
    const hours = Math.floor(daysms / oneHourMs);
    const hoursms = ms % oneHourMs;
    const minutes = Math.floor(hoursms / oneMinutesMs);
    const minutesms = ms % oneMinutesMs;

    const sec = Math.floor(minutesms / oneSecMs);

    if (!days && !hours && !minutes && !sec) {
      return { completed: true };
    }

    if (sec < 0) {
      return null;
    }

    return { days, hours, minutes, sec };
  }

  dayValidator(monthControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const monthValue = monthControl.value;
      const dayValue = control.value;
      const currentYear = new Date().getFullYear();
      const isLeapYear = (currentYear % 4 === 0 && currentYear % 100 !== 0) || currentYear % 400 === 0;

      if (monthValue === '2') {
        if (isLeapYear && (dayValue < 1 || dayValue > 29)) {
          return { invalidDayForFebruary: true };
        } else if (!isLeapYear && (dayValue < 1 || dayValue > 28)) {
          return { invalidDayForFebruary: true };
        }
      } else if (
        (monthValue === '4' || monthValue === '6' || monthValue === '9' || monthValue === '11') &&
        (dayValue < 1 || dayValue > 30)
      ) {
        return { invalidDayFor30DaysMonth: true };
      } else if (dayValue < 1 || dayValue > 31) {
        return { invalidDay: true };
      }

      return null; // Return null if the day is valid
    };
  }
}
