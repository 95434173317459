import { Deserializable } from '../interfaces/deserialize.interface';

export class TaxDefaultClasses implements Deserializable<TaxDefaultClasses> {
    constructor(
        public taxCodeDefaultTaxable?: string,
        public taxCodeShipping?: string,
    ) { }

    deserialize(input: any): TaxDefaultClasses {
        Object.assign(this, input);
        return this;
    }
}
