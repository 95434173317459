import { SharedCustomerService } from "./shared-customer.service";
import { SharedGlobalService } from "./shared-global.service";
import { SharedIntegrationService } from "./shared-integration.service";
import { SharedProductRatePlanService } from "./shared-product-rate-plan.service";
import { SharedProductService } from "./shared-product.service";

export const CROSS_SERVICES: any[] = [
  SharedCustomerService,
  SharedIntegrationService,
  SharedProductService,
  SharedProductRatePlanService,
  SharedGlobalService,
];

export * from "./shared-customer.service";
export * from "./shared-global.service";
export * from "./shared-integration.service";
export * from "./shared-product-rate-plan.service";
export * from "./shared-product.service";
