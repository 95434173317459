<div role="group" class="example-tel-input-container" [formGroup]="parts"
  [attr.aria-labelledby]="_formField.getLabelId()" (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">

  <input type="text" [placeholder]="placeholder" aria-label="Search" matInput formControlName="searchSel" #searchSel
    [matAutocomplete]="auto">

  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
    (optionSelected)="_handleInput(parts.controls.searchSel)">
    <ng-container *ngIf="valueSel">
      <ng-container *ngFor="let option of filteredOptions | async; let i=index">
      <mat-option [value]="getValueByKey(valueSel,option)">
        <ng-container *ngIf="!content">
          {{getValueByKey(labelSel,option)}}
        </ng-container>
        <ng-container *ngIf="content">
          <ng-container *ngTemplateOutlet="content.templateRef; context: { option }"></ng-container>
        </ng-container>

      </mat-option>
      <ng-container *ngIf="option.priority && option.priority !== 999 && items[i+1]?.priority === 999">
        <mat-divider class="optiondivider"></mat-divider>
      </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!valueSel">
      <ng-container *ngFor="let option of filteredOptions | async; let i=index">
      <mat-option  [value]="option">
        <ng-container *ngIf="!content">
          {{getValueByKey(labelSel,option)}}
        </ng-container>
        <ng-container *ngIf="content">
          <ng-container *ngTemplateOutlet="content.templateRef; context: { option }"></ng-container>
        </ng-container>
      </mat-option>
      <ng-container *ngIf="option.priority && option.priority !== 999 && items[i+1]?.priority === 999">
        <mat-divider class="optiondivider"></mat-divider>
      </ng-container>
      </ng-container>
    </ng-container>
  </mat-autocomplete>
</div>
