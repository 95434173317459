import { Deserializable } from '../interfaces/deserialize.interface';

export class Filter implements Deserializable<Filter> {
    id: number
    label: string
    sectionId: string
    status: string
    type: string
    isDefault: boolean
    isEditable: boolean
    detail:string

    deserialize(input: any): Filter {
        Object.assign(this, input);
        return this;
    }
}

export class FilterOption {
    id: number
    displayName:string
    name: string
    dataType: string
    originSource: string
    allowedOperators: Array<any>
    allowedValues: Array<any>
}

export interface FilterRule {
  attributeId: number
  attributeDisplayName: string
  operatorId: number
  operatorDisplayName: string
  settingValues: Array<any>
}
