<div [formGroup]="form" class="form-row">
  <div class="form-row-group size size1of1">
    <div class="form-row">
      <mat-form-field class="custom-mat-form-field size size1of1">
        <input matInput required placeholder="Contact Name" name="contactName" formControlName="contactName" />
        <mat-error><error-messages [control]="form.get('contactName')"></error-messages></mat-error>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field class="custom-mat-form-field size size1of2">
        <input matInput required placeholder="Address" name="street1" formControlName="street1" />
        <mat-error><error-messages [control]="form.get('street1')"></error-messages></mat-error>
      </mat-form-field>
      <mat-form-field class="custom-mat-form-field size size1of2">
        <input matInput placeholder="Apartment / Suite / etc." name="street2" formControlName="street2" />
        <mat-error><error-messages [control]="form.get('street2')"></error-messages></mat-error>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field class="custom-mat-form-field size size1of2">
        <input matInput required placeholder="City" name="City" formControlName="city" />
        <mat-error><error-messages [control]="form.get('city')"></error-messages></mat-error>
      </mat-form-field>
      <mat-form-field class="custom-mat-form-field size size1of2">
        <input matInput required placeholder="State" name="state" formControlName="state" />
        <mat-error><error-messages [control]="form.get('state')"></error-messages></mat-error>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field class="custom-mat-form-field size size1of2">
        <input matInput required placeholder="Zip / Postal Code" name="zip" formControlName="zip"/>
        <mat-error><error-messages [control]="form.get('zip')"></error-messages></mat-error>
      </mat-form-field>
      <mat-form-field class="custom-mat-form-field size size1of2">
        <mat-select name="country" formControlName="countryId" class="custom-mat-select">
          <mat-option *ngFor="let country of collectionOfCountry" [value]="country.id" class="custom-mat-option">{{ country.name }}</mat-option>
        </mat-select>
        <mat-error><error-messages [control]="form.get('countryId')"></error-messages></mat-error>
      </mat-form-field>
    </div>
    <div class="form-row row-checkbox">
      <mat-checkbox formControlName="saveNewShippingAddress" [checked]="this.form.get('saveNewShippingAddress').value" [disabled]="saveAddressDisabled" disableRipple="true" color="primary" class="custom-mat-checkbox rounded checkbox-simple-line">Save this address in my address book.</mat-checkbox>
    </div>
  </div>
</div>
