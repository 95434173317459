import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidatorService } from '../validators/validator.service';

@Component({
  selector: 'error-messages',
  template: `
    <span *ngIf="errorMessage !== null">{{ errorMessage }}</span>
  `
})
export class ErrorMessagesComponent {
  @Input() control: any;

  constructor(
    private _validatorService: ValidatorService
  ) {}

  get errorMessage() {
    for (let propertyName in this.control?.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
        return this._validatorService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
      }
    }
    return null;
  }
}
