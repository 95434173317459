<section [@systemMessages]="systemMessagesStatus" class="message system">
    <div class="message-wrapper" *ngIf="collectionOfMessage.length > 0">
        <div [@list]="collectionOfMessage.length" class="message-container">
            <div *ngFor="let message of collectionOfMessage" class="message-inner" [ngClass]="{'level-1': message.level == 1, 'level-2': message.level == 2, 'level-3': message.level == 3}">
                <div class="message">
                    <p class="typo body-1 semi-condensed fw400" [innerHTML]="message.message"></p>
                </div>
                <div class="message-actions" *ngIf="!message?.hideDismiss">
                    <button mat-flat-button (click)="onSubmit(message)" class="custom-mat-button button-dismiss status-default">Dismiss</button>
                </div>
            </div>
        </div>
    </div>
</section>