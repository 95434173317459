import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from 'src/environments/environment';

import { DataSharingService, RestService, StorageService } from 'src/app/_core/services';
import { HttpParamService } from 'src/app/_core/services/api/http-param.service';
import { HttpParamsInterface } from 'src/app/_shared/interfaces/http-params.interface';
import { RestResponse } from 'src/app/_shared/models';
import { NewProduct, NewProductRatePlanCharge } from 'src/app/_shared/models/product.model';
import { PUT_ExternalProductInterface } from 'src/app/_shared/interfaces/product-rateplan.interface';
import { Observable, BehaviorSubject, timer } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { ProductRatePlanName, ProductRatePlanChargesCategory, ChargeModelValues, TaxClassValues, ChargeType } from 'src/app/app.data';
import { HttpHeaders, HttpParamsOptions, HttpRequest } from '@angular/common/http';


const APIEndpoint: string = environment.APIEndpoint;
const APIVersion: string = environment.APIVersion;

const APIResourceCompanies: string = '/companies';
const APIResourceProducts: string = '/products';
const APIResourceProductRatePlans: string = '/product-rateplans';
const APIResourceExternalProducts: string = '/external-products';
const APIResourceIntegrations: string = '/integrations';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  subscription: Subscription;
  public serviceUrl_Integrations: string;
  public serviceUrl_Products: string;
  public serviceUrl_Company: string;
  public serviceUrl_ExternalProducts: string;
  public serviceUrl_ExternalProductsByName: string;
  private load$ = new BehaviorSubject('');
  private IMPORTER_TASKS_INTERVAL: number = 30000;

  constructor(private httpParamService: HttpParamService,
    private _shareData: DataSharingService,
    private _storage: StorageService,
    private _rest: RestService) {

      let entityId: string;

      this.subscription = this._shareData.currentCompany.subscribe(data => {
        if (data.companyId){
          entityId = `/${data.companyId}`;
        } else {
          entityId = `/${this._storage.readLocalStorage('entityId')}`;
        }

      // /companies/{{companyId}}
      this.serviceUrl_Company = APIEndpoint + APIVersion + APIResourceCompanies + entityId;

      // PRODUCTS
      // [GET]
      // /companies/{{companyId}}/products
      this.serviceUrl_Products = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceProducts;

      // EXTERNAL PRODUCTS BY NAME
      // /companies/{{companyId}}/integrations/{{companyIntegrationId}}/products?name=
      this.serviceUrl_ExternalProductsByName = APIEndpoint + APIVersion + APIResourceCompanies + entityId +  APIResourceIntegrations;

      // EXTERNAL PRODUCTS BY PRODUCT RATE PLAN
      // /companies/{{companyId}}/product-rateplans/external-products
      this.serviceUrl_ExternalProducts = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceProductRatePlans + APIResourceExternalProducts;

      // /companies/{{companyId}}/integrations
      // [GET]
      this.serviceUrl_Integrations = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceIntegrations;

    });
  }

  public getDefaultTemplate(): NewProductRatePlanCharge{
    return   {
      "name": ProductRatePlanName.oneTimePurchase,
      "category": ProductRatePlanChargesCategory.digital,
      "isFreeShipping": true,
      "depth": 0,
      "height": 0,
      "weight": 0,
      "width": 0,
      "chargeModel": ChargeModelValues.perUnitPricing,
      "chargeTier": [
          {
              "currency": "UYU",
              "price": 0.0,
              "priceFormat": "flatFee",
              "startingUnit": "1"
          }
      ],
      "taxClass": TaxClassValues.nonTaxable,
      "taxCode": "NT",
      "taxable": false,
      "allowChangeQuantity": false,
      "useSaleChannelData": false,
      "billCycleDay": 1,
      "billCycleType": "subscriptionStartDay",
      "billingPeriod": "month",
      "billingPeriodAlignment": "alignToCharge",
      "billingTiming": "inAdvance",
      "chargeType": ChargeType.oneTimeCharge,
      "endDateCondition": "subscriptionEnd",
      "specificBillingPeriod": 1,
      "weeklyBillCycleDay": null,
      "rules": [],
      "cutOffType": null,
      "cutOffValue": null
    } as NewProductRatePlanCharge;

  }
  public createProduct(_bodyParams: NewProduct): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_Products}`,_bodyParams);
  }

  public getProducts(pathParams: HttpParamsInterface): Observable<RestResponse> {
    let params = this.httpParamService.buildPathParams(pathParams);
    return this._rest.get(`${this.serviceUrl_Products}`, { params: params } );
  }

  public deleteProductByProductId(productId: number): Observable<RestResponse> {
    return this._rest.delete(`${this.serviceUrl_Products}/${productId}`);
  }

  public bulkPauseProductById(productId: number): Observable<RestResponse> {
    return this._rest.put(`${this.serviceUrl_Products}/${productId}/bulk-pause`);
  }

  public updateProductStatus(productId: number, nextStatus: string): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Products}/${productId}/status`,{ status: nextStatus });
  }

  public updateProductsStatus(data): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Company}/product-rateplans/bulk-change/status`, data);
  }

  public updateProduct(productId: number, bodyParams: NewProduct): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Products}/${productId}`,bodyParams);
  }

  public bulkPublishProduct(bodyParams: NewProduct): Observable<any> {
    return this._rest.post(`${this.serviceUrl_Company}/rbl-queue`,bodyParams);
  }

  public getBulkPublishedProducts(): Observable<any> {
    return this._rest.get(`${this.serviceUrl_Company}/rbl-queue`,{ params: {jobType: 'bulk_publish_product'} });
  }

  public getBulkPublishedProductsItems(queueId, pathParams?: HttpParamsInterface): Observable<any> {
    let params = pathParams ? this.httpParamService.buildPathParams(pathParams): null;

    return this._rest.get(`${this.serviceUrl_Company}/rbl-queue/details/${queueId}`, {params});
  }

  public updateExternalProduct(productId: number, bodyParams: PUT_ExternalProductInterface): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Products}/${productId}`,bodyParams);
  }

  public getProductByProductId(productId: number, pathParams?: HttpParamsInterface): Observable<RestResponse> {
    let params = pathParams ? this.httpParamService.buildPathParams(pathParams): null;
    return this._rest.get(`${this.serviceUrl_Products}/${productId}`, { params: params });
  }

  public searchProductByProductName(companyIntegrationId: number, productName: string): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Integrations}/${companyIntegrationId}/products?name=${productName}`)
  }

  //external-product

  public createLinkToExternalProduct(productId: number, _bodyParams: any): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_Products}/${productId}/external-products`,_bodyParams);
  }

  public deleteLinkToExternalProduct(productId: number, externalProductId: number): Observable<RestResponse> {
    return this._rest.delete(`${this.serviceUrl_Products}/${productId}/external-products/${externalProductId}`);
  }

  public searchProductByExternalProductName(companyIntegrationId: number, productName: string): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_ExternalProductsByName}/${companyIntegrationId}/products?name=${productName}`);
  }

  public searchProductByExternalProductId(companyIntegrationId: number, productId: string, urlParams: any = null): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_ExternalProductsByName}/${companyIntegrationId}/products/${productId}`, { params: urlParams });
  }

  public deleteExternalProduct(externalProductRatePlanChargeId: number, planId: boolean): Observable<RestResponse> {
    const params = planId ? `?unlinkAllChargesOfPlan=${planId}` : '';
    return this._rest.delete(`${this.serviceUrl_ExternalProducts}/${externalProductRatePlanChargeId}${params}`);
  }

  public createExternalProductByRateplanId(_bodyParams: any): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_ExternalProducts}`, _bodyParams);
  }

  // importers
  public getImporterProductTasksByCompanyIntegration(companyIntegrationId: number, pathParams: HttpParamsInterface): Observable<RestResponse> {
    let params = this.httpParamService.buildPathParams(pathParams);
    return this._rest.get(`${this.serviceUrl_Integrations}/${companyIntegrationId}/importers/products`, { params: params })
  }

  public getImporterProductTaskByCompanyIntegrationAndByImporterId(companyIntegrationId: number, importerId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Integrations}/${companyIntegrationId}/importers/products/${importerId}`)
  }

  public getImporterProductTasksByCompanyIntegrationStream(companyIntegrationId: number, pathParams: HttpParamsInterface) {
    return this.load$.pipe(
      switchMap(_ => timer(0, this.IMPORTER_TASKS_INTERVAL).pipe(
        concatMap(_ => this.getImporterProductTasksByCompanyIntegration(companyIntegrationId, pathParams)),
        map((res: RestResponse) => res),
      ))
    );
  }

  public getImporterProductSubTasksByCompanyIntegrationAndByImporterId(companyIntegrationId: number, importerId: number, pathParams: HttpParamsInterface): Observable<RestResponse> {
    let params = this.httpParamService.buildPathParams(pathParams);
    return this._rest.get(`${this.serviceUrl_Integrations}/${companyIntegrationId}/importers/products/${importerId}/importer_product_subtasks`, { params: params })
  }

  public createImporterProductTask(companyIntegrationId: number, _bodyParams: any): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_Integrations}/${companyIntegrationId}/importers/products`, _bodyParams);
  }

  public updateImporterProductTaskStatus(companyIntegrationId: number, importerId: number, nextStatus: string): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Integrations}/${companyIntegrationId}/importers/products/${importerId}`, { status: nextStatus });
  }

  public changeDisplayModifierName(productId, externalProductId, data): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Products}/${productId}/update-modifier/${externalProductId}`, data);
  }

  public syncProduct(productId, externalProductId, data): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Products}/${productId}/sync-product-with-external/${externalProductId}`, data);
  }
  public getBrands(companyIntegrationId): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Integrations}/${companyIntegrationId}/importers/products/options/brands`);
  }
  public getCategories(companyIntegrationId): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Integrations}/${companyIntegrationId}/importers/products/options/categories`);
  }
  public filterProducts(companyIntegrationId, data): Observable<RestResponse> {
    return this._rest.put(`${this.serviceUrl_Integrations}/${companyIntegrationId}/importers/products/options/filter`, data);
  }

  public getProductRateplanTemplates(httpParams): Observable<any> {
    let params = this.httpParamService.buildPathParams(httpParams);

    return this._rest.get(`${this.serviceUrl_Company}/product-rateplan-template/list`,{ params});
  }

  public getImportWizards(companyIntegrationId: number, pathParams: HttpParamsInterface) {
      return this.load$.pipe(
        switchMap(_ => timer(0, this.IMPORTER_TASKS_INTERVAL).pipe(
          concatMap(_ => this.getImportWizardByCompanyIntegration(companyIntegrationId, pathParams)),
          map((res: RestResponse) => res),
        ))
      );
    }

    public getImportWizardByCompanyIntegration(companyIntegrationId: number, pathParams: HttpParamsInterface): Observable<RestResponse> {
      let params = this.httpParamService.buildPathParams(pathParams);
      return this._rest.get(`${this.serviceUrl_Integrations}/${companyIntegrationId}/importers/wizard`, { params: params })
    }
    public getImportWizardById(companyIntegrationId: number,importId: any, pathParams: HttpParamsInterface): Observable<RestResponse> {
      let params = this.httpParamService.buildPathParams(pathParams);
      return this._rest.get(`${this.serviceUrl_Integrations}/${companyIntegrationId}/importers/wizard/${importId}`, { params: params })
    }

    public createImportWizard(companyIntegrationId: number, data): Observable<RestResponse> {
      const httpOptions = {
        context:"ignoreContentOverride",
        reportProgress: true, 
        observe: 'events',
    }
      return this._rest.postUpload(`${this.serviceUrl_Integrations}/${companyIntegrationId}/importers/wizard`, data, httpOptions)
    }

    public editImportWizard(companyIntegrationId: number,importerId: any, data): Observable<RestResponse> {
      const httpOptions = {
        context:"ignoreContentOverride",
        reportProgress: true, 
        observe: 'events',
    }
      return this._rest.postUpload(`${this.serviceUrl_Integrations}/${companyIntegrationId}/importers/wizard/${importerId}`, data, httpOptions)
    }
}
