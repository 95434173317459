//angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_core/services/auth.service';
import { SkeletonLoaderService, StorageService } from 'src/app/_core/services';
import { SharedGlobalService } from 'src/app/_core/services/api/shared-global.service';
import { RestResponse } from 'src/app/_shared/models';
import { NotificationInboxModel } from 'src/app/_shared/models/notifications.model';
import { MetaService } from 'src/app/_core/services/meta.service';
import { Unsubscriber } from 'src/app/_shared/unsubscriber/unsubscriber';
import {
  RebNgxIconsRegistry,
  RebNgxIconSettings,
  RebNgxIconUser,
  RebNgxIconLogOut,
  RebNgxIconTransfer,
  RebNgxIconDelete,
  RebNgxIconCheck,
  RebNgxIconPlus,
  RebNgxIconSearch,
  RebNgxIconCustomize,
  RebNgxIconBook
} from '@rhinosaas/reb-ngx/reb-ngx-icon';
import { HttpParamsInterface } from 'src/app/_shared/interfaces/http-params.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-global-header-impl',
  templateUrl: './global-header-impl.component.html',
  styleUrls: ['./global-header-impl.component.scss']
})

export class GlobalHeaderImplComponent implements OnInit , OnDestroy {
  collectionOfNotifications: Array<NotificationInboxModel> = [];
  hasNotifications: boolean = false;
  isNotificationsReady: boolean = false;
  companyName: string;
  userFirstName: string;
  userLastName: string;
  userRole: string;
  itemPerPage: number = 20;
  pageNumber: number = 1;
  private subs = new Unsubscriber();
  supportPin: any;
  
  constructor (
    private auth: AuthService,
    public meta: MetaService,
    private rebNgxIconsRegistry: RebNgxIconsRegistry,
    private sharedGlobalService: SharedGlobalService,
    private storage: StorageService,
    public skeleton: SkeletonLoaderService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.companyName = this.storage.readLocalStorage('entityName');
    this.userFirstName = (this.storage.readLocalStorage('userFirstName') != 'null' ? this.storage.readLocalStorage('userFirstName') : '');
    this.userLastName = (this.storage.readLocalStorage('userLastName') != 'null' ? this.storage.readLocalStorage('userLastName') : '');
    this.userRole = this.storage.readLocalStorage('userRole');
    this.supportPin = this.storage.readLocalStorage('supportPin');

    this.rebNgxIconsRegistry.registerIcons([
      RebNgxIconSettings,
      RebNgxIconUser,
      RebNgxIconLogOut,
      RebNgxIconTransfer,
      RebNgxIconDelete,
      RebNgxIconCheck,
      RebNgxIconPlus,
      RebNgxIconSearch,
      RebNgxIconCustomize,
      RebNgxIconBook
    ]);

    this.getUnreadNotifications();
  }

  signOut(){
    this.auth.logout();
  }

  getNotifications(status: string) {
    const httpParams: HttpParamsInterface = {
      orderBy: 'createdAt',
      sortBy: 'ASC',
      itemPerPage: this.itemPerPage,
      pageNo: this.pageNumber,
      status: status
    }

    return this.sharedGlobalService.getNotifications(httpParams).toPromise();
  }

  getUnreadNotifications() {
    this.isNotificationsReady = false;
    this.collectionOfNotifications = [];

    this.getNotifications('unread')
      .then((res: RestResponse) => {
        this.collectionOfNotifications.push(...res.data as any[]);
        this.hasNotifications = this.collectionOfNotifications.length > 0;
        this.isNotificationsReady = true;
      })
      .catch((err: RestResponse) => {
        this.meta.showNotification(err);
        this.collectionOfNotifications = [];
        this.hasNotifications = false;
        this.isNotificationsReady = true;
      });
  }

  goToSwitch() {
    this.auth.clearCompanyStorage();
  } 

  updateNotificationStatus($event, notificationId: number) {
    $event.stopPropagation();
    this.subs.addSub = this.sharedGlobalService.updateNotificationStatus(notificationId).subscribe(
        (res: any) => {
          this.collectionOfNotifications = this.collectionOfNotifications.filter(notification => notification.id !== res.data.id);
          this.hasNotifications = this.collectionOfNotifications.length > 0;

          if (this.collectionOfNotifications.length <= 7) {
            this.getNotifications('unread')
              .then((res: RestResponse) => {
                let updatedCollection = [...this.collectionOfNotifications];
                updatedCollection.push(...res.data as any[]);

                // filter repeated values
                this.collectionOfNotifications = Array.from(new Set(updatedCollection
                  .map(a => a.id)))
                    .map(id => {
                      return updatedCollection.find(a => a.id === id)
                    })
              })
              .catch((err: RestResponse) => {
                this.meta.showNotification(err);
                this.collectionOfNotifications = [];
                this.hasNotifications = false;
                this.isNotificationsReady = true;
              });
          }
        },
        (err: RestResponse) => {
          this.collectionOfNotifications = [];
          this.hasNotifications = false;
          this.meta.showNotification(err);
        });
  }

  markNotificationAsRead($event) {
    // TODO: implement when endpoint is ready
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  openExternalLink(url: string) {
    window.open(url, '_blank');
  }
}
