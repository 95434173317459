//angular
import { Component, OnDestroy, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
//app
import { AppAnimations } from '../animations';
import { LoaderService } from '../../_core/services';
import { Unsubscriber } from 'src/app/_shared/unsubscriber/unsubscriber';

@Component({
  selector: 'page-loader',
  templateUrl: './page-loader.component.html',
  animations: [AppAnimations],
  host: {'class':'app-page-loader'}
})

export class PageLoaderComponent implements OnDestroy {
  statusLoader: number = 0;
  private subs = new Unsubscriber();

  constructor(
    private _iconRegistry: MatIconRegistry,
    private _loader: LoaderService,
    private _renderer: Renderer2,
    private _sanitizer: DomSanitizer
  ) {
    this._iconRegistry.addSvgIcon('ico-loading-puff', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-loading-puff.svg'));

    this.subs.addSub = this._loader.changePageLoaderStatus.subscribe((value) => {
      this.statusLoader = value;
      if (value) {
        this._renderer.addClass(document.body, 'processing-request');
      } else {
        this._renderer.removeClass(document.body, 'processing-request');
      }
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
