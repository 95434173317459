import { Deserializable } from "../interfaces/deserialize.interface";

export interface CountryInterface {
  alpha2Code?: string,
  alpha3Code?: string,
  flag?: string,
  id?: number,
  name?: string,
  numericCode?: number,
  priority?: number;
}

export class Country implements Deserializable<Country> {
    constructor(
        public id?: number,
        public name?: string,
        public alpha2Code?: string,
        public alpha3Code?: string,
        public numericCode?: number,
        public flag?: string,
        public priority?: number,
    ){}

    deserialize(input: any): Country {
        Object.assign(this, input);
        return this;
    }
}
