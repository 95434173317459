export class RebilliaPlan {
    id: number;
    name: string;
    price: number;
    description_1: string;
    description_2: string;
}

export class PlanFeatures {
    id: number;
    name: string;
    value_1: string;
    value_2: string;
    value_3: string;
}

export class PlanFeaturesGroup {
    id: number;
    name: string;
    features: PlanFeatures[]
}
