import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

//models
import {
  CompanyIntegrationModel,
  ShipperHqServicesModel,
  ShippingRatesModel,
  ShippingServiciesModel
} from '../../models';
import { DataSharingService, UtilsService } from 'src/app/_core/services';
import { Unsubscriber } from '../../unsubscriber/unsubscriber';
import { ShippingCustomFreeShippingStaticValues, VisibleState } from 'src/app/app.data';
import { AppAnimations } from '../../animations';
import {ValidatorService} from "../../validators/validator.service";


@Component({
  animations: [AppAnimations],
  selector: 'app-shipping-method-dropdown',
  templateUrl: './shipping-method.component.html',
  styleUrls: ['./shipping-method.component.scss']

})
export class ShippingMethodComponent implements OnInit, OnDestroy {

  cleaveNumDecimalOptions;
  cleaveNumIntegerOptions;
  form: FormGroup;
  isCustomOptionSelected: boolean = false;
  isFixedShippingRateSelected: boolean = false;
  isFreeShippingOptionSelected: boolean = false;

  private subs = new Unsubscriber();

  /** collection of all shippings */
  @Input() collectionOfShippingMethod = [];
  /** collection of shipper hq services */
  @Input() collectionOfShipperHqServices = [];
  /** if the get returns an error */
  @Input() shippingError: boolean = false;
  /**If it has Shipping Integration Enabled (it's used in html) */
  @Input() isShippingIntegrationEnabled: boolean = false;
  /** to be shown on custom shipping method*/
  @Input() currencySymbol: string;
  /** to be shown on custom shipping method */
  @Input() currencyIso3: string;
  /** to select the shipping method */
  @Input() shippingServiceId?: string = '';
  /** If customer is selected */
  @Input() shippingAmount?: number = 0;

  @Input() fixedShippingRate?: number = 0;

  /** Shows the amount of each Service */
  @Input() showsShippingAmount?: boolean = false;
  /** optional */
  @Input() shippingCompanyIntegration?: CompanyIntegrationModel;

  @Input() _visibleState = VisibleState;
  @Input() shippingStaticValues = ShippingCustomFreeShippingStaticValues;

  /** emmitter to implement response data on the parent component */
  @Output() emitShippingMethod: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _shareData: DataSharingService,
    private _fb: FormBuilder,
    private utils: UtilsService,
    private _validatorService: ValidatorService,
  ) {
    this.subs.addSub = this._shareData.getSettingsVariables().subscribe(([decimalPlaces, decimalToken, thousandsToken, _factoringDimension, lengthMeasurement, weightMeasurement]) => {
      this.cleaveNumDecimalOptions = {
        numeral: true,
        numeralPositiveOnly: true,
        stripLeadingZeroes: false,
        numeralDecimalScale: decimalPlaces,
        delimiter: thousandsToken,
        numeralDecimalMark: decimalToken
      };

      this.cleaveNumIntegerOptions = {
        numeral: true,
        numeralPositiveOnly: true,
        stripLeadingZeroes: false,
        numeralDecimalScale: 0,
        delimiter: thousandsToken,
        numeralDecimalMark: decimalToken
      };
    });
  }

  ngOnInit(): void {
    this.setUpForm();
    this.generateCollection();
  }


  generateCollection() {
    if (this.collectionOfShippingMethod.length > 0) {
      const hasCustom = this.collectionOfShippingMethod.find( x => x.integrationName == this.shippingStaticValues.shippingCustomKey);
      if(hasCustom) {
        const hasFree = this.collectionOfShippingMethod.find( x => x.integrationName == this.shippingStaticValues.shippingFreeKey);
        if(hasFree == undefined) {
          this.collectionOfShippingMethod.unshift(
            <ShippingRatesModel>{
              amount: null,
              companyIntegrationId: hasCustom.companyIntegrationId,
              currency: hasCustom.currency,
              integrationName: this.shippingStaticValues.shippingFreeKey,
              serviceId: hasCustom.serviceId,
              serviceName: 'Free Shipping',

          });
        }
      }

      const hasShppingSelected = this.shippingServiceId !== undefined && this.shippingServiceId !== null;

      if (!hasShppingSelected && Number(this.shippingAmount) === 0) {
        const shippingSelected = this.collectionOfShippingMethod.find(item => item.carrierName === 'Free Shipping');
        this.form.get('shippingService').setValue(shippingSelected);
        this.form.get('shippingAmount').setValue(0);
        this.form.get('shippingAmount').disable();
        this.isCustomOptionSelected = false;
        this.isFixedShippingRateSelected = false;
        this.isFreeShippingOptionSelected = true;
      } else {
        const shippingSelected = this.shippingServiceId ? this.collectionOfShippingMethod.find(item => String(item.serviceId) === this.shippingServiceId) :
                                  this.collectionOfShippingMethod.find(item => item.carrierName === 'Custom Rate');

        this.onChangeShippingMethod(shippingSelected);
        }
    }

    if (this.collectionOfShipperHqServices && this.collectionOfShipperHqServices.length > 0) {
      const shipperHqSelected = this.collectionOfShipperHqServices.find((ele) => ele['serviceId'] === this.shippingServiceId)
      if (shipperHqSelected) {
        this.onChangeShipperHqService(shipperHqSelected);
      }
    }
  }

  setUpForm() {
    this.form = this._fb.group({
      shippingService: '',
      shippingAmount: '',
      shipperHqService: [''],
    });
  }

  onChangeShipperHqService(_shipperHqService: ShipperHqServicesModel) {
    this.form.controls.shippingService.setValue(null)
    this.form.controls.shipperHqService.setValue(_shipperHqService)
    this.isFixedShippingRateSelected = false;
    this.emitShippingMethod.emit(_shipperHqService);
  }

  resetShipperHq() {
    this.form.controls.shipperHqService.setValue(null)
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onChangeShippingMethod(_shippingMethod: ShippingServiciesModel) {
    this.resetShipperHq()
    if(_shippingMethod?.carrierName === 'Custom Rate' || _shippingMethod?.carrierName === 'Fixed Shipping Rate') {
      this.form.get('shippingService').setValue(_shippingMethod);
      if(this.shippingAmount) {
        this.form.get('shippingAmount').setValue(this.shippingAmount);
      } else {
        this.form.get('shippingAmount').setValue(null);
      }
      this.form.get('shippingAmount').enable();
      if (_shippingMethod?.carrierName === 'Fixed Shipping Rate') {
        this.isFixedShippingRateSelected = true;
        this.form.get('shippingAmount').setValidators([Validators.required, this._validatorService.positiveDecimalNumberIncludeZero]);
        this.form.get('shippingAmount').setValue(this.fixedShippingRate)
      } else {
        this.isCustomOptionSelected =  this.showsShippingAmount;
      }
      this.isFreeShippingOptionSelected = false;
      this.emitShippingMethod.emit(_shippingMethod);
    } else if(_shippingMethod?.carrierName === 'Free Shipping') {
      this.form.get('shippingService').setValue(_shippingMethod);
      this.form.get('shippingAmount').setValue(0);
      this.form.get('shippingAmount').disable();
      this.isCustomOptionSelected = false;
      this.isFixedShippingRateSelected = false;
      this.isFreeShippingOptionSelected = true;
      this.emitShippingMethod.emit(_shippingMethod);
    } else {
      this.form.get('shippingAmount').setValue(null);
      this.form.get('shippingService').setValue(_shippingMethod);
      this.isCustomOptionSelected = false;
      this.isFixedShippingRateSelected = false;
      this.isFreeShippingOptionSelected = false;
      this.emitShippingMethod.emit(_shippingMethod);
    }
  }

  getShippingFormData() {
    return this.form.value;
  }


  onFocusOutShippingAmount(event: any) {
    this.shippingAmount = this.utils.convertToNumber(event.target.value);
    const shippingSelected:ShippingRatesModel = this.collectionOfShippingMethod.find(item => item.carrierName === 'Custom Rate');
    shippingSelected.amount = String(this.shippingAmount);
    this.onChangeShippingMethod(shippingSelected);
  }

}
