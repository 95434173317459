import { Deserializable } from '../interfaces/deserialize.interface';
import { Address } from './address.model';
import { Company } from './company.model';

export class User implements Deserializable<User> {
    id: number
    picture: string
    avatar: string
    firstName: string
    middleName: string
    lastName: string
    username: string
    password: string
    gender: string
    dob: string
    address: Address
    language: string
    privacy: number
    emailWork: string
    emailPersonal: string
    phoneWork: string
    phoneNum: string
    status: string
    dateCreatedAt: string
    dateUpdatedAt: string
    company: Array<Company>
    auth0: string

    deserialize(input: any): User {
        Object.assign(this, input);
        return this;
    }
}

export class UserAuth0 implements Deserializable<UserAuth0> {
    family_name: string;
    gender: string;
    given_name: string;
    locale: string;
    name: string;
    nickname: string;
    picture: string;
    sub: string;
    updated_at: string;

    deserialize(input: any): UserAuth0 {
        Object.assign(this, input);
        return this;
    }
}

export class UserProfile implements Deserializable<UserProfile>{
  id: number;
  picture: string;
  firstName: string;
  lastName: string;
  username: string;
  phoneNum: string;
  company: Array<UserCompany>

  deserialize(input: any): UserProfile {
      Object.assign(this, input);
      return this;
  }
}

export class UserCompany implements Deserializable<UserCompany>{
  id: number;
  ​​​name: string;
  ​​​plan: Object;
  ​​​role: string;
  supportPin: string;
  status: string;
  cancelledAt: string;

  deserialize(input: any): UserCompany {
    Object.assign(this, input);
    return this;
  }
}
