//angular
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
//others
import { Auth0Client }  from '@auth0/auth0-spa-js';
import * as auth0 from 'auth0-js';
//app
import { AUTH_CONFIG_DESKTOP } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ORIGIN } from '../../_shared/providers/origin.provider';
import { StorageService } from './storage.service';
import { User } from '../../_shared/models/user.model';
import { RestResponse } from 'src/app/_shared/models';
import { AppService } from './app.service';
import { MatDialog } from '@angular/material/dialog';
import { SharedGlobalService } from './api/shared-global.service';
import { MetaService } from './meta.service';

@Injectable()
export class AuthService {
  auth0UserPass: any;
  auth0: any;
  userProfile: any;
  user: User;

  minutesToAdd = 30;

  constructor(
    @Inject(ORIGIN) private _origin: Window,
    private _appService: AppService,
    private _dialogRef: MatDialog,
    public meta: MetaService,
    private _router: Router,
    private _storage: StorageService,
    private sharedGlobalService: SharedGlobalService,
    ) {
    console.log(`@auth.service.ts was loaded on ${this._origin.location.hostname}`);

    this.auth0 = new Auth0Client({
      audience: AUTH_CONFIG_DESKTOP.audience,
      domain: AUTH_CONFIG_DESKTOP.domain,
      client_id: AUTH_CONFIG_DESKTOP.clientID,
      redirect_uri:AUTH_CONFIG_DESKTOP.callbackURL,
      response_type: AUTH_CONFIG_DESKTOP.responseType,
      scope: AUTH_CONFIG_DESKTOP.scope,
      useRefreshTokens: true
    });

    //To be used with auth0-js library
    this.auth0UserPass = new auth0.WebAuth({
      audience: AUTH_CONFIG_DESKTOP.audience,
      domain: AUTH_CONFIG_DESKTOP.domain,
      clientID: AUTH_CONFIG_DESKTOP.clientID,
      redirectUri: AUTH_CONFIG_DESKTOP.callbackURL,
      responseType: AUTH_CONFIG_DESKTOP.responseType,
      scope: AUTH_CONFIG_DESKTOP.scope
    });
  }

  authorize(){
    var webAuth = new auth0.WebAuth({
      audience: AUTH_CONFIG_DESKTOP.audience,
      domain: AUTH_CONFIG_DESKTOP.domain,
      clientID: AUTH_CONFIG_DESKTOP.clientID,
      responseType: 'token id_token code',
      scope: 'openid profile email offline_access'
    });

    webAuth.authorize({
      connection: 'google-oauth2'
    });
  }

  clearCompanyStorage() {
    localStorage.removeItem('entityId');
    localStorage.removeItem('entityName');
    localStorage.removeItem('supportPin');
    localStorage.removeItem('cancelledAt');
    localStorage.removeItem('status');
    localStorage.removeItem('noHasCompanies');
    localStorage.removeItem('userRole');
    this._router.navigate(['/login/switch']);
  }

  async handleAfterAuthentication(): Promise<void> {
    const user = await this.auth0.getUser();
    var email_verified: boolean = user.email_verified;
    if (email_verified){
      this.sharedGlobalService.getCurrentUserProfile().subscribe(
        (res: RestResponse) => {
          console.log(`@@auth.service.ts - handleAfterAuthentication - [SUCCESS]`);
          this.user = new User().deserialize(res.data);
          localStorage.setItem('userFirstName', this.user.firstName);
          localStorage.setItem('userLastName', this.user.lastName);
          localStorage.setItem('userAvatar', this.user.picture);
          localStorage.setItem('userEmail', this.user.username);
          localStorage.setItem('auth0', this.user.auth0);

          this._appService.setUserLoggedIn(true);
          if (this.user.company){
            this._router.navigate(['/login/switch']);
          } else {
            this._router.navigate(['/setup/']);
          }
        },
        (err: RestResponse) => {
          console.log(err);
          console.log('auth.service.ts - handleAfterAuthentication - [ERROR]');
          this.logout();
        }
      )
    } else {
      this._router.navigate(['/login/verify']);
    }
  }

  setSession(_tokenData): void {
    const helper = new JwtHelperService(),
        decodedToken = helper.decodeToken(_tokenData),
        expirationDate = helper.getTokenExpirationDate(_tokenData);

    let date: Date = new Date();
    let loggedDate = new Date(date.setDate(date.getDate() + (0 * 24 * 60 * 60 * 1000)));
    let loggedDay = loggedDate.getDate();
    let loggedMonth = loggedDate.getMonth() + 1;
    let loggedYear = loggedDate.getFullYear();
    let loggedHour = loggedDate.getHours();
    let loggedMin = loggedDate.getMinutes();
    let expDate = expirationDate;
    let expDay = expDate.getDate();
    let expMonth = expDate.getMonth() + 1;
    let expYear = expDate.getFullYear();
    let expHour = expDate.getHours();
    let expMin = expDate.getMinutes();

    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((decodedToken.exp * 1000));
    const loggedDateTime = loggedMonth + '/' + loggedDay + '/' + loggedYear + ' - ' + loggedHour + ':' + loggedMin;
    const expDateTime = expMonth + '/' + expDay + '/' + expYear + ' - ' + expHour + ':' + expMin;

    localStorage.setItem('access_token', _tokenData);
    //localStorage.setItem('id_token', _authResult.idToken);
    localStorage.setItem('logged_date', loggedDateTime);
    localStorage.setItem('expires_date', expDateTime);
    localStorage.setItem('expires_at', expiresAt);
    // this.handleAfterAuthentication();

  }

  setSessionUserPass(_authResult): void {
    let date: Date = new Date();
    let loggedDate = new Date(date.setDate(date.getDate() + (0 * 24 * 60 * 60 * 1000)));
    let loggedDay = loggedDate.getDate();
    let loggedMonth = loggedDate.getMonth() + 1;
    let loggedYear = loggedDate.getFullYear();
    let loggedHour = loggedDate.getHours();
    let loggedMin = loggedDate.getMinutes();
    let expDate = new Date((_authResult.expiresIn * 1000) + loggedDate.getTime());
    let expDay = expDate.getDate();
    let expMonth = expDate.getMonth() + 1;
    let expYear = expDate.getFullYear();
    let expHour = expDate.getHours();
    let expMin = expDate.getMinutes();

    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((_authResult.expiresIn * 1000) + loggedDate.getTime());
    const loggedDateTime = loggedMonth + '/' + loggedDay + '/' + loggedYear + ' - ' + loggedHour + ':' + loggedMin;
    const expDateTime = expMonth + '/' + expDay + '/' + expYear + ' - ' + expHour + ':' + expMin;

    // Set localstorage
    localStorage.setItem('access_token', _authResult.accessToken);
    localStorage.setItem('id_token', _authResult.idToken);
    localStorage.setItem('logged_date', loggedDateTime);
    localStorage.setItem('expires_date', expDateTime);
    localStorage.setItem('expires_at', expiresAt);

    this.auth0UserPass.client.userInfo(_authResult.accessToken, (err, profile) => {
      var sub = profile.sub.toString();
      var toArray = sub.split("|");
      var userProvider = toArray[0];
      var userId = toArray[1];
      localStorage.setItem('userId', userId);
      localStorage.setItem('userProvider', userProvider);
    })
  }

  public getProfile(cb): void {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('Access Token must exist to fetch profile');
    }
    const self = this;
    this.auth0UserPass.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        self.userProfile = profile;
      }
      cb(err, profile);
    })
  }

  public getRole():void {
    this.refreshToken();
    return this._storage.readLocalStorage('userRole');
  }

  public async handleAuthentication(): Promise<void> {
    try {
      const token = await this.auth0.getTokenSilently({ignoreCache:true});
      if(token) {
        this.setSession(token);
        this.handleAfterAuthentication();
      }
      else {
        console.log(`@@auth.service.ts - handleAuthentication() - ${"handle"}.`);
        this._router.navigate(['/login']);
      }
    } catch (error) {
      console.log(`@@auth.service.ts - handleAuthentication() - ${"handle"}.`);
      this._router.navigate(['/login']);
    }
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  public login(username: string, password: string): void {
    this.auth0UserPass.login({
      realm: AUTH_CONFIG_DESKTOP.connection,
      username,
      password
    }, (err, authResult) => {
      if (err) {
        alert(err);
        return;
      } else if (authResult && authResult.accessToken && authResult.idToken) {
        console.log(authResult);
        this.setSessionUserPass(authResult);
      }
    });
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('auth0');
    localStorage.removeItem('access_token');
    localStorage.removeItem('entityId');
    localStorage.removeItem('entityName');
    localStorage.removeItem('supportPin');
    localStorage.removeItem('status');
    localStorage.removeItem('cancelledAt');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('expires_date');
    localStorage.removeItem('noHasCompanies');
    localStorage.removeItem('id_token');
    localStorage.removeItem('logged_date');
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userFirstName');
    localStorage.removeItem('userId');
    localStorage.removeItem('userLastName');
    localStorage.removeItem('userProvider');
    localStorage.removeItem('userRole');

    // Go back to the / route
    // this._router.navigate(['/login/signin']);

    this._appService.setUserLoggedIn(false);

    this.meta.removeGatewayScripts();

    this.auth0UserPass.logout()
    //If the logout is executed, it closes all opened dialogs
    if(this._dialogRef) {
      this._dialogRef.closeAll();
    }
  }

  public loginWithGoogle(): void {

    this.auth0.loginWithRedirect({
        connection: 'google-oauth2',
       appState: {
        //targetUrl: '/dashboard'
        }
      }).then(token => {
      //logged, user profile data:
      // this.auth0.getUser().then(user => {
      //   console.log("user-->",user);
      // });
    });
   }

  refreshToken() {
    const token = localStorage.getItem('access_token'),
          helper = new JwtHelperService(),
          expirationDate = helper.getTokenExpirationDate(token),
          currentDate = new Date(),
          futureDate = new Date(currentDate.getTime() + this.minutesToAdd * 60000);

    if(expirationDate == undefined ) {
      this._router.navigate(['/login']);
    }
    else {
      if(expirationDate.getTime() <= futureDate.getTime() ){
        this.getToken();
      }
    }
  }

  getToken() {
    this.auth0.getTokenSilently({ignoreCache:true})
    .then(
      (token) => {
        this.setSession(token);
    }).catch(
      (errorToken) => {
        console.log(`@@auth.service.ts - refreshToken() - ${errorToken.error}.`);
        this._router.navigate(['/login']);
      }
    );
  }
}
