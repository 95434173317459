import { Country } from './country.model';
import { Deserializable } from '../interfaces/deserialize.interface';

export class Address implements Deserializable<Address> {
  constructor(
    public city?: string,
    public company?: string,
    public contactName?: string,
    public contactEmail?: string,
    public contactPhone?: string,
    public country: Country = new Country(),
    public id?: number,
    public name?: string,
    public state?: string,
    public street1?: string,
    public street2?: string,
    public type?: string,
    public zip?: string,
    public firstName?: string,
    public lastName?: string,
  ){}

  deserialize(input: any): Address {
      Object.assign(this, input);
      return this;
  }
}

export class BillingAddress implements Deserializable<BillingAddress> {
    constructor(
        public id?: number,
        public street1?: string,
        public street2?: string,
        public city?: string,
        public state?: string,
        public zip?: string,
        public country: Country = new Country(),
    ) { }

    deserialize(input: any): BillingAddress {
        Object.assign(this, input);
        return this;
    }
}

export interface POST_AddressInterface {
  city?: string,
  company?: string,
  contactName?: string,
  contactEmail?: string,
  contactPhone?: string,
  countryCode?: string,
  countryId: number, //[ required ]
  name?: string,
  id?: number,
  state?: string,
  street1?: string,
  street2?: string,
  type?: string,
  zip?: string,
  firstName?: string,
  lastName?: string,
}

export interface Put_AddressInterface {
  city?: string,
  company?: string,
  contactName?: string,
  contactEmail?: string,
  contactPhone?: string,
  countryId: number,
  state?: string,
  street1?: string,
  street2?: string,
  type?: string,
  zip?: string,
  firstName?: string,
  lastName?: string,
}
