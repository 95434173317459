import { Injectable } from "@angular/core";

@Injectable()
export class SkeletonLoaderService {
  constructor() {}

  public skeletonGridLoader: boolean = false;
  public skeletonGridDataLoader: boolean = false;

  public generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
}
