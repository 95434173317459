//angular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
//app
import { RestResponse } from '../../_shared/models/rest-response.model';
import { ExchangeRate } from 'src/app/_shared/models';
import { DataSharingService } from 'src/app/_core/services/data.sharing.service';

@Injectable()
export class RestService {

  constructor(
    private _http: HttpClient,
    private _shareData: DataSharingService,
  ) {}

  public get(serviceUrl: string, params?:any, noFormat?: boolean): Observable<RestResponse> {
    if (noFormat) {
      this._shareData.setNoFormatResponse(serviceUrl);
    }
    return this._http.get(serviceUrl, { responseType: 'json', observe: 'response', params: params?.params }).pipe(
      take(1),
      map((data: any) => this.handleSuccess(data)),
    );
  }

  public getDownload(serviceUrl: string, params?:any): Observable<RestResponse> {
    this._shareData.setNoFormatResponse(serviceUrl);
    return this._http.get(serviceUrl, { responseType: 'blob', observe: 'response', params: params?.params }).pipe(
      take(1),
      map((data: any) => this.handleSuccess(data)),
    );
  }

  public getExchangeRate(serviceUrl: string): Observable<ExchangeRate> {
    return this._http.get(serviceUrl, { responseType: 'json' }).pipe(
      map((data: ExchangeRate) => data)
    );
  }

  public post(serviceUrl: string, body: any): Observable<RestResponse> {
    return this._http.post(serviceUrl, body).pipe(
      map((data: any) => this.handlePostResponse(data)),
    );
  }

  public postUpload(serviceUrl: string, body: any, options?: any): Observable<RestResponse> {
    return this._http.post(serviceUrl, body, options).pipe(
      map((data: any) => this.handlePostResponse(data)),
    );
  }

  public put(serviceUrl: string, body: any = []): Observable<RestResponse> {
    return this._http.put(serviceUrl, body, { responseType: 'json', observe: 'response' }).pipe(
      map((data: any) => this.handleSuccess(data)),
    );
  }

  public delete(serviceUrl: string) {
    return this._http.delete(serviceUrl).pipe(
      map(() => this.handleDelResponse()),
    );
  }

  private handleSuccess(data: any){
    return new RestResponse().onSuccess(data.status, data.body);
  }

  private handleDelResponse(){
    return new RestResponse().onSuccessNoData(200);
  }

  private handlePostResponse(data: any) {
    return new RestResponse().onSuccess(200, data);
  }
}
