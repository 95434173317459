import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomerAddressBook, CustomerPaymentSource } from '../../models/customer.model';

import { MatIconRegistry } from '@angular/material/icon';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PaymentAccountType } from 'src/app/app.data';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogPaymentFormComponent } from '../../payment-form/dialog-payment-form/dialog-payment-form.component';
import { SharedGlobalService } from 'src/app/_core/services/api';
import { CompanyGateway, Country, RestResponse } from '../../models';
import { MetaService } from 'src/app/_core/services/meta.service';

@Component({
  selector: 'app-credit-card-dropdown',
  templateUrl: './credit-card.component.html'
})
export class CreditCardComponent implements OnInit {

  /** Mandatory If  _allowToCreateACard == true */
  collectionOfCountry: Array<Country> = [];
  form: FormGroup;
  dialogPaymentForm: MatDialogRef<DialogPaymentFormComponent>;
  /** It's used to 'save' the previous selected value */
  selectedCreditCatdBkup: any;

  @Input() _paymentAccountType = PaymentAccountType;
  /** Shows the option 'add new Credit card'  */
  @Input() _allowToCreateCard?: boolean = true;
  /** All cards customer*/
  @Input() _collectionOfCustomerPayments?: Array<CustomerPaymentSource> = [];
  /** the current payment method */
  @Input() _selectedCustomerPaymentMethod: any;
  /** Shows the Billing Address */
  @Input() _showsBillingAddress?: boolean = true;
  /** Shows the credit card's Owner */
  @Input() _showsContactHolder?: boolean = true;
  /** Shows the card's Gateway */
  @Input() _showsGateway?: boolean = true;
  /** Mandatory If  _allowToCreateACard == true, All company's gateways enabled */
  @Input() _companyGateway?: Array<CompanyGateway>= [];
  /** Mandatory If  _allowToCreateACard == true, selected customer */
  @Input() _customerId ?:number = 0 ;
  /** Mandatory If  _allowToCreateACard == true and It's required to show a default billing, current address to be related with the new card */
  @Input() _selectedBillToAddress: any;
  /** Mandatory If _allowToCreateACard == true, address collection */
  @Input() _collectionOfCustomerAddressBook: Array<CustomerAddressBook> = [];
  /** emmitter to implement response data on the parent component */
  @Output() _emitCreditCardForm: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _dialog: MatDialog,
    private _fb: FormBuilder,
    private _iconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
    private sharedGlobalService: SharedGlobalService,
    private _meta: MetaService,
  ) {
    this._iconRegistry.addSvgIcon('card-alipay', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-alipay.svg'));
    this._iconRegistry.addSvgIcon('card-amex', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-amex.svg'));
    this._iconRegistry.addSvgIcon('card-diners', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-diners.svg'));
    this._iconRegistry.addSvgIcon('card-discover', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-discover.svg'));
    this._iconRegistry.addSvgIcon('card-elo', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-elo.svg'));
    this._iconRegistry.addSvgIcon('card-hypercard', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-hypercard.svg'));
    this._iconRegistry.addSvgIcon('card-jcb', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-jcb.svg'));
    this._iconRegistry.addSvgIcon('card-maestro', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-maestro.svg'));
    this._iconRegistry.addSvgIcon('card-master', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-master.svg'));
    this._iconRegistry.addSvgIcon('card-paypal', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-paypal.svg'));
    this._iconRegistry.addSvgIcon('card-unionpay', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-unionpay.svg'));
    this._iconRegistry.addSvgIcon('card-verve', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-verve.svg'));
    this._iconRegistry.addSvgIcon('card-visa', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-visa.svg'));
  }

  ngOnInit(): void {
    this.setUpForm();
    this.form.get('customerPaymentMethod').setValue(this._selectedCustomerPaymentMethod);
    this.selectedCreditCatdBkup = this._selectedCustomerPaymentMethod;

    //Get list of countries.
    this.getCountries()
      .then((res: RestResponse) => {
        this.collectionOfCountry = <Array<Country>>res.data;
      })
      .catch((err: RestResponse) => {
        this._meta.showNotification(err);
      });
  }

  getCountries() {
    return this.sharedGlobalService.getCountries().toPromise();
  }

  onChangeCustomerPaymentMethod(_selectedValue: CustomerPaymentSource) {
    this._selectedCustomerPaymentMethod = _selectedValue;
    if(this._selectedCustomerPaymentMethod) {
      if (this._selectedCustomerPaymentMethod !== '0') {
        this.form.get('customerPaymentMethod').setValue(_selectedValue);
        this.form.get('companyGatewayId').setValue(_selectedValue.companyGateway.id);
      } else {
        this.form.get('customerPaymentMethod').setValue('');
        this.form.get('companyGatewayId').setValue('');
        this.showDialogPaymentForm();
      }
      this._emitCreditCardForm.emit(this._selectedCustomerPaymentMethod);
    } else {
      this._selectedCustomerPaymentMethod ='0';
      this.form.get('customerPaymentMethod').setValue('');
      this.form.get('companyGatewayId').setValue('');
    }
  }

  setUpForm() {
    this.form = this._fb.group({
      customerPaymentMethod: [''],
      companyGatewayId: [''],
    });
    this._selectedCustomerPaymentMethod = this._selectedCustomerPaymentMethod;
  }

  clearForm() {
    this.form.reset();
    this._selectedCustomerPaymentMethod = null;
  }

  showDialogPaymentForm() {
    this.dialogPaymentForm = this._dialog.open(DialogPaymentFormComponent, {
      panelClass: ['custom-mat-dialog', 'mat-dialog-new-payment-method'],
      data: {
        collectionOfCountry: this.collectionOfCountry,
        collectionOfCustomerAddressBook: this._collectionOfCustomerAddressBook,
        billingAddress: this._selectedBillToAddress,
        companyGateways: this._companyGateway,
        customerId: this._customerId,
      },
    });

    this.dialogPaymentForm.afterClosed().subscribe((res) => {
      if (res?.newCreatedCard) {
        let newCreatedCard = res.newCreatedCard;
        this._collectionOfCustomerPayments.push(newCreatedCard);
        this._selectedCustomerPaymentMethod = newCreatedCard;
        this.onChangeCustomerPaymentMethod(this._selectedCustomerPaymentMethod);
      } else {
        this.onChangeCustomerPaymentMethod(this.selectedCreditCatdBkup);
      }
    });
  }

}
