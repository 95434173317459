import { InjectionToken, FactoryProvider } from '@angular/core';

export const ORIGIN = new InjectionToken<Window>('window');

const originProvider: FactoryProvider = {
    provide: ORIGIN,
    useFactory: () => window
};

export const OriginProviders = [
    originProvider
]