<!--<div class="dialog-wrapper">-->
<!--  &lt;!&ndash; Head &ndash;&gt;-->
<!--  <div class="head">-->
<!--    Safer Shipping - Powered by-->
<!--  </div>-->
<!--  &lt;!&ndash; Body &ndash;&gt;-->
<!--  <div class="body">-->
<!--    <mat-selection-list #protectionList>-->
<!--      <mat-list-option class="selection-protection">-->
<!--        <img matListItemAvatar class="protection-image" src="assets/images/upsProtection.svg" alt="...">-->
<!--      </mat-list-option>-->
<!--    </mat-selection-list>-->
<!--    <ng-container *ngIf="protectionList.selectedOptions.selected.length">-->
<!--      <div class="padding-20  ups-subtitle">Package Protection Against</div>-->
<!--      <div class="padding-20  ups-option-protection">-->
<!--          <div>-->
<!--              Loss-->
<!--          </div>-->
<!--          <div>-->
<!--              Damage-->
<!--          </div>-->
<!--          <div>-->
<!--              Porch Piracy-->
<!--          </div>-->
<!--      </div>-->
<!--      <div class="padding-20 ups-protection-desc" >-->
<!--        This option protects your order against loss, damage, and perch pirated theft. Leave worry aside with our quick and easy claim submitting process-->
<!--      </div>-->
<!--      <div class="padding-20">-->
<!--        <b class="ups-protection-footer">-->
<!--          Shipping protection is recommended/provided by UPS Capital Insurance Agency, Inc.-->
<!--        </b>-->
<!--      </div>-->
<!--    </ng-container>-->
<!--  </div>-->
<!--  &lt;!&ndash; Nav &ndash;&gt;-->
<!--  <div class="nav">-->
<!--    <div class="nav margin nomargin">-->
<!--      <button mat-flat-button  [disabled]="!protectionList.selectedOptions.selected.length" (click)="onSubmit()" color="primary" class="mat-button custom-mat-button-primary">Activate</button>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<div class="dialog-wrapper">
  <!-- Head -->
  <div class="head">
    Safer Shipping - Powered by
  </div>
  <!-- Body -->
  <div class="body">
    <img matListItemAvatar class="protection-image" src="assets/images/upsProtection.svg" alt="...">
    <div class="padding-20  ups-subtitle">Package Protection Against</div>
    <div class="padding-20  ups-option-protection">
      <div>
        Loss
      </div>
      <div>
        Damage
      </div>
      <div>
        Porch Piracy
      </div>
    </div>
    <div class="padding-20 ups-protection-desc" >
      This option protects your order against loss, damage, and perch pirated theft. Leave worry aside with our quick and easy claim submitting process
    </div>
    <div class="padding-20">
      <b class="ups-protection-footer">
        Shipping protection is recommended/provided by UPS Capital Insurance Agency, Inc.
      </b>
    </div>
  </div>
  <!-- Nav -->
  <div class="nav">
    <div class="nav margin nomargin">
      <button mat-flat-button (click)="onSubmit()" color="primary" class="mat-button custom-mat-button-primary">Activate</button>
    </div>
  </div>
</div>

