//angular
import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable()
export class UserService {

  private strFirstName: string;
  private strLastName: string;

  private _firstName = new Subject<any>();
  private _lastName = new Subject<any>();

  public changeFirstName = this._firstName.asObservable();
  public changeLastName = this._lastName.asObservable();

  get FirstName(): string {
    return this.strFirstName;
  }

  set FirstName(value) {
    console.log(value);
    this.strFirstName = value;
    this._firstName.next(value);
  }

  get LastName(): string {
    return this.strLastName;
  }

  set LastName(value) {
    this.strLastName = value;
    this._lastName.next(value);
  }

  getFirstName(): string{
    return this.strFirstName;
  }

  setFirstName(firstName:string) {
    this.FirstName = firstName;
  }

}
