import {
  Component,
  Directive,
  ElementRef,
  Input,
  Optional,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { RouterLink, RouterLinkWithHref } from "@angular/router";
import { KeyName } from "src/app/app.keynames";
import { DialogConfirmationComponent } from "src/app/modules/main/_shared/dialog/confirmation/confirmation.component";
import { PermissionManagerService } from "./permissions.service";
import { ScopeRule } from "./restriction.model";

@Directive({
  selector: "[appRestriction]",
})
export class RestrictionDirective {
  disableClick = (e) => e.stopPropagation();

  constructor(
    @Optional() private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionManagerS: PermissionManagerService,
    private _elemRef: ElementRef,
    private _renderer: Renderer2,
    @Optional() private routerLink: RouterLink,
    @Optional() private routerLinkWithHref: RouterLinkWithHref,
    private dialog: MatDialog,
    @Optional() private control: NgControl
  ) {}

  @Input() set appRestriction(permission: string) {
    const [keyName, type, action] = permission.split("|");
    this.isGranted(keyName, type, action);
  }

  private isGranted(keyName, type, action) {
    if (!keyName) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    const restriction = this.permissionManagerS.hasRestriction(
      keyName,
      type,
      action
    );

    switch (action) {
      case ScopeRule.SCOPE_HIDE_WITHMESSAGE:
        if (!restriction) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          const compRef: any = this.viewContainer.createComponent(
            HideAndShowMessageComponent
          );
          compRef.instance.message = restriction.message;
        }
        break;
      case ScopeRule.SCOPE_HIDE:
        if (!restriction) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
        break;
      case ScopeRule.SCOPE_BUTTON_DISABLE:
        if (restriction) {
          const link = this.routerLink || this.routerLinkWithHref;
          if (link) {
            link.onClick = (...args) => {
              return this.routerLinkWithHref ? false : true;
            };
            this.disableElement(this._elemRef.nativeElement);
          } else if (this._elemRef) {
            this.removeRebButtonClick(this._elemRef.nativeElement, true);
            this._elemRef.nativeElement.removeAllListeners("click");
          }
        }
        break;
      case ScopeRule.SCOPE_LIMIT:
        if (restriction) {
          const link = this.routerLink || this.routerLinkWithHref;
          if (link) {
            link.onClick = (...args) => {
              this.openLimitDialog(restriction);
              return this.routerLinkWithHref ? false : true;
            };
          } else if (this._elemRef) {
            this.removeRebButtonClick(this._elemRef.nativeElement);
            this._elemRef.nativeElement.removeAllListeners("click");

            this._renderer.listen(
              this._elemRef.nativeElement,
              "click",
              (event) => {
                this.openLimitDialog(restriction);
              }
            );
          }
        }
        break;
      default:
        if (restriction) {
          this.viewContainer.clear();
        } else {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
        break;
    }
  }

  removeRebButtonClick(element, disabled?: boolean): void {
    if (element.localName == "reb-ngx-mat-menu-button-item") {
      for (let i = 0; i < element.children.length; i++) {
        const chil = element.children[i];
        for (let j = 0; j < chil.children.length; j++) {
          const element = chil.children[j];
          element.removeAllListeners("click");
          if (disabled) {
            this.disableElement(element);
          }
        }
      }
    }
  }

  openLimitDialog(restriction): void {
    this.dialog.open(DialogConfirmationComponent, {
      panelClass: "custom-mat-dialog",
      data: {
        keyName: KeyName.limit_reached,
        message: restriction.message,
        closeBtnText: "Close",
      },
    });
  }

  disableElement(element): void {
    setTimeout(() => {
      this._renderer.removeClass(element, "mat-stroked-button");
      this._renderer.addClass(element, "disabled");
      this._renderer.addClass(element, "mat-button-disabled");
      this._renderer.setProperty(
        element,
        "disabled",
        true //false
      );
    });
  }
}

@Component({
  selector: "app-hide-and-show-message",
  template: `<div>
    {{ message }}
    <div></div>
  </div>`,
})
export class HideAndShowMessageComponent {
  message: any;
  constructor() {}
}
