import { Currency } from './currency.model';
import { Deserializable } from '../interfaces/deserialize.interface';
import { Address } from './address.model';
import { CustomerProfile, CustomerPaymentSource, Customer } from './customer.model';
import { RatePlan } from '../interfaces/subscription.interface';
import { BillCycleTypeValues, BillingPeriodAlignmentValues, BillingPeriodValues, BillingTimingValues, ChargeModelValues, ChargeType, EndDateConditionValues, FrequencyUnitValues, UpToPeriodsTypeValues, WeekDay, VisibleState, ProductCategory, ProductRatePlanChargesCategory, RatePlanType } from 'src/app/app.data';
import { ProductRatePlanChargeTier } from './product.model';
import { CompanyCurrency, CompanyCurrencyGateway, CompanyGateway } from './company.model';
import { PaymentMethod } from './payment-method.model';
import { PricingModels } from './pricingModel.model';
import { CompanyIntegrationModel, ShippingServiciesModel } from './integration.model';

export class CustomerSubscription implements Deserializable<CustomerSubscription>{
    constructor(
        public id?: number,
        public first_name?: string,
        public last_name?: string,
        public email?: string,
        public phone?: string,
        public company?: string,
        public auto_collection?: string,
        public net_term_days?: number,
        public allow_direct_debit?: boolean,
        public created_at?: number,
        public taxability?: string,
        public updated_at?: number,
        public pii_cleared?: string,
        public deleted?: boolean,
        public card_status?: string,
        public promotional_credits?: number,
        public refundable_credits?: number,
        public excess_payments?: number,
        public unbilled_charges?: number,
        public preferred_currency_code?: string
    ){}

    deserialize(input: any): CustomerSubscription {
        Object.assign(this, input);
        return this;
    }
}

export class POST_SubscriptionRatePlanModel implements Deserializable<POST_SubscriptionRatePlanModel> {
  constructor(
    public name?: string,
    public productRatePlanId?: number,
    public ratePlanCharge: Array<POST_SubscriptionRatePlanChargeModel> = []
  ){}

  deserialize(input: any): POST_SubscriptionRatePlanModel {
      Object.assign(this, input);
      return this;
  }
}

export class POST_SubscriptionRatePlanChargeTierModel implements Deserializable<POST_SubscriptionRatePlanChargeTierModel> {
  constructor(
    public endingUnit?: string,
    public currency?: string,
    public price?: number,
    public priceFormat?: string,
    public startingUnit?: string,
    public tier?: number
  ){}

  deserialize(input: any): POST_SubscriptionRatePlanChargeTierModel {
      Object.assign(this, input);
      return this;
  }
}

export class POST_SubscriptionRatePlanChargeModel implements Deserializable<POST_SubscriptionRatePlanChargeModel> {
  constructor(
    public allowChangeQuantity?:any,
    public billCycleDay?: number,
    public billCycleType?: BillCycleTypeValues,
    public billingPeriod?: FrequencyUnitValues,
    public billingPeriodAlignment?: BillingPeriodAlignmentValues,
    public billingTiming?: BillingTimingValues,
    public category?: ProductRatePlanChargesCategory,
    public chargeModel?: ChargeModelValues,
    public chargeType?: ChargeType,
    public description?: string,
    public effectiveEndDate?: string,
    public effectiveStartDate?: string,
    public endDateCondition?: string,
    public chargeTier: Array<POST_SubscriptionRatePlanChargeTierModel> = [],
    public name?: string,
    public numberOfPeriods?: number,
    public processedThroughDate?: string,
    public productRatePlanChargeId?: number,
    public quantity?: number,
    public specificBillingPeriod?: number,
    public specificEndDate?: string,
    public taxCode?: string,
    public taxMode?: string,
    public taxable?: boolean,
    public upToPeriods?: number,
    public upToPeriodsType?: UpToPeriodsTypeValues,
    public weeklyBillCycleDay?: WeekDay,
    public isFreeShipping?: boolean,
    public weight?: number,
    public width?: number,
    public height?: number,
    public depth?: number,
    public rules?: Array<any>,
    public delay?: number,
    public delayType?: string,
    public usageType?: string
  ){}

  deserialize(input: any): POST_SubscriptionRatePlanChargeModel {
      Object.assign(this, input);
      return this;
  }
}

export class POST_SubscriptionModel implements Deserializable<POST_SubscriptionModel> {
  constructor(
    public billingAddressId?: number,
    public companyCurrencyId?: number,
    public companyGatewayId?: number,
    public customerId?: number,
    public customerPaymentMethodId?: number,
    public detail?: string,
    public effectiveStartDate?: string,
    public name?: string,
    public offlinePaymentId?: string,
    public ratePlan: Array<POST_SubscriptionRatePlanModel> = [],
    public shippingAddressId?: number,
    public shippingCompanyIntegrationId?: number,
    public shippingServiceId?: string,
    public shippingAmount?: number,
  ){}

  deserialize(input: any): POST_SubscriptionModel {
    console.log('@@@@input', input);
    Object.assign(this, input);
    return this;
  }
}

export class Subscription implements Deserializable<Subscription> {
    constructor(
        public id?: string,
        public customer_id?: string,
        public plan_id?: string,
        public plan_name?: string,
        public plan_quantity?: string,
        public plan_unit_price?: number,
        public setup_fee?: number,
        public plan_amount?: number,
        public billing_period?: number,
        public billing_period_unit?: string,
        public plan_free_quantity?: number,
        public status?: string,
        public trial_start?: Date,
        public trial_end?: Date,
        public current_term_start?: Date,
        public current_term_end?: Date,
        public next_billing_at?: Date,
        public po_number?: string,
        public created_at?: Date,
        public started_at?: Date,
        public activated_at?: Date,
        public updated_at?: Date,
        public has_scheduled_changes?: boolean,
        public resource_version?: Date,
        public deleted?: boolean,
        public object?: Object,
        public currency_code?: number,
        public due_invoices_count?: number,
        public mrr?: number,
        public exchange_rate?: number,
        public base_currency_code?: string,
        public name?: string
    ){}

    deserialize(input: any): Subscription {
        Object.assign(this, input);
        return this;
    }
}

export class Subscriptions implements Deserializable<Subscriptions>{
    constructor(
        public subscription?: Subscription,
        public customer?: CustomerSubscription
    ){}

    deserialize(input: any): Subscriptions {
        Object.assign(this, input);
        return this;
    }
}

export class SubscriptionGridModel implements Deserializable<SubscriptionGridModel> {
  constructor(
    public billingAddress?: Address,
    public createdAt?: string,
    public customer?: CustomerProfile,
    public customerPaymentMethod?: CustomerPaymentSource,
    public detail?: string,
    public id?: number,
    public lastCharge?: string,
    public name?: string,
    public nextCharge?: string,
    public oneTimeChargeQty?: number,
    public oneTimeChargeAmount?: number,
    public ratePlan?: Array<RatePlan>,
    public recurringChargeQty?: number,
    public recurringChargeAmount?: number,
    public shippingAddress?: Address,
    public source?: string,
    public sourceCompanyIntegration?: CompanyIntegrationModel,
    public status?: string,
    public updatedAt?: string,
    public usageChargeQty?: number,
    public usageChargeAmount?: number
  ){}

  deserialize(input: any): SubscriptionGridModel {
      Object.assign(this, input);
      return this;
  }
}

export class SubscriptionNextBill implements Deserializable<SubscriptionNextBill>{
  constructor(
    public billingAddress?: Address,
    public companyCurrency?: CompanyCurrency,
    public companyGateway?: CompanyGateway,
    public customer?: Customer,
    public customerPaymentMethod?: CustomerPaymentSource,
    public grandTotal?: number,
    public items: Array<SubscriptionNextBillCharge> = [],
    public name?: string,
    public nextBillDate ?:string,
    public shippingAddress?: Address,
    public shippingAmount?: number,
    public shippingMethod?: ShippingServiciesModel,
    public shippingRate?: string,
    public subtotal?: number,
    public taxAmount?: number
  ){}

  deserialize(input: any): SubscriptionNextBill {
      Object.assign(this, input);
      return this;
  }
}

export class New_SubscriptionRatePlanChargeTierModel implements Deserializable<New_SubscriptionRatePlanChargeTierModel> {
  constructor(
    public endingUnit?: string,
    public currency?: string,
    public price?: number,
    public priceFormat?: string,
    public startingUnit?: string,
    public tier?: number
  ){}

  deserialize(input: any): New_SubscriptionRatePlanChargeTierModel {
      Object.assign(this, input);
      return this;
  }
}

export class New_SubscriptionRatePlanChargeModel implements Deserializable<New_SubscriptionRatePlanChargeModel> {
  constructor(
    public billCycleDay?: number,
    public billCycleType?: BillCycleTypeValues,
    public billingPeriod?: FrequencyUnitValues,
    public billingPeriodAlignment?: BillingPeriodAlignmentValues,
    public billingTiming?: BillingTimingValues,
    public category?: ProductRatePlanChargesCategory,
    public chargeModel?: ChargeModelValues,
    public chargeTier: Array<New_SubscriptionRatePlanChargeTierModel> = [],
    public chargeType?: ChargeType,
    public chargedThroughDate?: string,
    public description?: string,
    public dimensionalRule?: string,
    public effectiveEndDate?: string,
    public effectiveStartDate?: string,
    public endDateCondition?: string,
    public externalRatePlanCharges: Array<ExternalRatePlanCharge> = [],
    public fixedShippingPrice?: string,
    public isFreeShipping?: string,
    public id?: number,
    public shippingAmount?: number,
    public lastBillRun?: any,
    public listPriceBase?: string,
    public mrr?: string,
    public name?: string,
    public numberOfPeriods?: number,
    public originLocation?: string,
    public price?: string,
    public priceIncreasePercentage?: string,
    public processedThroughDate?: string,
    public productRateplanChargeId?: number,
    public shippingGroup?: string,
    public quantity?: number,
    public specificBillingPeriod?: number,
    public specificEndDate?: string,
    public status?: string,
    public taxAmount?:number,
    public taxClass?: string,
    public taxCode?: string,
    public taxMode?: string,
    public taxable?: boolean,
    public upToPeriods?: number,
    public usageType?: any,
    public isGift?: any,
    public upToPeriodsType?: UpToPeriodsTypeValues,
    public weeklyBillCycleDay?: WeekDay,
    public rules?: any[],
  ){}

  deserialize(input: any): New_SubscriptionRatePlanChargeModel {
      Object.assign(this, input);
      return this;
  }
}

export class ExternalDetail implements Deserializable<ExternalDetail> {
  constructor(
    public option_id?: number,
    public option_name?: string,
    public rebillia_option?: boolean,
    public value_id?: string,
    public value_name?: string,
  ) {}

  deserialize(input: any): ExternalDetail {
    Object.assign(this, input);
    return this;
  }
}

export class ExternalRatePlanCharge implements Deserializable<ExternalRatePlanCharge> {
  constructor(
    public companyIntegration?: CompanyIntegrationModel,
    public externalDetail: Array<ExternalDetail> = [],
    public externalId?: string,
    public externalName?: string,
    public externalSku?: string,
    public externalUrl?: string,
    public id?: number,
  ) {}

  deserialize(input: any): ExternalRatePlanCharge {
    Object.assign(this, input);
    return this;
  }
}

export class New_SubscriptionRateplanModel implements Deserializable<New_SubscriptionRateplanModel> {
  constructor(
    public createdAt?: string,
    public id?: number,
    public lastCharge?: string,
    public name?: string,
    public nextCharge?: string,
    public oneTimeChargeAmount?: number,
    public oneTimeChargeQty?: number,
    public productId?: number,
    public productName?: string,
    public productRatePlanId?: number,
    public ratePlanCharge?: Array<New_SubscriptionRatePlanChargeModel>,
    public recurringChargeAmount?: number,
    public recurringChargeQty?: number,
    public status?: string,
    public statusSubPanel?: VisibleState,
    public subscriptionRatePlanCharges?: any,
    public updatedAt?: string,
    public usageChargeAmount?: number,
    public usageChargeQty?: number,
    public type?: RatePlanType,
    public rules?: Array<any>
  ){}

  deserialize(input: any): New_SubscriptionRateplanModel {
      Object.assign(this, input);
      return this;
  }
}

export class New_SubscriptionModel implements Deserializable<New_SubscriptionModel> {
  constructor(
    public createdAt?: string,
    public billingAddress?: Address,
    public companyCurrency?: CompanyCurrency,
    public companyGateway?: CompanyGateway,
    public customer?: Customer,
    public customerPaymentMethod?: PaymentMethod,
    public detail?: string,
    public id?: number,
    public name?: string,
    public nextBill?: string,
    public offlinePaymentId?: string,
    public oneTimeChargeAmount?: number,
    public oneTimeChargeQty?: number,
    public ratePlan: Array<New_SubscriptionRateplanModel> = [],
    public recurringChargeAmount?: number,
    public recurringChargeQty?: number,
    public shippingAddress?: Address,
    public shippingAmount?: number,
    public shippingCompanyIntegration?: CompanyIntegrationModel,
    public shippingServiceId?: string,
    public status?: string,
    public updatedAt?: string,
    public usageChargeAmount?: number,
    public usageChargeQty?: number,

  ){}

  deserialize(input: any): New_SubscriptionModel {
    Object.assign(this, input);
    return this;
  }
}

export interface POST_SubscriptionInterface {
  billingAddressId?: number, //[ required ]
  companyCurrencyId?: number, //[ required ]
  companyGatewayId?: number, //[ required ]
  customerId?: number, //[ required ]
  customerPaymentMethodId?: number,
  detail?: string,
  effectiveStartDate?: string, //[ required ]
  name?: string,
  offlinePaymentId?: string,
  ratePlan?: Array<POST_SubscriptionRatePlanModel>, //[ required ]
  shippingAddressId?: number,
  shippingCompanyIntegrationId?: number,
  shippingServiceId?: string,
  shippingAmount?: number,
  sourceCompanyIntegrationId?: any;
  shippingCarrierId?: string,
  shippingMethodName?: string,
  paymentMethodId?: string,
  fixedShippingRate?: number,
  shippingProtectionId?: number
}

export interface SubscriptionNextBillCharge {
  billCycleDay: number,
  billCycleType: BillCycleTypeValues,
  billingPeriod: BillingPeriodValues,
  billingPeriodAlignment: BillingPeriodAlignmentValues,
  billingTiming: BillingTimingValues,
  chargeModel: PricingModels,
  chargeTier: string,
  chargeType: ChargeType,
  chargedThroughDate: string,
  createdAt: string,
  description: string,
  effectiveEndDate: string,
  effectiveStartDate: string,
  endDateCondition: EndDateConditionValues,
  id: number,
  mrr: number,
  name: string,
  numberOfPeriod: number,
  price: number,
  processedThroughDate: string,
  quantity: number,
  specificBillingPeriod: number,
  specificEndDate: number,
  taxCode: string,
  taxMode: string,
  taxable: boolean,
  updatedAt: string
  upToPeriods: number,
  upToPeriodsType: UpToPeriodsTypeValues,
  weeklyBillCycleDay: string,
  rules: any[]
}

export interface POST_createExternalRatePlanCharge {
  companyIntegrationId: number,
  externalDetail: Array<ExternalDetail>,
  externalId: string,
  externalName: string,
  externalSku?: string,
  externalUrl?: string,
}

export class SubscriptionCounter implements Deserializable<SubscriptionCounter>{
    constructor(
        public subscriptionCount?: string
    ){}

    deserialize(input: any): SubscriptionCounter {
        Object.assign(this, input);
        return this;
    }
}

 export interface ChargePlanRule {
  action: ChargePlanRuleAction;
  amount: string;
  amountType: ChargePlanRuleAmountType;
  count: number;
  countType: string;
  currentValue: number;
  createdAt: string;
  event: string;
  id: number;
  ruleType: string;
  status: string;
  updatedAt: string;
}
export type ChargePlanRuleAction  =  'add'| 'remove'| 'fixed'
export type ChargePlanRuleAmountType  =  'percent'| 'price'
// export class Plan implements Deserializable<Plan> {
//     id: number // A unique ID for your system to identify the plan.
//     name: string // The display name used in web interface for identifying the plan. [ required, max chars = 50]
//     invoiceName: string // Display name used in invoice.If it is not configured then name is used in invoice. [ optional, max chars = 100 ]
//     description: string // Description about the plan to show in the hosted pages & customer portal. [ optional, max chars = 500 ]
//     price: number // The price of the plan. [ optional, in cents, min = 0 ]
//     currencyCode: Currency // The currency code(ISO 4217 format) of the plan. [ max chars = 3 ]
//     period: number // Defines billing frequency.Example: to bill customer every 3 months, provide "3" here. [ integer, default=1, min = 1 ]
//     periodUnit: string // Defines billing frequency in association with billing period. [ enumerated string, default=month ] - Possible values are: 1. day: Charge based on day(s) / 2. week: Charge based on week(s) / 3. month: Charge based on month(s) / 4. year: Charge based on year(s).
//     trialPeriod: number // The free time window for your customer to try your product. [ optional, integer, min = 1 ]
//     trialPeriodUnit: number // Time unit for the trial period. [ optional, enumerated string ] - Possible values are: 1. day: In days / 2. month: In months.
//     pricingModel: string // Defines how the recurring charges for the subscription is calculated. [ enumerated string, default=flat_fee ] - Possible values are: 1. flat_fee: Charge a single price on recurring basis / 2. per_unit: Charge the price for each unit of the plan for the subscription on recurring basis / 3. tiered: Charges a different per unit price for the quantity purchased from every tier / 4. volume: Charges the per unit price for the total quantity purchased based on the tier under which it falls / 5. stairstep: Charges a price for a range / 6. freeQuantity: Free quantity the subscriptions of this plan will have.Only the quantity more than this will be charged for the subscription. [ integer, default=0, min = 0 ]
//     setupCost: number //One - time setup fee charged as part of the first invoice. [ optional, in cents, min = 1 ]
//     status: string // The plan state. [ enumerated string, default=active ] - Possible values are: 1. active: New subscriptions can be created with the plan / 2. archived: No new subscriptions allowed for the plan.Existing subscriptions on this plan will remain as- is and can be migrated to another active plan if required.
//     archivedAt: Date // Time at which the plan was moved to archived status. [optional, timestamp(UTC) in seconds]
//     billingCycles: number // The number of billing cycles the subscription is active.The subscription is moved to non renewing state and then to cancelled state automatically. [optional, integer, min = 1]
//     redirectUrl: string // The url to redirect on successful checkout.Eg: https://yoursite.com/success.html?plan=basic. [optional, string, max chars = 500]
//     enabledInHostedPages: boolean // If true, allow checkout through plan specific hosted page URL for this plan. [boolean, default=true]
//     enabledInPortal: boolean // If enabled, customers can switch to this plan using the 'Change Subscription' option in the customer portal. [boolean, default=true]
//     addonApplicability: string // Indicates if all or only some addons are applicable with the plan. [enumerated string, default=all] - Possible values are: 1. all: All addons are applicable with this plan. / 2. restricted: Only addons marked as 'applicable_addons' are applicable with the plan.
//     taxCode: string // The Avalara tax codes to which items are mapped to should be provided here.Applicable only if you use Chargebee's AvaTax for Sales integration. [optional, string, max chars = 50]
//     avalaraSaleType: string // Indicates the type of sale carried out.This is applicable only if you use Chargebee’s AvaTax for Communications integration. [optional, enumerated string] - Possible values are: 1. wholesale: Transaction is a sale to another company that will resell your product or service to another consumer. / 2. retail: Transaction is a sale to an end user. / 3. consumed: Transaction is for an item that is consumed directly. / 4. vendor_use: Transaction is for an item that is subject to vendor use tax.
//     avalaraTransactionType: number // Indicates the type of product to be taxed.Values for this field can be taken from Avalara.This is applicable only if you use Chargebee’s AvaTax for Communications integration. [optional, integer]
//     avalaraServiceType: number // Indicates the type of service for the product to be taxed.Values for this field can be taken from Avalara.This is applicable only if you use Chargebee’s AvaTax for Communications integration. [optional, integer]
//     sku: string // The field is used as Product name / code in your third party accounting application.Chargebee will use it as an alternate name in your accounting application. [optional, string, max chars = 100]
//     accountingCode: string // This field is to capture the Account code setup in your Accounting system for integration purposes only. [optional, string, max chars = 100]
//     accountingCategory1: string // The name of the category of your product in Xero.If you've integrated with QuickBooks, this will be the "Class". Use the format "<Category>:<Name>". E.g. "Region: North". [optional, string, max chars = 100]
//     accountingCategory2: string // The name of the category of your product in Xero.Use the format<Category>: <Name>". E.g. "Region: North". [optional, string, max chars = 100]
//     isShippable: boolean // If enabled, charges for this plan / addon will be added to orders. [optional, boolean, default=false]
//     shippingFrequencyPeriod: number // Defines the shipping frequency.Example: to bill customer every 2 weeks, provide "2" here. [optional, integer, min = 1]
//     shippingFrequencyPeriodUnit: string // Defines the shipping frequency in association with shipping period. [optional, enumerated string] - Possible values are: 1. year: Ship based on year(s). / 2. month: Ship based on month(s). / 3. week: Ship based on week(s). / 4. day: Ship based on day(s).
//     updatedAt: Date //Timestamp indicating when this plan was last updated.This attribute will be present only if the resource has been updated after 2016 - 11 - 09. [optional, timestamp(UTC) in seconds]
//     giftable: boolean // Specifies if the plan should be gifted or not. [boolean, default=false]
//     claimUrl: string // The url to redirect on successful claim.Eg: https://yoursite.com/claim_success.html?plan=basic. [optional, string, max chars = 500]
//     invoiceNotes: string // Invoice Notes for this resource.Read More. [optional, string, max chars = 1000]
//     taxable: boolean // Specifies if the plan should be taxed or not. [optional, boolean, default=true]
//     taxProfileId: string //Tax profile of the plan. [optional, string, max chars = 50]
//     metaData: Object // Additional data about this resource can be stored here in the JSON Format.Learn more. [optional, jsonobject]
//     tiers: Array<any> // List of tiers for this plan(applicable only if it is tiered / volume / stairtstep pricing. [optional, list of tier]- Tier attributes: 1. startingUnit: The lower limit of a range of units for the tier. [integer, min = 1] / 2. endingUnit: The upper limit of a range of units for the tier. [optional, integer] / 3. price: The price of the tier if the charge model is a stairtstep pricing, or the price of each unit in the tier if the charge model is tiered / volume pricing. [in cents, default=0, min = 0]
//     applicableAddons: Array<any> //List of addons applicable with this plan. [optional, list of applicable_addon] - Applicable addon attributes: 1. id: Identifier of the addon appplicable to the plan. [string, max chars = 100]
//     attachedAddons: Array<any> // Indicates if the addon is attached with the plan as mandatory or recommended. [optional, list of attached_addon] - Attached addon attributes: 1. id: Identifier of the addon attached with the plan.Only recurring addons can be attached with the plan. [string, max chars = 100] / 2. quantity: Quantity of the addon.Applicable only for the quantity based addons. [integer, min = 1] / 3. billingCycles: The number of billing cycles this addon will be attached to the plan. [optional, integer, min = 1] / 4. type: Specifies attachment type of the addon to the plan. [enumerated string] - Possible values are: 1. recommended: Addon will be charged with this plan unless specifically removed. / 2. mandatory Addon will be always charged with this plan.

//     deserialize(input: any): Plan {
//         Object.assign(this, input);
//         return this;
//     }
// }

