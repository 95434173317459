<!-- [@panelAnimation]="ratePlan.statusSubPanel" -->
<div  class="grid-row-panel">
  <!-- *ngIf="isDetailsArrayReady[ratePlanIndex]" -->
  <div class="row-wrapper">
    <mat-list class="custom-mat-list list-grid-std list-rateplan-charges borderless nopadding">
      <mat-list-item class="custom-mat-list-item grid-row grid-row-header">
        <div class="cell-wrapper col-info">
          <div class="cell col-charge-info">Charge Info</div>
          <div class="cell">Cycle</div>
          <div class="cell" *ngIf="!isNewSubscription">Next Bill Date</div>
          <div class="cell">Tax</div>
          <div class="cell">Price</div>
          <div class="cell">Sale Channel</div>
        </div>
        <div class="cell-wrapper col-actions"></div>
      </mat-list-item>
      <mat-list-item *ngFor="let charge of collectionOfProductRatePlanCharges; let ratePlanChargeIndex = index; last as isLastRecurringCharge" class="custom-mat-list-item grid-row">
        <div class="cell-wrapper col-info">
          <!-- Info -->
          <div class="cell col-charge-info">
            <label class="status" *ngIf="!isNewSubscription">
              <div [class]="'status-inner'" [ngClass]="{ 'status-active': charge.status === _ratePlanChargeStatus.active, 'status-archived': charge.status === _ratePlanChargeStatus.archived, 'status-disabled': charge.status === _ratePlanChargeStatus.disabled, 'status-ended': charge.status === _ratePlanChargeStatus.ended}">
                <span *ngIf="charge.status === _ratePlanChargeStatus.active" class="status-label">Active</span>
                <span *ngIf="charge.status === _ratePlanChargeStatus.archived" class="status-label">Archived</span>
                <span *ngIf="charge.status === _ratePlanChargeStatus.disabled" class="status-label">Disabled</span>
                <span *ngIf="charge.status === _ratePlanChargeStatus.ended" class="status-label">Ended</span>
              </div>
            </label>
            <mat-icon *ngIf="charge.lastBillRun?.status === _ratePlanChargeStatus.error" svgIcon="ico-error" class="custom-mat-icon ico-alert ico-size ico-xs" matTooltipPosition="right" matTooltipClass="mat-tooltip" matTooltip="{{charge.lastBillRun?.date}}. We were not beign able to process one or more charges on this subscription. Check Bill Run Id #{{charge.lastBillRun?.companyBillRunId}} for more details."></mat-icon>
            <div class="cell-inner cell-inner-with-status">
              <label class="label-primary">{{charge.name}}</label>
              <label *ngIf="charge.category" class="label-secondary">{{charge.category | titlecase}} <span *ngIf="charge.isFreeShipping">( Free Shipping )</span></label>
            </div>
          </div>
          <!-- Type -->
          <div class="cell">
            <div class="cell-inner">
              <label *ngIf="charge.chargeType === _chargeType.oneTimeCharge" class="label-primary">One-Time Charge</label>
              <label *ngIf="charge.chargeType === _chargeType.recurringCharge" class="label-primary">
                <span *ngIf="charge.billingPeriod === _frequencyUnitValues.day">Every {{charge.specificBillingPeriod}} Day/s</span>
                <span *ngIf="charge.billingPeriod === _frequencyUnitValues.month">Every {{charge.specificBillingPeriod}} Month/s</span>
                <span *ngIf="charge.billingPeriod === _frequencyUnitValues.week">Every {{charge.specificBillingPeriod}} Week/s</span>
                <span *ngIf="charge.billingPeriod === _frequencyUnitValues.year">Every {{charge.specificBillingPeriod}} Year/s</span>
              </label>
              <label *ngIf="charge.chargeType === _chargeType.recurringCharge" class="label-secondary">
                <span *ngIf="charge.billCycleType === _billCycleTypeValues.subscriptionStartDay">On Subscription Start</span>
                <span *ngIf="charge.billCycleType === _billCycleTypeValues.specificDayOfMonth">On the {{charge.billCycleDay}}</span>
                <span *ngIf="charge.billCycleType === _billCycleTypeValues.specificDayOfWeek">On {{charge.weeklyBillCycleDay | titlecase}} </span>
              </label>
            </div>
          </div>
          <!-- Delay -->
          <div class="cell" *ngIf="!isNewSubscription">
            <div *ngIf="charge.delay === null && charge.delayType === null" class="cell-inner">
              <label class="label-primary">{{charge.chargedThroughDate | dateTz:'mediumDate'}}</label>
              <label class="label-secondary">{{charge.chargedThroughDate | dateTz:'longTime'}}</label>
            </div>
            <div *ngIf="charge.delay !== null && charge.delayType !== null" class="cell-inner">
              <label class="label-primary">Delay: {{charge.delay}}</label>
              <label class="label-secondary">Delay Type: {{charge.delayType}}</label>
            </div>
          </div>
          <!-- Tax -->
          <div class="cell">
            <div class="cell-inner" *ngIf="charge?.taxAmount">
              <label class="label-primary">{{currencyBaseSymbol}}{{(charge.taxAmount === null ? 0.00 : charge.taxAmount)}} <span class="typo caption fw300 semi-condensed">{{currencyBaseIso3}}</span></label>
              <label class="label-secondary">{{charge.taxCode}}</label>
            </div>

            <div class="cell-inner" *ngIf="!charge.taxAmount">
              <label class="label-primary">{{charge.taxCode}}</label>
              <label class="label-secondary" *ngIf="charge.taxable">Taxable</label>
            </div>
          </div>
          <!-- Price -->
          <div class="cell">
            <div class="cell-inner">
              <label class="label-primary">
                {{currencyBaseSymbol}}{{_formatDecimalNumberToReceive(charge.chargeTier[0].price * charge.qty)}} <span class="typo caption fw300 semi-condensed">{{currencyBaseIso3}}</span>
              </label>
              <div *ngIf="charge.chargeModel === _chargeModelValues.perUnitPricing" class="cell-container">
                <label class="label-secondary">{{charge.qty}} x {{currencyBaseSymbol}}{{charge.chargeTier[0].price}} <span class="typo caption fw300 semi-condensed">{{currencyBaseIso3}}</span></label>
                <mat-icon *ngIf="charge.chargeModel === _chargeModelValues.perUnitPricing" matSuffix matTooltipPosition="right" matTooltipClass="mat-tooltip" matTooltip="Per Unit Charge Model: With the per unit charge model, the amount to charge is expressed as a price per unit. The price is calculated based on the quantity of a service or product purchased by the customer, where the total price charged per period would be the quantity multiplied by the per-unit price. An example of a per unit charge would be a software-as-a-service vendor that charges {{currencyBaseSymbol}}50 per user per month for their service." svgIcon="ico-help" class="custom-mat-icon ico-tooltip ico-size ico-2xs"></mat-icon>
              </div>

              <div *ngIf="charge.chargeModel === _chargeModelValues.flatFeePricing" class="cell-container">
                <label class="label-secondary">Flat Fee Pricing</label>
                <mat-icon *ngIf="charge.chargeModel === _chargeModelValues.flatFeePricing" matSuffix matTooltipPosition="right" matTooltipClass="mat-tooltip" matTooltip="Flat Fee Charge Model. Flat fee is the simplest charge model. In this model, the amount to charge is a single price (that is, a fixed amount) applied on a one-time or recurring basis. An example of a flat fee charge model would be a gym membership that costs {{currencyBaseSymbol}}50 per month." svgIcon="ico-help" class="custom-mat-icon ico-tooltip ico-size ico-2xs"></mat-icon>
              </div>

              <div *ngIf="charge.chargeModel === _chargeModelValues.tieredPricing" class="cell-container">
                <label class="label-secondary">Tiered Pricing</label>
                <mat-icon *ngIf="charge.chargeModel === _chargeModelValues.tieredPricing" matSuffix matTooltipPosition="right" matTooltipClass="mat-tooltip" matTooltip="Tiered Pricing Charge Model: With a tiered charge model, pricing changes progressively as the volume increases. Like the volume pricing model, the tiered pricing model uses a price table to calculate the pricing. The price table is made up of individual tiers that define a range of volumes and the pricing rule to apply if the customer purchases a quantity that falls within the range of that tier. Each tier is defined by a starting unit, an ending unit, a list price, and a price format (which can be either flat fee or per unit). Unlike the volume model, tiered pricing cumulates all previous tiers when calculating the charge. So, if the quantity purchased falls into Tier 3, the calculation would include (the pricing of Tier 1 + the pricing of Tier 2 + the pricing of the remaining units in Tier 3)." svgIcon="ico-help" class="custom-mat-icon ico-tooltip ico-size ico-2xs"></mat-icon>
              </div>

              <div *ngIf="charge.chargeModel === _chargeModelValues.volumePricing" class="cell-container">
                <label class="label-secondary">Volume Pricing</label>
                <mat-icon *ngIf="charge.chargeModel === _chargeModelValues.volumePricing" matSuffix matTooltipPosition="right" matTooltipClass="mat-tooltip" matTooltip="Volume Pricing Charge Model: With a volume pricing charge model, the price to be charged is based on the volume purchased. When setting up a volume charge model, a price table is used to define the pricing for each range of volumes, as well as the pricing rule to apply if the customer purchases a quantity that falls within the range of that tier. Each tier is defined by a starting unit, an ending unit, a list price, and a price format (which can be either flat fee or per unit). For example, if the user purchases anywhere from 0-50 licenses, they will be charged per unit price of {{currencyBaseSymbol}}120 (5 units x {{currencyBaseSymbol}}120 per unit = {{currencyBaseSymbol}}600) . However, if the user purchases anywhere from 51 – 100 units, they will be charged a per unit price of {{currencyBaseSymbol}}100 (60 units x {{currencyBaseSymbol}}100 per unit = {{currencyBaseSymbol}}6000)." svgIcon="ico-help" class="custom-mat-icon ico-tooltip ico-size ico-2xs"></mat-icon>
              </div>
            </div>
          </div>
          <!-- Linked Product -->
          <div class="cell">
            <!--  -->
            <div *ngIf="charge.externalProduct.length === 0" class="cell-inner" style="flex-direction: row;">
              <!-- (click)="showDialoglinkCharge(charge, ratePlanIndex)" -->
              <button *ngIf="!isNewSubscription" mat-stroked-button color="primary" disableRipple="true" class="custom-mat-button button-new-block">Link With External Product</button>
              <label *ngIf="isNewSubscription" class="label-primary">No linked products</label>
            </div>
            <div *ngIf="charge.externalProduct.length > 0" class="cell-inner">
              <button mat-icon-button [matMenuTriggerFor]="externalRatePlanCharge" disableRipple="true" class="custom-mat-icon-button button-menu-expander">
                <label matLine class="label-primary"><b>( {{charge?.externalProduct?.length}} )</b> Linked Products</label>
                <mat-icon svgIcon="ico-arrow-down" class="custom-mat-icon ico-size ico-2xs"></mat-icon>
              </button>
              <mat-menu #externalRatePlanCharge="matMenu" [overlapTrigger]="false" class="custom-mat-menu-panel menu-external-products">
                <div mat-menu-item *ngFor="let externalRatePlanCharge of charge?.externalProduct" class="custom-mat-menu-item item-section has--no--icon">
                  <div class="item-wrapper">
                    <label class="label-primary label-product-name">{{externalRatePlanCharge.externalName}}</label>
                    <label class="label-secondary label-product-salechannel">{{externalRatePlanCharge.companyIntegration.displayName}}</label>
                    <div *ngIf="externalRatePlanCharge.externalDetail.length > 0" class="product-variants">
                      <label matLine *ngFor="let externalDetail of externalRatePlanCharge.externalDetail; let externalDetailIndex = index;" class="label-secondary label-product-variant">
                        {{externalDetail.option_name}}: <b>{{externalDetail.value_name}}</b>
                      </label>
                    </div>
                    <div *ngIf="externalRatePlanCharge.externalDetail.length === 0" class="product-variants">
                      <label matLine class="label-secondary label-product-variant">No variant found</label>
                    </div>
                  </div>
                  <nav class="item-nav">
                    <a *ngIf="!isNewSubscription" class="custom-mat-link hasIco item-nav-option active" (click)="showDialogUpdateLinkCharge(charge, externalRatePlanCharge, ratePlanIndex)">
                      <mat-icon svgIcon="ico-edit" class="custom-mat-icon ico-size ico-1xs"></mat-icon>Edit
                    </a>
                    <a *ngIf="!isNewSubscription" class="custom-mat-link hasIco item-nav-option active" (click)="showDialogUnlinkCharge(charge, externalRatePlanCharge, ratePlanIndex)">
                      <mat-icon svgIcon="ico-unlink" class="custom-mat-icon ico-size ico-1xs"></mat-icon>Unlink Charge
                    </a>
                  </nav>
                </div>
              </mat-menu>
            </div>
          </div>
        </div>
        <div class="cell-wrapper col-actions">
          <button mat-icon-button class="custom-mat-icon-button"  *ngIf="showEditAction" (click)="showDialogEditRatePlanCharge(charge, ratePlanChargeIndex)">
            <mat-icon  >edit</mat-icon>
          </button>
          <button mat-icon-button class="custom-mat-icon-button"  *ngIf="showDeleteAction" (click)="showDialogConfirmationForDeleteCharge(charge, ratePlanChargeIndex)">
            <mat-icon class="icon-danger" >delete</mat-icon>
          </button>
          <!-- <mat-menu #gridActions="matMenu" [overlapTrigger]="false" class="custom-mat-menu-panel">
            <a mat-menu-item class="custom-mat-menu-item" >
              <mat-icon  class="custom-mat-icon ico-size ico-2xs">edit</mat-icon>Edit
            </a>
            <a mat-menu-item class="custom-mat-menu-item item-danger" *ngIf="showDeleteAction" (click)="showDialogConfirmationForDeleteCharge(charge, ratePlanChargeIndex)">
              <mat-icon svgIcon="ico-delete" class="custom-mat-icon ico-size ico-2xs"></mat-icon>Delete
            </a>
          </mat-menu> -->
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>
