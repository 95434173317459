<div [formGroup]="form"  class="form-section-body">
  <!-- Billing Address -->
  <div formGroupName="billingAddress" class="form-block">
    <div [hidden]="_isNewAddressMandatory" class="form-row">
      <mat-form-field class="custom-mat-form-field size size1of1">
        <mat-label>Billing Address</mat-label>
        <mat-select (selectionChange)="onChangeBillToAddress($event)" [value]="selectedBillToAddress" required disableOptionCentering="true" panelClass="custom-mat-select-panel list-address-books" class="custom-mat-select select-address-books">
          <mat-select-trigger [class]="'custom-mat-select-trigger'" [ngClass]="{'heightFixed': selectedBillToAddress !== '0'}" >
            <ng-container *ngIf="selectedBillToAddress !== '0' ; else newAddress">
              <label class="address-contact">
                <span>{{ selectedBillToAddress?.contactName }}</span>
              </label>
              <label class="address-info">
                <span>{{ selectedBillToAddress?.street1 }} {{ selectedBillToAddress?.street2 }}</span>
              </label>
              <label class="address-info">
                <span>{{selectedBillToAddress?.city}} • {{selectedBillToAddress?.state}} • {{selectedBillToAddress?.zip}} • {{selectedBillToAddress?.country?.alpha2Code}}</span>
              </label>
            </ng-container>
            <ng-template #newAddress>
              <label class="address-contact">
                <span>Enter a new address</span>
              </label>
            </ng-template>
          </mat-select-trigger>
          <mat-option class="option-address option-address-new" value="0">Enter a new address</mat-option>
          <mat-option *ngFor="let address of _collectionOfCustomerAddressBook" [value]="address" class="custom-mat-option option-address">
            <label class="label-address-nickname">{{address.contactName}}</label>
            <p matLine>
              <label class="label-address-info">
                <span>{{address.street1}} {{address.street2}}</span>
              </label>
            </p>
            <p matLine>
              <label class="label-address-info">
                <span>{{address.city}} • {{address.state}} • {{address.zip}} • {{address.country.alpha2Code}}</span>
              </label>
            </p>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <div [@onRowStatusChange]="statusForNewBillingAddress" class="form-row"> -->
    <div *ngIf="statusForNewBillingAddress" class="form-row">
      <mat-form-field class="custom-mat-form-field size size1of1">
        <input required matInput placeholder="Contact Name" formControlName="contactName"/>
        <mat-error><error-messages [control]="form.get('billingAddress').get('contactName')"></error-messages></mat-error>
      </mat-form-field>
    </div>
    <!-- <div [@onRowStatusChange]="statusForNewBillingAddress" class="form-row"> -->
    <div *ngIf="statusForNewBillingAddress" class="form-row">
      <mat-form-field class="custom-mat-form-field size size1of2">
        <input required matInput placeholder="Address" formControlName="street1"/>
        <mat-error><error-messages [control]="form.get('billingAddress').get('street1')"></error-messages></mat-error>
      </mat-form-field>
      <mat-form-field class="custom-mat-form-field size size1of2">
        <input matInput placeholder="Apartment / Suite / etc." formControlName="street2"/>
        <mat-error><error-messages [control]="form.get('billingAddress').get('street2')"></error-messages></mat-error>
      </mat-form-field>
    </div>
    <!-- <div [@onRowStatusChange]="statusForNewBillingAddress" class="form-row"> -->
    <div *ngIf="statusForNewBillingAddress" class="form-row">
      <mat-form-field class="custom-mat-form-field size size1of2">
        <input required matInput placeholder="City" formControlName="city"/>
        <mat-error><error-messages [control]="form.get('billingAddress').get('city')"></error-messages></mat-error>
      </mat-form-field>
      <mat-form-field class="custom-mat-form-field size size1of2">
        <input required matInput placeholder="State" formControlName="state"/>
        <mat-error><error-messages [control]="form.get('billingAddress').get('state')"></error-messages></mat-error>
      </mat-form-field>
    </div>
    <!-- <div [@onRowStatusChange]="statusForNewBillingAddress" class="form-row"> -->
    <div *ngIf="statusForNewBillingAddress" class="form-row">
      <mat-form-field class="custom-mat-form-field size size1of2">
        <input required matInput placeholder="Zip / Postal Code" formControlName="zip"/>
        <mat-error><error-messages [control]="form.get('billingAddress').get('zip')"></error-messages></mat-error>
      </mat-form-field>
      <mat-form-field class="custom-mat-form-field size size1of2">
        <!-- <mat-select (selectionChange)="onSelectionChange($event)" required placeholder="Country" formControlName="country" class="custom-mat-select">
          <mat-option *ngFor="let country of collectionOfCountry" [value]="country" class="custom-mat-option">{{country.name}}</mat-option>
        </mat-select> -->
        <mat-label>Country</mat-label>
        <app-mat-select-search required formControlName="country" [items]="collectionOfCountry"></app-mat-select-search>

        <mat-error><error-messages [control]="form.get('billingAddress').get('country')"></error-messages></mat-error>
      </mat-form-field>
    </div>
  </div>
  <!-- Payment Form -->
  <div class="form-block">
    <div [hidden]="!_isPaymentGatewayVisible" class="form-row">
      <mat-form-field class="custom-mat-form-field size size1of1">
        <mat-label>Payment Gateway</mat-label>
        <mat-select required name="gateway" [(value)]="selectedCompanyGateway" (selectionChange)="onGatewayChange($event.value)">
          <mat-option *ngFor="let gateway of collectionOfCompanyGateway" [value]="gateway">{{gateway.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div [hidden]="!isPaymentSourceReady"  class="form-row size size1of1">
      <section class="payment-form size size1of1">
        <!-- Credit Card -->
        <div class="card-object">
          <!-- Payment Card Holder Name -->
          <div class="hosted-field-wrapper field--card-holdername">
            <mat-form-field floatLabel="always" class="custom-mat-form-field size size1of1">
              <mat-label>Name on card</mat-label>
              <input matInput #cardHolderName required placeholder="John Smith">
            </mat-form-field>
          </div>
          <!-- AUTHORIZE.NET -->
          <div formGroupName="authorizeForm" [hidden]="selectedCompanyGateway?.keyName !== _gatewayCode.authorize" class="card-payment-form payment-form-authorize">
            <!-- Payment Card Number -->
            <div class="hosted-field-wrapper field--card-number">
              <mat-form-field floatLabel="always" class="size size1of1 custom-mat-form-field">
                <mat-label>Card Number</mat-label>
                <input matInput id="authorize_CardNumber" [cleave]="cleaveCardNumOptions" required placeholder="•••• •••• •••• ••••" formControlName="authorize_CardNumber">
                <mat-error><error-messages [control]="form.get('authorizeForm').get('authorize_CardNumber')"></error-messages></mat-error>
              </mat-form-field>
            </div>
            <!-- Payment Card Exp -->
            <div class="hosted-field-wrapper field--card-exp">
              <mat-form-field floatLabel="always" class="size size1of1 custom-mat-form-field">
                <mat-label>Exp. Month</mat-label>
                <input matInput id="authorize_cardExp" [cleave]="cleaveEXPMonthOptions" required placeholder="MM/YY" formControlName="authorize_cardExp">
                <mat-error><error-messages [control]="form.get('authorizeForm').get('authorize_cardExp')"></error-messages></mat-error>
              </mat-form-field>
            </div>
            <!-- Payment Card CVV -->
            <div class="hosted-field-wrapper field--card-exp">
              <mat-form-field floatLabel="always" class="size size1of1 custom-mat-form-field">
                <mat-label>Security Code</mat-label>
                <input matInput id="authorize_CardCVV" [cleave]="cleaveCardCVVOptions" required placeholder="•••" formControlName="authorize_CardCVV">
                <mat-error><error-messages [control]="form.get('authorizeForm').get('authorize_CardCVV')"></error-messages></mat-error>
              </mat-form-field>
            </div>
            <!-- Payment Card Zip  -->
            <div class="hosted-field-wrapper field--card-exp">
              <mat-form-field floatLabel="always" class="size size1of1 custom-mat-form-field">
                <mat-label>Zip</mat-label>
                <input matInput id="authorize_CardZip" required placeholder="••••" formControlName="authorize_CardZip">
                <mat-error><error-messages [control]="form.get('authorizeForm').get('authorize_CardZip')"></error-messages></mat-error>
              </mat-form-field>
            </div>
          </div>
          <!-- PAYPAL SDK -->
          <div formGroupName="paypalForm" [hidden]="selectedCompanyGateway?.keyName !== _gatewayCode.paypal" class="card-payment-form payment-form-authorize">
            <!-- Payment Card Number -->
            <div class="hosted-field-wrapper field--card-number">
              <mat-form-field floatLabel="always" class="size size1of1 custom-mat-form-field">
                <mat-label>Card Number</mat-label>
                <input matInput id="paypal_CardNumber" [cleave]="cleaveCardNumOptions" required placeholder="•••• •••• •••• ••••" formControlName="paypal_CardNumber">
                <mat-error><error-messages [control]="form.get('paypalForm').get('paypal_CardNumber')"></error-messages></mat-error>
              </mat-form-field>
            </div>
            <!-- Payment Card Exp -->
            <div class="hosted-field-wrapper field--card-exp">
              <mat-form-field floatLabel="always" class="size size1of1 custom-mat-form-field">
                <mat-label>Exp. Month</mat-label>
                <input matInput id="paypal_cardExp" [cleave]="cleaveEXPMonthOptions" required placeholder="MM/YY" formControlName="paypal_cardExp">
                <mat-error><error-messages [control]="form.get('paypalForm').get('paypal_cardExp')"></error-messages></mat-error>
              </mat-form-field>
            </div>
            <!-- Payment Card CVV -->
            <div class="hosted-field-wrapper field--card-exp">
              <mat-form-field floatLabel="always" class="size size1of1 custom-mat-form-field">
                <mat-label>Security Code</mat-label>
                <input matInput id="paypal_CardCVV" [cleave]="cleaveCardCVVOptions" required placeholder="•••" formControlName="paypal_CardCVV">
                <mat-error><error-messages [control]="form.get('paypalForm').get('paypal_CardCVV')"></error-messages></mat-error>
              </mat-form-field>
            </div>
            <!-- Payment Card Zip  -->
            <!-- <div class="hosted-field-wrapper field--card-exp">
              <mat-form-field floatLabel="always" class="size size1of1 custom-mat-form-field">
                <mat-label>Zip</mat-label>
                <input matInput id="paypal_CardZip" required placeholder="••••" formControlName="paypal_CardZip">
                <mat-error><error-messages [control]="form.get('paypalForm').get('paypal_CardZip')"></error-messages></mat-error>
              </mat-form-field>
            </div> -->
          </div>
                    
          <!-- Braintree -->
          <div [hidden]="selectedCompanyGateway?.keyName !== _gatewayCode.braintree" class="card-payment-form payment-form-braintree">
            <!-- Payment Card Number -->
            <div class="hosted-field-wrapper field--card-number">
              <label class="label-placeholder">Card Number <span class="mat-placeholder-required mat-form-field-required-marker">*</span></label>
              <div class="hosted-field-container">
                <div id="braintree_CardNumber" class="hosted-field field-card-number"></div>
              </div>
            </div>
            <!-- Payment Card Exp -->
            <div class="hosted-field-wrapper field--card-exp">
              <label class="label-placeholder">Exp Date <span class="mat-placeholder-required mat-form-field-required-marker">*</span></label>
              <div class="hosted-field-container">
                <div id="braintree_CardExp" class="hosted-field field-card-exp"></div>
              </div>
            </div>
            <!-- Payment Card CVV -->
            <div class="hosted-field-wrapper field--card-cvv">
              <label class="label-placeholder">CVV <span class="mat-placeholder-required mat-form-field-required-marker">*</span></label>
              <div class="hosted-field-container">
                <div id="braintree_CardCVV" class="hosted-field field-card-cvv"></div>
              </div>
            </div>
            <!-- Payment Card Zip -->
            <div class="hosted-field-wrapper field--card-zip">
              <label class="label-placeholder">Zip <span class="mat-placeholder-required mat-form-field-required-marker">*</span></label>
              <div class="hosted-field-container">
                <div id="braintree_CardZip" class="hosted-field field-card-zip"></div>
              </div>
            </div>
          </div>
          <!-- Square -->
          <div [hidden]="selectedCompanyGateway?.keyName !== _gatewayCode.square" class="card-payment-form payment-form-square">
            <!-- Card item -->
            <div class="size size1of1" id="square_Card"></div>
          </div>
          <!-- Stripe -->
          <div [hidden]="selectedCompanyGateway?.keyName !== _gatewayCode.stripe" class="card-payment-form payment-form-stripe">
            <!-- Payment Card Number -->
            <div class="hosted-field-wrapper field--card-number">
              <label class="label-placeholder">Card Number <span class="mat-placeholder-required mat-form-field-required-marker">*</span></label>
              <div class="hosted-field-container">
                <div id="stripe_CardNumber" class="hosted-field field-card-number"></div>
              </div>
            </div>
            <!-- Payment Card Exp -->
            <div class="hosted-field-wrapper field--card-exp">
              <label class="label-placeholder">Exp Date <span class="mat-placeholder-required mat-form-field-required-marker">*</span></label>
              <div class="hosted-field-container">
                <div id="stripe_CardExp" class="hosted-field field-card-exp"></div>
              </div>
            </div>
            <!-- Payment Card CVV -->
            <div class="hosted-field-wrapper field--card-cvv">
              <label class="label-placeholder">CVV <span class="mat-placeholder-required mat-form-field-required-marker">*</span></label>
              <div class="hosted-field-container">
                <div id="stripe_CardCVV" class="hosted-field field-card-cvv"></div>
              </div>
            </div>
          </div>
          <!-- Usepay -->
          <div [hidden]="selectedCompanyGateway?.keyName !== _gatewayCode.usaepay" class="card-payment-form payment-form-stripe">
            <div class="size size1of1" id="paymentCardContainer">
              <!-- PaymentCard iFrame content will go here. -->
            </div>
          </div>

          <!-- Cybersource -->
          <div formGroupName="cybersourceForm" [hidden]="selectedCompanyGateway?.keyName !== _gatewayCode.cybersource" class="card-payment-form payment-form-stripe">
            <!-- Payment Card Number -->
            <div class="hosted-field-wrapper field--card-number">
              <label class="label-placeholder">Card Number <span class="mat-placeholder-required mat-form-field-required-marker">*</span></label>
              <div class="hosted-field-container">
                <div id="number-container" class="hosted-field field-card-number"></div>
              </div>
            </div>
            <!-- Payment Card Exp -->
            <div class="hosted-field-wrapper field--card-exp">
              <mat-form-field floatLabel="always" class="size size1of1 custom-mat-form-field">
                <mat-label>Exp. Date</mat-label>
                <input matInput id="cybersource_cardExp" [cleave]="cleaveEXPMonthOptions" required placeholder="MM/YY" formControlName="cybersource_cardExp">
                <mat-error><error-messages [control]="form.get('cybersourceForm').get('cybersource_cardExp')"></error-messages></mat-error>
              </mat-form-field>
            </div>
            <!-- Payment Card CVV -->
            <div class="hosted-field-wrapper field--card-cvv">
              <label class="label-placeholder">CVV <span class="mat-placeholder-required mat-form-field-required-marker">*</span></label>
              <div id="securityCode-container" class="hosted-field field-card-cvv"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div *ngIf="isGatewaySelected && !isPaymentSourceReady">
      <skeleton-loader-gateway [_isPaymentGatewayVisible] = false></skeleton-loader-gateway>
    </div>
  </div>
</div>
