import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/_core/services';
import { EXCHANGE_RATE } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private storage: StorageService) {}

  private setHeaders(headers: HttpHeaders, method: string, context?: any) {
    switch (method) {
      case 'DELETE':
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        break;
      default:
        if(context != 'ignoreContentOverride') {
          headers = headers.set('Content-Type', 'application/json');
        }
    }

    // Get the auth token from local storage.
    const authToken = this.storage.readLocalStorage('access_token');

    return headers
      .set('Authorization', `Bearer ${authToken}`)
      .set('Accept', 'application/json');
  };

  private setExchangeRateHeaders(headers: HttpHeaders) {
    return headers
      .set('Content-Type', 'application/json')
      .set('Authorization', EXCHANGE_RATE.token)
      .set('Accept', 'application/json');
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = new HttpHeaders();
 
    // handles the exchange rate specif auth header
    if (httpRequest.url == EXCHANGE_RATE.url) {
      headers = this.setExchangeRateHeaders(headers);
    } else {
      headers = this.setHeaders(headers, httpRequest.method, httpRequest.context);
    }

    // Clone the request and set the new headers.
    const authReq = httpRequest.clone({ headers });

    // send cloned request with headers to the next handlers.
    return next.handle(authReq);
  }
}
