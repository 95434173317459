import { animateChild, trigger, state, animate, transition, style, keyframes, query, stagger } from '@angular/animations';
import { HoverState, ToggleState, VisibleState } from 'src/app/app.data';

const ShakeAnimation = [
	style({ transform: 'rotate(0)' }),
	animate('0.1s', style({ transform: 'rotate(2deg)' })),
	animate('0.1s', style({ transform: 'rotate(-2deg)' })),
	animate('0.1s', style({ transform: 'rotate(2deg)' })),
	animate('0.1s', style({ transform: 'rotate(0)' })),
];

const fadeIn = [
	style({ opacity: '0' }),
	animate('0.33s', style({ opacity: '1' })),
];

const fadeOut = [
	style({ opacity: '1' }),
	animate('0.33s', style({ opacity: '0' })),
];


export const loadingAnimation = trigger('loadingAnimation',
[
    state('isLoaded', style({
        'max-height': '0',
        'overflow': 'hidden'
    })),
    state('isLoading', style({
        'max-height': '2048px',
        'overflow': 'auto'
    })),
    transition('isLoaded <=> isLoading', animate('.3s ease-in-out')),
]);
export const toggleLoader = trigger('toggleLoader',
[
    state('isLoaded', style({
        'opacity': '1',
        'transform': 'translateY(0)'
    })),
    state('isLoading', style({
        'opacity': '0',
        'transform': 'translateY(20px)'
    })),
    transition('isLoaded <=> isLoading', animate('.3s ease-in-out')),
]);
export const logoAnimation = trigger('logoAnimation',
[
  transition(`${ToggleState.on} => ${ToggleState.off}`, [
    query('#logo-sidenav .rect-1',
      style({ opacity: 0 }), { optional: true }
    ),
    query('#logo-sidenav  .rect-1',
      stagger('.3s', [
        animate('.3s ease-in-out',
          keyframes([
            style({ opacity: 0, offset: 0 }),
            style({ opacity: .75, offset: 0.75 }),
            style({ opacity: 1, offset: 1.0 })
          ]))
        ]), { optional: true }
      ),
  ]),
]);
export const toggleButton = trigger('toggleButton',
[
    state(ToggleState.on, style({
      'border-radius': '24px',
      'width': '48px'
    })),
    state(ToggleState.off, style({
      'border-radius': '24px',
      'width': '120px'
    })),
    transition(`${ToggleState.on} <=> ${ToggleState.off}`, animate('.3s ease-in-out')),
]);
export const toggleLabel = trigger('toggleLabel',
[
    state(ToggleState.on, style({
      'opacity': '0'
    })),
    state(ToggleState.off, style({
      'opacity': '1'
    })),
    transition(`${ToggleState.on} <=> ${ToggleState.off}`, animate('.3s ease-in-out')),
]);
export const toggleLink = trigger('toggleLink',
[
    state(ToggleState.on, style({
      'width': '48px'
    })),
    state(ToggleState.off, style({
      'width': '100%'
    })),
    transition(`${ToggleState.on} <=> ${ToggleState.off}`, animate('.3s ease-in-out')),
]);
export const toggleGridColumn = trigger('onToggleGridColumnStatusChange',
[
    state(VisibleState.isHidden, style({
      'max-width': '0',
      'opacity': '0',
      'overflow': 'hidden',
      'pointer-events': 'none',
    })),
    state(VisibleState.isVisible, style({
      'max-width': '2048px',
      'opacity': '1',
      'overflow': 'auto',
      'pointer-events': 'auto'
    })),
    transition(`${VisibleState.isHidden} <=> ${VisibleState.isVisible}`, animate('.33s ease-in-out')),
]);

export const messageAnimation = trigger('messageAnimation',
[
    state('hidden', style({
        'max-height': '0',
        'overflow': 'hidden'
    })),
    state('visible', style({
        'max-height': '2048px',
        'overflow': 'auto'
    })),
    transition('hidden <=> visible', animate('1s ease-in-out')),
]);
export const panelAnimation = trigger('panelAnimation',
[
    state(VisibleState.isVisible, style({
      'max-height': '2048px',
      'opacity': '1',
      'overflow': 'auto',
    })),
    state(VisibleState.isHidden, style({
      'max-height': '0',
      'opacity': '0',
      'overflow': 'hidden',
      'pointer-events': 'none'
    })),
    transition(`${VisibleState.isHidden} <=> ${VisibleState.isVisible}`, animate('.3s ease-in-out')),
]);
export const pageAnimation = trigger('pageAnimation',
[
    state('isLoaded', style({
        'opacity': '1',
        'transform': 'translateY(0)'
    })),
    state('isLoading', style({
        'opacity': '0',
        'transform': 'translateY(20px)'
    })),
    transition('isLoaded <=> isLoading', animate('.3s ease-in-out')),
]);
export const sandwichAnimation = trigger('sandwichAnimation',
[
    state('opened', style({
            'opacity': '0',
            'pointer-events': 'none'
    })),
    state('closed', style({
        'opacity': '1',
        'pointer-events': 'auto'
    })),
    transition('opened <=> closed', animate('.3s ease-in-out')),
]);
export const spanelAccountAnimation = trigger('spanelAccountAnimation',
[
    state('closed', style({
        'transform': 'translateX(100%)',
        'pointer-events': 'none'
    })),
    state('opened', style({
        'transform': 'translateX(0%)',
        'pointer-events': 'auto'
    })),
    transition('closed <=> opened', animate('.3s ease-in-out')),
]);
export const sidePanelAnimation = trigger('onSidePanelStatusChange',
[
  state(VisibleState.isVisible, style({
      'opacity': '1',
      'pointer-events': 'auto',
      'transform': 'translateX(0)',
      'visibility': 'visible'
  })),
  state(VisibleState.isHidden, style({
      'opacity': '0',
      'pointer-events': 'none',
      'transform': 'translateX(1em)',
      'visibility': 'hidden'
  })),
  transition(`${VisibleState.isHidden} <=> ${VisibleState.isVisible}`, animate('.33s ease-in-out')),
]);
export const panelPreferences = trigger('onPanelStatusChange',
[
        state(VisibleState.isVisible, style({
            'opacity': '1',
            'visibility': 'visible',
            'pointer-events': 'auto',
            'transform': 'translateY(0%)'
        })),
        state(VisibleState.isHidden, style({
            'opacity': '0',
            'visibility': 'hidden',
            'pointer-events': 'none',
            'transform': 'translateY(5%)'
        })),
        transition(`${VisibleState.isHidden} <=> ${VisibleState.isVisible}`, animate('.33s ease-in-out')),
]);
export const panelFadeInOut = trigger('onPanelStatusChange',
[
        state(VisibleState.isVisible, style({
            'opacity': '1',
            'visibility': 'visible',
            'pointer-events': 'auto',
            'transform': 'translateX(0%)'
        })),
        state(VisibleState.isHidden, style({
            'opacity': '0',
            'visibility': 'hidden',
            'pointer-events': 'none',
            'transform': 'translateX(-5%)'
        })),
        transition(`${VisibleState.isHidden} <=> ${VisibleState.isVisible}`, animate('.33s ease-in-out')),
]);
export const panelSearchLoader = trigger('onSearching',
[
    state('isLoaded', style({
        'opacity': '0',
    })),
    state('isLoading', style({
        'opacity': '1'
    })),
    transition('0 <=> 1', animate('.45s ease-in-out')),
]);
export const SpinnerAnimation = trigger('onLoaderStatusChange',
[
    state('isLoaded', style({
        'opacity': '0',
        'pointer-events': 'none'
    })),
    state('isLoading', style({
        'opacity': '1',
        'pointer-events': 'auto'
    })),
    transition('isLoaded <=> isLoading', animate('.3s ease-in-out')),
]);
export const PageAnimation = trigger('onPageStatusChange',
[
    state('isLoaded', style({
        'opacity': '1',
        'pointer-events': 'auto',
        'transform': 'translateY(0)'
    })),
    state('isLoading', style({
        'opacity': '0',
        'pointer-events': 'none',
        'transform': 'translateY(20px)'
    })),
    transition('isLoaded <=> isLoading', animate('.3s ease-in-out')),
]);
export const navMobileAnimation = trigger('navMobileAnimation',
[
    state('0', style({
        'opacity': '0',
        'overflow-x': 'hidden',
        'overflow-y': 'hidden',
        'pointer-events': 'none',
        'transform': 'scale(1.1)',
        'z-index': '-1',
    })),
    state('1', style({
        'opacity': '1',
        'overflow-x': 'hidden',
        'overflow-y': 'auto',
        'pointer-events': 'auto',
        'transform': 'scale(1)',
        'z-index': '99',
    })),
    transition('0 <=> 1', animate('.3s ease-in-out')),
]);
export const bodyLoader = trigger('bodyLoader',
[
    state('0', style({
        opacity: 0
    })),
    state('1', style({
        opacity: 1
    })),
    transition('0 <=> 1', animate('.45s ease-in-out')),
]);
export const bodyIsLoading = trigger('bodyIsLoading',
[
    state('0', style({
        'opacity': '1'
    })),
    state('1', style({
        'opacity': '0'
    })),
    transition('0 <=> 1', animate('.75s ease-in-out')),
]);
export const messageDisplay = trigger('messageDisplay',
[
    state('0', style({
        'height': '*',
        'max-height': '0',
        'opacity': '0',
        'transform': 'translateY(25px)'
    })),
    state('1', style({
        'height': 'auto',
        'max-height': '999px',
        'opacity': '1',
        'transform': 'translateY(0)'
    })),
    transition('0 <=> 1', animate('.45s ease-in-out')),
]);
export const accountInfoIsLoading = trigger('accountInfoIsLoading',
[
    state('0', style({
        'height': '128px',
    })),
    state('1', style({
        'height': '64px',
    })),
    transition('0 <=> 1', animate('.33s ease-in-out')),
]);
export const pageLoader = trigger('pageLoader',
[
    state('0', style({
        'opacity': '0'
    })),
    state('1', style({
        'opacity': '1'
    })),
    transition('0 <=> 1', animate('.45s ease-in-out')),
]);
export const listAnimate = trigger('list',
[
    transition('* => *', [
        query(':enter', style({ opacity: 0 }), { optional: true }),
        query(':enter', stagger('.3s', [
            animate('.3s ease-in-out', keyframes([
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 0 }),
                style({ opacity: .75, transform: 'translateY(0)', offset: 0.75 }),
                style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 })]))]), { optional: true }),
        query(':leave', stagger('.3s', [
            animate('.3s ease-in', keyframes([
                style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
                style({ opacity: .75, transform: 'translateY(-10%)', offset: 0.75 }),
                style({ opacity: 0, transform: 'translateY(-10%)', offset: 1.0 })]))]), { optional: true })
    ])
]);
export const listItemAnimate = trigger('items',
[
    transition('* => void', [
        style({ transform: 'translateY(5%)',  opacity: 1 }),
        animate('.3s ease-in-out', style({ transform: 'translateY(0)', opacity: 0 }))
    ]),
    transition('void => *', [
        style({ transform: 'translateY(5%)', opacity: 0 }),
        animate('.33s ease-in-out', style({ transform: 'translateY(0px)', opacity: 1 }))
    ]),
]);
export const smoothAppearance = trigger('smoothAppearance',
[
    transition(':enter', [
        query('.smooth--enter', style({ opacity: 0}), { optional: true }),
        query('.smooth--enter', stagger(100, [
            animate('.33s ease-in', keyframes([
                style({ opacity: 0, offset: 0 }),
                style({ opacity: 1, offset: 1 }),
            ]))
        ]), { optional: true })
    ])
])
export const systemMessages = trigger('systemMessages',
[
    state(VisibleState.isHidden, style({
        'max-height': '0'
    })),
    state(VisibleState.isVisible, style({
        'max-height': '2048px'
    })),
    transition(`${VisibleState.isHidden} <=> ${VisibleState.isVisible}`, animate('1s ease-in-out')),
]);
export const showWhenLoaded = trigger('showWhenLoaded',
[
    state(VisibleState.isHidden, style({ opacity: 0 })),
    state(VisibleState.isVisible, style({ opacity: 1 })),
    transition(`${VisibleState.isHidden} <=> ${VisibleState.isVisible}`, animate('.33s ease-in-out')),
]);
export const loaderAnimation = trigger('onLoadingStatusChange',
[
    state('isLoading', style({
        'opacity': '1',
        'max-height': '2048px',
        'overflow': 'auto',
        'pointer-events': 'auto'
    })),
    state('isLoaded', style({
        'opacity': '0',
        'max-height': '0px',
        'overflow': 'hidden',
        'pointer-events': 'none'
    })),
    transition('isLoading <=> isLoaded', animate('.45s ease-in-out')),
]);
export const rowAnimation = trigger('onRowStatusChange',
[
    state(VisibleState.isHidden, style({
        'opacity': '0',
        'max-height': '0',
        'min-height': '0',
        'overflow': 'hidden',
        'pointer-events': 'none',
        'transform': 'translateY(1em)'
    })),
    state(VisibleState.isVisible, style({
        'opacity': '1',
        'max-height': '4096px',
        'min-height': '64.5px',
        'pointer-events': 'auto',
        'transform': 'translateY(0)'
    })),
    transition(`${VisibleState.isHidden} <=> ${VisibleState.isVisible}`, animate('.33s ease-in-out')),
]);
export const sectionAnimation = trigger('onSectionStatusChange',
  [
    state(VisibleState.isHidden, style({
      'max-height': '0',
      'opacity': '0',
      'overflow': 'hidden',
      'pointer-events': 'none',
      'transform': 'translateY(1em)'
    })),
    state(VisibleState.isVisible, style({
      'max-height': '4096px',
      'opacity': '1',
      'overflow': 'auto',
      'pointer-events': 'auto',
      'transform': 'translateY(0)'
    })),
    transition(`${VisibleState.isHidden} <=> ${VisibleState.isVisible}`, animate('.33s ease-in-out')),
  ]);
export const colAnimation = trigger('onColumnStatusChange',
[
    state(VisibleState.isHidden, style({
        'max-width': '0',
        'min-width': '0',
        'opacity': '0',
        'overflow': 'hidden',
        'pointer-events': 'none',
    })),
    state(VisibleState.isVisible, style({
        'opacity': '1',
        'pointer-events': 'auto'
    })),
    transition(`${VisibleState.isHidden} <=> ${VisibleState.isVisible}`, animate('.33s ease-in-out')),
]);
export const skeletonAnimation = trigger('skeletonAnimation', [
  transition(':enter', [
    style({ height: 0, opacity: 0 }),
    animate('.33s ease-out', style({  opacity: 1 }))
  ]),
  transition(':leave', [
    style({ height: 'inherit', opacity: 1 }),
    animate('.33s ease-in', style({ opacity: 0 }))
  ])
])
export const skeletonListAnimation = trigger('skeletonListAnimation', [
  transition(':enter', [
    style({
      'opacity': '0',
      'max-height': '0',
      'min-height': '0',
      'overflow': 'hidden',
      'pointer-events': 'none',
      'transform': 'translateX(16px)'
    }),
    animate('.33s ease-out', style({ 'transform': 'translateX(0)' }))
  ]),
  transition(':leave', [
    style({
      'opacity': '1',
      'max-height': '4096px',
      'min-height': '64.5px',
      'overflow': 'auto',
      'pointer-events': 'auto',
      'transform': 'translateX(0)'
    }),
    animate('.33s ease-in', style({ 'transform': 'translateX(0px)' }))
  ])
]);
export const fadeInAnimation = trigger('fadeInAnimation', [
  transition('* => *', [
    query(
      ':enter',
      [style({ opacity: 0 }), animate('1s', style({ opacity: 1 }))],
      { optional: true }
    )
  ])
]);
