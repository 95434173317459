import { Country } from './country.model';

export class CurrencyGateway {
    constructor(
        public id?: number,
        public name?: string,
        public isDefault?: boolean,
        public status?: string,
        public internalGatewayCode?: string,
        public internalGatewayId?: number
    ){}
}

export class Currency {
    constructor(
        public flag?: string,
        public id?: number,
        public iso3?: string,
        public name?: string,
        public numericCode?: number,
        public symbol?: string,
    ){}
}
