<header class="global-header">
  <div class="right-options">
    <!-- defines other options to the left of notifications -->
    <ng-content select="#other-options"></ng-content>
    <div *appRestriction="'feature_reportAnIssue|enabled|SCOPE_HIDE'" class="button-container">
      <reb-ngx-mat-menu
      iconLeft="ico-phone-call"
      label="Help Center"
      kind="text"
      overlayPanelClass=""
      size="large"
    >
      <!-- defines the header menu -->
      <div class="header-menu">
        <div class="top">
          <reb-ngx-label size="14">Help Center</reb-ngx-label>
        </div>
      </div>
      <reb-ngx-divider marginHorizontal="none" marginVertical="16"></reb-ngx-divider>
      <!-- defines the middle menu options -->
      <ng-content select="#company-menu-items-helpdesk"></ng-content>
      <reb-ngx-divider marginHorizontal="none" marginVertical="16"></reb-ngx-divider>
    </reb-ngx-mat-menu>
    </div>
    <!-- <reb-ngx-mat-button-icon  icon="ico-phone-call"
    kind="text"
    mood="none"
    size="large"></reb-ngx-mat-button-icon> -->

    <ng-content select="#notifications"></ng-content>
    <reb-ngx-avatar
      *ngIf="showAvatar"
      [firstChar]="userFirstName"
      [lastChar]="userLastName"
      size="small"
      fontColor="#FFFFFF"
      backgroundColor="#F7A850"
    ></reb-ngx-avatar>
    <reb-ngx-mat-menu
      iconRight="ico-chevron-down"
      [label]="companyName"
      kind="text"
      overlayPanelClass="rn-panel-account"
      size="medium"
    >
      <!-- defines the header menu -->
      <div class="header-menu">
        <div class="top">
          <reb-ngx-label size="16" weight="500">{{ userName }}</reb-ngx-label>
          <reb-ngx-label size="12">{{ companyName }}</reb-ngx-label>
        </div>
        <reb-ngx-chip mood="info">{{ userRole }}</reb-ngx-chip>
      </div>
      <reb-ngx-divider marginHorizontal="none" marginVertical="16"></reb-ngx-divider>
      <!-- defines the middle menu options -->
      <ng-content select="#company-menu-items-middle"></ng-content>
      <reb-ngx-divider marginHorizontal="none" marginVertical="16"></reb-ngx-divider>
      <!-- defines the bottom menu options -->
      <ng-content select="#company-menu-items-bottom"></ng-content>
    </reb-ngx-mat-menu>
  </div>
</header>
