<div class="dialog-wrapper">
  <!-- Head -->
  <div class="head">
    <ng-container *ngTemplateOutlet="dialogHead ? dialogHead : defaultDialogHead"></ng-container>
  </div>
  <!-- Body -->
  <div class="body">
    <ng-container *ngTemplateOutlet="dialogBody ? dialogBody : defaultDialogBody"></ng-container>
  </div>
  <!-- Nav -->
  <div class="nav">
    <ng-container *ngTemplateOutlet="dialogNav ? dialogNav : defaultDialogNav"></ng-container>
  </div>
</div>

<ng-template #defaultDialogHead>Dialog Head...</ng-template>
<ng-template #defaultDialogBody>Dialog Body...</ng-template>
<ng-template #defaultDialogNav>Dialog Nav...</ng-template>
