//angular
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { DeviceDetectorModule } from 'ngx-device-detector';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//app data
import { AppData } from './app.data';
import { AppKeyNames } from './app.keynames';
//app modules
import { AppMaterialModule } from './app.material';
import { AppRouterModule } from './app.routing';
import { AppSharedModule } from './_shared/app.shared.module';
import { CoreModule } from './_core/core.module';
//app providers
import { OriginProviders } from './_shared/providers/origin.provider';
//app services
import { AuthGuardService } from './_guard/auth.guard';
//app components
import { AppComponent } from './app.component';
import { CallbackComponent } from './modules/login/callback/callback.component';
import { ForbiddenComponent } from './_shared/pages/forbidden/forbidden.component';
import { PageNotFoundComponent } from './_shared/pages/pagenotfound/pagenotfound.component';
import { UnauthorizedComponent } from './_shared/pages/unauthorized/unauthorized.component';

// this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { IdleExpiry, SimpleExpiry } from '@ng-idle/core';
import { CrossServiceModule } from './_core/services/api/cross-service.module';
import myLocaleEs from '@angular/common/locales/es'
import myLocaleFr from '@angular/common/locales/fr'
import localeEnGB from '@angular/common/locales/en-GB';

import {DatePipe, registerLocaleData} from '@angular/common';
import { RestrictionResolver } from './_shared/services/permission/restriction.resolver';
import { PermissionsGuardService } from './_shared/services/permission/permissions.guard';
import { LoggedInGuard } from './_guard/loggedin.guard';

registerLocaleData(localeEnGB);
registerLocaleData(myLocaleEs);
registerLocaleData(myLocaleFr);

const COMPONENTS: any[] = [
  AppComponent,
  CallbackComponent,
  ForbiddenComponent,
  PageNotFoundComponent,
  UnauthorizedComponent,
]

const MODULES: any[] = [
  AppMaterialModule,
  AppSharedModule,
  AppRouterModule,
  BrowserModule,
  BrowserAnimationsModule,
  CoreModule,
  CrossServiceModule,
  HttpClientModule,
  NgIdleKeepaliveModule.forRoot()
  // FormsModule,
  // ReactiveFormsModule,
  // DeviceDetectorModule.forRoot()
]

const PROVIDERS: any[] = [
  AppData,
  AppKeyNames,
  AuthGuardService,
  LoggedInGuard,
  RestrictionResolver,
  PermissionsGuardService,
  OriginProviders,
  DatePipe
  // {provide: LOCALE_ID, useValue: 'en'}
]

@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [ COMPONENTS ],
  imports: [ MODULES ],
  providers: [ PROVIDERS, {
    provide: IdleExpiry,
    useClass: SimpleExpiry
} ]
})

export class AppModule { }
