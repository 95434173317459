//angular
import { Component, HostBinding, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
//app
import { AppAnimations } from 'src/app/_shared/animations';
import { KeyName } from '../../../../../app.keynames';
import { LoaderService } from 'src/app/_core/services';
import { RatePlanStatus, VisibleState } from 'src/app/app.data';
import { RestResponse, SubscriptionCounter } from 'src/app/_shared/models';
import { HttpParamsInterface } from 'src/app/_shared/interfaces/http-params.interface';
import { SharedProductService } from 'src/app/_core/services/api';
import { Unsubscriber } from 'src/app/_shared/unsubscriber/unsubscriber';
import { MetaService } from 'src/app/_core/services/meta.service';
import { ProductRatePlanModel } from 'src/app/_shared/models/product.model';

@Component({
  animations: [AppAnimations],
  selector: 'app-dialog-confirmation',
  templateUrl: './confirmation.component.html'
})

export class DialogConfirmationComponent {
  _cantOfPlanToDelete = 0;
  _keyName = KeyName;
  _plansToDelete = [];
  _ratePlanStatus = RatePlanStatus;
  _isResponseReady: boolean = false;
  _subscriptionCount: any;
  _visibleState = VisibleState;
  _word_confirmation = "";
  extraCheckbox = false;
  
  private subs = new Unsubscriber();

  @HostBinding('class') class = 'dialog-confirmation';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<DialogConfirmationComponent>,
    private _iconRegistry: MatIconRegistry,
    private _loader: LoaderService,
    public _meta: MetaService,
    private sharedProductService: SharedProductService,
    private _sanitizer: DomSanitizer
  ) {
    this._iconRegistry.addSvgIcon('card-alipay', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-alipay.svg'));
    this._iconRegistry.addSvgIcon('card-amex', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-amex.svg'));
    this._iconRegistry.addSvgIcon('card-diners', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-diners.svg'));
    this._iconRegistry.addSvgIcon('card-discover', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-discover.svg'));
    this._iconRegistry.addSvgIcon('card-elo', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-elo.svg'));
    this._iconRegistry.addSvgIcon('card-hypercard', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-hypercard.svg'));
    this._iconRegistry.addSvgIcon('card-jcb', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-jcb.svg'));
    this._iconRegistry.addSvgIcon('card-maestro', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-maestro.svg'));
    this._iconRegistry.addSvgIcon('card-master', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-master.svg'));
    this._iconRegistry.addSvgIcon('card-paypal', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-paypal.svg'));
    this._iconRegistry.addSvgIcon('card-unionpay', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-unionpay.svg'));
    this._iconRegistry.addSvgIcon('card-verve', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-verve.svg'));
    this._iconRegistry.addSvgIcon('card-visa', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-visa.svg'));
    this._iconRegistry.addSvgIcon('ico-warning', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-error-10.svg'));

    //ShowWarningMessage is used to display a warning message to the user.
    if(data.showWarningMessage){
      this._isResponseReady = false;
      const httpParams: HttpParamsInterface = {}
      this.subs.addSub = this.sharedProductService.getSubscriptionCounterByProductRatePlanId(data.ratePlan.id, httpParams)
      .subscribe(
        (res: RestResponse) => {
          console.log('@@@res',res);
          let data = new SubscriptionCounter().deserialize(res.data);
          console.log('@@@data',data);
          this._isResponseReady = true;
          this._subscriptionCount = data.subscriptionCount;
          console.log('@@@this._subscriptionCounter',this._subscriptionCount);
        },
        (err: RestResponse) => {
          this._isResponseReady = false;
          this._meta.showNotification(err);
        }
      )
    }
  }

  onClose(): void {
    this._dialogRef.close();
  }

  onSubmit(code: any) {
    this._loader.startPageLoader();

    if(this.extraCheckbox) {
      this._dialogRef.close({code, extraCheckbox: true});
    } else {
      this._dialogRef.close(code);
    }
  }

  disableButton(){
    if(this.data.word_confirmation == this._word_confirmation ){
      return false;
    } else
      return true;
  }
}
