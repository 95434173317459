//angular
import { NgModule, Optional, SkipSelf } from '@angular/core';
//app
import { httpInterceptorProviders } from './http-interceptors';
import { SERVICES } from './services/';
import { AuthService } from './services/auth.service';
import { GatewayService } from './services/gateway.service';
import { MetaService } from './services/meta.service';
import { NotificationService } from './services/notification.service';
import { SnackbarMessageService } from './services/snackbar-message.service';
import { TaxService } from './services/tax.service';
import { RebNgxSnackbarService } from '@rhinosaas/reb-ngx/reb-ngx-mat-snack-bar';

const PROVIDERS: any[] = [
  httpInterceptorProviders,
  SERVICES,
  AuthService,
  GatewayService,
  MetaService,
  NotificationService,
  TaxService,
  SnackbarMessageService,
  RebNgxSnackbarService
]

@NgModule({
  exports: [],
  imports: [],
  providers: PROVIDERS
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
