import { Deserializable } from "../interfaces/deserialize.interface";

export class Timezone implements Deserializable<Timezone> {
    constructor(
        public id?: number,
        public value?: string,
        public offset?: number,
        public isdst?: boolean,
        public text?: string,
    ){}

    deserialize(dataJSON: any): Timezone {
        Object.assign(this, dataJSON);
        return this;
    }
}