import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from 'src/environments/environment';

import { DataSharingService, RestService, StorageService } from 'src/app/_core/services';
import { HttpParamService } from 'src/app/_core/services/api/http-param.service';
import { HttpParamsInterface } from 'src/app/_shared/interfaces/http-params.interface';
import { Observable } from 'rxjs';
import { POST_SubscriptionInterface, RestResponse } from 'src/app/_shared/models';
import { POST_SubscriptionRatePlan } from 'src/app/_shared/models/product.model';

const APIEndpoint: string = environment.APIEndpoint;
const APIVersion: string = environment.APIVersion;

const APIResourceCompanies: string = '/companies';
const APIResourceSubscriptions: string = '/subscriptions';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private subscription: Subscription;
  public serviceUrl_Subscriptions: string;
  public serviceUrl_Company: string;

  constructor(private httpParamService: HttpParamService,
    private _shareData: DataSharingService,
    private _storage: StorageService,
    private _rest: RestService) {

      let entityId: string;

      this.subscription = this._shareData.currentCompany.subscribe(data => {
        if (data.companyId){
          entityId = `/${data.companyId}`;
        } else {
          entityId = `/${this._storage.readLocalStorage('entityId')}`;
        }

      // SUBSCRIPTIONS
      // /companies/{{companyId}}/subscriptions
      this.serviceUrl_Subscriptions = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceSubscriptions;
      // /companies/{{companyId}}
        this.serviceUrl_Company = APIEndpoint + APIVersion + APIResourceCompanies + entityId;
    });
  }

  public getCompanyPlan(): Observable<any> {
    return this._rest.get(`${this.serviceUrl_Company}/plan`)
  }

  public getCompanyOrder(): Observable<any> {
    return this._rest.get(`${this.serviceUrl_Company}/order`)
  }

  // get payment method by comppany id
  public getPaymentMethod(): Observable<any> {
    return this._rest.get(`${this.serviceUrl_Company}/billing/paymentmethod`)
  }

  public getPaymentMethodV2(): Observable<any> {
    return this._rest.get(`${this.serviceUrl_Company}/billing/paymentmethod/v2/list`)
  }

  public updatePaymentMethodV2(invoiceId: string,customerPaymentMethodId: string ): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Company}/billing/paymentmethod/v2/invoice/${invoiceId}/customer-payment-method/${customerPaymentMethodId}`, {})
  }

  public getCompanyRateplans(): Observable<any> {
    return this._rest.get(`${this.serviceUrl_Company}/v2/subscriptions?include=ratePlanCharge&orderBy=createdAt`)
  }


  public createPaymentMethodsByCompanyId( data): Observable<any> {
    return this._rest.post(`${this.serviceUrl_Company}/billing/paymentmethod`, data)
  }

  public createPaymentMethodsByCompanyIdV2( data): Observable<any> {
    return this._rest.post(`${this.serviceUrl_Company}/billing/paymentmethod/v2/new-customer-payment-method`, data)
  }

  public setAsPrimaryPaymentMethodsByCompanyId(id, data): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Company}/billing/paymentmethod/set-primary/${id}`, data)
  }

  public updatePaymentMethodsByCompanyId(id, data): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Company}/billing/paymentmethod/${id}`, data)
  }

  public updatePaymentMethodsOrderByCompanyId(id, data): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Company}/billing/paymentmethod/${id}/priority-order`, data)
  }

  public updatePaymentMethodsByCompanyIdV2(id, data): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Company}/billing/paymentmethod/v2/${id}`, data)
  }

  public addSubscriptionRatePlanRules(subscriptionId: number, bodyParams: POST_SubscriptionRatePlan): Observable<any> {
    return this._rest.post(`${this.serviceUrl_Subscriptions}/${subscriptionId}/rateplans`, bodyParams)
  }

  public createExternalRatePlanCharge(subscriptionId: number, ratePlanChargeId: number, bodyParams: any): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_Subscriptions}/${subscriptionId}/rateplan-charges/${ratePlanChargeId}/external-rateplan-charge`, bodyParams);
  }

  public createRatePlanChargeBySubscriptionId(subscriptionId: number, ratePlanId: number, _bodyParams: any ): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_Subscriptions}/${subscriptionId}/rateplans/${ratePlanId}/rateplan-charges`, _bodyParams);
  }

  public moveRatePlanToDifferentSubscriptionBag(subscriptionId: number, ratePlanId: number): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_Subscriptions}/${subscriptionId}/rateplans/${ratePlanId}/move-rateplan/new-bag`, {});
  }

  public createSubscription(_bodyParams: POST_SubscriptionInterface): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_Subscriptions}`, _bodyParams);
  }

  public deleteExternalRatePlanCharge(subscriptionId: number, ratePlanChargeId: number, externalRatePlanChargeId: number): Observable<any> {
    return this._rest.delete(`${this.serviceUrl_Subscriptions}/${subscriptionId}/rateplan-charges/${ratePlanChargeId}/external-rateplan-charge/${externalRatePlanChargeId}`);
  }

  public deleteRatePlanChargeBySubscriptionId(subscriptionId: number, chargeId: number): Observable<RestResponse> {
    return this._rest.delete(`${this.serviceUrl_Subscriptions}/${subscriptionId}/rateplan-charges/${chargeId}`);
  }

  public deleteSubscriptionBySubscriptionId(subscriptionId: number): Observable<RestResponse> {
    return this._rest.delete(`${this.serviceUrl_Subscriptions}/${subscriptionId}`);
  }

  public getExternalOrdersBySubscriptionId(subscriptionId: number, pathParams?: HttpParamsInterface): Observable<RestResponse> {
    let params = pathParams ? this.httpParamService.buildPathParams(pathParams) : null;
    return this._rest.get(`${this.serviceUrl_Subscriptions}/${subscriptionId}/external-invoices`, { params: params });
  }

  public createOrderManually(externalInvoiceId: number,companyIntegration: any, bodyParams?: any): Observable<RestResponse> {
    return this._rest.put(`${this.serviceUrl_Company }/companyIntegration/${companyIntegration}/external-invoices/${externalInvoiceId}`, bodyParams);
  }

  public getInvoicesBySubscriptionId(subscriptionId: number, pathParams?: HttpParamsInterface): Observable<RestResponse> {
    let params = pathParams ? this.httpParamService.buildPathParams(pathParams) : null;
    return this._rest.get(`${this.serviceUrl_Subscriptions}/${subscriptionId}/invoices`, { params: params });
  }

  public getLogsBySubscriptionId(subscriptionId: number, pathParams: HttpParamsInterface, noFormat: boolean): Observable<RestResponse> {
    let params = this.httpParamService.buildPathParams(pathParams);
    return this._rest.get(`${this.serviceUrl_Subscriptions}/${subscriptionId}/logs`, { params: params }, noFormat);
  }

  public getNextBillBySubscriptionId(subscriptionId: number, pathParams?: HttpParamsInterface): Observable<RestResponse> {
    let params = pathParams ? this.httpParamService.buildPathParams(pathParams) : null;
    return this._rest.get(`${this.serviceUrl_Subscriptions}/${subscriptionId}/nextBill`, { params: params });
  }

  public getSubscriptions(pathParams: HttpParamsInterface): Observable<RestResponse> {
    let params = this.httpParamService.buildPathParams(pathParams);
    return this._rest.get(`${this.serviceUrl_Subscriptions}`, { params: params } );
  }

  public getRateplans(pathParams: HttpParamsInterface): Observable<RestResponse> {
    let params = this.httpParamService.buildPathParams(pathParams);
    return this._rest.get(`${this.serviceUrl_Company}/rateplans`, { params: params } );
  }

  public getSubscriptionBySubscriptionId(subscriptionId: number, pathParams?: HttpParamsInterface): Observable<RestResponse> {
    let params = pathParams ? this.httpParamService.buildPathParams(pathParams) : null;
    return this._rest.get(`${this.serviceUrl_Subscriptions}/${subscriptionId}`, { params: params } );
  }

  public getSubscriptionRatePlans(subscriptionId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Subscriptions}/${subscriptionId}/rateplans`);
  }

  public getSubscriptionRatePlanCharges(subscriptionId: number, ratePlanId: number, pathParams: HttpParamsInterface): Observable<RestResponse> {
    let params = pathParams ? this.httpParamService.buildPathParams(pathParams) : null;
    return this._rest.get(`${this.serviceUrl_Subscriptions}/${subscriptionId}/rateplans/${ratePlanId}`, { params: params } );
  }

  public getSubscriptionRatePlanChargeById(subscriptionId: number, ratePlanChargeId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Subscriptions}/${subscriptionId}/rateplan-charges/${ratePlanChargeId}`);
  }

  public getSubscriptionUpcomingDates(subscriptionId: number, endPeriodDate: string ): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Subscriptions}/${subscriptionId}/upcoming?date=${endPeriodDate}`);
  }

  public updateRateplanChargeByRatePlanChargeId(subscriptionId: number, ratePlanChargeId: number, bodyParams: any): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Subscriptions}/${subscriptionId}/rateplan-charges/${ratePlanChargeId}`, bodyParams)
  }

  public updateRateplanChargeSaleChannelData(subscriptionId: number, ratePlanChargeId: number, bodyParams: any ): Observable<RestResponse> {
    return this._rest.put(`${this.serviceUrl_Subscriptions}/${subscriptionId}/rateplan-charges/${ratePlanChargeId}/use-sale-channel-data`, bodyParams);
  }

  public updateSubscriptionRatePlanChargeStatus(subscriptionId: number, ratePlanChargeId: number, nextStatus: string): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Subscriptions}/${subscriptionId}/rateplan-charges/${ratePlanChargeId}/status`, { status: nextStatus });
  }

  public updateSubscriptionStatus(subscriptionId: number, nextStatus: string): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Subscriptions}/${subscriptionId}/status`, { status: nextStatus });
  }

  public updateSubscriptionSaleChannel(subscriptionId: number, bodyParams: any): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Subscriptions}/${subscriptionId}/link-sale-channel`, bodyParams);
  }

  public updateExternalRatePlanCharge(subscriptionId: number, ratePlanChargeId: number, externalRatePlanChargeId: number, bodyParams: any ): Observable<RestResponse> {
    return this._rest.put(`${this.serviceUrl_Subscriptions}/${subscriptionId}/rateplan-charges/${ratePlanChargeId}/external-rateplan-charge/${externalRatePlanChargeId}`, bodyParams);
  }

  public updateSubscriptionRatePlanRules(subscriptionId: number, ratePlanChargeId: number, bodyParams: any): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Subscriptions}/${subscriptionId}/rateplans/${ratePlanChargeId}/rules`, bodyParams)
  }

  public updateSubscriptionRatePlanStatus(subscriptionId: number, ratePlanChargeId: number, bodyParams: any): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Subscriptions}/${subscriptionId}/rateplans/${ratePlanChargeId}/status`, bodyParams)
  }

  public updateSubscription(subscriptionId: number, bodyParams: POST_SubscriptionInterface): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Subscriptions}/${subscriptionId}`, bodyParams);
  }

  public changeRatePlan(subscriptionId: number, queryParams: any, bodyParams: any): Observable<any> {
    return this._rest.post(`${this.serviceUrl_Subscriptions}/${subscriptionId}/change-rateplan?productRatePlanId=${queryParams.productRatePlanId}&ratePlanId=${queryParams.ratePlanId}`, bodyParams);
  }

  public createRateplanChargeUpcoming(subscriptionId: number, reateplanChargeId: number, bodyParams: any): Observable<any> {
    return this._rest.post(`${this.serviceUrl_Subscriptions}/${subscriptionId}/upcoming/rateplan-charges/${reateplanChargeId}`, bodyParams);
  }

  public requestPaymentNotification(bodyParams: any): Observable<any> {
    return this._rest.post(`${this.serviceUrl_Company}/subscriptions/notification/requestpayment`, bodyParams);
  }


  public getCompanyPlanHistory(rateplanId) {
    return this._rest.get(`${this.serviceUrl_Company}/rateplan-history/${rateplanId}`);
  }

  public chargeNow(subscriptionId, data){
    return this._rest.put(`${this.serviceUrl_Subscriptions}/${subscriptionId}/charge-now`, data)
  }
}
