import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  RebNgxIconsRegistry,
  RebNgxIconBell,
  RebNgxIconBellNotification,
  RebNgxIconChevronDown,
  RebNgxIconPhoneCall
} from '@rhinosaas/reb-ngx/reb-ngx-icon';
import { SupportComponent } from '../support/support.component';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-global-header',
  styleUrls: ['./global-header.component.scss'],
  templateUrl: './global-header.component.html'
})
export class GlobalHeaderComponent implements OnInit {
  /**
   * Company Name
   */
  @Input() companyName: string = '';

  /**
   * Disable Notifications
   */
  @Input() disableNotifications: boolean = false;

  /**
   * Show Avatar
   */
  @Input() showAvatar: boolean = true;

  /**
   * User First Name
   */
  @Input() userFirstName: string = '';

  /**
   * User Last Name
   */
  @Input() userLastName: string = '';

  /**
   * User Role
   */
  @Input() userRole: string = '';

  userName: string = '';

  constructor(private rebNgxIconsRegistry: RebNgxIconsRegistry, private dialog: MatDialog,     private _iconRegistry: MatIconRegistry, private _sanitizer: DomSanitizer) {
    rebNgxIconsRegistry.registerIcons([
      RebNgxIconBell,
      RebNgxIconBellNotification,
      RebNgxIconChevronDown,
      RebNgxIconPhoneCall
    ]);
    this._iconRegistry.addSvgIcon('ico-ticket-issue', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-help-contactus.svg'));
    this._iconRegistry.addSvgIcon('ico-customer-portal', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-question-01.svg'));
  }

  ngOnInit() {
    this.userName = `${this.userFirstName} ${this.userLastName}`;
  }

  openSupport(){
    this.dialog.open(SupportComponent, {
      panelClass: ['custom-mat-dialog'],
    }).afterClosed().subscribe((res)=>{})
  }

  openExternalLink(url: string) {
    window.open(url, '_blank');
  }
}
