import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {}

  public readLocalStorage(key: string): any {
    const data = localStorage.getItem(key);
    if (data != null) {
      return data;
    }
    return;
  }

  public writeLocalStorage(key: string, value: any): void {
    localStorage.setItem(key, value);
  }  

}