<div class="skeleton" [ngClass]="{'skeleton-form-wrapper': !insideSection, 'top': top}">
  <div class="form-content">
    <div class="form-section">
      <div *ngIf="hasTitle" class="form-section-side">
        <h1 class="label-title">
          <skeleton-loader [theme]="{'width': '100px', 'height': '20px'}"></skeleton-loader>
        </h1>
      </div>
      <div class="form-section-container">
        <div class="form-section-body">
          <div *ngFor="let fake of _skeleton.generateFake(numberOfInputs)" class="form-row">
            <mat-form-field class="custom-mat-form-field size size1of1">
              <input matInput disabled>
              <skeleton-loader [theme]="{'width': '40%'}"></skeleton-loader>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
