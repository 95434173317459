import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataSharingService } from 'src/app/_core/services';
import { UtilsService } from '../services';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  displayDateFormat: string;
  decimalPlaces: string;

  constructor(
    private _shareData: DataSharingService,
    private _utils: UtilsService,
  ) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(httpRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (httpRequest.method == 'GET' && !this._shareData.isNoFormatResponse(httpRequest.url)) {
          if (event instanceof HttpResponse) {
            const body = event.body;
            // used for date format
            // this._utils.iterateBody(body, this._utils.isDateStringValid.bind(this._utils),this._utils.formatDateToReceive.bind(this._utils));
            // used for decimal format
            this._utils.iterateBody(body, this._utils.isDecimalField.bind(this._utils),this._utils.formatDecimalNumberToReceive.bind(this._utils));
          }
        }
        return event;
      })
    );
  }
}
