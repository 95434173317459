//angular
import { Component, Inject, ViewChild, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
//app
import { AppAnimations } from 'src/app/_shared/animations';
import { PaymentFormComponent } from '../payment-form.component';
import { RestResponse } from 'src/app/_shared/models';
import { POST_CustomerPaymentSourceInterface } from '../../interfaces/customer.interface';

import { SharedCustomerService } from 'src/app/_core/services/api/shared-customer.service';
import { LoaderService } from 'src/app/_core/services';
import { MetaService } from 'src/app/_core/services/meta.service';
import { Unsubscriber } from 'src/app/_shared/unsubscriber/unsubscriber';

@Component({
  animations: [AppAnimations],
  selector: 'app-dialog-payment-form',
  templateUrl: './dialog-payment-form.component.html',
})

export class DialogPaymentFormComponent implements OnDestroy {
  private subs = new Unsubscriber();

  formSubmitted: boolean = false;
  @ViewChild(PaymentFormComponent) _paymentFormComponent: PaymentFormComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _dialogRef: MatDialogRef<DialogPaymentFormComponent>,
    private _iconRegistry: MatIconRegistry,
    private _loader: LoaderService,
    private _meta: MetaService,
    private _sanitizer: DomSanitizer,
    private sharedCustomerService: SharedCustomerService
  ) {
    this._iconRegistry.addSvgIcon('ico-close', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-close-01.svg'));
  }

  createPaymentSource(payment: any) {
    let bodyParams: POST_CustomerPaymentSourceInterface = {
      accountKind: null,
      accountName: null,
      accountType: null,
      billingAddress: null,
      companyGatewayId: null,
      paymentMethod: null,
      type: null
    }

    let billingAddress = payment?.billingAddress;

    bodyParams.accountKind = payment?.accountKind
    bodyParams.accountName = payment?.accountName,
    bodyParams.accountType = payment?.accountType,
    bodyParams.billingAddress = {
      street1: billingAddress?.street1,
      street2: billingAddress?.street2 || '',
      city: billingAddress?.city,
      state: billingAddress?.state,
      zip: billingAddress?.zip,
      countryId: billingAddress?.country?.id
    }
    bodyParams.companyGatewayId = payment?.companyGatewayId,
    bodyParams.paymentMethod = {
      nonce: payment.paymentNonce
    }
    bodyParams.type = payment?.type;

    if (billingAddress.id) {
      bodyParams.customerAddressBookId = billingAddress?.id;
    }

    this._loader.startPageLoader();
    this.subs.addSub = this.sharedCustomerService.createPaymentMethodsByCustomerId(this._data.customerId, bodyParams).subscribe(
      (res: RestResponse) => {
        this._loader.stopPageLoader();
        this._meta.showNotification(res);
        this._dialogRef.close({ newCreatedCard: res.data });
      },
      (err: RestResponse) => {
        this._loader.stopPageLoader();
        this._meta.showNotification(err);
      }
    )
  }

  onClose(): void {
    this._loader.stopPageLoader();
    this._dialogRef.close();
  }

  onGetData(_data: any) {
    if(_data) {
      this.createPaymentSource(_data);
    } else {
      this.formSubmitted = !this.formSubmitted;
    }
  }

  onSubmit() {
    this.formSubmitted = !this.formSubmitted;
    this._paymentFormComponent.processCardPaymentForm();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
