import { Deserializable } from '../interfaces/deserialize.interface';

export enum Role {
    admin = 'admin',
    analyst = 'analyst',
    executive = 'executive',
    owner = 'owner',
    sales = 'sales',
    support = 'support',
    user = 'user'
}

export class UserRole implements Deserializable<UserRole> {
    id: number;
    description: string;
    displayName: string;
    name: string;

    deserialize(input: any): UserRole {
        Object.assign(this, input);
        return this;
    }
}