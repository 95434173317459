//angular
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../_core/services/auth.service';
//app

@Injectable()
export class LoggedInGuard implements CanActivate {

    constructor(
        public _auth: AuthService,
        public _router: Router
    ) {
        console.log(`login.guard.ts was loaded.`)
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      debugger
        if (this._auth.isAuthenticated()) {
            this._router.navigate(['/main/dashboard']);
            return false;

        } else {
            // authorised so return true
            return true;
        }
    }

}
