import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { RebNgxNavbarComponent } from '@rhinosaas/reb-ngx';
import {
  RebNgxIconsRegistry,
  RebNgxIconMenuDashboard,
  RebNgxIconMenuSubscriptions,
  RebNgxIconMenuProducts,
  RebNgxIconMenuCustomer,
  RebNgxIconMenuInvoices,
  RebNgxIconMenuAnalytics,
  RebNgxIconMenuTransactions,
  RebNgxIconMenuSalesChannel,
  RebNgxIconMenuStoreSetup,
  RebNgxIconMenuSubscriptionsSub,
  RebNgxIconMenuUpcomingSub
} from '@rhinosaas/reb-ngx/reb-ngx-icon';
import {DataSharingService} from "../../../../_core/services";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']

})

export class NavbarComponent implements OnInit {
  @ViewChild(RebNgxNavbarComponent, { static: true }) navbarRef: RebNgxNavbarComponent;

  collectionOfItems = [];

  @Output() emitNavbarStatus: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private rebNgxIconsRegistry: RebNgxIconsRegistry,
    private _shareData: DataSharingService,
  ) {
    this._shareData.getCompany().subscribe(data => {
      if (data.type) {
        this.collectionOfItems = [
          {
            title: 'Dashboard',
            icon: RebNgxIconMenuDashboard,
            disabled: false,
            hide: false,
            routerLink: '/main/dashboard'
          },
          {
            title: 'Subscriptions',
            icon: RebNgxIconMenuSubscriptions,
            disabled: false,
            hide: false,
            routerLink: '/main/subscriptions',
            childrens: [
              {
                title: 'Active Plans',
                icon: RebNgxIconMenuSubscriptionsSub,
                disabled: false,
                hide: false,
                routerLink: '/main/subscriptions'
              },
              {
                title: 'Upcoming Charges',
                icon:  RebNgxIconMenuUpcomingSub,
                disabled: false,
                hide: false,
                routerLink: '/main/upcoming-charges'
              },
            ]
          },
          {
            title: 'Products',
            icon: RebNgxIconMenuProducts,
            disabled: false,
            hide: false,
            routerLink: '/main/products'
          },
          data.type === 'internal' ? {
              title: 'Accounts',
              icon: RebNgxIconMenuCustomer,
              disabled: false,
              hide: false,
              routerLink: '/main/customers',
              childrens: [
                {
                  title: 'Customers',
                  icon: RebNgxIconMenuCustomer,
                  disabled: false,
                  hide: false,
                  routerLink: '/main/customers'
                },
                {
                  title: 'Leads',
                  icon:  RebNgxIconMenuUpcomingSub,
                  disabled: false,
                  hide: false,
                  routerLink: '/main/leads'
                },
              ]
          } :
          {
            title: 'Accounts',
            icon: RebNgxIconMenuCustomer,
            disabled: false,
            hide: false,
            routerLink: '/main/customers'
          },
          {
            title: 'Invoices',
            icon: RebNgxIconMenuInvoices,
            disabled: false,
            hide: false,
            routerLink: '/main/invoices'
          },
          {
            title: 'Analytics',
            icon: RebNgxIconMenuAnalytics,
            disabled: false,
            hide: false,
            routerLink: '/main/analytics'
          },
          {
            title: 'Transactions',
            icon: RebNgxIconMenuTransactions,
            disabled: false,
            hide: false,
            routerLink: '/main/transactions'
          },
          {
            title: 'Sales Channel',
            icon: RebNgxIconMenuSalesChannel,
            disabled: false,
            hide: false,
            routerLink: '/main/integrations/sales-channels'
          },
          {
            title: 'Settings',
            icon: RebNgxIconMenuStoreSetup,
            disabled: false,
            hide: false,
            routerLink: '/main/settings/store/store-profile'
          }
        ];
      }
    })
  }

  ngOnInit(): void {
    this.rebNgxIconsRegistry.registerIcons([
      RebNgxIconMenuDashboard,
      RebNgxIconMenuSubscriptions,
      RebNgxIconMenuProducts,
      RebNgxIconMenuCustomer,
      RebNgxIconMenuInvoices,
      RebNgxIconMenuAnalytics,
      RebNgxIconMenuTransactions,
      RebNgxIconMenuSalesChannel,
      RebNgxIconMenuStoreSetup,
      RebNgxIconMenuSubscriptionsSub,
      RebNgxIconMenuUpcomingSub
    ]);

    this.navbarRef.updateNavbarStatus('expanded');

    document.getElementsByClassName("logo-wrapper")[0]?.addEventListener("click", this.redirectToWebsite);

  }

  getNavbarStatus(navbarStatus: 'expanded' | 'contracted') {
    this.emitNavbarStatus.emit(navbarStatus);
  }

  redirectToWebsite(): void {
    window.open('https://rebillia.com', 'target')
  }
}
