//angular
import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
//app data
import { PaymentAccountType, PaymentSource } from 'src/app/app.data';
//app models
import { CustomerPaymentSource } from '../../models/customer.model';

@Component({
  selector: 'payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss']
})
export class PaymentCardComponent implements OnInit {

  @Input() _paymentAccountType = PaymentAccountType;
  @Input() _paymentMethod: CustomerPaymentSource;
  @Input() _paymentSource = PaymentSource;

  @Input() _showExpDate: boolean = true;

  constructor(
    private _iconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
  ) {
    this._iconRegistry.addSvgIcon('card-alipay', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-alipay.svg'));
    this._iconRegistry.addSvgIcon('card-amex', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-amex.svg'));
    this._iconRegistry.addSvgIcon('card-diners', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-diners.svg'));
    this._iconRegistry.addSvgIcon('card-discover', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-discover.svg'));
    this._iconRegistry.addSvgIcon('card-elo', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-elo.svg'));
    this._iconRegistry.addSvgIcon('card-hypercard', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-hypercard.svg'));
    this._iconRegistry.addSvgIcon('card-jcb', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-jcb.svg'));
    this._iconRegistry.addSvgIcon('card-maestro', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-maestro.svg'));
    this._iconRegistry.addSvgIcon('card-master', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-master.svg'));
    this._iconRegistry.addSvgIcon('card-paypal', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-paypal.svg'));
    this._iconRegistry.addSvgIcon('card-unionpay', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-unionpay.svg'));
    this._iconRegistry.addSvgIcon('card-verve', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-verve.svg'));
    this._iconRegistry.addSvgIcon('card-visa', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/card-visa.svg'));
  }

  ngOnInit(): void {
  }

}
