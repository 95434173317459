import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from 'src/environments/environment';

import { DataSharingService, RestService, StorageService } from 'src/app/_core/services';
import { HttpParamService } from 'src/app/_core/services/api/http-param.service';
import { HttpParamsInterface } from 'src/app/_shared/interfaces/http-params.interface';
import { Observable } from 'rxjs';
import { RestResponse } from 'src/app/_shared/models';


const APIEndpoint: string = environment.APIEndpoint;
const APIVersion: string = environment.APIVersion;

const APIResourceCompanies: string = '/companies';
const APIResourceProducts: string = '/products';
const APIResourceProductRatePlans: string = '/product-rateplans';
const APIResourceExternalProducts: string = '/external-products';
const APIResourceIntegrations: string = '/integrations';

@Injectable({
  providedIn: 'root'
})
export class SharedProductService {

  subscription: Subscription;
  public serviceUrl_ExternalProducts: string;
  public serviceUrl_ExternalProductsByName: string;
  public serviceUrl_Products: string;
  public serviceUrl_ProductRatePlans: string;

  constructor(private httpParamService: HttpParamService,
    private _shareData: DataSharingService,
    private _storage: StorageService,
    private _rest: RestService) {

      let entityId: string;

      this.subscription = this._shareData.currentCompany.subscribe(data => {
        if (data.companyId){
          entityId = `/${data.companyId}`;
        } else {
          entityId = `/${this._storage.readLocalStorage('entityId')}`;
        }

      // PRODUCTS
      // [GET]
      // /companies/{{companyId}}/products
      this.serviceUrl_Products = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceProducts;

      // EXTERNAL PRODUCTS BY NAME
      // /companies/{{companyId}}/integrations/{{companyIntegrationId}}/products?name=
      this.serviceUrl_ExternalProductsByName = APIEndpoint + APIVersion + APIResourceCompanies + entityId +  APIResourceIntegrations;

      // EXTERNAL PRODUCTS BY PRODUCT RATE PLAN
      // /companies/{{companyId}}/product-rateplans/external-products
      this.serviceUrl_ExternalProducts = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceProductRatePlans + APIResourceExternalProducts;

      // PRODUCT RATE PLAN
      this.serviceUrl_ProductRatePlans = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceProductRatePlans;

    });
  }

  public getProductsRatePlansbyProductId(productId: number, pathParams: HttpParamsInterface): Observable<RestResponse> {
    let params = this.httpParamService.buildPathParams(pathParams);
    return this._rest.get(`${this.serviceUrl_Products}/${productId}/product-rateplans`, { params: params });
  }

  public getSubscriptionCounterByProductRatePlanId(productRatePlanId: number, pathParams: HttpParamsInterface): Observable<RestResponse> {
    let params = this.httpParamService.buildPathParams(pathParams);
    return this._rest.get(`${this.serviceUrl_ProductRatePlans}/${productRatePlanId}/count-subscriptions`, { params: params });
  }

  public searchProduct(value: string): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Products}?include=salesChannels&query=${value}`);
  }
}
