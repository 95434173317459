//angular
import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
//app
import { LoadingState } from 'src/app/app.data';

@Injectable()
export class LoaderService {

  private _loader: LoadingState;
  private _loaderStatus = new Subject<any>();
  public changeLoaderStatus = this._loaderStatus.asObservable();

  private _loading: number = 0;
  private _loadingStatus = new Subject<any>();
  public changeLoadingStatus = this._loadingStatus.asObservable();

  private _page_loading: number = 0;
  private _page_loadingStatus = new Subject<any>();
  public changePageLoaderStatus = this._page_loadingStatus.asObservable();

  get loader(): LoadingState {
    return this._loader;
  }

  set loader(value) {
    this._loader = value;
    this._loaderStatus.next(value);
  }

  get loading(): number {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
    this._loadingStatus.next(value);
  }

  get pageLoading(): number {
    return this._page_loading;
  }

  set pageLoading(value) {
    this._page_loading = value;
    this._page_loadingStatus.next(value);
  }

  startLoader() {
    this.loader = LoadingState.isLoading;
  }

  startLoading() {
    this.loading = 1;
  }

  stopLoader() {
    this.loader = LoadingState.isLoaded;
  }

  stopLoading() {
    this.loading = 0;
  }

  startPageLoader(){
    this.pageLoading = 1;
  }

  stopPageLoader() {
    this.pageLoading = 0;
  }

}
