import { Address } from './address.model';
import { Deserializable } from '../interfaces/deserialize.interface';

export class PaymentMethod implements Deserializable<PaymentMethod> {
    constructor(
      public accountType?: string,
      public accountNumber?: string,
      public accountName?: string,
      public billingAddress: Address = new Address(),
      public expDate?: string,
      public id?: number,
      public nonce?: string,
      public type?: string,
    ) { }

    deserialize(input: any): PaymentMethod {
        Object.assign(this, input);
        return this;
    }
}
