import { Address } from './address.model';
import { Currency } from './currency.model';
import { ExchangeRate } from './exchange-rate.model';
import { Filter } from './filter.model';
import { Gateway, GatewayConfig } from './gateway.model';
import { InvoiceObject } from './invoice.model';
import { Integration, IntegrationKey, CompanyIntegrationModel, IntegrationSettings, BigcommerceProductModel, ShopifyProductModel } from "./integration.model";
import { Language } from './languages.model';
import { PaymentMethod } from './payment-method.model';
import { RebilliaPlan, PlanFeatures, PlanFeaturesGroup } from './rebillia-plans.model';
import { Subscriptions } from './subscriptions.model';
import { RestResponse } from './rest-response.model';
import { TaxLocale, TaxRegion } from './tax-region.model';
import { Timezone } from './timezone.model';
import { User, UserAuth0 } from './user.model';
import { UserRole } from './role.model';
import { CompanyTimezone } from './company.model';
import { PaymentRunOperation } from './system.model';

export const MODELS: any[] = [
  Address,
  BigcommerceProductModel,
  CompanyTimezone,
  Currency,
  ExchangeRate,
  Filter,
  Gateway,
  GatewayConfig,
  InvoiceObject,
  Integration,
  IntegrationKey,
  IntegrationSettings,
  CompanyIntegrationModel,
  Language,
  PaymentMethod,
  PaymentRunOperation,
  PlanFeatures,
  PlanFeaturesGroup,
  RebilliaPlan,
  RestResponse,
  ShopifyProductModel,
  TaxLocale,
  TaxRegion,
  Timezone,
  Subscriptions,
  User,
  UserAuth0,
  UserRole,
];

export * from './address.model';
export * from './country.model';
export * from './company.model';
export * from './currency.model';
export * from './filter.model';
export * from './exchange-rate.model';
export * from './gateway.model';
export * from './industries.model';
export * from './invoice.model';
export * from './integration.model';
export * from './languages.model';
export * from './payment-method.model';
export * from './system.model';
export * from './rebillia-plans.model';
export * from './role.model';
export * from './subscriptions.model';
export * from './rest-response.model';
export * from './tax-region.model';
export * from './timezone.model';
export * from './user.model';
