import { Address, BillingAddress } from './address.model';
import { CompanyCurrency, CompanyGateway } from './company.model';
import { CompanyIntegrationModel } from './integration.model';
import { Country } from './country.model';
import { Deserializable } from '../interfaces/deserialize.interface';
import { PaymentAccountType, PaymentSource, VisibleState } from 'src/app/app.data';
import { PaymentMethod } from '.';
import { RatePlan } from './../interfaces/subscription.interface';
import { SubscriptionNextBill } from './subscriptions.model';
import { UserProfile } from './user.model';

export interface CustomerCollection {
  addressBook: BillingAddress
  businessName: string
  createdAt: Date
  firstName: string
  email: string
  externalCustomer: any
  id: number
  lastInvoice: CustomerLastInvoice
  lastName: string;
  locale: string;
  paymentMethods: CustomerPaymentSource
  phoneNum: string
  phoneExt: string
  preferredCurrency: string
  status: string
  subscriptions: Array<CustomerSubscription>
  taxExempt: string
  unpaidInvoice: Array<any>
  updatedAt: Date,
  relatedCompanyId?: number,
  relatedCompanyStatus?: string,
  supportPin?: string
}

export interface CompanyCurrencyGateway {
  accountId: number;
  companyCurrency: any;
  companyGateway: any;
  card: Array<any> [];
  connectionType: string;
  credentialsLastUpdate: Date;
  dateAdded: Date;
  keyName: string;
  name: string;
  setting: Array<any> [];
  status: string;
  type: string;
}

export interface CustomerLastInvoice {
  createdAt: string
  date: string
  total: string
}

export interface CustomerLogsCollection {
    id: number;
    name: string;
    message: string;
    source: string;
    jsonContent: string;
    level: number;
    isRead: boolean;
    user: UserProfile;
    createdAt: Date;
    updatedAt: Date;
}

export interface CustomerSubscription {
  createdAt: DateInterface
  id: number
  name: string
  shippingAmount: string
  status: string
  updatedAt: DateInterface
}

export interface DateInterface {
  date: string,
  timezone_type: number,
  timezone: string
}

export class Customer implements Deserializable<Customer> {
  constructor(
    public allow_direct_debit?: boolean,
    public auto_collection?: boolean,
    public card?: {
      billing_zip?: string,
      card_type?: string,
      created_at?: Date,
      customer_id?: string,
      expiry_month?: number,
      expiry_year?: number,
      funding_type?: string,
      gateway?: string,
      gateway_account_id?: string,
      iin: string,
      last4?: string,
      masked_number?: string,
      object?: string,
      payment_source_id?: string
      resource_version?: number,
      status?: string,
      updated_at?: Date,
    },
    public card_status?: string,
    public company?: string,
    public created_at?: Date,
    public deleted?: boolean,
    public email?: string,
    public excess_payments?: number,
    public firstName?: string,
    public id?: number,
    public lastName?: string,
    public net_term_days?: number,
    public resource_version?: number,
    public primary_payment_source_id?: string,
    public promotional_credits?: number,
    public payment_method?: {
      gateway?: string,
      gateway_account_id?: string,
      object?: string,
      reference_id?: string,
      status?: string,
      type?: string,
    },
    public phone?: string,
    public preferred_currency_code?: string,
    public refundable_credits?: number,
    public subscriptions?: Array<any>,
    public taxability?: string,
    public unbilled_charges?: number,
    public updated_at?: Date,
  ){}

  deserialize(input: any): Customer {
      Object.assign(this, input);
      return this;
  }
}

export class CustomerAddressBook implements Deserializable<CustomerAddressBook> {
  constructor(
    public city?: string,
    public company?: string,
    public contactName?: string,
    public contactEmail?: string,
    public contactPhone?: string,
    public country?: Country,
    public id?: number,
    public name?: string,
    public street1?: string,
    public street2?: string,
    public state?: string,
    public zip?: string,
    public firstName?: string,
    public lastName?: string,
  ) {}

  deserialize(input: any): CustomerAddressBook {
    Object.assign(this, input);
    return this;
  }
}

export class CustomerProfile implements Deserializable<CustomerProfile> {
    constructor(
      public agent?: string,
      public businessName?: string,
      public createdAt?: Date,
      public dob?: string,
      public email?: string,
      public firstName?: string,
      public id?: number,
      public lastName?: string,
      public locale?: string,
      public phoneExt?: string,
      public phoneNum?: string,
      public preferredCurrency?: string,
      public status?: string,
      public taxCode?: string,
      public taxExempt?: string,
      public updatedAt?: Date,
      public companyIntegrationId?: any,
      public externalCustomerId?: any,
      public externalCustomer?: any,
      public relatedCompanyId?: number,
      public relatedCompanyStatus?: string,
      public storeCreditAutoUse?: boolean,
      public storeCredit?: number,
      public relatedCompanyName?: string,
    ) { }

    deserialize(input: any): CustomerProfile {
        Object.assign(this, input);
        return this;
    }
}

export class CustomerPaymentOffline implements Deserializable<CustomerPaymentOffline> {
    constructor(
        public label?: string,
        public value?: string
    ) { }

    deserialize(input: any): CustomerPaymentOffline {
        Object.assign(this, input);
        return this;
    }
}

export class CustomerPaymentSource implements Deserializable<CustomerPaymentSource> {
    constructor(
      // public accountKind?: PaymentAccountKind,
      public accountName?: string,
      public accountType?: PaymentAccountType,
      public accountNumber?: string,
      public bankName?: string,
      public billingAddress: BillingAddress = new BillingAddress(),
      public companyGateway: CompanyGateway = new CompanyGateway(),
      public expDate?: string,
      public id?: number,
      public routingNumber?: string,
      public type?: PaymentSource,
      public verified?: string,
      public priorityOrder?: number
    ) { }

    deserialize(input: any): CustomerPaymentSource {
        Object.assign(this, input);
        return this;
    }
}

export class CustomerSubscriptionGridModel implements Deserializable<CustomerSubscriptionGridModel> {
  constructor(
    public billingAddress?: Address,
    public companyCurrency?: CompanyCurrency,
    public companyGateway?: CompanyGateway,
    public createdAt?: string,
    public customer?: Customer,
    public customerPaymentMethod?: PaymentMethod,
    public detail?: string,
    public id?: number,
    public isSubPanelExpanded?: boolean,
    public isNextBillReady?: boolean,
    public lastCharge?: string,
    public name?: string,
    public nextCharge?: string,
    public offlinePaymentId?: string,
    public oneTimeChargeAmount?: number,
    public oneTimeChargeQty?: number,
    public ratePlan?: Array<RatePlan>,
    public recurringChargeAmount?: number,
    public recurringChargeQty?: number,
    public shippingAddress?: Address,
    public shippingIntegration?: CompanyIntegrationModel,
    public shippingServiceId?: string,
    public status?: string,
    public statusForSubPanel?: VisibleState,
    public subscriptionNextBill?: SubscriptionNextBill,
    public updatedAt?: string,
    public usageChargeAmount?: number,
    public usageChargeQty?: number
  ) {}

  deserialize(input: any): CustomerSubscriptionGridModel {
      Object.assign(this, input);
      return this;
  }

}
