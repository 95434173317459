//angular
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
//app
import { DialogBulkComponent } from '../../modules/main/_shared/dialog/bulk/bulk.component';
import { DataSharingService } from './data.sharing.service';
import { CheckboxState, VisibleState, OpacityState } from '../../app.data';
import { Filter } from '../../_shared/models';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class GridService {
  gridItemsActions: boolean;

  collectionOfFilter: Array<Filter> = [];
  selectedFilterId: number = 0;
  selectedFilterLabel: string = 'Manage Filters';
  checkedCount: number = 0;
  statusBulkCheckbox: string = CheckboxState.uncheck;
  inputSearchIsVisible: boolean = true;
  inputSearchIsValid: boolean = false;
  searchQuery: string = '';
  // TODO visibleStatusForInputSearch
  statusSnackBar: string = VisibleState.isHidden;
  collectionOfGridItems: Array<any> = [];

  bulkIsChecked: boolean = false;
  bulkIsIndeterminate: boolean = false;
  dialogBulkRef: MatSnackBarRef<DialogBulkComponent>;
  bulkSectionName: string = '';
  bulkCollectionOfActions: Array<string> = [];

  statusGrid: string = OpacityState.isHidden;
  statusGridMessage: string = OpacityState.isHidden;
  currentPageNumber: number = 1;
  itemsPerPage: number = 25;
  orderBy: string = '';
  sortBy: string = 'DESC';
  totalItems: number = 0;
  totalPages: number = 0;
  pageIndex: number = 0;

  sortedData: Array<any> = [];

  constructor(
    private _dialogBulk: MatSnackBar,
    private _shareData: DataSharingService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  private getGridData: any;

  clearData() {
    this.bulkIsChecked = false;
    this.bulkIsIndeterminate = false;
    this.bulkSectionName = '';
    this.bulkCollectionOfActions = [];
    this.checkedCount = 0;
    this.collectionOfFilter = [];
    this.collectionOfGridItems = [];
    this.selectedFilterId = 0;
    this.selectedFilterLabel = '';
    this.statusBulkCheckbox = CheckboxState.uncheck;
    this.inputSearchIsVisible = true;
    this.inputSearchIsValid = false;
    this.searchQuery = '';
    this.statusSnackBar = VisibleState.isHidden;
    this.statusGrid = OpacityState.isHidden;
    this.statusGridMessage = OpacityState.isHidden;
    this.currentPageNumber = 1;
    this.itemsPerPage = 25;
    this.orderBy = '';
    this.sortBy = 'ASC';
    this.totalItems = 0;
    this.totalPages = 0;
    this.sortedData = [];
    this.pageIndex = 0;
  }

  closeBulkDialog() {
    this.dialogBulkRef.dismiss();
    this.statusSnackBar = VisibleState.isHidden;
  }

  onBulkCheckboxChange() {
    console.log('@@@@onBulkCheckboxChange');
    this.checkedCount = 0;

    for (const record of Object.values(this.collectionOfGridItems)) {
      if (record['checked']) {
        this.checkedCount++;
      }
    }

    if (this.checkedCount > 0 && this.checkedCount < this.collectionOfGridItems.length) {
      // If some checkboxes are checked but not all; then set Indeterminate state of the master to true.
      this.bulkIsChecked = false;
      this.bulkIsIndeterminate = true;
      this.inputSearchIsVisible = false;
      if (this.statusSnackBar == VisibleState.isHidden ){
        this.openBulkDialog();
      };
    } else if (this.checkedCount === this.collectionOfGridItems.length) {
      /*If checked count is equal to total items; then check the master checkbox and also set Indeterminate state to false.*/
      this.bulkIsChecked = true;
      this.bulkIsIndeterminate = false;
      this.inputSearchIsVisible = false;
      if (this.statusSnackBar == VisibleState.isHidden) {
        this.openBulkDialog();
      };
    } else {
      /*If none of the checkboxes in the list is checked then uncheck master also set Indeterminate to false.*/
      this.bulkIsChecked = false;
      this.bulkIsIndeterminate = false;
      this.inputSearchIsVisible = true;
      this.closeBulkDialog();
    }

    this.statusBulkCheckbox = (this.checkedCount > 0 ? CheckboxState.checked : CheckboxState.uncheck);
    this._shareData.setObservable(this.checkedCount);
  }

  onBulkHeaderCheckboxChange() {
    console.log('@@@@onBulkHeaderCheckboxChange');
    this.bulkIsChecked = !this.bulkIsChecked;
    this.statusBulkCheckbox = (this.bulkIsChecked ? CheckboxState.checked : CheckboxState.uncheck);

    for (const value of Object.values(this.collectionOfGridItems)) {
      value['checked'] = this.bulkIsChecked;
    }

    if (this.bulkIsChecked) {
      this.checkedCount = this.collectionOfGridItems.length;
      this.inputSearchIsVisible = false;
      if (this.statusSnackBar == VisibleState.isHidden) {
        this.openBulkDialog();
      };
    } else {
      this.checkedCount = 0;
      this.inputSearchIsVisible = true;
      this.closeBulkDialog();
    }

    this._shareData.setObservable(this.checkedCount);
  }

  onGetGridData(fn: any) {
    this.getGridData = fn;
  }

  async onPageChange(e: any) {
    let pageNo: number = e.pageIndex + 1;
    this.itemsPerPage = e.pageSize;
    this.currentPageNumber = pageNo;
    await this.updateQueryParams(this.itemsPerPage, this.currentPageNumber);
    this.getGridData();
  }

  async updateQueryParams(itemsPerPage?: number, page?: number, query?: string, filterId?: number): Promise<void> {
    const queryParams: any = {};

    if (itemsPerPage !== undefined) {
      queryParams.itemsPerPage = itemsPerPage;
    }

    if (page !== undefined) {
      queryParams.page = page;
    }

    if (query !== undefined) {
      queryParams.query = query;
    }

    if (filterId !== undefined) {
      queryParams.filterId = filterId;
    }

    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge'
    });

    return Promise.resolve();
  }

  onSortData(sort: Sort){
    //ToDo: Implement sort method on Grids.
    const data = this.collectionOfGridItems.slice();
    console.log(data);
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        // case 'customerName': return compare(a.customerName, b.customerName, isAsc);
        // case 'createdOn': return compare(a.createdOn, b.createdOn, isAsc);
        // case 'customerId': return compare(a.customerId, b.customerId, isAsc);
        // case 'billingAddress': return compare(a.billingAddress, b.billingAddress, isAsc);
        // case 'paymentMethod': return compare(a.paymentMethod, b.paymentMethod, isAsc);
        // case 'currency': return compare(a.currency, b.currency, isAsc);
        // case 'locale': return compare(a.locale, b.locale, isAsc);
        default: return 0;
      }
    });
  }

  openBulkDialog() {
    this.statusSnackBar = VisibleState.isVisible;
    this.dialogBulkRef = this._dialogBulk.openFromComponent(DialogBulkComponent, {
      panelClass: ['custom-mat-dialog', 'mat-dialog-bulk-actions'],
      data: {
        sectionName: this.bulkSectionName,
        collectionOfActions: this.bulkCollectionOfActions,
      }
    });

    this.dialogBulkRef.afterDismissed().subscribe(res => {
      this.statusSnackBar = VisibleState.isHidden;

      if (res.dismissedByAction === true) {
        console.log('dismissedByAction');
      } else {
        console.log('cancel');
      }
    })
  }

  removeBulkCheckedItems() {
    console.log('@@@@onBulkHeaderCheckboxChange');
    this.collectionOfGridItems.forEach(item => {
      if(item.checked === true) delete item.checked;
    })
  }


}
