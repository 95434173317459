import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ValidatorService } from '../validators/validator.service';

@Component({
  selector: 'app-error-message',
  templateUrl: './form-control-error.message.component.html'
})
export class FormControlErrorMessageComponent implements OnChanges, OnInit {
  @Input() errors: any;

  error: string = '';

  constructor(private validatorService: ValidatorService) {}

  ngOnInit(): void {
    this.error = this.validatorService.getFormControlErrorMessage(this.errors);
  }

  ngOnChanges(): void {
    this.error = this.validatorService.getFormControlErrorMessage(this.errors);
  }
}
