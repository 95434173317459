import { trigger, state, animate, transition, style, keyframes, query, stagger } from '@angular/animations';

export const slideInRightAnimation = trigger('slideInRightAnimation',
[
  transition(':enter', [
      query('.slide--right', style({ opacity: 0, transform: 'translateX(8px)' }), { optional: true }),
      query('.slide--right', stagger(100, [
          animate('.33s ease-in', keyframes([
              style({ opacity: 0, transform: 'translateX(8px)', offset: 0 }),
              style({ opacity: 1, transform: 'translateX(0)', offset: 1 }),
          ]))
      ]), { optional: true })
  ])
]);
