//angular
import { Component, OnInit, OnDestroy } from '@angular/core';
//app
import { AppData } from '../../../../app.data';
import { listAnimate, listItemAnimate, systemMessages } from 'src/app/_shared/animations';
import { LoaderService } from '../../../../_core/services';
import { RestResponse } from '../../../../_shared/models';
import { SystemMessage } from '../../../../_shared/models/system.model';

import { MainService } from '../_services/api/main.service';
import { MetaService } from 'src/app/_core/services/meta.service';
import { Unsubscriber } from 'src/app/_shared/unsubscriber/unsubscriber';

@Component({
  selector: 'system-message',
  templateUrl: './system-message.component.html',
  animations: [listAnimate, listItemAnimate, systemMessages ]
})

export class SystemMessageComponent implements OnInit, OnDestroy {
  collectionOfMessage: Array<SystemMessage> = [];
  systemMessagesStatus: string = 'isHidden';
  resSuccess: boolean;
  private subs = new Unsubscriber();

  constructor(
    private _data: AppData,
    private _loader: LoaderService,
    public _meta: MetaService,
    private mainService: MainService
  ) { }

  getSystemMessages() {
    this.subs.addSub = this.mainService.getSystemMessages().subscribe(
      (res: RestResponse) => {
        this.systemMessagesStatus = (res.data ? 'isVisible' : 'isHidden');
        this.collectionOfMessage = <Array<SystemMessage>>res.data;
        this.collectionOfMessage = [...this.mainService.getLocalCompanyMessages(),  ...this.collectionOfMessage]
        this.resSuccess = res.isSuccess;
      },
      (err: RestResponse) => {
        this.resSuccess = err.isSuccess;
        this._meta.showNotification(err);
      }
    );
  }

  ngOnInit() {
    this.getSystemMessages();
  }

  onSubmit(_message:SystemMessage){
    const index = this.collectionOfMessage.map(function (field) { return field.id; }).indexOf(_message.id);

    if(_message.status == 'local'){
      if (index !== -1) {
        const companyId = localStorage.getItem('entityId');
        localStorage.setItem(companyId + '-hideStatusDeactivatedMessage', '1')
        this.collectionOfMessage.splice(index, 1);
      }
      return;
    }

    this._loader.startPageLoader();
    this.subs.addSub = this.mainService.updateSystemMessages(_message.id).subscribe(
      (res: RestResponse) => {
        if (index !== -1) {
          this.collectionOfMessage.splice(index, 1);
        }
        this.systemMessagesStatus = (this.collectionOfMessage.length > 0 ? 'isVisible' : 'isHidden');
        this._loader.stopPageLoader();
        this._meta.showNotification(res);
      },
      (err: RestResponse) => {
        this._loader.stopPageLoader();
        this._meta.showNotification(err);
      }
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
