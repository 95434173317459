import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { MainService } from "../../_services/api/main.service";
import { DomSanitizer, Meta } from "@angular/platform-browser";
import { RestResponse } from "src/app/_shared/models";
import { MetaService } from "src/app/_core/services/meta.service";
import { LoaderService } from "src/app/_core/services";
import { MatIconRegistry } from "@angular/material/icon";
import { ErrorService } from "src/app/_core/services/error.service";

@Component({
  selector: "app-support",
  templateUrl: "./support.component.html",
  styleUrls: ["./support.component.scss"],
})
export class SupportComponent implements OnInit {
  file: any;

  form: FormGroup;
  requestTypes = [
    { name: "Product Support", value: "productSupport" },
    { name: "Technical Support", value: "technicalSupport" },
    { name: "Billing Support", value: "billingSupport" },
    { name: "General Question", value: "generalQuestion" },
    { name: "Improvement Submission", value: "improvementSubmission" },
  ];

  relatedPages = [
    { name: "Management App", value: "managementApp" },
    { name: "Storefront Integration", value: "storefrontIntegration" },
  ];
  storefrontPages = [
    { name: "Checkout Page", value: "managementApp" },
    { name: "Product Page", value: "productPage" },
    { name: "Customer Self-Serve Interfaces", value: "customerSelfServce" },
    { name: "Other", value: "other" },
  ];

  extensions = ".jpeg,.jpg,.png";
  submited = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private _meta: MetaService,
    private _loader: LoaderService,
    private _dialogRef: MatDialogRef<SupportComponent>,
    private mainService: MainService,
    private _iconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
    private errorService: ErrorService
  ) {
    this._iconRegistry.addSvgIcon(
      "ico-close",
      this._sanitizer.bypassSecurityTrustResourceUrl(
        "assets/svg/ico-close-01.svg"
      )
    );
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      requestType: [null, Validators.required],
      relatedPage: [null, Validators.required],
      content: [null, Validators.required],
      relatedPageCnt: [null, Validators.required],
    });
  }

  public setCurrentPage() {
    this.form.get("relatedPageCnt").setValue(this.router.url);
  }

  onFileSelect(input: any) {
    const files = input.files;
    this.file = files[0];
  }

  onClose() {
    this._dialogRef.close();
  }

  onSubmit() {
    this.submited = true;
    const logs = this.errorService.getReadableLogs();

    const formData = new FormData();
    // Append files to the virtual form.
    if (this.file) {
      formData.append("file", this.file, "ticket.jpeg");
    }
    const formValue = this.form.value;

    for (let key of Object.keys(formValue)) {
      const value = formValue[key];
      formData.append(key, value);
    }

    formData.append("logs", logs);

    this.mainService.submitSupport(formData).subscribe(
      (res: RestResponse<any>) => {
        if(res.data.type>0) {
          this._loader.stopPageLoader();
          this._meta.showNotification(res);
          this._dialogRef.close();
        }
      },
      (err: RestResponse) => {
        this._loader.stopPageLoader();
        this._meta.showNotification(err);
        this.submited = false;
      }
    );
  }
}
