<div *ngIf="shippingError" class="form-row">
  <section class="message message-row size size1of1">
    <div class="message-wrapper error">
      <div class="message-inner">
        <p class="message-description">There was an error when trying to retrieve the shipping rates.</p>
      </div>
    </div>
  </section>
</div>
<section [formGroup]="form" class="shipping-services-list">
  <div style="padding-left: 10px; padding-right: 10px; margin-top: 10px;">
    <mat-form-field *ngIf="collectionOfShipperHqServices && collectionOfShipperHqServices.length > 0" class="custom-mat-form-field size size1of1">
      <mat-label>Shipper HQ options</mat-label>
      <mat-select formControlName="shipperHqService" (selectionChange)="onChangeShipperHqService($event.value)" panelClass="custom-mat-select-panel" class="custom-mat-select">
        <mat-option *ngFor="let address of collectionOfShipperHqServices" [value]="address" class="custom-mat-option">
          <label>{{ address.carrierTitle }} - {{ address.serviceName }} <span *ngIf="address.amount"> ( {{ currencySymbol }}{{ address.amount }} )</span></label>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-radio-group *ngIf="!isShippingIntegrationEnabled && shippingCompanyIntegration !== null" class="custom-mat-radio-group column group-choose-shipping-service">
    <mat-radio-button color="primary" class="custom-mat-radio-button" disabled>
      <p class="typo body-1 semi-condensed fw500 label-title">{{ shippingAmount === 0 ? 'Free Shipping' : shippingCompanyIntegration?.displayName }}</p>
    </mat-radio-button>
    <div *ngIf="shippingCompanyIntegration?.integrationKeyName === shippingStaticValues.shippingCustomKey && shippingAmount !== 0" class="panel panel-radio panel-address">
      <div class="panel-wrapper">
        <section class="new-address-book">
          <div class="form-row">
            <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field custom-mat-form-field field-amount size size1of1">
              <mat-label>Amount</mat-label>
              <span matPrefix style="position: absolute; top: 21px;">{{currencySymbol}}</span>
              <input matInput placeholder="0,000.00" [value]="shippingAmount" disabled/>
              <span matSuffix>{{currencyIso3}}</span>
            </mat-form-field>
          </div>
        </section>
      </div>
    </div>
  </mat-radio-group>
  <mat-list *ngIf="!isShippingIntegrationEnabled && shippingCompanyIntegration === null" class="custom-mat-list list-grid-std list-company-currency">
    <mat-list-item class="custom-mat-list-item grid-row">
      <h3 matLine class="typo body-1 semi-condensed fw400 label-title">Shipping integrations are disabled at the moment.</h3>
    </mat-list-item>
  </mat-list>
  <mat-radio-group *ngIf="isShippingIntegrationEnabled" formControlName="shippingService" class="custom-mat-radio-group column group-choose-shipping-service">
    <span *ngFor="let shippingMethod of collectionOfShippingMethod; let i = index">
      <mat-radio-button (change)="onChangeShippingMethod($event.value)" [value]="shippingMethod" color="primary" class="custom-mat-radio-button">
        <p *ngIf="shippingMethod.integrationName !== shippingStaticValues.shippingCustomKey && shippingMethod.integrationName !== shippingStaticValues.shippingFreeKey" [style.display]="shippingMethod.carrierName === 'Fixed Shipping Rate' ? 'flex' : ''">
          <label class="label-primary">{{ shippingMethod.serviceName }}</label>
          <mat-icon
            *ngIf="shippingMethod.carrierName === 'Fixed Shipping Rate'"
            matSuffix
            matTooltipPosition="right"
            matTooltipClass="mat-tooltip"
            matTooltip="Fixed shipping rate is a custom entered dollar value that does not follow any of the regular shipping methods offered in the global shipping rules. It is most commonly used when needing to apply a custom rate that should not be available for other customers"
            svgIcon="ico-help"
            class="custom-mat-icon ico-tooltip ico-size ico-2xs"></mat-icon>
        </p>
        <p *ngIf="showsShippingAmount && shippingMethod.integrationName !== shippingStaticValues.shippingCustomKey && shippingMethod.integrationName !== shippingStaticValues.shippingFreeKey">
          <label class="label-primary"> {{currencySymbol}} {{shippingMethod.amount}} {{shippingMethod.currency}}</label>
        </p>
        <p>
          <label class="label-secondary">{{ shippingMethod.carrierName }}</label>
        </p>
      </mat-radio-button>
    <div [@onRowStatusChange]="(isFixedShippingRateSelected && shippingMethod.carrierName === 'Fixed Shipping Rate' ? _visibleState.isVisible : _visibleState.isHidden)" class="panel panel-radio panel-address">
      <div class="panel-wrapper">
        <section class="new-address-book">
          <div class="form-row">
            <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field custom-mat-form-field field-amount size size1of1">
              <mat-label>Amount</mat-label>
              <span matPrefix style="position: absolute; top: 21px;">{{currencySymbol}}</span>
              <input matInput type="text" placeholder="0,000.00" name="shippingAmount" formControlName="shippingAmount" [cleave]="cleaveNumDecimalOptions" />
              <span matSuffix>{{currencyIso3}}</span>
            </mat-form-field>
          </div>
        </section>
      </div>
    </div>
    <div [@onRowStatusChange]="(isCustomOptionSelected && shippingMethod.integrationName === 'customRate' ? _visibleState.isVisible : _visibleState.isHidden)" class="panel panel-radio panel-address">
      <div class="panel-wrapper">
        <section class="new-address-book">
          <div class="form-row">
            <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field custom-mat-form-field field-amount size size1of1">
              <mat-label>Amount</mat-label>
              <span matPrefix style="position: absolute; top: 21px;">{{currencySymbol}}</span>
              <input matInput type="text" placeholder="0,000.00" name="shippingAmount" formControlName="shippingAmount" [cleave]="cleaveNumDecimalOptions" (focusout)="onFocusOutShippingAmount($event)" />
              <span matSuffix>{{currencyIso3}}</span>
            </mat-form-field>
          </div>
        </section>
      </div>
    </div>
    </span>
  </mat-radio-group>
</section>
