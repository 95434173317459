<div class="form-row">
  <mat-form-field class="custom-mat-form-field size size1of1">
      <mat-label>Shipping Address</mat-label>
        <mat-select (selectionChange)="onChangeShipToAddress($event.value)" [value]="selectedAddress" disableOptionCentering="true" 
        panelClass="custom-mat-select-panel list-address-books" class="custom-mat-select select-address-books">
          <mat-select-trigger [class]="'custom-mat-select-trigger'" [ngClass]="{'heightFixed': selectedAddress !== '0'}">
            <ng-container *ngIf="(allowToCreateShipping && selectedAddress !== '0') ; else newAddress">
              <label class="address-contact">
                <span>{{selectedAddress?.contactName}}</span>
              </label>
              <label class="address-info">
                <span>{{ selectedAddress?.street1 }} {{ selectedAddress?.street2 }}</span>
              </label>
              <label class="address-info">
                <span>{{ selectedAddress?.city }} • {{ selectedAddress?.state }} • {{ selectedAddress?.zip }} • {{ selectedAddress?.country?.alpha2Code }}</span>
              </label>
            </ng-container>
            <ng-template #newAddress>
              <label class="address-contact">
                <span>Enter a new address</span>
              </label>
            </ng-template>
          </mat-select-trigger>
          <mat-option *ngIf="allowToCreateShipping" class="option-address option-address-new" value="0">Enter a new address
          </mat-option>
          <mat-option *ngFor="let address of collectionOfAddressBook" [value]="address" class="custom-mat-option option-address">
            <label class="label-address-nickname">{{ address.contactName }}</label>
            <p matLine>
              <label class="label-address-info">
                <span>{{ address.street1 }} {{ address.street2 }}</span>
              </label>
            </p>
            <p matLine>
              <label class="label-address-info">
                <span>{{ address.city }} • {{ address.state }} • {{ address.zip }} • {{ address.country.alpha2Code }}</span>
              </label>
            </p>
          </mat-option>
        </mat-select>
  </mat-form-field>
</div>
