<main class="base">
  <app-global-header-impl></app-global-header-impl>
  <app-navbar (emitNavbarStatus)="getNavbarStatus($event)"></app-navbar>
  <section class="page" [@animateBody]="status">
    <!-- <banner-test></banner-test> -->
    <system-message></system-message>
    <export-notification></export-notification>
    <router-outlet #outlet="outlet"></router-outlet>
  </section>
</main>
