import { Component, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatIconRegistry } from '@angular/material/icon';
//app
import { KeyName, collectionOfKeyNamesMessages, messageIterface } from 'src/app/app.keynames';


@Component({
  host: {'class':'dialog-message-timer'},
  selector: 'app-dialog-message-timer',
  templateUrl: './message-timer.component.html'
})

export class DialogMessageTimerComponent implements OnInit  {

  @Input() _keyName = KeyName;

  _collectionOfKeyNames: Array<messageIterface> = collectionOfKeyNamesMessages;
  message:any;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private _iconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer
  ) {
    this._iconRegistry.addSvgIcon('ico-error-400', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-error-01.svg'));
    this._iconRegistry.addSvgIcon('ico-error-500', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-error-06.svg'));
    this._iconRegistry.addSvgIcon('ico-success', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-success-01.svg'));
    this._iconRegistry.addSvgIcon('ico-warning', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-error-10.svg'));
  }

  ngOnInit(): void {
    let messageItem = this._collectionOfKeyNames.find(key=> key.value == this.data.keyName);
    if(messageItem === undefined) {
      if(this.data.customMessage) {
        messageItem = {
          value : this.data.statusCode,
          message : this.data.customMessage
        }
      } else {
        messageItem = this.data.statusCode > 200 ?  this._collectionOfKeyNames.find(key=> key.value == KeyName.request_error) : this._collectionOfKeyNames.find(key=> key.value == KeyName.request_success);
      }
    }

    if(this.data?.extraData?.length > 0) {

      this.message = messageItem.messageWithVariables.map(elem => {
        if (elem === '') {
            elem = this.data?.extraData.shift();
        }
        return elem;
      }).join(' ');

    } else {
      this.message = messageItem.message;
    }

  }
}
