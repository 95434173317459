//angular
import { Component, Inject, ViewChild, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
//app
import { AppAnimations } from 'src/app/_shared/animations';
import { LoaderService } from 'src/app/_core/services';
import { Unsubscriber } from 'src/app/_shared/unsubscriber/unsubscriber';
import { AccountShippingAddressComponent } from '../../elements/account-shipping-address/account-shipping-address.component';
import { responseCode } from 'src/app/app.data';
import { KeyName } from 'src/app/app.keynames';

@Component({
  animations: [AppAnimations],
  selector: 'dialog-shipping-address-form',
  templateUrl: './dialog-shipping-address-form.component.html'
})
export class DialogShippingAddressFormComponent implements OnDestroy {

  bodyParams: any = {};
  /** It's the default country option when the new address is selected */
  defaultCountryAlpha3Code: string = null;
  formSubmitted: boolean = false;

  @ViewChild(AccountShippingAddressComponent) _accountShippingAddress: AccountShippingAddressComponent;
  
  private subs = new Unsubscriber();

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _dialogRef: MatDialogRef<DialogShippingAddressFormComponent>,
    private _iconRegistry: MatIconRegistry,
    private _loader: LoaderService,
    private _sanitizer: DomSanitizer
  ) { 
    this._iconRegistry.addSvgIcon('ico-close', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-close-01.svg'));
    this.defaultCountryAlpha3Code = 'USA';
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onClose() {
    this._dialogRef.close();
  }

  onSubmit(): void {

    this.formSubmitted = !this.formSubmitted;
    this._loader.startPageLoader();
    this._accountShippingAddress.onSubmit();
  }

  onGetDetailsBodyParams(_bodyParams: any) {
    this.formSubmitted = !this.formSubmitted;
    this._loader.stopPageLoader();
    if(_bodyParams.code == responseCode.successCode || _bodyParams.code == responseCode.successCodeWithError) {
      this._dialogRef.close({code: KeyName.create_customer_address, checkStatus:_bodyParams.code, bodyParams: _bodyParams.body, isStored: _bodyParams.isStored});
    }
  }

}
