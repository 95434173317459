//angular
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AppAnimations } from '../../animations';

//app
import { BillCycleTypeValues, BillingPeriodsValues, ChargeModelValues, ChargeType, FrequencyUnitValues, GridColumnsSubscriptionRatePlans, RatePlanStatus, SubscriptionRatePlanChargeStatus, SubscriptionRatePlanChargeStatusActions, VisibleState } from 'src/app/app.data';
import { CompanyCurrency, ExternalRatePlanCharge, New_SubscriptionRatePlanChargeModel, RestResponse } from '../../models';
import { LoaderService, GridService, SkeletonLoaderService, UtilsService } from 'src/app/_core/services';
import { MetaService } from 'src/app/_core/services/meta.service';
import { DialogConfirmationComponent } from 'src/app/modules/main/_shared/dialog/confirmation/confirmation.component';
import { KeyName } from 'src/app/app.keynames';
import { DialogLinkRateplanChargeComponent } from 'src/app/modules/main/_shared/link-rateplan-charge/link-rateplan-charge.component';
import { SubscriptionService } from 'src/app/modules/main/subscriptions/_services/api/subscription.service';

@Component({
  animations: [AppAnimations],
  selector: 'app-grid-charges',
  templateUrl: './app-grid-charges.component.html'
})
export class GridChargesComponent {

  @Input() _billCycleTypeValues = BillCycleTypeValues;
  @Input() _billingPeriod = BillingPeriodsValues;
  @Input() _chargeModelValues = ChargeModelValues;
  @Input() _chargeType = ChargeType;
  @Input() _frequencyUnitValues = FrequencyUnitValues;
  @Input() gridCols = GridColumnsSubscriptionRatePlans;
  @Input() _ratePlanStatus = RatePlanStatus;
  @Input() _ratePlanChargeStatus = SubscriptionRatePlanChargeStatus;
  @Input() _visibleState = VisibleState;
  @Input() _subscriptionRatePlanChargeStatusActions = SubscriptionRatePlanChargeStatusActions;
  @Input() _formatDecimalNumberToReceive = this.utils.formatDecimalNumberToReceive.bind(this.utils);

  subscriptionCompanyCurrency: CompanyCurrency = new CompanyCurrency;
  dialogConfirmation: MatDialogRef<DialogConfirmationComponent>;

  ratePlanCharge: any;

  @Input() collectionOfProductRatePlanCharges: Array<any> = [];
  /** Indicate if Its called from new subscription */
  @Input() isNewSubscription: boolean = false;
  /** Indicate the currency base */
  @Input() currencyBaseSymbol: string = '';
  /** Indicate the currency Iso3 */
  @Input() currencyBaseIso3: string = '';
  /** Indicate if the sales channel section is editable */
  @Input() isSalesChannelsEditable : boolean = false;
  /** Indicate if shows edit action on actions section */
  @Input() showEditAction: boolean = false;
  /** Emmiter used to take edit action (should be implemented on Parent component) */
  @Output() _emitEditAction: EventEmitter<any> = new EventEmitter<any>();
  /** Indicate if shows Delete action on actions section */
  @Input() showDeleteAction: boolean = false;
  /** Emmiter used to take delete action (should be implemented on Parent component) */
  @Output() _emitDeleteAction: EventEmitter<any> = new EventEmitter<any>();
  useSaleChannelData: boolean = false;
  @Input() subscriptionId: number;
  @Input() ratePlanIndex: number;

  constructor(
    private iconRegistry: MatIconRegistry,
    private loader: LoaderService,
    public meta: MetaService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    public grid: GridService,
    public skeleton: SkeletonLoaderService,
    private _dialog: MatDialog,
    private subscriptionService: SubscriptionService,
    private utils: UtilsService,) {

      this.iconRegistry.addSvgIcon('ico-arrow-down',this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-arrow-down-01.svg'));
      this.iconRegistry.addSvgIcon('ico-channel', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-channels-02.svg'));
      this.iconRegistry.addSvgIcon('ico-charge', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-charge-02.svg'));
      this.iconRegistry.addSvgIcon('ico-checklist', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-checklist-03.svg'));
      this.iconRegistry.addSvgIcon('ico-close',this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-close-03.svg'));
      this.iconRegistry.addSvgIcon('ico-delete', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-delete-02.svg'));
      this.iconRegistry.addSvgIcon('ico-edit', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-edit-02.svg'));
      this.iconRegistry.addSvgIcon('ico-error',this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-error-01.svg'));
      this.iconRegistry.addSvgIcon('ico-filter', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-filter-07.svg'));
      this.iconRegistry.addSvgIcon('ico-help',this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-question-04.svg'));
      this.iconRegistry.addSvgIcon('ico-invoice',this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-invoices-01.svg'));
      this.iconRegistry.addSvgIcon('ico-location',this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-location-01.svg'));
      this.iconRegistry.addSvgIcon('ico-plus',this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-add-02.svg'));
      this.iconRegistry.addSvgIcon('ico-settings', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-settings-02.svg'));
      this.iconRegistry.addSvgIcon('ico-switch', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-switch-02.svg'));
      this.iconRegistry.addSvgIcon('ico-unlink',this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-unlink-01.svg'));
  }

  getCollectionData() {
    return this.collectionOfProductRatePlanCharges;
  }

  showDialogEditRatePlanCharge(ratePlanCharge: any, _ratePlanIndex: number) {
    this.ratePlanCharge = ratePlanCharge;
    const bodyParams = {ratePlanCharge, _ratePlanIndex};
    this._emitEditAction.emit(bodyParams);
  }

  refreshGridItemData(ratePlanCharge: any) {
    // details
    this.ratePlanCharge.name = ratePlanCharge?.name;
    this.ratePlanCharge.category = ratePlanCharge?.category;
    this.ratePlanCharge.isFreeShipping = ratePlanCharge?.isFreeShipping;
    this.ratePlanCharge.depth = this.utils.formatDecimalNumberToReceive(ratePlanCharge?.depth);
    this.ratePlanCharge.height = this.utils.formatDecimalNumberToReceive(ratePlanCharge?.height);
    this.ratePlanCharge.weight = this.utils.formatDecimalNumberToReceive(ratePlanCharge?.weight);
    this.ratePlanCharge.width = this.utils.formatDecimalNumberToReceive(ratePlanCharge?.width);

    // price
    this.ratePlanCharge.chargeTier = ratePlanCharge?.chargeTier;
    this.ratePlanCharge.chargeModel = ratePlanCharge?.chargeModel;
    this.ratePlanCharge.amount = ratePlanCharge?.chargeTier[0]?.price;
    this.ratePlanCharge.taxClass = ratePlanCharge?.taxClass;
    this.ratePlanCharge.taxCode = ratePlanCharge?.taxCode;
    this.ratePlanCharge.taxable = ratePlanCharge?.taxable;
    this.ratePlanCharge.usePriceFromSaleChannel = ratePlanCharge?.usePriceFromSaleChannel;

    // schedule
    this.ratePlanCharge.billCycleDay = ratePlanCharge?.billCycleDay;
    this.ratePlanCharge.billCycleType = ratePlanCharge?.billCycleType;
    this.ratePlanCharge.billingPeriod = ratePlanCharge?.billingPeriod;
    this.ratePlanCharge.billingPeriodAlignment = ratePlanCharge?.billingPeriodAlignment;
    this.ratePlanCharge.billingTiming = ratePlanCharge?.billingTiming;
    this.ratePlanCharge.chargeType = ratePlanCharge?.chargeType;
    this.ratePlanCharge.endDateCondition = ratePlanCharge?.endDateCondition;
    this.ratePlanCharge.specificBillingPeriod = ratePlanCharge?.specificBillingPeriod;
    this.ratePlanCharge.upToPeriods = ratePlanCharge?.upToPeriods;
    this.ratePlanCharge.upToPeriodsType = ratePlanCharge?.upToPeriodsType;
    this.ratePlanCharge.weeklyBillCycleDay = ratePlanCharge?.weeklyBillCycleDay;
    this.ratePlanCharge.delayType = ratePlanCharge?.delayType;
    this.ratePlanCharge.delay = ratePlanCharge?.delay;
    this.ratePlanCharge.startingDate = ratePlanCharge?.startingDate;

  }

  showDialogConfirmationForDeleteCharge(_rateCharge, _index) {
    this.dialogConfirmation = this.dialog.open(DialogConfirmationComponent, {
      panelClass: 'custom-mat-dialog',
      data: {
        keyName: KeyName.remove_charge_inLine,
        charge: _rateCharge,
        word_confirmation: 'DELETE CHARGE'
      }
    });
    this.dialogConfirmation.afterClosed().subscribe((res) => {
      if (res === KeyName.remove_charge_inLine) {
        this._emitDeleteAction.emit(_rateCharge);
      }
      this.loader.stopPageLoader();
      }
    );
  }

  showDialogUpdateLinkCharge(ratePlanCharge: New_SubscriptionRatePlanChargeModel, externalRatePlanCharge: ExternalRatePlanCharge, ratePlanIndex: number) {
    const dialogLinkRateplanCharge = this._dialog.open(DialogLinkRateplanChargeComponent, {
      panelClass: ['custom-mat-dialog', 'mat-dialog-link-rateplan-charge'],
      data: {
        fromSubscription: true,
        isEdit: true,
        charge: ratePlanCharge,
        externalRatePlanCharge: externalRatePlanCharge,
        integrationId: externalRatePlanCharge.companyIntegration.id,
        integrationKeyName: externalRatePlanCharge.companyIntegration.integrationKeyName,
        subscriptionCompanyCurrency: this.subscriptionCompanyCurrency,
        usageBased: ratePlanCharge.usageType,
        isGift: ratePlanCharge.isGift,
      },
    });

    dialogLinkRateplanCharge.afterClosed().subscribe((res) => {
      if (res?.code == KeyName.form_submitted) {
        this.useSaleChannelData = res.useSaleChannelData;

        this.updateExternalRatePlanCharge(ratePlanCharge.id, externalRatePlanCharge.id, res, ratePlanIndex);
      }
    });
  }

  showDialogUnlinkCharge(ratePlanCharge: New_SubscriptionRatePlanChargeModel, externalRatePlanCharge: ExternalRatePlanCharge, index: number) {
    this.dialogConfirmation = this._dialog.open(DialogConfirmationComponent, {
      panelClass: ['custom-mat-dialog'],
      data: {
        keyName: KeyName.delete_external_rate_plan_charge,
        externalRatePlanCharge: externalRatePlanCharge,
        word_confirmation: 'UNLINK CHARGE',
      },
    });
    this.dialogConfirmation.afterClosed().subscribe((resCode) => {
      if (resCode === KeyName.delete_external_rate_plan_charge) {
        console.log('unlink charge');
        this.deleteExternalRatePlanCharge(ratePlanCharge.id, externalRatePlanCharge.id, index);
      }
    });
  }

  deleteExternalRatePlanCharge(ratePlanChargeId: number, externalRatePlanChargeId: number, ratePlanIndex: number) {
    this.subscriptionService.deleteExternalRatePlanCharge(this.subscriptionId, ratePlanChargeId, externalRatePlanChargeId)
      .subscribe(
        (res: RestResponse) => {
          this.loader.stopPageLoader();
          this.meta.showNotification(res);

          const ratePlanChargeIndex = this.grid.collectionOfGridItems.findIndex(ratePlanCharge => ratePlanCharge.id === ratePlanChargeId);
          this.grid.collectionOfGridItems[ratePlanChargeIndex].externalRatePlanCharges = this.grid.collectionOfGridItems[ratePlanChargeIndex].externalRatePlanCharges.filter(item => externalRatePlanChargeId !== item.id);

          this.subscriptionService.getSubscriptionRatePlanChargeById(this.subscriptionId, ratePlanChargeId)
            .subscribe(
              (res: RestResponse) => {
                let ratePlanCharge = <New_SubscriptionRatePlanChargeModel>res.data;
                this.grid.collectionOfGridItems[ratePlanChargeIndex].price = ratePlanCharge.price;
                this.grid.collectionOfGridItems[ratePlanChargeIndex].taxAmount = ratePlanCharge.taxAmount;
              },
              (err: RestResponse) => {
                this.loader.stopPageLoader();
                this.meta.showNotification(err);
              }
            )
        },
        (err: RestResponse) => {
          this.loader.stopPageLoader();
          this.meta.showNotification(err);
        }
      );
  }
  updateExternalRatePlanCharge(ratePlanChargeId: number, externalRatePlanChargeId: number, responseData, ratePlanIndex: number) {
    let bodyParams = { externalDetail: responseData.externalDetail };

   this.subscriptionService.updateExternalRatePlanCharge(this.subscriptionId, ratePlanChargeId, externalRatePlanChargeId, bodyParams)
      .subscribe(
        (res: RestResponse) => {
          const ratePlanChargeIndex = this.grid.collectionOfGridItems.findIndex(ratePlanCharge => ratePlanCharge.id === ratePlanChargeId);
          this.grid.collectionOfGridItems[ratePlanChargeIndex].externalRatePlanCharges = [res.data];

          let bodyParams: any = {};
          if (this.useSaleChannelData) {
            bodyParams = {
              price: responseData.price,
              quantity: responseData.quantity
            }
          } else {
            bodyParams = {
              useSaleChannelData: this.useSaleChannelData
            }
          }
          this.updateRateplanChargeSaleChannelData(ratePlanChargeId, bodyParams, ratePlanIndex);
        },
        (err: RestResponse) => {
          // TODO: check if necessary to call updateRateplanChargeSaleChannelData
          this.loader.stopPageLoader();
          this.meta.showNotification(err);
        }
      );
  }

  updateRateplanChargeSaleChannelData(_ratePlanChargeId, _bodyParams, _index: number) {
     this.subscriptionService.updateRateplanChargeSaleChannelData(this.subscriptionId, _ratePlanChargeId, _bodyParams)
      .subscribe(
        (res: any) => {
          const ratePlanChargeIndex = this.grid.collectionOfGridItems[_index].ratePlanCharge.findIndex(ratePlanCharge => res.data.id === ratePlanCharge.id);
          this.grid.collectionOfGridItems[_index].ratePlanCharge[ratePlanChargeIndex] = <New_SubscriptionRatePlanChargeModel>res.data;
          this.loader.stopPageLoader();
          this.meta.showNotification(res);
        },
        (err: RestResponse) => {
          this.loader.stopPageLoader();
          this.meta.showNotification(err);
        }
      );
  }
}

