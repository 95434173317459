<div class="dialog-wrapper">
  <!-- Head -->
  <div class="head">
    <label class="label-title">{{_data.isEdit ? 'Edit' : 'Link'}} <b>{{_data.charge.name}}</b> charge</label>
    <button mat-icon-button (click)="onClose()" class="custom-mat-icon-button button-close-panel">
      <mat-icon svgIcon="ico-close" class="custom-mat-icon ico-size ico-2xs"></mat-icon>
    </button>
  </div>
  <div class="body">
    <form [formGroup]="form" class="form-wrapper">
      <div class="form-content size size1of1">
        <div class="form-section">
          <div class="form-section-container">
            <div *ngIf="!_data.isEdit" class="form-section-head">
              <label class="typo body-1 fw400 semi-condensed">Please, choose the product you want to link the charge with.</label>
            </div>
            <div class="form-section-body">
              <div class="form-row">
                <mat-form-field appearance="outline" class="custom-mat-form-field custom-mat-form-field-outline size size1of1">
                  <mat-label>Sales Channel</mat-label>
                  <!-- <mat-select required [disabled]="!!_data.integrationId" formControlName="companyIntegration" (valueChange)="onCompanyIntegrationChange($event)" class="custom-mat-select">
                    <mat-option *ngFor="let companyIntegration of collectionOfCompanyIntegration" [value]="companyIntegration" class="custom-mat-option">{{ companyIntegration.displayName }}</mat-option>
                  </mat-select> -->
                  <mat-select required formControlName="companyIntegration" (valueChange)="onCompanyIntegrationChange($event)" class="custom-mat-select">
                    <mat-option *ngFor="let companyIntegration of collectionOfCompanyIntegration" [value]="companyIntegration" class="custom-mat-option">{{ companyIntegration.displayName }}</mat-option>
                  </mat-select>
                  <mat-error><error-messages [control]="form.get('companyIntegration')"></error-messages></mat-error>
                </mat-form-field>
              </div>
              <div class="form-row" *ngIf="!!integrationId">
                <mat-form-field floatLabel="always" appearance="outline" class="custom-mat-form-field custom-mat-form-field-outline size size1of1" disabled>
                  <mat-label>Product Name</mat-label>
                  <input matInput type="text" (keyup)="onCleanProductSearchInput(false)" formControlName="productName" placeholder="Search for a product by name." [matAutocomplete]="auto"/>
                  <mat-error><error-messages [control]="form.get('productName')"></error-messages></mat-error>
                  <mat-icon matSuffix *ngIf="!_data.isEdit && !selectedExternalProduct" svgIcon="ico-search" class="custom-mat-icon ico-size ico-1xs"></mat-icon>
                  <button mat-icon-button *ngIf="!_data.isEdit && selectedExternalProduct" matSuffix (click)="onCleanProductSearchInput()" class="custom-mat-icon-button button-clean-search">
                    <mat-icon svgIcon="ico-close" class="custom-mat-icon ico-size ico-2xs"></mat-icon>
                  </button>
                </mat-form-field>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectProductFiltered($event.option.value)" [displayWith]="autocompleteProductDisplayFn" class="custom-mat-autocomplete autocomplete-products">
                  <mat-option *ngIf="autocompleteIsLoading" class="custom-mat-option is-loading">
                    <mat-spinner class="custom-mat-spinner" diameter="20"></mat-spinner>
                    <label class="label-loading">Feel free to spin in your chair</label>
                  </mat-option>
                  <ng-container *ngIf="!autocompleteIsLoading" class="autocomplete-container">
                    <mat-option *ngFor="let product of collectionOfProductFiltered" [value]="product" class="custom-mat-option product-option">
                      <span>{{ product.name }}</span>
                      <div class="option-sku-row" *ngIf="product.sku">SKU: {{product.sku}}</div>
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </div>
              <ng-container *ngIf="addUseProductsOptionsCheckbox && selectedExternalProduct && collectionOfProductOptions.length > 0">
                <div  class="form-row row-checkbox">
                  <mat-checkbox [checked]="checkedUseProductsOptions" [disabled]="disabledProductsOptionsCheckbox" (change)="onChangeUseProductsOptionsCheckbox()" disableRipple="true" color="primary" class="custom-mat-checkbox rounded checkbox--with--description">
                    <span class="typo semi-condensed fw400">Use product’s option from checkout</span>
                  </mat-checkbox>
                </div>
                  <div  class="form-row">
                    <mat-checkbox  [ngModelOptions]="{standalone: true}" [(ngModel)]="linkThisToAllChargesId" color="primary" class="custom-mat-checkbox rounded checkbox--with--description">
                      <span class="flex typo semi-condensed fw400">Link all active plans with this charge item
                        <mat-icon matSuffix matTooltipPosition="right" matTooltipClass="mat-tooltip" matTooltip="By checking this checkbox, Rebillia will replace the linked product for all active subscribers with this charge item, and future recurring orders will contain this product" svgIcon="ico-help" class="custom-mat-icon ico-tooltip ico-size ico-2xs"></mat-icon>
                      </span>
                    </mat-checkbox>
                  </div>
              </ng-container>
              <div *ngIf="isProductReady && !checkedUseProductsOptions && collectionOfProductOptions.length > 0" class="form-row">
                <mat-list [formGroup]="options" class="custom-mat-list list-grid-std list-tiers tiers-shipping size size1of1">
                  <mat-list-item *ngFor="let option of collectionOfProductOptions; let i = index;" class="custom-mat-list-item size size1of1" style="margin-bottom: 14px;">
                    <mat-form-field *ngIf="!option.values" appearance="outline" class="custom-mat-form-field custom-mat-form-field-outline size size1of1">
                      <mat-label>{{option.displayName}}</mat-label>
                      <input *ngIf="_data.fromSubscription" matInput formControlName="{{i}}" (keyup)="onKeyUpInput()"/>
                      <input *ngIf="_data.fromProduct" matInput required formControlName="{{i}}"/>
                      <mat-error><error-messages [control]="form.get('options').get('{{i}}')"></error-messages></mat-error>
                      <mat-error *ngIf="form.get('options').get('{{i}}')?.value === null" class="size size1of1">{{expressionChangedAfterItHasBeenCheckedMessages[i]}}</mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="option.values?.length > 0" appearance="outline" class="custom-mat-form-field custom-mat-form-field-outline size size1of1">
                      <mat-label>{{option.displayName}}</mat-label>
                      <mat-select *ngIf="_data.fromSubscription" formControlName="{{i}}" class="custom-mat-select" (selectionChange)="onChangeProductOption()">
                        <mat-option *ngFor="let optionObj of option.values" [value]="optionObj.id" class="custom-mat-option">{{ optionObj.value }}</mat-option>
                      </mat-select>
                      <mat-select *ngIf="_data.fromProduct" [required]="option.required" formControlName="{{i}}" class="custom-mat-select">
                        <mat-option *ngFor="let optionObj of option.values" [value]="optionObj.id" class="custom-mat-option">{{ optionObj.value }}</mat-option>
                      </mat-select>
                      <mat-error><error-messages [control]="form.get('options').get('{{i}}')"></error-messages></mat-error>
                      <mat-error *ngIf="form.get('options').get('{{i}}')?.value === null" class="size size1of1">{{expressionChangedAfterItHasBeenCheckedMessages[i]}}</mat-error>
                    </mat-form-field>
                  </mat-list-item>
                </mat-list>
              </div>
              <div *ngIf="_data.fromSubscription && isProductReady" class="form-row row-checkbox">
                <mat-checkbox formControlName="useSaleChannelData" (change)="onChangeUseSaleChannelDataCheckbox()" color="primary" disableRipple="true" class="custom-mat-checkbox rounded checkbox-simple-line">
                  <span class="flex typo semi-condensed fw400">Use Price & Qty from sale channel
                    <mat-icon matSuffix matTooltipPosition="right" matTooltipClass="mat-tooltip" matTooltip="By checking this checkbox, Rebillia will ignore the price and quantity set in this page and will take the values from the sale channel integration" svgIcon="ico-help" class="custom-mat-icon ico-tooltip ico-size ico-2xs"></mat-icon>
                  </span>
                </mat-checkbox>
              </div>
              <div *ngIf="_data.fromSubscription && isProductReady && this.form.get('useSaleChannelData').value" class="form-row">
                <mat-form-field appearance="outline" class="custom-mat-form-field custom-mat-form-field-outline size size1of2">
                  <mat-label>Price</mat-label>
                  <mat-select formControlName="priceType" class="custom-mat-select">
                    <mat-option value="standard" class="custom-mat-option">Use standard price {{_data?.subscriptionCompanyCurrency ? _data?.subscriptionCompanyCurrency?.currency?.symbol : null}} {{selectedExternalProduct ? selectedExternalProduct?.price : null}}</mat-option>
                    <mat-option *ngIf="variant" value="variant" class="custom-mat-option">Use variant's price {{_data?.subscriptionCompanyCurrency ? _data?.subscriptionCompanyCurrency?.currency?.symbol : null}} {{calulatedVariantPrice}}</mat-option>
                    <mat-option value="currentPrice" class="custom-mat-option">Use current price {{_data?.subscriptionCompanyCurrency ? _data?.subscriptionCompanyCurrency?.currency?.symbol : null}} {{_data.charge.price ? _data?.charge?.price : null}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="custom-mat-form-field custom-mat-form-field-outline size size1of2">
                  <mat-label>Quantity</mat-label>
                  <input matInput required formControlName="quantity"/>
                  <mat-error><error-messages [control]="form.get('quantity')"></error-messages></mat-error>
                </mat-form-field>
              </div>
              <!-- form options skeleton -->
              <div *ngIf="_data.fromSubscription && _data.isEdit && !isProductReady">
                <skeleton-loader-form [hasTitle]=false [insideSection]=true [numberOfInputs]="3"></skeleton-loader-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="nav">
    <div class="inner">
      <a mat-button disableRipple="true" (click)="onClose()" class="custom-mat-button button-cancel">Cancel</a>
      <button mat-flat-button (click)="onSubmit()" [disabled]="formSubmitted" ngClass="{'is--loading': formSubmitted}" color="primary" class="mat-button custom-mat-button-primary">{{_data.isEdit ? 'Save Changes' : 'Link External Product'}}</button>
    </div>
  </div>
</div>
