//angular
import { Component, Inject, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { Unsubscriber } from 'src/app/_shared/unsubscriber/unsubscriber';

@Component({
  selector: 'dialog-user-idle',
  templateUrl: './dialog-user-idle.component.html'
})
export class DialogUserIdleComponent implements OnDestroy {
  idleCounter: number;
  private subs = new Unsubscriber();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<DialogUserIdleComponent>,
    private _iconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
      ) {

      this._iconRegistry.addSvgIcon('ico-close', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-close-01.svg'));
      this._iconRegistry.addSvgIcon('ico-warning', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-error-10.svg'));

      this.subs.addSub = data.component.idle.onTimeoutWarning.subscribe((countdown) => {
        this.idleCounter = countdown;
        if(this.idleCounter == 1) {
          this._dialogRef.close();
        }
      });
  }

  stay() {
    this._dialogRef.close('stay');
  }

  logout() {
    this._dialogRef.close('logout');
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
