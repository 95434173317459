<fieldset class="card">
  <div class="card-type">
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.alipay" svgIcon="card-alipay" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.americanexpress" svgIcon="card-amex" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.americanexpress2" svgIcon="card-amex" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.american_express" svgIcon="card-amex" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.amex" svgIcon="card-amex" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.AmericanExpress" svgIcon="card-amex" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.diners" svgIcon="card-diners" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.discover" svgIcon="card-discover" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.elo" svgIcon="card-elo" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.hypercard" svgIcon="card-hypercard" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.jcb" svgIcon="card-jcb" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.maestro" svgIcon="card-maestro" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.master" svgIcon="card-master" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.mastercard" svgIcon="card-master" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.paypal" svgIcon="card-paypal" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.unionpay" svgIcon="card-unionpay" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.verve" svgIcon="card-verve" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentAccountType.visa" svgIcon="card-visa" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
    <mat-icon *ngIf="_paymentMethod?.accountType?.toLowerCase() == _paymentSource.other" svgIcon="ico-card" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
  </div>
  <div class="card-data">
    <label class="label label-card-number">Ending in <b>{{ _paymentMethod?.accountNumber }}</b></label>
    <label *ngIf="_showExpDate" class="label label-card-exp">{{ _paymentMethod?.expDate }}</label>
  </div>
</fieldset>
