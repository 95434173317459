import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpParamsInterface } from 'src/app/_shared/interfaces/http-params.interface';

@Injectable({
  providedIn: 'root'
})
export class HttpParamService {

  constructor() { }

  public buildPathParams(params: HttpParamsInterface):HttpParams {
    const { includeList, orderBy, sortBy, filterId, pageNo, itemPerPage, query, status, companyIntegrationId, startDate, endDate, currency_ISO3, productId } = params;

    let httpParams = new HttpParams();

    if(includeList) {
      httpParams = httpParams.set('include', includeList.join());
    }

    if(status) {
      httpParams = httpParams.set('status', status);
    }

    if(orderBy) {
      httpParams = httpParams.set('orderBy', orderBy);
    }

    if(sortBy) {
      httpParams = httpParams.set('sortBy', sortBy);
    }

    if(filterId) {
      httpParams = httpParams.set('filterId', filterId.toString());
    }

    if(pageNo) {
      httpParams = httpParams.set('pageNo', pageNo.toString());
    }

    if(itemPerPage) {
      httpParams = httpParams.set('itemPerPage', itemPerPage.toString());
    }

    if(query) {
      httpParams = httpParams.set('query', query);
    }

    if(companyIntegrationId) {
      httpParams = httpParams.set('companyIntegrationId', companyIntegrationId);
    }

    if(startDate) {
      httpParams = httpParams.set('startDate', startDate);
    }

    if(endDate) {
      httpParams = httpParams.set('endDate', endDate);
    }

    if(currency_ISO3) {
      httpParams = httpParams.set('currency_ISO3', currency_ISO3);
    }

    if(productId) {
      httpParams = httpParams.set('productId', productId);
    }

    return httpParams;
  }
}
