<div class="dialog-wrapper">
  <div class="head">
    <mat-icon *ngIf="
      data.code !== this._keyName.revoke_access_user &&
      data.code !== this._keyName.cancel_invite &&
      data.code !== this._keyName.email_status_enabled &&
      data.code !== this._keyName.email_status_disabled &&
      data.code !== this._keyName.test_Avalara_configuration &&
      data.code !== this._keyName.test_gateway &&
      data.code !== this._keyName.reconnect_gateway &&
      data.keyName !== this._keyName.set_as_default_smart_routing &&
      data.keyName !== this._keyName.enable_integration" svgIcon="ico-warning" class="custom-mat-icon icon"></mat-icon>
    <img *ngIf="data.code === this._keyName.revoke_access_user || data.code === this._keyName.cancel_invite" class="custom-img" src="./assets/images/revoke_access-01.png">
    <img *ngIf="data.code === this._keyName.email_status_enabled || data.code === this._keyName.email_status_disabled || data.keyName === this._keyName.set_as_default_smart_routing || data.keyName === this._keyName.enable_integration" class="custom-img" src="./assets/images/change_status-02.png">
    <img *ngIf="data.code === this._keyName.test_Avalara_configuration || data.code === this._keyName.test_gateway || data.code === this._keyName.reconnect_gateway" class="custom-img" src="./assets/images/test-02.png">
  </div>
  <div class="body">
    <div *ngIf="data.code === this._keyName.remove_shipping_zone" class="block-delete-integration">
        <h1 class="typo display-2 fw300 semi-condensed label-title">Remove Shipping Zone</h1>
        <p class="typo body-1 fw400 semi-condensed label-message">By clicking 'Remove', you will remove shipping zone from all payment methods.</p>
    </div>
    <div *ngIf="data.code === this._keyName.remove_shipping_protection" class="block-delete-integration">
        <h1 class="typo display-2 fw300 semi-condensed label-title">Remove UPS shipping protection</h1>
        <p class="typo body-1 fw400 semi-condensed label-message">By clicking 'Remove', you will remove UPS shipping protection from the subscription.</p>
    </div>

    <!-- Delete integration  -->
    <div *ngIf="data.code === this._keyName.export_csv" class="block-delete-integration">
        <h1 class="typo display-2 fw300 semi-condensed label-title">Export View to CSV</h1>
        <p class="typo body-1 fw400 semi-condensed label-message">By clicking "Start Export", Rebillia will compile the export file. A push notification with a download link will be available once the export file is ready to download.</p>
    </div>

    <div *ngIf="data.code === this._keyName.manually_rerun_payment_run" class="block-delete-integration">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Manually Re-Run Payment Run</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">By clicking "Re-Run", Rebillia will re-run the chosen Payment Run.</p>
    </div>

    <!-- Delete integration  -->
    <div *ngIf="data.code === this._keyName.account_closed" class="block-delete-integration">
        <h1 class="typo display-2 fw300 semi-condensed label-title">Account Closed</h1>
        <p class="typo body-1 fw400 semi-condensed label-message">This account has been closed and can not be accessed any further. To reactivate the account, please contact us through support@rebillia.com</p>
    </div>

    <!-- ADDRESS START -->
    <!-- Delete address  -->
    <div *ngIf="data.code === this._keyName.remove_address" class="block-delete-address">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Remove this address?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">Removing it means you won't be able to use this address for other Rebillia product or service without adding it again.</p>
      <div class="block-card">
        <p class="typo subheading-1 condensed fw400">{{ data.address.street1 }} {{ data.address.street2 }}, {{ data.address.city }}, {{ data.address.state }}, {{ data.address.zip }}, {{ data.address.country.alpha2Code }}</p>
      </div>
    </div>
    <!-- ADDRESS ENDS -->

    <!-- MANAGE CUSTOMERS START -->
    <!-- change customer status -->
    <div *ngIf="data.keyName === this._keyName.change_customer_status" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Change Customer Status?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The Customer Status <strong>{{ data.customer.status }}</strong>, will change to <strong>{{ data.nextStatus }}</strong></p>
      <mat-form-field *ngIf="data.nextStatus === 'disabled' || data.nextStatus === 'archived'" appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>

    <div *ngIf="data.keyName === this._keyName.enforce_customer_gdpr" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Enforce GDPR</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">By submitting this confirmation form, you understand that all active subscriptions and open invoices will be cancelled, as well as all personal information, including but not limited to - Full Name, Email Address, Physical Addresses, Payment Methods - will be <strong>permanently</strong> removed from the system, and could not be retrieved.</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>

    <div *ngIf="data.keyName === this._keyName.block_account" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Block Account</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">By submitting this confirmation form, you understand that all active subscriptions and open invoices will be cancelled.</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the phrase <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>

    <div *ngIf="data.keyName === this._keyName.unblock_account" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Unblock Account</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">By submitting this confirmation form, you understand that all canceled subscriptions and canceled invoices will be activated.</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the phrase <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>

    <div *ngIf="data.keyName === this._keyName.merge_customers" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Merge Customers</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">For security reasons, payment methods can not be transferred between customer accounts. By merging customer accounts and moving the subscriptions to the main account, all subscriptions will need to get a new payment method assigned to them.</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>
    <!-- MANAGE CUSTOMERS ENDS -->

    <!-- MANAGE PAYMENT METHODS -->
    <!-- Set as default Payment Method -->
    <div *ngIf="data.code === this._keyName.set_as_default_payment_method" class="block--set_as_default_payment_method">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Set as default this payment method?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">With this action, you will set up this payment as the selected to pay your next billing.</p>
    </div>
    <!-- Confirm Payment with credit card -->
    <div *ngIf="data.code === this._keyName.do_payment_with_credit_card" class="block--payment-with-credit-card">
      <h1  class="typo display-2 fw300 semi-condensed label-title">Confirm Payment with Credit Card</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">You are more close to be a part of Merchants Rebillia Team. Confirm to start with your membership!</p>
    </div>
    <!-- Remove Payment Method -->
    <div *ngIf="data.code === this._keyName.remove_payment" class="block-delete-payment-method">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Remove this payment method?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">Removing it means you won't be able to use this payment method for other Rebillia product or service without adding it again.</p>
      <div class="block-card">
        <mat-icon *ngIf="data.cardType.toLowerCase() === 'alipay'" svgIcon="card-alipay" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
        <mat-icon *ngIf="data.cardType.toLowerCase() === 'amex'" svgIcon="card-amex" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
        <mat-icon *ngIf="data.cardType.toLowerCase() === 'diners'" svgIcon="card-diners" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
        <mat-icon *ngIf="data.cardType.toLowerCase() === 'discover'" svgIcon="card-discover" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
        <mat-icon *ngIf="data.cardType.toLowerCase() === 'elo'" svgIcon="card-elo" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
        <mat-icon *ngIf="data.cardType.toLowerCase() === 'hypercard'" svgIcon="card-hypercard" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
        <mat-icon *ngIf="data.cardType.toLowerCase() === 'jcb'" svgIcon="card-jcb" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
        <mat-icon *ngIf="data.cardType.toLowerCase() === 'maestro'" svgIcon="card-maestro" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
        <mat-icon *ngIf="data.cardType.toLowerCase() === 'master'" svgIcon="card-master" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
        <mat-icon *ngIf="data.cardType.toLowerCase() === 'paypal'" svgIcon="card-paypal" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
        <mat-icon *ngIf="data.cardType.toLowerCase() === 'unionpay'" svgIcon="card-unionpay" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
        <mat-icon *ngIf="data.cardType.toLowerCase() === 'verve'" svgIcon="card-verve" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
        <mat-icon *ngIf="data.cardType.toLowerCase() === 'visa'" svgIcon="card-visa" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
        <p class="typo subheading-1 condensed fw400 label-last4">Ending in <strong>( {{ data.cardNumber }} )</strong></p>
      </div>
    </div>
    <!-- MANAGE PAYMENT ENDS -->
    <div *ngIf="data.keyName === this._keyName.mark_as_template" class="block-delete-gateway">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Save As Template</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">Create a template based on selected product rateplan.</p>
    </div>
    <div *ngIf="data.keyName === this._keyName.sync_with_template" class="block-delete-gateway">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Re-Apply Template</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">Sync details of the product rateplan with connected template.</p>
    </div>
    <div *ngIf="data.keyName === this._keyName.apply_template" class="block-delete-gateway">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Apply template</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">Apply this template to all product rateplans that are connected.</p>
    </div>
    <!-- MANAGE USERS START -->
    <!-- Revoke access user -->
    <div *ngIf="data.code === this._keyName.revoke_access_user" class="block-revoke-user-access">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Revoke access to user?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The user <strong>{{data.user.email}}</strong> will can not enter to Rebillia platform.</p>
    </div>
    <!-- Cancel access user -->
    <div *ngIf="data.code === this._keyName.cancel_invite" class="block-cancel-user-access">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Cancel invite user?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">This user <strong>{{data.user.email}}</strong> will can not accept the invite and enter to the platform.</p>
    </div>
    <!-- Cancel access account -->
    <div *ngIf="data.code === this._keyName.cancel_account" class="block-cancel-user-access">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Cancel Account</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">Are you sure you want to cancel your Rebillia account?</p>
    </div>
    <!-- MANAGE USERS ENDS -->

    <!-- MANAGE CURRENCY START -->
    <!-- Delete currecy -->
    <div *ngIf="data.code === this._keyName.delete_currency" class="block-delete-currency">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Delete currency?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">When you delete this currency, you can not use it in the future.</p>
    </div>
    <!-- MANAGE CURRENCY ENDS -->

    <!-- MANAGE SMART ROUTING -->
    <!-- Disengage company gateway -->
    <div *ngIf="data.keyName === this._keyName.disengage_company_gateway" class="block-disengage-gateway">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Disengage gateway?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">If you want to remove the gateway account <strong>{{data.companyGateway.name}}</strong> set for <strong>[{{data.companyCurrency.currency.iso3}}]</strong> click 'Disengage Gateway'. Otherwise click 'Cancel'.</p>
    </div>

    <!-- MANAGE CHAGES START -->
    <!-- remove charges -->
    <div *ngIf="data.code === this._keyName.delete_charge" class="block-delete-charge">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Remove charge?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">When you remove this charge, you can not use it in the future.</p>
    </div>
    <!-- MANAGE CHAGES ENDS -->

    <!-- MANAGE PAYMENT GATEWAYS START -->
    <!-- Archive gateway -->
    <div *ngIf="data.code === this._keyName.change_gateway_status_to_archive" class="block-gateway">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Archive {{data.gateway.name}}?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message"><b>On archiving,</b> customers will no longer be able to use this payment gateway for processing payments. Also, existing subscriptions attached to this gateway will no longer continue to make payments via this payment gateway.</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>
    <!-- Delete gateway -->
    <div *ngIf="data.code === this._keyName.delete_gateway" class="block-gateway">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Delete gateway?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">When you delete this gateway, you can not use it in the future.</p>
    </div>
    <!-- Enable gateway -->
    <div *ngIf="data.code === this._keyName.change_gateway_status_to_active" class="block-gateway">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Enable {{data.gateway.name}}?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message"><b>On enabeling,</b> either new or existing customers can use this payment gateway for processing payments. Also, either new or existing subscriptions attached to this gateway can continue to make payments via this payment gateway.</p>
    </div>
    <!-- Enable gateway -->
    <div *ngIf="data.code === this._keyName.change_gateway_status_to_disabled" class="block-gateway">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Disable {{data.gateway.name}}?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message"><b>On disabeling,</b> new customers can no longer use this payment gateway for processing payments but existing subscriptions attached to this gateway can continue to make payments via this payment gateway.</p>
    </div>
    <!-- Reconnect gateway -->
    <div *ngIf="data.code === this._keyName.reconnect_gateway" class="block-gateway">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Reconnect {{data.gateway.name}}?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message"><b>On reconnecting,</b> customers will be able to use this payment gateway for processing payments. Also, existing subscriptions attached to this gateway will continue to make payments via this payment gateway.</p>
    </div>
    <!-- Test Avalara configuration -->
    <div *ngIf="data.code === this._keyName.test_Avalara_configuration" class="block-delete-gateway">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Configuration Test</h1>
      <!-- <p class="typo body-1 fw400 semi-condensed label-message">[ Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem ]</p> -->
    </div>
    <!-- Test gateway -->
    <div *ngIf="data.code === this._keyName.test_gateway" class="block-delete-gateway">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Configuration Test</h1>
      <!-- <p class="typo body-1 fw400 semi-condensed label-message">[ Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem ]</p> -->
    </div>
    <!-- MANAGE PAYMENT GATEWAYS ENDS -->

    <!-- MANAGE PLANS START -->
    <!-- Delete plan -->
    <div *ngIf="data.code === this._keyName.delete_plan" class="block-delete-plan">
      <h1  class="typo display-2 fw300 semi-condensed label-title">Delete plan?</h1>
      <p *ngIf="_cantOfPlanToDelete === 1" class="typo body-1 fw400 semi-condensed label-message">The plan <strong>{{ _plansToDelete[0].name }}</strong> will be permanently deleted. You will no longer be able to link this plan to any subscription.</p>
      <p *ngIf="_cantOfPlanToDelete > 1" class="typo body-1 fw400 semi-condensed label-message">You delete <strong>{{ _cantOfPlanToDelete }} plans</strong> that will be permanently deleted. You will no longer be able to link these plans to any subscription.</p>
      <p class="typo body-1 fw400 semi-condensed label-message">Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</p>
      <mat-form-field class="example-full-width">
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>
    <!-- MANAGE PLANS ENDS -->

    <!-- MANAGE EMAIL TEMPLATES -->
    <!-- Change Status -->
    <div *ngIf="data.code === this._keyName.email_status_enabled || data.code === this._keyName.email_status_disabled" class="block-email-change-status">
      <h1 *ngIf="data.code === this._keyName.email_status_enabled" class="typo display-2 fw300 semi-condensed label-title">Enable {{ data.email.title }}?</h1>
      <p *ngIf="data.code === this._keyName.email_status_enabled" class="typo body-1 fw400 semi-condensed label-message">{{ data.email.description }}</p>
      <h1 *ngIf="data.code === this._keyName.email_status_disabled" class="typo display-2 fw300 semi-condensed label-title">Disable {{ data.email.title }}?</h1>
      <p *ngIf="data.code === this._keyName.email_status_disabled" class="typo body-1 fw400 semi-condensed label-message">After you disable this email templates, notifications that are using this template will not be send anymore. Disabling this email template will not delete your email template design. You can always come back later and enable it again.</p>
    </div>

    <!-- INTEGRATIONS START -->
    <!-- enable integration -->
    <div *ngIf="data.keyName === this._keyName.enable_integration" class="block-enable-integration">
      <h1 *ngIf="data.keyName === this._keyName.enable_integration" class="typo display-2 fw300 semi-condensed label-title">Enable {{ data.companyIntegration.displayName }}?</h1>
      <p *ngIf="data.keyName === this._keyName.enable_integration" class="typo body-1 fw400 semi-condensed label-message">{{ data.companyIntegration.description }}</p>
    </div>

    <!-- Archive integration  -->
    <div *ngIf="data.keyName === this._keyName.archive_integration" class="block-delete-integration">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Archive?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">If you want to archive the integration <strong>{{data.integration.displayName}}</strong>. Please type in the word: <strong>{{ data.word_confirmation }}</strong> to confirm. Otherwise click 'Cancel'. </p>
      <section class="message message-row">
        <div class="message-wrapper attention">
          <div class="message-inner">
            <p class="typo label fw400 semi-condensed"><strong>ATTENTION:</strong></p>
          </div>
          <span *ngIf="data.collectionOfMessages.length > 0">
            <li class="message-inner" *ngFor="let integrationMessage of data.collectionOfMessages">
              <i class="typo normal label fw400 semi-condensed">
                {{ integrationMessage.message }}
              </i>
            </li>
          </span>
        </div>
      </section>

      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>

    <!-- Disable SMTP integration  -->
    <div *ngIf="data.code === this._keyName.disable_SMTP_integration" class="block-delete-integration">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Disable?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message"> After disabling this integration, You will not be able to send notifications using this channel. </p>
    </div>

    <!-- Delete integration  -->
    <div *ngIf="data.code === this._keyName.delete_integration" class="block-delete-integration">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Disconnect?</h1>
      <!-- <p class="typo body-1 fw400 semi-condensed label-message">[ Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem ]</p> -->
    </div>
    <div *ngIf="data.code === this._keyName.delete_integration_shipping" class="block-delete-integration">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Disconnect {{data.shippingProvider.displayName}}?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">If you want to remove the shipping carrier account <strong>{{data.shippingProvider.displayName}}</strong>, Please type in the word: <strong>{{ data.word_confirmation }}</strong> to confirm. Otherwise click 'Cancel'.</p>
      <section class="message message-row">
        <div class="message-wrapper attention">
          <div class="message-inner">
            <p class="typo label fw400 semi-condensed"><strong>ATTENTION:</strong></p>
          </div>
          <li class="message-inner">
            <i class="typo normal label fw400 semi-condensed">
              All {{data.shippingProvider.displayName}} settings and account credentials will be deleted.
            </i>
          </li>
          <span *ngIf="data.collectionOfMessages.length > 0">
            <li class="message-inner" *ngFor="let integrationMessage of data.collectionOfMessages">
              <i class="typo normal label fw400 semi-condensed">
                {{ integrationMessage.message }}
              </i>
            </li>
          </span>
        </div>
      </section>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field padding nopadding size size1of1 ">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>

    <div *ngIf="data.code === this._keyName.delete_integration_tax" class="block-delete-integration">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Disconnect {{data.shippingProvider.displayName}}?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">If you want to remove your tax account <strong>{{data.shippingProvider.displayName}}</strong>, Please type in the word: <strong>{{ data.word_confirmation }}</strong> to confirm. Otherwise click 'Cancel'.</p>
      <section class="message message-row">
        <div class="message-wrapper attention">
          <div class="message-inner">
            <p class="typo label fw400 semi-condensed"><strong>ATTENTION:</strong></p>
          </div>

          <li class="message-inner">
            <i class="typo normal label fw400 semi-condensed">
              All {{data.shippingProvider.displayName}} settings and account credentials will be deleted.
            </i>
          </li>
          <span *ngIf="data.collectionOfMessages.length > 0">
            <li class="message-inner" *ngFor="let integrationMessage of data.collectionOfMessages">
              <i class="typo normal label fw400 semi-condensed">
                {{ integrationMessage.message }}
              </i>
            </li>
          </span>
        </div>
      </section>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>

    <!-- remove integration -->
    <div *ngIf="data.keyName === this._keyName.remove_integration" class="block-delete-integration">
      <h1 class="typo display-2 fw300 semi-condensed label-title">remove {{data.shippingProvider.displayName}}?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">If you want to remove <strong>{{data.shippingProvider.displayName}}</strong> click 'Remove'. Otherwise click 'Cancel'.</p>
    </div>
    <!-- INTEGRATIONS ENDS -->

    <!-- SMART ROUTING -->
    <!-- Set As Default -->
    <div *ngIf="data.keyName === this._keyName.set_as_default_smart_routing" class="block-email-change-status">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Set As Default?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">When your customer adds a payment method on checkout or updates their payment details, a gateway account must be linked to it. All the customer's future payments made using this payment method are processed through this gateway account.</p>
    </div>

    <!-- TAXES -->
    <!-- Remove Tax Region -->
    <div *ngIf="data.keyName === this._keyName.delete_tax_region" class="block-delete-tax-region">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Stop collecting tax in {{data.taxRegion.name}}?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">Deleting {{data.taxRegion.name}} also deletes any local {{data.taxRegion.name}} tax authorities you've set up. Deleted regions and local authorities can be added back by going to Configure Region.</p>
    </div>

    <!-- INVOICES -->
    <!-- Remove Charge/Credit -->
    <div *ngIf="data.keyName === this._keyName.remove_charge_credit" class="block-delete-charge-credit">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Remove this charge?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">Once you delete this charge, it's gone forever.</p>
    </div>
    <!-- Cancel Invoice -->
    <div *ngIf="data.code === this._keyName.cancel_invoice" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Cancel Invoice?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The invoice <strong>#{{ data.invoice.id }}</strong> will be cancel. Once you cancel this invoice you can delete it but once you delete it, it's gone forever!</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>

    <!-- Delete Invoice -->
    <div *ngIf="data.code === this._keyName.delete_invoice" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Delete Invoice?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The invoice <strong>#{{ data.invoice.id }}</strong> will be deleted. Once you delete it's gone forever!</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>

    <!-- SUBSCRIPTIONS -->
    <!-- Delete charge -->
    <div *ngIf="data.keyName === this._keyName.remove_charge" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Delete charge?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The charge <strong>{{ data.charge.name }}</strong> will be permanently deleted.</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>
    <!-- Delete charge in line -->
    <div *ngIf="data.keyName === this._keyName.remove_charge_inLine" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Delete charge?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The charge <strong>{{ data.charge.name }}</strong> will be removed from this list.</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>
    <!-- Delete subscription -->
    <div *ngIf="data.keyName === this._keyName.delete_subscription" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Delete subscription?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The subscription <strong>{{ data.subscription.name }}</strong> will be permanently deleted.</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>
    <!-- Change subscription status -->
    <div *ngIf="data.keyName === this._keyName.change_subscription_status" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Change Subscription Status?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The Subscription Status <strong>{{ data.subscription.status }}</strong>, will change to <strong>{{ data.nextStatus }}</strong></p>
    </div>
    <!-- Change subscription status to Archived -->
    <div *ngIf="data.keyName === this._keyName.archive_subscription" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Change Subscription Status?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The Subscription Status <strong>{{ data.subscription.status }}</strong>, will change to <strong>{{ data.nextStatus }}</strong></p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>
    <!-- Change subscription rate plan status -->
    <div *ngIf="data.keyName === this._keyName.change_subscription_rate_plan_status" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Change Subscription Rate Plan Status?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The Subscription Rate Plan Charge Status <strong>{{ data.currentStatus }}</strong>, will change to <strong>{{ data.nextStatus }}</strong></p>
      <section class="message message-row">
        <div class="message-wrapper attention">
          <div class="message-inner">
            <p class="typo label fw400 semi-condensed"><strong>ATTENTION:</strong></p>
          </div>
          <span *ngIf="data.collectionOfMessages.length > 0">
            <li class="message-inner" *ngFor="let integrationMessage of data.collectionOfMessages">
              <i class="typo normal label fw400 semi-condensed">
                {{ integrationMessage.message }}
              </i>
            </li>
          </span>
        </div>
      </section>
    </div>
    <!-- Skip rateplan charge -->
    <div *ngIf="data.keyName === this._keyName.skip_rate_plan_charge" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Skip Upcoming Charge</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">Are you sure you want to skip this upcoming charge? If yes, click the "Skip Charge" button below. Otherwise, cancel.</p>
    </div>

    <!-- Sync Active Plans -->
    <div *ngIf="data.keyName === this._keyName.sync_active_plans" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Sync Active Plans</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">Are you sure you want to sync active plans? If yes, click the "Sync Active Plans" button below. Otherwise, cancel.</p>
    </div>

    <!-- Set external order-->
    <div *ngIf="data.keyName === this._keyName.set_order_action" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Set External Order</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">Are you sure you want to set external order for this invoice? If yes, click the "Set External Order" button below. Otherwise, cancel.</p>
    </div>

    <!-- Pause bulk product -->
    <div *ngIf="data.keyName === this._keyName.bulk_pause_product" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Pause {{ data.product.name }}</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">Are you sure you want to pause this product? This action will pause all active subscription plans associated with this product, disable the product and all of its rate plans, and remove the subscription options from the external product (if applicable).</p>
    </div>

    <!-- Change price for rateplan charge -->
    <div *ngIf="data.keyName === this._keyName.change_price_for_rate_plan_charge" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Change Per Unit Price</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">To change the per unit price for the specific upcoming charge record, please enter the new price value in the input below and click the "Change Price" button</p>
      <mat-form-field floatLabel="always" [class]="'custom-mat-form-field field-amount size size1of2'" >
        <mat-label>Amount (per unit)</mat-label>
        <span matPrefix>{{data.currencySymbol}}</span>
        <input matInput type="text" [(ngModel)]="data.charge['newPrice']" required placeholder="0,000.00" maxlength="13" [cleave]="data.cleaveNumDecimalOptions">
        <span matSuffix>{{data.currencyIso3}}</span>
        <mat-error><error-messages [control]="data.price"></error-messages></mat-error>
      </mat-form-field>
    </div>
    <!-- Change shipping price for rateplan charge -->
    <div *ngIf="data.keyName === this._keyName.change_shipping_price_for_rate_plan_charge" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Change Shipping Price</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">To change the shipping price for this specific upcoming charge record, please enter the new price value in the input below and click the "Change Shipping Price" button.</p>
      <mat-form-field floatLabel="always" [class]="'custom-mat-form-field field-amount size size1of2'" >
        <mat-label>SHIPPING AMOUNT</mat-label>
        <span matPrefix>{{data.currencySymbol}}</span>
        <input matInput type="text" [(ngModel)]="data.charge['newPrice']" required placeholder="0,000.00" maxlength="13" [cleave]="data.cleaveNumDecimalOptions">
        <span matSuffix>{{data.currencyIso3}}</span>
        <mat-error><error-messages [control]="data.price"></error-messages></mat-error>
      </mat-form-field>
    </div>
    <!-- Change subscription rate plan charge status -->
    <div *ngIf="data.keyName === this._keyName.change_subscription_rate_plan_charge_status" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Change Subscription Rate Plan Charge Status?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The Subscription Rate Plan Charge Status <strong>{{ data.charge.status }}</strong>, will change to <strong>{{ data.nextStatus }}</strong></p>
    </div>
    <div *ngIf="data.keyName === this._keyName.activate_lead" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Activate this lead?</h1>
    </div>
    <!-- Delete subscription external rate plan charge -->
    <div *ngIf="data.keyName === this._keyName.delete_external_rate_plan_charge" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Unlink external rate plan charge?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The external rate plan charge <strong>{{ data.externalRatePlanCharge.externalName }}</strong> will be unlinked.</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>

    <div *ngIf="data.keyName === this._keyName.delete_webhook" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Delete webhook?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The webhook <strong>{{ data.webhook.url }}</strong> will be deleted.</p>
    </div>

    <!-- Unlink subscription sale channel -->
    <div *ngIf="data.keyName === this._keyName.subscription_unlink_sale_channel" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Unlink sale channel for this subscription?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The sale channel <strong>{{ data.subscription?.sourceCompanyIntegration?.displayName}}</strong> for subscription will be unlinked.</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>

    <!-- MANAGE PRODUCT START -->
    <!-- Delete product -->
    <div *ngIf="data.code === this._keyName.delete_product" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Delete product?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The product <strong>{{ data.product.name }}</strong> will be permanently deleted. You will no longer be able to link this product to any subscription.</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>

    <!-- change autocollection invoice-->
    <div *ngIf="data.keyName === this._keyName.change_auto_collection_invoice" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Change Auto Collection Status?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The invoice Status <strong> {{ (data.currentStatus ? 'On':'Off')}} </strong>, will change to <strong>{{ (data.nextStatus ? 'On':'Off')}}</strong></p>
    </div>

    <!-- change product state-->
    <div *ngIf="data.keyName === this._keyName.change_product_status" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Change Status of the Products ?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The Product Status <strong>{{ data.product.status }}</strong>, will change to <strong>{{ data.nextStatus }}</strong></p>
    </div>

    <!-- change products state-->
    <div *ngIf="data.keyName === this._keyName.change_products_status" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Change Products Status?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The status of the products will change to <strong>{{ data.nextStatus }}</strong></p>
    </div>
    <!-- MANAGE PRODUCT ENDS -->

    <!-- MANAGE IMPORTER PRODUCT TASK STARTS -->
    <div *ngIf="data.keyName === this._keyName.change_importer_product_task_status" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Change Importer Product Task Status?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The Importer Product Task Status <strong>{{ data.importerProductTask.status }}</strong>, will change to <strong>{{ data.nextStatus }}</strong></p>
    </div>
    <!-- MANAGE IMPORTER PRODUCT TASK ENDS -->

    <!-- MANAGE PRODUCT RATE PLAN START -->
    <!-- Delete rate plan -->
    <div *ngIf="data.keyName === this._keyName.delete_rate_plan" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Delete Rate Plan?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The Rate Plan <strong>{{ data.ratePlan.name }}</strong> will be permanently deleted.</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>

    <div *ngIf="data.keyName === this._keyName.product_rateplan_discontinue" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Discontinue <strong>{{ data.ratePlan.name }}</strong></h1>
      <p class="typo body-2 fw400 semi-condensed label-message">Are you sure you want to discontinue this plan?</p>
      <p class="typo body-1 fw400 semi-condensed label-message">By discontinuing this plan, all active subscribers will be cancelled and new subscriptions can not be created for this plan.</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>

    <!-- SALES CHANNELS -->
    <!-- remove customer group -->
    <div *ngIf="data.code === this._keyName.delete_customer_group" class="block-delete-charge">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Remove Customer Group?</h1>
    </div>

    <!-- change rate plan state-->
    <div *ngIf="data.keyName === this._keyName.change_product_rateplan_status">
      <div *ngIf="data.nextStatus !== _ratePlanStatus.archived" class="fluid-max max-460">
        <h1 class="typo display-2 fw300 semi-condensed label-title">Change Rate Plan Status?</h1>
        <p class="typo body-1 fw400 semi-condensed label-message">The Rate Plan Status <strong>{{ data.ratePlan.status | titlecase }}</strong>, will change to <strong>{{ data.nextStatus | titlecase }}</strong></p>
      </div>
      <div *ngIf="data.nextStatus === _ratePlanStatus.archived" class="fluid-max max-460">
        <h1 class="typo display-2 fw300 semi-condensed label-title">Archive Rate Plan?</h1>
        <p class="typo body-1 fw400 semi-condensed label-message">The Rate Plan <strong>{{ data.ratePlan.name }}</strong> will be archived.</p>
        <div class="form-wrapper">
          <section *ngIf="!_isResponseReady" class="loading">
            <div class="mat-progress-spinner custom-mat-progress-spinner spinner-loading-01">
              <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
                <path opacity="0.2" fill="#000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z" />
                <path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="0.5s" repeatCount="indefinite" /></path>
              </svg>
            </div>
          </section>
          <div [@onRowStatusChange]="(_isResponseReady ? _visibleState.isVisible : _visibleState.isHidden)" class="form-content">
            <section *ngIf="_subscriptionCount > 0" class="message message-row">
              <div class="message-wrapper attention">
                <div class="message-inner">
                  <p class="message-description">You are about to archive the “{{data.ratePlan.name}}” rate plan. It has <strong>#{{_subscriptionCount}}</strong> active subscribers on it. By continuing, all charges associated with this plan will continue working properly but creating orders in the sale channels might fail.</p>
                </div>
              </div>
            </section>
            <div class="form-row">
              <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1" style="min-width: 270px;">
                <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
                <input matInput [(ngModel)]="_word_confirmation">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MANAGE PRODUCT RATE PLAN ENDS -->

    <!-- MANAGE PRODUCT RATE PLAN CHARGE START -->
    <!-- Delete charge -->
    <div *ngIf="data.keyName === this._keyName.delete_rate_plan_charge" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Delete charge?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The charge <strong>{{ data.charge.name }}</strong> will be permanently deleted.</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>

    <!-- Delete external product -->
    <div *ngIf="data.keyName === this._keyName.delete_external_product" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Unlink Product?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The product <strong>{{ data.external_product.name }}</strong> will no longer be linked to <strong>{{ data.product.name }}</strong>.</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>

      <p class="typo body-1 fw400 semi-condensed label-message">
        Unlinking the product will also unlink all external products linked to any of the charges listed in this page
      </p>

    </div>
    <!-- Sync modifiers -->
    <div *ngIf="data.keyName === this._keyName.sync_modifiers" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Sync Product Data?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">Update data in Rebillia with live data from <strong>{{ data.bigcommerceProduct.name}}</strong> product object in your sale channel product catalog.</p>
      <!-- <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Sync Variant & Modifier Values</mat-label>
      </mat-form-field> -->
      <mat-checkbox [(ngModel)]="extraCheckbox">Sync Variant & Modifier Values</mat-checkbox>
    </div>


    <!-- Delete external product-charge -->
    <div *ngIf="data.keyName === this._keyName.delete_external_charge" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Unlink Charge?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The product <strong>{{ data.externalProduct.externalName}}</strong> will no longer be linked to <strong>{{data.charge.name}}</strong>.</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
      <mat-checkbox [(ngModel)]="extraCheckbox">
        <span class="flex typo "> Unlink all active plans with this charge item
          <mat-icon matSuffix matTooltipPosition="right" matTooltipClass="mat-tooltip" matTooltip="By checking this checkbox, Rebillia will remove the linked product for all active subscribers with this charge item, and future recurring orders will contain plain text with the name of the charge" svgIcon="ico-help" class="custom-mat-icon ico-tooltip ico-size ico-2xs"></mat-icon>
        </span>

       </mat-checkbox>
    </div>

    <!-- MANAGE PRODUCT RATE PLAN CHARGE ENDS -->
    <!-- billing-run execute now-->
    <div *ngIf="data.keyName === this._keyName.execute_billing_run_now" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Run Billing Process</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">You will execute <strong>{{ data.word_confirmation }}</strong>, are you sure?</p>
    </div>

    <!-- save_rbl_scope_key_list -->
    <div *ngIf="data.keyName === this._keyName.save_rbl_scope_key_list" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">From Beta to Stable</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">Are you sure?</p>
    </div>

    <!-- save_experimental_features -->
    <div *ngIf="data.keyName === this._keyName.save_experimental_features" class="block-delete-plan">
      <h3 class="typo display-3 fw300 semi-condensed label-title">{{ data.confirmationText }}</h3>
    </div>

    <!-- payment-run execute now-->
    <div *ngIf="data.keyName === this._keyName.execute_payment_run_now" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Run Payment Process</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">You will execute <strong>{{ data.word_confirmation }}</strong>, are you sure?</p>
    </div>

    <!-- NOTIFICATIONS -->
    <!-- Delete notification -->
    <div *ngIf="data.keyName === this._keyName.delete_notification" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Delete notification?</h1>
      <p class="typo body-1 fw400 semi-condensed label-message">The notification <strong>{{ data.notificationChannel.displayName }}</strong> will be permanently deleted.</p>
      <mat-form-field appearance="outline" floatLabel="always" class="custom-mat-form-field size size1of1">
        <mat-label>Please type in the word <strong>{{ data.word_confirmation }}</strong> to confirm</mat-label>
        <input matInput [(ngModel)]="_word_confirmation">
      </mat-form-field>
    </div>

    <!-- Authorization -->
    <!-- Limit reached -->
    <div *ngIf="data.keyName === this._keyName.limit_reached" class="block-delete-plan">
      <h1 class="typo display-2 fw300 semi-condensed label-title">Limit reached!</h1>
      <p class="typo body-1 fw400 semi-condensed label-message" [innerHtml]='data.message'></p>
    </div>

  </div>
  <div class="nav">

    <!-- Remove Address -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.remove_address" color="primary" class="mat-button custom-mat-button-primary">Remove Address</button>

    <!-- Enable/Disabled Email Template -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.email_status_enabled" color="primary" class="mat-button custom-mat-button-primary">Enable Email Template</button>
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.email_status_disabled" color="primary" class="mat-button custom-mat-button-primary">Disabled Email Template</button>

    <!-- Remove Payment Method -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.remove_payment" color="primary" class="mat-button custom-mat-button-primary">Remove Payment Method</button>

    <!-- Set as default Payment Method -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.set_as_default_payment_method" color="primary" class="mat-button custom-mat-button-primary">Set as default</button>

    <!-- Set as default Payment Method -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.do_payment_with_credit_card" color="primary" class="mat-button custom-mat-button-primary">Confirm</button>

    <!-- Set as default Smart Routing -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.set_as_default_smart_routing" color="primary" class="mat-button custom-mat-button-primary">Set As Default</button>

    <!-- Revoke access user -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.revoke_access_user" color="primary" class="mat-button custom-mat-button-primary">Revoke access user</button>

    <!-- enable integration -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.enable_integration" color="primary" class="mat-button custom-mat-button-primary">Confirm</button>

    <!-- Cancel invite user -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.cancel_invite" color="primary" class="mat-button custom-mat-button-primary">Cancel invite user</button>

    <!-- Cancel acount user -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.cancel_account" color="primary" class="mat-button custom-mat-button-primary">Confirm</button>

    <!-- Cancel invoice -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.cancel_invoice" [disabled]="disableButton()" color="accent" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Cancel Invoice</button>

    <!-- delete invoice -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.delete_invoice" [disabled]="disableButton()" color="accent" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Delete Invoice</button>

    <!-- Delete currency -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.delete_currency" color="primary" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Delete currency</button>

    <!-- Delete charge -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.delete_charge" color="primary" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Remove Charge</button>

    <!-- Delete gateway -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.delete_gateway" color="primary" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Delete gateway</button>

    <!-- Delete notification -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.delete_notification" [disabled]="disableButton()" color="primary" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Delete Notification</button>

    <!-- Delete plan -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.delete_plan" [disabled]="disableButton()" color="accent" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Delete plan</button>

    <!-- Delete Product -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.delete_product" [disabled]="disableButton()" color="accent" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Delete Product</button>

    <!-- Delete integration -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.delete_integration" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Disconnect</button>

    <!-- Delete shipping integration -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.delete_integration_shipping" [disabled]="disableButton()" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Disconnect</button>

    <!-- Delete tax integration -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.delete_integration_tax" [disabled]="disableButton()" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Disconnect</button>

    <!-- Disable SMTP integration  -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === this._keyName.disable_SMTP_integration" color="primary" class="mat-button custom-mat-button-primary">Disable</button>

    <!-- Disengage company gateway -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.disengage_company_gateway" color="primary" class="mat-button custom-mat-button-primary">Disengage gateway</button>

    <!-- Reconnect company gateway -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.reconnect_gateway" color="primary" class="mat-button custom-mat-button-primary">Reconnect gateway</button>

    <!-- Remove Charge/Credit -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.remove_charge_credit" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Remove Charge</button>

    <!-- Remove Tax Region -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.delete_tax_region" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Remove Tax Region</button>

    <!-- remove integration -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.remove_integration" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Remove</button>

    <!-- Archive Integration -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" [disabled]="disableButton()" *ngIf="data.keyName === _keyName.archive_integration" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Archive Integration</button>

    <!-- Change Customer status -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" [disabled]="(data.nextStatus !== 'disabled' && data.nextStatus !== 'archived' ? false : disableButton())" *ngIf="data.keyName === _keyName.change_customer_status" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Change customer status</button>

    <!-- Change Customer status -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.enforce_customer_gdpr" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Enforce GDPR</button>

    <!-- Block Account -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" [disabled]="disableButton()" *ngIf="data.keyName === _keyName.block_account" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Block Account</button>

    <!-- Unblock Account -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" [disabled]="disableButton()" *ngIf="data.keyName === _keyName.unblock_account" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Unblock Account</button>

    <!-- merge_customers -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.merge_customers" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Confirm</button>

    <!-- Archive Gateway -->
    <button mat-flat-button (click)="onSubmit(data.code)" [disabled]="disableButton()" *ngIf="data.code === _keyName.change_gateway_status_to_archive" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Archive gateway</button>

    <!-- Enable Gateway -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.change_gateway_status_to_active" color="primary" class="mat-button custom-mat-button-primary">Enable gateway</button>

    <!-- Disabled Gateway -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.change_gateway_status_to_disabled" color="primary" class="mat-button custom-mat-button-primary">Disable gateway</button>

    <!-- Test Avalara configuration -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.test_Avalara_configuration" color="primary" class="mat-button custom-mat-button-primary">Test</button>

    <!-- Test gateway -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.test_gateway" color="primary" class="mat-button custom-mat-button-primary">Test gateway</button>

    <!-- Delete Rate Plan -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" [disabled]="disableButton()" *ngIf="data.keyName === _keyName.delete_rate_plan" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Delete Rate Plan</button>

    <!-- Discontinue Rate Plan -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" [disabled]="disableButton()" *ngIf="data.keyName === _keyName.product_rateplan_discontinue" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Discontinue Rate Plan</button>

    <!-- Delete rate plan Charge -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" [disabled]="disableButton()" *ngIf="data.keyName === _keyName.delete_rate_plan_charge" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Delete Charge</button>

    <!-- Delete external product -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" [disabled]="disableButton()" *ngIf="data.keyName === _keyName.delete_external_product" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Unlink Product</button>

    <!-- Sync modifiers -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" [disabled]="!extraCheckbox" *ngIf="data.keyName === _keyName.sync_modifiers" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Sync</button>

    <!-- Delete external product -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" [disabled]="disableButton()" *ngIf="data.keyName === _keyName.delete_external_charge" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Unlink Charge</button>

    <!-- Delete Charge -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" [disabled]="disableButton()" *ngIf="data.keyName === _keyName.remove_charge" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Delete Charge</button>

    <!-- Remove Charge -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" [disabled]="disableButton()" *ngIf="data.keyName === _keyName.remove_charge_inLine" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Remove Charge</button>

    <!-- Delete Subscription -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" [disabled]="disableButton()" *ngIf="data.keyName === _keyName.delete_subscription" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Delete Subscription</button>

    <!-- Change Subscription status -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.change_subscription_status" color="primary" class="mat-button custom-mat-button-primary">Change Status</button>

    <!-- Skip rate plan charge -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.skip_rate_plan_charge" color="primary" class="mat-button custom-mat-button-primary">Skip Charge</button>

    <!-- Sync Active Plans -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.sync_active_plans" color="primary" class="mat-button custom-mat-button-primary">Sync Active Plans</button>

    <!-- Sync Active Plans -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.set_order_action" color="primary" class="mat-button custom-mat-button-primary">Set External Order</button>

    <!-- Pause bulk product -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.bulk_pause_product" color="primary" class="mat-button custom-mat-button-primary">Pause Product</button>

    <!-- Change price for rate plan charge -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.change_price_for_rate_plan_charge" color="primary" class="mat-button custom-mat-button-primary">Change Price</button>

    <!-- Change shipping price for rate plan charge -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.change_shipping_price_for_rate_plan_charge" color="primary" class="mat-button custom-mat-button-primary">Change Shipping Price</button>

    <!-- Change subscription status to Archived -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" [disabled]="disableButton()" *ngIf="data.keyName === _keyName.archive_subscription" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Archive Subscription</button>

    <!-- Change Subscription rate plan charge status -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.change_subscription_rate_plan_status" color="primary" class="mat-button custom-mat-button-primary">Change Status</button>


    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.activate_lead" color="primary" class="mat-button custom-mat-button-primary">Change Status</button>

    <!-- Change Subscription rate plan charge status -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.change_subscription_rate_plan_charge_status" color="primary" class="mat-button custom-mat-button-primary">Change Status</button>

    <!-- Change Subscription rate plan charge status -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.mark_as_template" color="primary" class="mat-button custom-mat-button-primary">Save As Template</button>

    <!-- Change Subscription rate plan charge status -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.sync_with_template" color="primary" class="mat-button custom-mat-button-primary">Re-Apply Template</button>

      <!-- Change Subscription rate plan charge status -->
      <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.apply_template" color="primary" class="mat-button custom-mat-button-primary">Apply Changes</button>

    <!-- Delete Subscription external rate plan charge -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" [disabled]="disableButton()" *ngIf="data.keyName === _keyName.delete_external_rate_plan_charge" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Unlink External rate plan charge</button>
    <!-- Delete webhook  -->
      <button mat-flat-button (click)="onSubmit(data.keyName)"  *ngIf="data.keyName === _keyName.delete_webhook" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Delete webhook</button>

    <!-- Unlink sale channel for subscription -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" [disabled]="disableButton()" *ngIf="data.keyName === _keyName.subscription_unlink_sale_channel" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Unlink Sale Channel</button>

    <!-- Change product status -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.change_product_status || data.keyName === _keyName.change_products_status" color="primary" class="mat-button custom-mat-button-primary">Change Status</button>

    <!-- Change importer product task status -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.change_importer_product_task_status" color="primary" class="mat-button custom-mat-button-primary">Change Status</button>

    <!-- Change autocollection invoice -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.change_auto_collection_invoice" color="primary" class="mat-button custom-mat-button-primary">Change Auto Collection Status</button>

    <!-- Change product rate plan status -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.change_product_rateplan_status && data.nextStatus !== _ratePlanStatus.archived" color="primary" class="mat-button custom-mat-button-primary">Change Status</button>
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.change_product_rateplan_status && data.nextStatus === _ratePlanStatus.archived" [disabled]="disableButton()"  color="primary" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Archive Rate Plan</button>

    <!-- Execute Billing process now -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.execute_billing_run_now" color="primary" class="mat-button custom-mat-button-primary">Run Now</button>

    <!-- Save rbl scope key list -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.save_rbl_scope_key_list" color="primary" class="mat-button custom-mat-button-primary">Confirm & Save</button>

    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.save_experimental_features" color="primary" class="mat-button custom-mat-button-primary">Confirm</button>

    <!-- Execute Payment process now -->
    <button mat-flat-button (click)="onSubmit(data.keyName)" *ngIf="data.keyName === _keyName.execute_payment_run_now" color="primary" class="mat-button custom-mat-button-primary">Run Now</button>

    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.remove_shipping_protection" color="primary" class="mat-button custom-mat-button-primary">Remove</button>

    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.remove_shipping_zone" color="primary" class="mat-button custom-mat-button-primary">Remove</button>

     <!-- SALES CHANNELS -->
    <!-- remove customer group -->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === _keyName.delete_customer_group" color="primary" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Remove Group</button>

    <!-- Account deactivate-->
    <button mat-flat-button (click)="onSubmit(data.switchCompany)" *ngIf="data.code === this._keyName.account_closed" color="primary" class="mat-button custom-mat-button custom-mat-button-primary" style="margin-bottom: 10px;">Switch Company</button>
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === this._keyName.account_closed" color="primary" class="mat-button custom-mat-button custom-mat-button-primary button-delete" style="margin-bottom: 10px;">Log out</button>
    <!-- Grid export csv-->
    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === this._keyName.export_csv" color="primary" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Start export</button>

    <button mat-flat-button (click)="onSubmit(data.code)" *ngIf="data.code === this._keyName.manually_rerun_payment_run" color="primary" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Re-run</button>
    <!-- Cancel and close dialog -->
    <a mat-button disableRipple="true" *ngIf="!data.hideCancel" (click)="onClose()" class="custom-mat-button button-cancel">{{data.closeBtnText || 'Cancel'}}</a>
  </div>
</div>
