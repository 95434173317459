// angular
import { Injectable, EventEmitter } from '@angular/core';
// rxjs
import { BehaviorSubject, Observable, zip } from 'rxjs';

@Injectable()
export class DataSharingService {

  private dataSource = new BehaviorSubject({});
  currentData = this.dataSource.asObservable();

  private collectionOfServiceUrl: string[] = [];

  private companySource = new BehaviorSubject({companyId: null, companyName: null, type: null, status: null, cancelledAt: null });
  currentCompany = this.companySource.asObservable();

  private companySettingsEnvironment = new BehaviorSubject({environment:null});
  currentCompanySettingsEnvironment = this.companySettingsEnvironment.asObservable();

  private companySettingsCurrencyBaseSource = new BehaviorSubject({currencyBase:null});
  currentCompanySettingsCurrencyBaseSource = this.companySettingsCurrencyBaseSource.asObservable();

  private companySettingsShippingSource = new BehaviorSubject({shipping:null});
  currentCompanySettingsShippingSource = this.companySettingsShippingSource.asObservable();

  private companySettingsTaxSource = new BehaviorSubject({tax:null});
  currentCompanySettingsTaxSource = this.companySettingsTaxSource.asObservable();

  private companySettingsTimezone = new BehaviorSubject({});
  currentCompanySettingsTimezone = this.companySettingsTimezone.asObservable();

  private companyPaginator = new BehaviorSubject({});
  currentCompanyPaginator = this.companyPaginator.asObservable();

  private customerSource = new BehaviorSubject({ customerId: null, customerName: null, customerEmail: null, customerPhone: null });
  currentCustomer = this.customerSource.asObservable();

  private activeLeadSource = new BehaviorSubject({});
  currentLead = this.activeLeadSource.asObservable();

  private formSource = new BehaviorSubject({});
  currentForm = this.formSource.asObservable();

  private productSource = new BehaviorSubject({ productId: null, productName: null });
  currentProduct = this.productSource.asObservable();

  private previousUrlSource = new BehaviorSubject({});
  previousUrl = this.previousUrlSource.asObservable();

  private displayDateFormat = new BehaviorSubject({});
  currentDisplayDateFormat = this.displayDateFormat.asObservable();

  private decimalPlaces = new BehaviorSubject({});
  currentDecimalPlaces = this.decimalPlaces.asObservable();

  private decimalToken = new BehaviorSubject({});
  currentDecimalToken = this.decimalToken.asObservable();

  private thousandsToken = new BehaviorSubject({});
  currentThousandsToken = this.thousandsToken.asObservable();

  private factoringDimension = new BehaviorSubject({});
  currentFactoringDimension = this.factoringDimension.asObservable();

  private lengthMeasurement = new BehaviorSubject({});
  currentLengthMeasurement = this.lengthMeasurement.asObservable();

  private weightMeasurement = new BehaviorSubject({});
  currentWeightMeasurement = this.weightMeasurement.asObservable();

  private settingsVariables = new BehaviorSubject({});
  currentSettingsVariables: any = this.settingsVariables.asObservable();

  public actionClicked = new EventEmitter<string>()

  //-------
  //GETTERS
  //-------
  getCompany(): Observable<any> {
    return this.currentCompany;
  }

  getCompanyPaginator(): Observable<any> {
    return this.currentCompanyPaginator;
  }

  getCompanySettingsEnvironment(): Observable<any> {
    return this.currentCompanySettingsEnvironment;
  }

  getCompanySettingsCurrencyBaseSource(): Observable<any> {
    return this.currentCompanySettingsCurrencyBaseSource;
  }

  getCompanySettingsShippingSource(): Observable<any> {
    return this.currentCompanySettingsShippingSource;
  }

  getCompanySettingsTaxSource(): Observable<any> {
    return this.currentCompanySettingsTaxSource;
  }

  getCompanySettingsTimezone(): Observable<any> {
    return this.currentCompanySettingsTimezone;
  }

  getCustomer(): Observable<any> {
    return this.currentCustomer;
  }

  getActiveLead(): Observable<any> {
    return this.currentLead;
  }

  getForm(): Observable<any> {
    return this.currentForm;
  }

  getProduct(): Observable<any> {
    return this.currentProduct;
  }

  getObservable(): Observable<any> {
    return this.currentData;
  }

  getPreviousUrl(): Observable<any> {
    return this.previousUrl;
  }

  isNoFormatResponse(serviceUrl: string): boolean {
    return this.collectionOfServiceUrl.some(url => url == serviceUrl);
  }

  getDisplayDateFormat(): Observable<any> {
    return this.currentDisplayDateFormat;
  }

  getDecimalPlaces(): Observable<any> {
    return this.currentDecimalPlaces;
  }

  getThousandsToken(): Observable<any> {
    return this.currentThousandsToken;
  }

  getDecimalToken(): Observable<any> {
    return this.currentDecimalToken;
  }


  getFactoringDimension(): Observable<any> {
    return this.currentFactoringDimension;
  }

  getLengthMeasurement(): Observable<any> {
    return this.currentLengthMeasurement;
  }

  getWeightMeasurement(): Observable<any> {
    return this.currentWeightMeasurement;
  }

  getSettingsVariables(): Observable<any> {
    this.currentSettingsVariables = zip(this.getDecimalPlaces(), this.getDecimalToken(), this.getThousandsToken(), this.getFactoringDimension(), this.getLengthMeasurement(), this.getWeightMeasurement());

    return this.currentSettingsVariables;
  }

  //-------
  //SETTERS
  //-------
  setCompany(_value:any){
    this.companySource.next(_value);
  }

  setCompanySettingsEnvironment(_value:any){
    this.companySettingsEnvironment.next(_value);
  }

  setCompanySettingsCurrencyBaseSource(_value:any){
    this.companySettingsCurrencyBaseSource.next(_value);
  }

  setCompanySettingsShippingSource(_value:any){
    this.companySettingsShippingSource.next(_value);
  }

  setCompanySettingsTaxSource(_value:any){
    this.companySettingsTaxSource.next(_value);
  }

  setCompanySettingsTimezone(_value:any){
    this.companySettingsTimezone.next(_value);
  }

  setCompanyTableRecordsPerPage(_value:any) {
    this.companyPaginator.next(_value);
  }

  setCustomer(_value: any) {
    this.customerSource.next(_value);
  }

  setActiveLead(_value: any) {
    this.activeLeadSource.next(_value);
  }

  setForm(_value: any) {
    this.formSource.next(_value);
  }

  setObservable(_data:any) {
    this.dataSource.next(_data);
  }

  setProduct(_value: any) {
    this.productSource.next(_value);
  }

  setPreviousUrl(_value: string) {
    this.previousUrlSource.next(_value);
  }

  setNoFormatResponse(serviceUrl: string) {
    this.collectionOfServiceUrl.push(serviceUrl);
  }

  setDisplayDateFormat(_value: string) {
    this.displayDateFormat.next(_value);
  }

  setDecimalPlaces(_value: string) {
    this.decimalPlaces.next(_value);
  }

  setDecimalToken(_value: string) {
    this.decimalToken.next(_value);
  }

  setThousandsToken(_value: string) {
    this.thousandsToken.next(_value);
  }

  setSettingsVariables(_value: any) {
    this.settingsVariables.next(_value);
  }

  setFactoringDimension(_value: string) {
    this.factoringDimension.next(_value);
  }

  setLengthMeasurement(_value: string) {
    this.lengthMeasurement.next(_value);
  }

  setWeightMeasurement(_value: string) {
    this.weightMeasurement.next(_value);
  }

  //TODO: Remove an replace
  private data: any;

  getData(): any {
    return this.data;
  }

  setData(data: any) {
    this.data = data;
  }

  getDateConfig(): any{
    return {companySettingsTimezone:this.companySettingsTimezone.value, displayDateFormat: this.displayDateFormat.value};
  }
}
