import { Component, Input, TemplateRef } from '@angular/core';
import { AppAnimations } from 'src/app/_shared/animations';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  animations: [AppAnimations],
  host: { '[@smoothAppearance]': '', '[@slideInRightAnimation]': '' }
})
export class DialogComponent {
  @Input() dialogHead: TemplateRef<any>;
  @Input() dialogBody: TemplateRef<any>;
  @Input() dialogNav: TemplateRef<any>;
}
