//angular
import { Component, Input } from '@angular/core';
import { AppAnimations } from '../animations';

@Component({
  selector: 'skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
  animations: [ AppAnimations ],
  host: { 'class': 'skeleton-loader' }
})
export class SkeletonLoaderComponent {
  @Input()
  theme: { [k: string]: string } = {};
}
