
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from '../services';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private _utils: UtilsService,
    ) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let body = new FormData();
    if (httpRequest.method == 'POST' || httpRequest.method == 'PUT') {
      if (httpRequest.body instanceof Object) {
        // used for date format
        body = this._utils.iterateBody(httpRequest.body, this._utils.isDateValid.bind(this._utils), this._utils.formatDateToSend.bind(this._utils));
        // used for decimal format
        this._utils.iterateBody(body, this._utils.isDecimalField.bind(this._utils),this._utils.formatDecimalNumberToSend.bind(this._utils));
      }
    }

    const reqCloned = httpRequest.clone({ body: body ? body : httpRequest.body });
    return next.handle(reqCloned);
  }
}
