<div class="card">
  <div class="card-wrapper">
    <div class="card-container">
      <div class="card-body">
        <div class="card-body-container">
          <div class="card-image">
            <figure>
              <img src="assets/svg/{{_bodyImage}}"/>
            </figure>
          </div>
          <div class="card-title">
            <h1 class="typo subheading-2 semi-condensed fw300 label-title">{{_bodyTitle}}</h1>
          </div>
          <div class="card-description">
            <p class="typo caption semi-condensed fw300 label-description">{{_bodyDescription}}</p>
          </div>
        </div>
      </div>
      <nav class="nav">
        <div class="nav-wrapper">
          <div class="nav-container">
            <element-button-secondary
              [text]="_linkText"
              (click)="goToPath(_linkPath)"
            ></element-button-secondary>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
