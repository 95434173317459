import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler) {
    const started = Date.now();
    let ok: string;

    // extend server response observable with logging
    return next.handle(httpRequest).pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        (event: HttpEvent<any>) => ok = event instanceof HttpResponse ? 'SUCCEEDED' : 'CANCELED',
        // Operation failed; error is an HttpErrorResponse
        (error: HttpErrorResponse) => ok = `FAILED -> errors: ${error.message}`
      ),
      // Log when response observable either completes or errors
      finalize(() => {
        let style: string;
        if (ok == 'SUCCEEDED') {
          style = 'color: green; background-color: aquamarine;';
        } else {
          style = 'color: darkred; background-color: darksalmon;';
        }
        const elapsed = Date.now() - started;
        const msg = `%c${httpRequest.method} "${httpRequest.urlWithParams}" ${ok} in ${elapsed} ms.`;
        console.log(msg, style);
      })
    );
  }
}
