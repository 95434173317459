//angular
import { Injectable } from "@angular/core";
//others
import { Observable } from "rxjs";
import { TaxClassValues } from "src/app/app.data";
//app
import { Address, CompanyIntegrationModel, IntegrationSettings, RestResponse, TaxItem } from "src/app/_shared/models";
import { TaxDefaultClasses } from "src/app/_shared/models/tax.model";
import { SharedGlobalService } from "./api/shared-global.service";
import { SharedIntegrationService } from "./api/shared-integration.service";


//const
export const enum AvalaraDocumentType {
  salesInvoice = 'SalesInvoice',
  salesOrder = 'SalesOrder',
}
//Interfaces
export interface TaxNewTransactionModel {
  address: TaxTransactionAddressModel,
  customerCode: string,
  entityUseCode?: string,
  items: Array<TaxItem>
}
export interface TaxTransactionAddressModel {
  street1: string,
  street2: string,
  street3: string,
  city: string,
  state: string,
  zip: number,
  country: string
}

@Injectable()
export class TaxService {

  constructor(
    private sharedIntegrationService: SharedIntegrationService,
    private sharedGlobalService: SharedGlobalService,
  ) {}

  public calculateTaxTransaction(_customer: string, _address: Address, _items: Array<any>, _entityUseCode = null, _shippingItem = null): Observable<any> {
    const address: TaxTransactionAddressModel = {
      street1: (_address.street1 == null ? '' : _address.street1),
      street2: (_address.street2 == null ? '' : _address.street2),
      street3: '',
      city: (_address.city == null ? '' : _address.city),
      state: (_address.state == null ? '' : _address.state),
      zip: Number(_address.zip),
      country: _address?.country?.alpha2Code
    }

    const items: Array<TaxItem> = [];
    _items.forEach((element: any) => {
      let taxItem: TaxItem = new TaxItem();
      taxItem.amount = String(element.amount);
      taxItem.quantity = String(element.qty);
      taxItem.itemCode = null;
      taxItem.taxCode = (element.taxClass ? element.taxClass : (element.taxCode ? element.taxCode : null));
      items.push(taxItem);
    });

    if (_shippingItem){
      items.push(_shippingItem);
    }

    const body: TaxNewTransactionModel = {
      address: address,
      customerCode: _customer,
      items: items
    }

    if(_entityUseCode){
      body.entityUseCode = _entityUseCode
    }

    return new Observable(
      (observer) => {
        this.sharedGlobalService.createTaxTransaction(body).subscribe(
        (res: any) => {
          observer.next(res);
        },
        (err: any) => {
          observer.error(err);
        }
      )}
    )
  }

  public getTaxSettings(_taxIntegrationId: number): Observable<any>  {
    return new Observable(
      (observer) => {
      this.sharedIntegrationService.getIntegrationSettingsByIntegrationId(_taxIntegrationId)
        .subscribe(
          (res: RestResponse) => {
            let companyIntegration: CompanyIntegrationModel = new CompanyIntegrationModel().deserialize(res.data);
            let data: TaxDefaultClasses = new TaxDefaultClasses;
            companyIntegration.integrationSetting.forEach((_integrationSetting:IntegrationSettings) => {
              switch (_integrationSetting.keyName) {
                case TaxClassValues.codeDefaultTaxable:
                  data.taxCodeDefaultTaxable = _integrationSetting.value
                  break;
                case TaxClassValues.codeShipping:
                  data.taxCodeShipping = _integrationSetting.value;
                  break;
              }
            })
            observer.next(data);
          },
          (err: RestResponse) => {
            observer.error(err);
          }
        )
    })

  }

}
