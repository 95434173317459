//angular
import { Component, OnInit, OnDestroy } from '@angular/core';
//app
import {AppData} from '../../../../app.data';
import { listAnimate, listItemAnimate, systemMessages } from 'src/app/_shared/animations';
import { LoaderService } from '../../../../_core/services';
import { RestResponse } from '../../../../_shared/models';
import { SystemMessage } from '../../../../_shared/models';

import { MainService } from '../_services/api/main.service';
import { MetaService } from 'src/app/_core/services/meta.service';
import { Unsubscriber } from 'src/app/_shared/unsubscriber/unsubscriber';
import {SharedGlobalService} from "../../../../_core/services/api";
import {HttpParamsInterface} from "../../../../_shared/interfaces/http-params.interface";

@Component({
  selector: 'export-notification',
  templateUrl: './export-notification.component.html',
  animations: [listAnimate, listItemAnimate, systemMessages ]
})

export class ExportNotificationComponent implements OnInit, OnDestroy {
  collectionOfMessage: Array<SystemMessage> = [];
  systemMessagesStatus: string = 'isHidden';
  resSuccess: boolean;
  private subs = new Unsubscriber();

  constructor(
    private _data: AppData,
    private _loader: LoaderService,
    public _meta: MetaService,
    private mainService: MainService,
    private sharedGlobalService: SharedGlobalService,
) { }

  getNotifications(status: string) {
    const httpParams: HttpParamsInterface = {
      orderBy: 'createdAt',
      sortBy: 'ASC',
      status: status
    }
    this.subs.addSub = this.sharedGlobalService.getExportNotificationsStream(httpParams).subscribe(
      (res: RestResponse) => {
        this.systemMessagesStatus = (res.data ? 'isVisible' : 'isHidden');
        this.collectionOfMessage = <any[]>res.data;
        this.resSuccess = res.isSuccess;
      },
      (err: RestResponse) => {
        this.resSuccess = err.isSuccess;
        this._meta.showNotification(err);
      }
    );
  }


  ngOnInit() {
    this.getNotifications('unread');
  }

  updateNotificationStatus($event, notificationId: number) {
    $event.stopPropagation();
    this.subs.addSub = this.sharedGlobalService.updateNotificationStatus(notificationId).subscribe(
      (res: any) => {
        this.collectionOfMessage = this.collectionOfMessage.filter(notification => notification.id !== res.data.id);
        this.systemMessagesStatus = (this.collectionOfMessage.length > 0 ? 'isVisible' : 'isHidden');
        this._loader.stopPageLoader();
        this._meta.showNotification(res);
      },
      (err: RestResponse) => {
        this._loader.stopPageLoader();
        this._meta.showNotification(err);
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
