import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { UtilsService } from 'src/app/_core/services';

interface ValidatorValue {
  requiredLength?: number;
  max?: number;
  min?: number;
}

@Injectable()
export class ValidatorService {
  constructor(private utils: UtilsService) {}

  // TODO: this methiod will be used for the new form elements!!!
  public getFormControlErrorMessage(errors) {
    if (!this.utils.isEmpty(errors)) {
      for (let propertyName in errors) {
        if (errors.hasOwnProperty(propertyName)) {
          return this.getValidatorErrorMessage(propertyName, errors[propertyName]);
        }
      }
    }
    return null;
  }

  public getValidatorErrorMessage(name: string, value?: ValidatorValue) {
    let config = {
      'required': 'Required',
      'invalidPositiveIntegerNumber': 'Value should be a positive integer number',
      'minlength': `Minimum length ${value.requiredLength} characters`,
      'maxlength': `Maximum length ${value.requiredLength} characters`,
      'max': `Value should not to be greater than ${value.max} `,
      'min': `Value should not to be lower than ${value.min} `,
      'emptySpace': 'Value cannot be empty',
      'incompleteMaskedInput': 'Incomplete field',
      'invalidEmail': 'Invalid Email',
      'invalidWebSite': 'Invalid Web Site',
      'nonZero' :'Value should be greater than 0',
      'invalidPositiveNumber' : 'Value should be a positive number',
      'invalidDay': 'Wrong day for selected month',
      'invalidDayFor30DaysMonth': 'Selected month has 30 days',
      'invalidDayForFebruary': 'Wrong day for February',
      'containsComma' :'Make sure to avoid using any quotation marks in the input.',
    };
    return config[name];
  }

  public email(control: any): ValidationErrors | null {
    const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ((control.value !== null && String(control.value).match(EMAIL_REGEX)) || !control.value) {
      return null;
    } else {
      return { 'invalidEmail': true };
    }
  }

  // NOTE: do not use with input of type number
  public emptySpace(control: any): ValidationErrors | null {
    if ((control.value !== null && typeof control.value == 'string' ? control.value.replace(/\s/g, '').length > 0 : true) || !control.value) {
      return null;
    } else {
      return { 'emptySpace': true }
    }
  }

  public doesntContainQuotes(control: any): ValidationErrors | null {
    const REGEX = /'|"/;
    if ((control.value !== null && !String(control.value).match(REGEX))) {
      return null;
    } else {
      return { 'containsComma': true };
    }
  }

  public positiveDecimalNumberIncludeZero(control: any): ValidationErrors | null {
    const REGEX = /^(\d+|\d{1,3}(,\d{3})*)(0|([1-9](\d*|\d{0,2}(,\d{3})*)))?(\.\d{1,4})?(?!\S)/;
    if ((control.value !== null && String(control.value).match(REGEX)) || !control.value) {
      return null;
    } else {
      return { 'invalidPositiveNumber': true };
    }
  }

  public positiveDecimalNumberExcludeZero(control: any): ValidationErrors | null {
    const REGEX = /^(?=.*[1-9])(0|([1-9](\d*|\d{0,2}(,\d{3})*)))?(\.\d{1,4})?(?!\S)?(?!\S)/;
    if ((control.value !== null && String(control.value).match(REGEX))) {
      return null;
    } else {
      return { 'nonZero': true };
    }
  }

  public positiveIntegerNumberExcludeZero(control: any): ValidationErrors | null {
    const REGEX = /^\+?([1-9]\d*)$/g;
    if ((control.value !== null && String(control.value).match(REGEX))) {
      return null;
    } else {
      return { 'invalidPositiveIntegerNumber': true };
    }
  }

  public website(control: any): ValidationErrors | null {
    const WEBSITE_REG = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/;
    if ((control.value !== null && String(control.value).match(WEBSITE_REG)) || !control.value) {
      return null;
    } else {
      return { 'invalidWebSite': true };
    }
  }
}
