<div class="skeleton">
  <div class="form-row">
    <mat-form-field class="custom-mat-form-field size size1of1">
      <input matInput disabled>
      <skeleton-loader [theme]="{'width': '30%'}"></skeleton-loader>
    </mat-form-field>
  </div>
  <div class="form-row">
    <mat-form-field *ngIf="_isPaymentGatewayVisible" class="custom-mat-form-field size size1of2">
      <input matInput disabled>
      <skeleton-loader [theme]="{'width': '40%'}"></skeleton-loader>
    </mat-form-field>
    <mat-form-field class="custom-mat-form-field size size1of4">
      <input matInput disabled>
      <skeleton-loader [theme]="{'width': '40%'}"></skeleton-loader>
    </mat-form-field>
    <mat-form-field class="custom-mat-form-field size size1of4">
      <input matInput disabled>
      <skeleton-loader [theme]="{'width': '40%'}"></skeleton-loader>
    </mat-form-field>
    <mat-form-field class="custom-mat-form-field size size1of4">
      <input matInput disabled>
      <skeleton-loader [theme]="{'width': '40%'}"></skeleton-loader>
    </mat-form-field>
  </div>
</div>