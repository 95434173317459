import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'element-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() _bodyDescription: string;
  @Input() _bodyImage: string;
  @Input() _bodyTitle: string;
  @Input() _linkPath: string;
  @Input() _linkText: string;

  @Output() _goToPath: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  goToPath(_linkPath){
    this._goToPath.emit(_linkPath);
  }

  ngOnInit(): void {
  }

}
