import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DataSharingService, RestService, StorageService } from 'src/app/_core/services';
import { POST_ChargesInterface } from 'src/app/_shared/interfaces/charges.interface';
import { POST_CustomerPaymentSourceInterface } from 'src/app/_shared/interfaces/customer.interface';
import { HttpParamsInterface } from 'src/app/_shared/interfaces/http-params.interface';
import { POST_AddressInterface, Put_AddressInterface, RestResponse } from 'src/app/_shared/models';
import { environment } from 'src/environments/environment';
import { HttpParamService } from './http-param.service';

const APIEndpoint: string = environment.APIEndpoint;
const APIVersion: string = environment.APIVersion;
const APIResourceCompanies = '/companies';
const APIResourceCostumers = '/customers';

@Injectable({
  providedIn: 'root'
})
export class SharedCustomerService {

  subscription: Subscription;
  public serviceUrl_Customers: string;

  constructor(
    private httpParamService: HttpParamService,
    private _shareData: DataSharingService,
    private _storage: StorageService,
    private _rest: RestService
  ) {
      let entityId: string;

      this.subscription = this._shareData.currentCompany.subscribe(data => {
        if (data.companyId){
          entityId = `/${data.companyId}`;
        } else {
          entityId = `/${this._storage.readLocalStorage('entityId')}`;

        }
      // Customers
      // /companies/{{companyId}}/customers
      this.serviceUrl_Customers = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceCostumers;
    });
  }

  public getLogsByCustomerId(customerId: number, noFormat: boolean): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Customers}/${customerId}/logs`, noFormat);
  }

  //addressByClient
  public createAddressBookByCustomerId(customerId: number,_bodyParams: POST_AddressInterface): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_Customers}/${customerId}/addressbooks`, _bodyParams);
  }

  public deleteAddressbookByCustomerId(customerId: number, addressIdToDelete): Observable<RestResponse> {
    return this._rest.delete(`${this.serviceUrl_Customers}/${customerId}/addressbooks/${addressIdToDelete}`);
  }

  public getAddressBookByCustomerId(customerId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Customers}/${customerId}/addressbooks`);
  }

  public getAddressByAddressbookId(customerId: number, addressBookId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Customers}/${customerId}/addressbooks/${addressBookId}`);
  }

  public updateAddressByAddressbookId(customerId: number, addressId: number, _bodyParams: Put_AddressInterface): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Customers}/${customerId}/addressbooks/${addressId}`, _bodyParams);
  }

  //invoices
  public getInvoicesByCustomerId(customerId: number, pathParams: HttpParamsInterface): Observable<RestResponse> {
    let params = this.httpParamService.buildPathParams(pathParams);
    return this._rest.get(`${this.serviceUrl_Customers}/${customerId}/invoices`, { params: params } );
  }

  //transactions
  public getTransactionsByCustomerId(customerId: number, pathParams: HttpParamsInterface, noFormat: boolean): Observable<RestResponse> {
    let params = this.httpParamService.buildPathParams(pathParams);
    return this._rest.get(`${this.serviceUrl_Customers}/${customerId}/transactions`, { params: params }, noFormat);
  }

  //changesCredits
  public createChangesCreditsByCustomerId(customerId: number,_bodyParams: POST_ChargesInterface): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_Customers}/${customerId}/charges_credits`, _bodyParams);
  }

  public deleteChangesCreditsByCustomerId(customerId: number, chargeCreditId: number): Observable<RestResponse> {
    return this._rest.delete(`${this.serviceUrl_Customers}/${customerId}/charges_credits/${chargeCreditId}`);
  }

  public getCustomerProfile(customerId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Customers}/${customerId}`);
  }

  //searchByCustomer
  public getCustomerByQuery(queryToSearch: string, pathParams?: HttpParamsInterface): Observable<RestResponse> {
    let params = pathParams ? this.httpParamService.buildPathParams(pathParams) : null;
    return this._rest.get(`${this.serviceUrl_Customers}?query=${queryToSearch}`,{params: params});
  }

  // get ChangesCreditsByCustomerId
  public getChangesCreditsByCustomerId(customerId: number, pathParams: HttpParamsInterface): Observable<RestResponse> {
    let params = this.httpParamService.buildPathParams(pathParams);
    return this._rest.get(`${this.serviceUrl_Customers}/${customerId}/charges_credits`, { params: params } );
  }

  //paymentmethods
  //payments methods by CustomerId
  public getPaymentMethodsByCustomerId(customerId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Customers}/${customerId}/paymentmethods`);
  }

  public getPaymentMethodByPaymentId(customerId: number, paymentmethods: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Customers}/${customerId}/paymentmethods/${paymentmethods}`);
  }

  public createPaymentMethodsByCustomerId(customerId: number,_bodyParams: POST_CustomerPaymentSourceInterface): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_Customers}/${customerId}/paymentmethods`, _bodyParams);
  }

  public deletePaymentMethodsByCustomerId(customerId: number, paymentIdToDelete): Observable<RestResponse> {
    return this._rest.delete(`${this.serviceUrl_Customers}/${customerId}/paymentmethods/${paymentIdToDelete}`);
  }

  public updatePaymentMethod(customerId: number, paymentSourceId: number, _bodyParams: any): Observable<any> {
    return this._rest.put(`${this.serviceUrl_Customers}/${customerId}/paymentmethods/${paymentSourceId}`, _bodyParams);
  }

}
