import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ContentChild, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl, Validators } from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { map, Observable, startWith, Subject } from 'rxjs';
/** Data structure for holding telephone number. */
export class SearchAutocomplete {
  constructor(public searchSel: any) {}
}

import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appMatSelectSearchOption]',
})
export class MatSelectSearchOptionDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}
@Directive({
  selector: '[appMatSelectSearchTrigger]',
})
export class MatSelectSearchTriggerDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Component({
  selector: 'app-mat-select-search',
  templateUrl: './mat-select-search.component.html',
  styleUrls: ['./mat-select-search.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: MatSelectSearchComponent}],
    host: {
      '[class.example-floating]': 'shouldLabelFloat',
      '[id]': 'id',
    },
})

  export class MatSelectSearchComponent implements OnInit,ControlValueAccessor, MatFormFieldControl<SearchAutocomplete>, OnDestroy {
    static nextId = 0;
    @ViewChild('searchSel') searchSelInput: HTMLInputElement;

    parts = this._formBuilder.group({
      searchSel: ['', [Validators.required]]
    });
    stateChanges = new Subject<void>();
    focused = false;
    touched = false;
    controlType = 'example-tel-input';
    id = `example-tel-input-${MatSelectSearchComponent.nextId++}`;
    onChange = (_: any) => {};
    onTouched = () => {};

    get empty() {
      const {
        value: {searchSel},
      } = this.parts;

      return !searchSel;
    }

    get shouldLabelFloat() {
      return this.focused || !this.empty;
    }

    @Input('aria-describedby') userAriaDescribedBy: string;

    @Input()
    get placeholder(): string {
      return this._placeholder;
    }
    set placeholder(value: string) {
      this._placeholder = value;
      this.stateChanges.next();
    }
    private _placeholder: string;

    @Input()
    get required(): boolean {
      return this._required;
    }
    set required(value: BooleanInput) {
      this._required = coerceBooleanProperty(value);
      this.stateChanges.next();
    }
    private _required = false;

    @Input()
    get disabled(): boolean {
      return this._disabled;
    }
    set disabled(value: BooleanInput) {
      this._disabled = coerceBooleanProperty(value);
      this._disabled ? this.parts.disable() : this.parts.enable();
      this.stateChanges.next();
    }
    private _disabled = false;

    @Input()
    get value(): SearchAutocomplete | null {
      if (this.parts.valid) {
        const {
          value: {searchSel},
        } = this.parts;
        return new SearchAutocomplete(searchSel!);
      }
      return null;
    }
    set value(tel: SearchAutocomplete | null) {
//       let { searchSel } = tel || new SearchAutocomplete('');
//       if(!this.valueSel) {
//         searchSel = tel;
//       }
//      if(!searchSel) searchSel = null;
      let searchSel = tel;
      this.parts.setValue({searchSel});
      this.stateChanges.next();
    }

    get errorState(): boolean {
      return this.parts.invalid && this.touched;
    }

    constructor(
      private _formBuilder: FormBuilder,
      private _focusMonitor: FocusMonitor,
      private _elementRef: ElementRef<HTMLElement>,
      @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
      @Optional() @Self() public ngControl: NgControl,
    ) {
      if (this.ngControl != null) {
        this.ngControl.valueAccessor = this;
      }
    }

    ngOnDestroy() {
      this.stateChanges.complete();
      this._focusMonitor.stopMonitoring(this._elementRef);
    }

    onFocusIn(event: FocusEvent) {
      if (!this.focused) {
        this.focused = true;
        this.stateChanges.next();
      }
    }

    onFocusOut(event: FocusEvent) {
      if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
        this.touched = true;
        this.focused = false;
        this.onTouched();
        this.stateChanges.next();
      }
    }

    autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
      if (!control.errors && nextElement) {
        this._focusMonitor.focusVia(nextElement, 'program');
      }
    }

    autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
      if (control.value.length < 1) {
        this._focusMonitor.focusVia(prevElement, 'program');
      }
    }

    setDescribedByIds(ids: string[]) {
      const controlElement = this._elementRef.nativeElement.querySelector(
        '.example-tel-input-container',
      )!;
      controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick() {
      this._focusMonitor.focusVia(this.searchSelInput, 'program');
    }

    writeValue(tel: SearchAutocomplete | null): void {
      this.value = tel;
    }

    registerOnChange(fn: any): void {
      this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
      this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
      this.disabled = isDisabled;
    }

    _handleInput(control: AbstractControl): void {
      this.onChange(this.value.searchSel);
    }

    // Autoselect implementation
    filteredOptions: Observable<any[]>;
    // @Input() items = [];
    @Input() valueSel: string;
    @Input() labelSel: string = 'name';
    @Input() multiple: boolean = false;
    @ContentChild(MatSelectSearchOptionDirective) content!: MatSelectSearchOptionDirective;
    @ContentChild(MatSelectSearchTriggerDirective) triggerContent!: MatSelectSearchTriggerDirective;

    _items = []
    @Input()
    get items(): any[] {
      return this._items;
    }

    set items(items: any[]) {
      this._items = items;
      const firstElement = items[0]
      if(firstElement && "priority" in firstElement && !firstElement.sorted) {
        // sort array
        this.items.forEach(element => {
          element.sorted = true;
          if(!element.priority) element.priority = 999;
        });

        this.items = this.items.sort((p1,p2)=>(p1.priority > p2.priority) ? 1 : (p1.priority < p2.priority) ? -1 : 0)
      }
      this.value = this.parts.value.searchSel;
    }

    ngOnInit() {
      this.filteredOptions = this.parts.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );
    }

    private _filter(value: any): string[] {
      if(value.searchSel && !this.valueSel){
        return this.items.filter(option => this.getValueByKey(this.labelSel, option).toLowerCase().includes(value.searchSel));
      }

      if(value.searchSel && Number.isInteger(value.searchSel )) {
        return  this.items.filter(item=>this.getValueByKey(this.valueSel, item) == value.searchSel)
      }
      const filterValue = value.searchSel ? value.searchSel.toLowerCase(): '';
      return this.items.filter(option => this.getValueByKey(this.labelSel, option).toLowerCase().includes(filterValue));
    }

    displayFn = (id: any): string=> {
      if(!this.valueSel) {
        return this.getValueByKey(this.labelSel, id)
      }
      const selectedElement = this.items.find(item=>this.getValueByKey(this.valueSel, item) == id);
      return selectedElement? this.getValueByKey(this.labelSel, selectedElement): null
    }

    getValueByKey(nestedKeys, object){
      if(!nestedKeys) {
        return object;
      }
      if(!object) return undefined;

      const arrayKeys = nestedKeys.split('.');
      const key = arrayKeys[0];

      if(arrayKeys.length == 1){
        return  object[key];
      }

      arrayKeys.shift();
      return this.getValueByKey(arrayKeys.join('.'), object[key])

    }
  }
