import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DataSharingService, RestService, StorageService } from 'src/app/_core/services';
import { HttpParamsInterface } from 'src/app/_shared/interfaces/http-params.interface';
import { RestResponse } from 'src/app/_shared/models';
import { NewProductRatePlan, NewProductRatePlanCharge } from 'src/app/_shared/models/product.model';
import { environment } from 'src/environments/environment';
import { HttpParamService } from './http-param.service';

const APIEndpoint: string = environment.APIEndpoint;
const APIVersion: string = environment.APIVersion;
const APIResourceCompanies = '/companies';
const APIResourceProductRatePlans: string = '/product-rateplans';
const APISingleResourceProductRatePlans: string = '/products';
const APIResourceProductRatePlanCharges: string = '/product-rateplan-charges';
const APIResourceProductRatePlanTemplates: string = '/product-rateplan-template';

@Injectable({
  providedIn: 'root'
})
export class SharedProductRatePlanService {

  private subscription: Subscription;
  private serviceUrl_ProductsRatePlans: string;
  public serviceUrl_ProductRatePlanCharges: string;
  public serviceUrl_SingleProductsRatePlans: string;
  private serviceUrl_ProductsRatePlanTemplates: string;
  constructor(
    private httpParamService: HttpParamService,
    private _shareData: DataSharingService,
    private _storage: StorageService,
    private _rest: RestService
  ) {
    let entityId: string;

    this.subscription = this._shareData.currentCompany.subscribe(data => {
      if (data.companyId) {
        entityId = `/${data.companyId}`;
      } else {
        entityId = `/${this._storage.readLocalStorage('entityId')}`;
      }

      // PRODUCT RATE PLANS
      //[GET] [POST] [PUT]
      // /companies/{{companyId}}/product-rateplans
      this.serviceUrl_ProductsRatePlans = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceProductRatePlans;

      this.serviceUrl_ProductsRatePlanTemplates = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceProductRatePlanTemplates;

      // /products
      this.serviceUrl_SingleProductsRatePlans = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APISingleResourceProductRatePlans;
      // PRODUCT RATE PLAN CHARGES
      // /companies/{{companyId}}/product-rateplan-charges
      this.serviceUrl_ProductRatePlanCharges = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceProductRatePlanCharges;

    });
  }

  public getProductRatePlanByRatePlanId(ratePlanId: number, pathParams: HttpParamsInterface, isTemplate?: any): Observable<RestResponse> {
    let params = this.httpParamService.buildPathParams(pathParams);
    if(isTemplate){
      return this._rest.get(`${this.serviceUrl_ProductsRatePlanTemplates}/${ratePlanId}`, { params: params });
    } else {
      return this._rest.get(`${this.serviceUrl_ProductsRatePlans}/${ratePlanId}`, { params: params });
    }
  }

  // /{product}/product-rateplans
  public getProductRatePlans(productId, pathParams: HttpParamsInterface): Observable<RestResponse> {
    let params = this.httpParamService.buildPathParams(pathParams);
    return this._rest.get(`${this.serviceUrl_SingleProductsRatePlans}/${productId}/product-rateplans`, { params: params });
  }

  public createProductRatePlan(_bodyParams: NewProductRatePlan, isTemplate?: boolean): Observable<RestResponse> {
    if(isTemplate){
      delete _bodyParams.productId;
      return this._rest.post(`${this.serviceUrl_ProductsRatePlanTemplates}/`, _bodyParams);
    } else {
      return this._rest.post(`${this.serviceUrl_ProductsRatePlans}`, _bodyParams);
    }
  }

  public updateProductRatePlanByRatePlanId(ratePlanId: number, _bodyParams: NewProductRatePlan, isTemplate?: boolean): Observable<any> {
    if(isTemplate){
      delete _bodyParams.productId;
      return this._rest.put(`${this.serviceUrl_ProductsRatePlanTemplates}/${ratePlanId}`, _bodyParams);
    } else {
      return this._rest.put(`${this.serviceUrl_ProductsRatePlans}/${ratePlanId}`, _bodyParams);
    }
  }

  public getProductRatePlanChargesByRatePlanId(ratePlanId: number, pathParams: HttpParamsInterface): Observable<RestResponse> {
    let params = this.httpParamService.buildPathParams(pathParams);
    return this._rest.get(`${this.serviceUrl_ProductsRatePlans}/${ratePlanId}/product-rateplan-charges`, { params: params });
  }

  /**Templates */
  public getProductRatePlanChargesByRatePlanTemplateId(ratePlanId: number, pathParams: HttpParamsInterface): Observable<RestResponse> {
    let params = this.httpParamService.buildPathParams(pathParams);
    return this._rest.get(`${this.serviceUrl_ProductsRatePlanTemplates}/product-rateplans/${ratePlanId}/product-rateplan-charges`, { params: params });
  }
  public updateProductRatePlanByRatePlanTemplateId(ratePlanId: number, _bodyParams: NewProductRatePlan): Observable<any> {
    return this._rest.put(`${this.serviceUrl_ProductsRatePlanTemplates}/product-rateplan-charges/${ratePlanId}`, _bodyParams);
  }
  public createProductRatePlanChargeTemplates(_bodyParams: NewProductRatePlanCharge): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_ProductsRatePlanTemplates}/product-rateplan-charges`, _bodyParams);
  }

  public updateProductRatePlanChargesByRatePlanChargeTemplateId(ratePlanId: number, _bodyParams: NewProductRatePlanCharge): Observable<any> {
    return this._rest.put(`${this.serviceUrl_ProductsRatePlanTemplates}/product-rateplan-charges/${ratePlanId}`, _bodyParams);
  }
  public deleteRatePlanChargeTemplate(chargeId: number): Observable<RestResponse> {
    return this._rest.delete(`${this.serviceUrl_ProductsRatePlanTemplates}/product-rateplan-charges/${chargeId}`);
  }
  public updateRatePlanTemplateStatus(_rateplanId: number, nextStatus: string): Observable<any> {
    return this._rest.put(`${this.serviceUrl_ProductsRatePlanTemplates}/${_rateplanId}/status`, { status: nextStatus });
  }

  public markAsTemplate(_rateplanId: number): Observable<any> {
    return this._rest.put(`${this.serviceUrl_ProductsRatePlanTemplates}/${_rateplanId}/mark-as-template`);
  }

  public syncWithTemplate(_rateplanId: number): Observable<any> {
    return this._rest.put(`${this.serviceUrl_ProductsRatePlanTemplates}/${_rateplanId}/sync-with-template`);
  }

  public applyTemplate(_productRateplanTemplateId: number): Observable<any> {
    return this._rest.put(`${this.serviceUrl_ProductsRatePlanTemplates}/${_productRateplanTemplateId}/apply-template`);
  }

  /**End templates */

  public updateRatePlanStatus(_rateplanId: number, nextStatus: string): Observable<any> {
    return this._rest.put(`${this.serviceUrl_ProductsRatePlans}/${_rateplanId}/status`, { status: nextStatus });
  }

  public discontinueRatePlan(_rateplanId: number): Observable<any> {
    return this._rest.put(`${this.serviceUrl_ProductsRatePlans}/${_rateplanId}/discontinue`);
  }

  public deleteRatePlan(ratePlanId: number): Observable<RestResponse> {
    return this._rest.delete(`${this.serviceUrl_ProductsRatePlans}/${ratePlanId}`);
  }

  //rate plan charges
  public createProductRatePlanCharges(_bodyParams: NewProductRatePlanCharge): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_ProductRatePlanCharges}`, _bodyParams);
  }

  public updateProductRatePlanChargesByRatePlanChargeId(ratePlanId: number, _bodyParams: NewProductRatePlanCharge): Observable<any> {
    return this._rest.put(`${this.serviceUrl_ProductRatePlanCharges}/${ratePlanId}`, _bodyParams);
  }

  public syncActivePlans(chargeId: number, bodyParams: []): Observable<RestResponse> {
    return this._rest.put(`${this.serviceUrl_ProductRatePlanCharges}/${chargeId}/sync-active-plans`, bodyParams);
  }

  public deleteRatePlanCharge(chargeId: number): Observable<RestResponse> {
    return this._rest.delete(`${this.serviceUrl_ProductRatePlanCharges}/${chargeId}`);
  }

  public linkImageToRatePlanCharge(chargeId: number, data: { image: string }): Observable<RestResponse> {
    return this._rest.put(`${this.serviceUrl_ProductRatePlanCharges}/${chargeId}/image`, data);
  }

  public linkImageToRatePlan(productRatePlan: number, data: { image: string }): Observable<RestResponse> {
    return this._rest.put(`${this.serviceUrl_ProductsRatePlans}/${productRatePlan}/image`, data);
  }

}
