//angular
import { Component,OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
//others
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
//rxjs
import { Subscription } from 'rxjs';
//app
import { AppService } from '../app/_core/services/app.service';
import { DialogUserIdleComponent } from './_shared/idle-user/dialog-user-idle.component';
import { IdleUserTimes } from 'src/app/app.data';
import { AuthService } from './_core/services/auth.service';
import { Unsubscriber } from 'src/app/_shared/unsubscriber/unsubscriber';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnDestroy {
  dialogUserIdle: MatDialogRef<DialogUserIdleComponent>;
  idleTime : number = IdleUserTimes.idleTime;
  idleState = 'Not started.';
  lastPing?: Date = null;
  timeOut : number = IdleUserTimes.timeOut;
  routeIsLoading: boolean = true;
  subscription: Subscription;
  timedOut = false;
  private subs = new Unsubscriber();

  constructor(
    private _router: Router,
    private idle: Idle,
    private keepalive: Keepalive,
    private appService: AppService,
    private _auth: AuthService,
    public _dialog: MatDialog
  ) {
    // sets an idle timeout on seconds
    idle.setIdle(this.idleTime);
    // sets a timeout period on seconds. after (idle + timeout) seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(this.timeOut);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.subs.addSub = idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this._auth.logout();
    });

    var self= this;
    this.subs.addSub = idle.onIdleStart.subscribe(() => {
        this.idle.clearInterrupts();
        this.idleState = 'You\'ve gone idle!'
        this.dialogUserIdle = this._dialog.open(DialogUserIdleComponent,{
          disableClose:true,
          panelClass: ['custom-mat-dialog', 'mat-dialog-idle-user'],
          data: {
            component: self
          }
        });

        this.dialogUserIdle.afterClosed().subscribe(
          response => {
            if(response !== undefined ) {
              if(response == 'stay') {
                this._auth.getToken();
                this.reset();
              }
              else {
                this.logout();
              }
            }
        });
    });

    this.subs.addSub = idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      console.log('@@' + this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    this.subs.addSub = keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.reset();

    this.subs.addSub = this.appService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch();
        this.timedOut = false;
      } else {
        idle.stop();
      }
    });

    idle.stop();

    this.subs.addSub = this._router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    });

  }

  logout() {
    this.dialogUserIdle.close();
    this.appService.setUserLoggedIn(false);
    this.idle.stop();
    this._auth.logout();
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.routeIsLoading = true;
      browserRefresh = !this._router.navigated;
      const accessToken = localStorage.getItem('access_token'),
      islogged = accessToken ? true: false;
      if (islogged) {
        this.reset();
      } else {
        this.idle.stop();
      }
    }
    if (event instanceof NavigationEnd) {
      this.routeIsLoading = false;
    }
    if (event instanceof NavigationCancel) {
      this.routeIsLoading = false;
    }
    if (event instanceof NavigationError) {
      this.routeIsLoading = false;
    }
  }

  reset() {
    if(this.idle.getInterrupts()?.length == 0) {
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    }
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
