import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LoaderService } from 'src/app/_core/services';

@Component({
  selector: 'app-shipping-protection',
  templateUrl: './shipping-protection.component.html',
  styleUrls: ['./shipping-protection.component.scss']
})
export class ShippingProtectionComponent {

  constructor(
    private _dialogRef: MatDialogRef<ShippingProtectionComponent>,
    private _loader: LoaderService

  ) { }

  onSubmit():void {
    // Since we have only one shipping protection i am closing with boolean instead of value
    this._dialogRef.close(true);
    this._loader.startPageLoader();
  }
}
