//angular
import { Component, Inject, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatIconRegistry } from '@angular/material/icon';
//others
//app
import { DataSharingService } from 'src/app/_core/services/data.sharing.service';
import { GridService } from 'src/app/_core/services/grid.service';
import { VisibleState, CheckboxState } from '../../../../../app.data';
import { Unsubscriber } from 'src/app/_shared/unsubscriber/unsubscriber';

@Component({
  selector: 'dialog-bulk',
  templateUrl: './bulk.component.html',
  host: {'class':'dialog-bulk'}
})

export class DialogBulkComponent implements OnDestroy {
  bulkRecords: number;
  private subs = new Unsubscriber();

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public _data: any,
    private _dialog: MatSnackBarRef<DialogBulkComponent>,
    private _iconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
    private _shareData: DataSharingService,
    // public _grid: GridService,
  ) {
    this._iconRegistry.addSvgIcon('ico-archive', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-archive-01.svg'));
    this._iconRegistry.addSvgIcon('ico-copy', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-copy-01.svg'));
    this._iconRegistry.addSvgIcon('ico-delete', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-delete-02.svg'));
    this._iconRegistry.addSvgIcon('ico-export', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-export-01.svg'));
    this._iconRegistry.addSvgIcon('ico-switch', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-switch-04.svg'));
    this._iconRegistry.addSvgIcon('ico-check', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-check-02.svg'));
    this._iconRegistry.addSvgIcon('ico-disable', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-delete-03.svg'));

    this._iconRegistry.addSvgIcon('ico-close', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-close-01.svg'));

    this.subs.addSub = this._shareData.currentData.subscribe(data => {
      this.bulkRecords = Number(data);
    });
  }

  onClose(): void {
    this._dialog.dismissWithAction();
    // this._grid.removeBulkCheckedItems();
    // this._grid.statusBulkCheckbox = CheckboxState.uncheck;
    // this._grid.statusSnackBar = VisibleState.isHidden;
    // this._grid.bulkIsChecked = false;
    // this._grid.bulkIsIndeterminate = false;
    // this._grid.checkedCount = 0;
  }

  doAction(action): void {
    this._shareData.actionClicked.emit(action)
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
