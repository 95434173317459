<!-- Head -->
<ng-template #dialogHead>
  <label class="label-title">Report an Issue</label>
  <button mat-icon-button (click)="onClose()" class="custom-mat-icon-button button-close-panel">
    <mat-icon svgIcon="ico-close" class="custom-mat-icon ico-size ico-2xs"></mat-icon>
  </button>
</ng-template>
<!-- Body -->
<ng-template #dialogBody>
      <form [formGroup]="form" novalidate class="form-wrapper fullwidth">
        <div class="form-section fullwidth">
          <div class="form-section-body fullwidth">

                  <div class="form-row">
                    <mat-form-field class="size size1of1">
                      <!-- <input matInput required placeholder="First name" formControlName="firstname"> -->
                      <mat-select placeholder="Request Type " name="requestType" formControlName="requestType" class="custom-mat-select">
                        <mat-option *ngFor="let requestType of requestTypes" [value]="requestType.value " class="custom-mat-option">{{ requestType.name }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    </div>
                    <div class="form-row">
                    <mat-form-field class="size size1of1">
                      <!-- <input matInput required placeholder="First name" formControlName="firstname"> -->
                      <mat-select  placeholder="Related Page" name="relatedPage" formControlName="relatedPage" class="custom-mat-select">
                        <mat-option *ngFor="let relatedPage of relatedPages" [value]="relatedPage.value " class="custom-mat-option">{{ relatedPage.name }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    </div>
                    <div class="form-row flex">
                    <ng-container *ngIf="form.get('relatedPage')?.value === 'managementApp'">
                      <mat-form-field class="size size1of1" >
                        <input matInput required placeholder="Page" formControlName="relatedPageCnt">
                      </mat-form-field>
                      <reb-ngx-mat-button kind="primary" size="small"  (click)="setCurrentPage()"
                      >Current Page</reb-ngx-mat-button>
                    </ng-container>

                    <ng-container *ngIf="form.get('relatedPage')?.value === 'storefrontIntegration'">
                      <mat-form-field class="size size1of1" >
                        <mat-select  placeholder="Storefront page" name="relatedPageCnt" formControlName="relatedPageCnt" class="custom-mat-select">
                          <mat-option *ngFor="let storefrontPage of storefrontPages" [value]="storefrontPage.value " class="custom-mat-option">{{ storefrontPage.name }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </div>
                  <div class="form-row">
                    <mat-form-field class="size size1of1">
                      <textarea  matInput placeholder="Content" formControlName="content"></textarea>
                    </mat-form-field>
                  </div>
                  <!-- <div class="form-row">
                      <mat-label>Attachment (Optional)</mat-label>
                      <input  type="file" formControlName="file">
                  </div> -->
                  <div class="form-row">
                    <input #test class="form-control file-native" id="csv" [accept]="extensions" type="file" (change)="onFileSelect($event.target)"
                      name="Csv file">


                    <button mat-stroked-button type="button" (click)="test.click()" value="Choose file">
                      <div class="upload-inner-button flex">
                        <!-- <mat-icon svgIcon="ico-import" class="custom-mat-icon ico-size ico-1xs"></mat-icon> -->

                        <ng-container *ngIf="!file">
                          Choose attachment ({{extensions}})
                        </ng-container>
                        <ng-container *ngIf="file">
                          Choose another attachment ({{extensions}})
                        </ng-container>
                      </div>
                    </button>

                    <ng-container *ngIf="file">
                      {{file.name}}
                    </ng-container>
                  </div>

          </div>
        </div>
      </form>
</ng-template>
  <ng-template #dialogNav>
    <button mat-flat-button [disabled]="submited" (click)="onSubmit()" color="primary" class="mat-button custom-mat-button-primary">Submit</button>
  </ng-template>

  <app-dialog
    [dialogHead]="dialogHead"
    [dialogBody]="dialogBody"
    [dialogNav]="dialogNav">
  </app-dialog>
