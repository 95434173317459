import { slideInRightAnimation } from './slide.animations';
import {
    accountInfoIsLoading,
    bodyIsLoading,
    bodyLoader,
    colAnimation,
    listAnimate,
    listItemAnimate,
    loadingAnimation,
    logoAnimation,
    messageAnimation,
    messageDisplay,
    navMobileAnimation,
    panelAnimation,
    panelFadeInOut,
    pageAnimation,
    panelPreferences,
    panelSearchLoader,
    pageLoader,
    toggleLoader,
    loaderAnimation,
    rowAnimation,
    sandwichAnimation,
    sectionAnimation,
    showWhenLoaded,
    sidePanelAnimation,
    skeletonAnimation,
    skeletonListAnimation,
    smoothAppearance,
    spanelAccountAnimation,
    SpinnerAnimation,
    systemMessages,
    toggleButton,
    toggleGridColumn,
    toggleLink,
    toggleLabel
  } from './common.animations';

export const AppAnimations = [
    accountInfoIsLoading,
    bodyIsLoading,
    bodyLoader,
    colAnimation,
    listAnimate,
    listItemAnimate,
    loadingAnimation,
    loaderAnimation,
    logoAnimation,
    messageAnimation,
    messageDisplay,
    navMobileAnimation,
    pageLoader,
    panelAnimation,
    panelFadeInOut,
    panelPreferences,
    panelSearchLoader,
    pageAnimation,
    rowAnimation,
    sandwichAnimation,
    sectionAnimation,
    showWhenLoaded,
    sidePanelAnimation,
    slideInRightAnimation,
    smoothAppearance,
    spanelAccountAnimation,
    SpinnerAnimation,
    systemMessages,
    toggleButton,
    toggleGridColumn,
    toggleLabel,
    toggleLink,
    toggleLoader,
    skeletonAnimation,
    skeletonListAnimation
]

export * from './common.animations';
export * from './slide.animations';
