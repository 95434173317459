import { Injectable } from "@angular/core";

interface QueueError {
  message: string;
  status: number;
  data: any;
}
export interface ConsoleError extends QueueError {
}
export interface NetworkError extends QueueError {
}



@Injectable()
export class ErrorService {
  queueConsole: ConsoleError[] = [];
  queueNetwork: NetworkError[] = [];

  constructor() {}

  push(error: ConsoleError){
    if(this.queueConsole.length> 50) {
      this.queueConsole.shift()
    }
    this.queueConsole.push(error);
  }

  pushNetwork(error: NetworkError){
    if(this.queueNetwork.length> 50) {
      this.queueNetwork.shift()
    }
    this.queueNetwork.push(error);
  }

  getQueue(){
    return {queueConsole: this.queueConsole, queueNetwork: this.queueNetwork};
  }

  getReadableLogs(){
    let logs = "Console Logs\n";

    if(this.queueConsole.length == 0){
      logs+= 'No logs were found \n';
    } else {
      this.queueConsole.forEach(log=>{
        logs+=log.message + '\n';
      })
    }

 
    logs += "Network Logs\n";
    if(this.queueNetwork.length == 0){
      logs+= 'No logs were found \n';
    } else {
      this.queueNetwork.forEach(log=>{
        logs+=log.message + '\n';
      })
    }

    return logs;
  }
}
