<div class="dialog-wrapper">
  <div class="body">
    <div class="form-wrapper">
      <div class="form-content">
        <div class="form-row row-hero">
          <label class="typo display-2 fw300 semi-condensed">You are about to be signed out of Rebillia!</label>
        </div>
        <div class="form-row">
          <label>For security reasons your connection times out after you've been inactive for a while.</label>
        </div>
      </div>
    </div>
  </div>
  <!-- Nav -->
  <div class="nav">
    <!-- <button mat-button (click)="logout()" color="primary" class="mat-button custom-mat-button custom-mat-button-primary button-delete">Logout</button> -->
    <button mat-button (click)="stay()" class="custom-mat-button-primary mat-stroked-button">Stay Logged In ( {{idleCounter | formatTime}} )</button>
  </div>

</div>
