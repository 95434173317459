import { Component } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { MetaService } from 'src/app/_core/services/meta.service';

@Component({
  selector: 'app-base-new',
  styleUrls: ['./layout-new.component.scss'],
  templateUrl: './layout-new.component.html',
  animations: [
    trigger('animateBody', [
      state(
        'contracted',
        style({
          marginLeft: '70px'
        })
      ),
      state(
        'expanded',
        style({
          marginLeft: '248px'
        })
      ),
      transition('expanded <=> contracted', animate('400ms ease-in-out')),
    ])
  ]
})

export class LayoutBaseNewComponent {
  status: 'expanded' | 'contracted' = 'contracted';

  constructor(
    private _meta: MetaService,
  ) {
    this._meta.setCompanyCurrencyBase();
    this._meta.setCompanySettings();
  }

  getNavbarStatus(navbarStatus: 'expanded' | 'contracted') {
    this.status = navbarStatus;
  }
}
