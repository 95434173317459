//Angular
import { Injectable } from '@angular/core';
//components
import { responseCode } from 'src/app/app.data';
import { collectionOfKeyNamesMessages, KeyName, messageIterface } from 'src/app/app.keynames';
import { RestResponse } from 'src/app/_shared/models';
import { MetaService } from './meta.service';
//Rebillia's library
import { MatSnackbarModel, RebNgxSnackbarService } from '@rhinosaas/reb-ngx';

@Injectable()
export class SnackbarMessageService {

  collectionOfKeyNames: Array<messageIterface> = collectionOfKeyNamesMessages;
  message:any;

  constructor(
    private rebilliaSnackbar: RebNgxSnackbarService,
    private meta: MetaService,
    ) { }

  private getErrorMessage(response: RestResponse) {

    const data = {
      statusCode: (response?.code ? response.code : responseCode.errorBadRequestCode),
      keyName: response?.code == responseCode.successCode ? KeyName.request_success : response?.error?.code
    }

    let message: any;

    let messageItem = this.collectionOfKeyNames.find(key=> key.value == data.keyName);
    
    if(messageItem == undefined) {
      messageItem = data.statusCode > 200 ?  this.collectionOfKeyNames.find(key=> key.value == KeyName.request_error) : this.collectionOfKeyNames.find(key=> key.value == KeyName.request_success);
    }

    message = messageItem.message;

    return message;

  }

  public showNotificationRebilliaComponent(response: RestResponse, showCloseButton?: boolean, duration?:number) {

    let body: MatSnackbarModel = {
      mood: '',
      message: ''
    };

    body.duration = duration ? duration : 5000;
    body.message = this.getErrorMessage(response);
    body.closeButton = showCloseButton;
    
    if (response.code == 200) {
      body.mood = 'success';
    } else if (response.code == 204) {
      body.mood = 'info';
    } else {
      body.mood = 'error';
    }
    this.rebilliaSnackbar.openSnackBar(body);
  }

   // Use only for custom Front-end messages:
  //_customMessage: any message that We need to show instead of messages alloweded in app.keynames.ts
  //_templateLiterals contains the object to be shown in the notification message
  public showNotificationCustomMessage(statusCode: any, _keyName: any, customMessage?: string, _templateLiterals?: string[], showCloseButton?: boolean, duration?:number ) {

    let body: MatSnackbarModel = {
      mood: '',
      message: ''
    };

    body.duration = duration ? duration : 5000;
    body.message = customMessage;
    body.closeButton = showCloseButton;
    
    if (statusCode == responseCode.successCode) {
      body.mood = 'success';
    } else if (statusCode == responseCode.successCodeWithError) {
      body.mood = 'info';
    } else {
      body.mood = 'error';
    }
    this.rebilliaSnackbar.openSnackBar(body);

  }
}
