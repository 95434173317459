import { Component, Input } from '@angular/core';
import { AppAnimations } from '../../animations';

@Component({
  selector: 'skeleton-loader-gateway',
  templateUrl: './skeleton-loader-gateway.component.html',
  styleUrls: ['./skeleton-loader-gateway.component.scss'],
  animations: [ AppAnimations ],
})
export class SkeletonLoaderGatewayComponent {
  @Input() _isPaymentGatewayVisible?: boolean = true;
  
  constructor() {}
}
