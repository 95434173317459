<div [formGroup]="form" class="custom-mat-form-field size size1of1">
  <mat-form-field appearance="outline" class="custom-mat-form-field custom-mat-form-field-outline field-outline-payment-method size size1of1">
    <mat-label>Payment Methods</mat-label>
    <mat-select (selectionChange)="onChangeCustomerPaymentMethod($event.value)" formControlName="customerPaymentMethod" disableOptionCentering="true" panelClass="custom-mat-select-panel list-credit-cards" class="custom-mat-select select-credit-card">
      <mat-select-trigger class="custom-mat-select-trigger">
        <ng-container *ngIf="_selectedCustomerPaymentMethod !== '0' ; else newCreditCard"  >
          <span class="credit-card-item">
            <span class="ico-card">
              <mat-icon *ngIf="_selectedCustomerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.alipay" svgIcon="card-alipay" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
              <mat-icon *ngIf="_selectedCustomerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.americanexpress || _selectedCustomerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.americanexpress2 || _selectedCustomerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.american_express" svgIcon="card-amex" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
              <mat-icon *ngIf="_selectedCustomerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.diners" svgIcon="card-diners" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
              <mat-icon *ngIf="_selectedCustomerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.discover" svgIcon="card-discover" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
              <mat-icon *ngIf="_selectedCustomerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.elo" svgIcon="card-elo" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
              <mat-icon *ngIf="_selectedCustomerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.hypercard" svgIcon="card-hypercard" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
              <mat-icon *ngIf="_selectedCustomerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.jcb" svgIcon="card-jcb" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
              <mat-icon *ngIf="_selectedCustomerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.maestro" svgIcon="card-maestro" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
              <mat-icon *ngIf="_selectedCustomerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.master" svgIcon="card-master" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
              <mat-icon *ngIf="_selectedCustomerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.mastercard" svgIcon="card-master" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
              <mat-icon *ngIf="_selectedCustomerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.paypal" svgIcon="card-paypal" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
              <mat-icon *ngIf="_selectedCustomerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.unionpay" svgIcon="card-unionpay" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
              <mat-icon *ngIf="_selectedCustomerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.verve" svgIcon="card-verve" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
              <mat-icon *ngIf="_selectedCustomerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.visa" svgIcon="card-visa" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
            </span>
            <span class="label-credit-card">
              <span class="label-primary">Ending in {{_selectedCustomerPaymentMethod?.accountNumber}}</span>
              <span class="label-secondary">{{_selectedCustomerPaymentMethod?.accountName}}</span>
            </span>
            <span class="label-card-exp">
              Exp. {{_selectedCustomerPaymentMethod?.expDate}}
            </span>
          </span>

        </ng-container>
        <ng-template #newCreditCard>
          <label class="address-contact">
            <span>Enter a new credit card</span>
          </label>
        </ng-template>
      </mat-select-trigger>
      <mat-option class="custom-mat-option" *ngIf="_allowToCreateCard" value="0">Enter a new credit card</mat-option>
      <mat-option *ngFor="let customerPaymentMethod of _collectionOfCustomerPayments" [value]="customerPaymentMethod" class="custom-mat-option option-card">
        <span class="customer-payment-card-ico">
          <mat-icon *ngIf="customerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.alipay" svgIcon="card-alipay" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
          <mat-icon *ngIf="customerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.americanexpress || customerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.americanexpress2 || customerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.american_express" svgIcon="card-amex" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
          <mat-icon *ngIf="customerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.diners" svgIcon="card-diners" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
          <mat-icon *ngIf="customerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.discover" svgIcon="card-discover" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
          <mat-icon *ngIf="customerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.elo" svgIcon="card-elo" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
          <mat-icon *ngIf="customerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.hypercard" svgIcon="card-hypercard" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
          <mat-icon *ngIf="customerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.jcb" svgIcon="card-jcb" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
          <mat-icon *ngIf="customerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.maestro" svgIcon="card-maestro" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
          <mat-icon *ngIf="customerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.master" svgIcon="card-master" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
          <mat-icon *ngIf="customerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.mastercard" svgIcon="card-master" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
          <mat-icon *ngIf="customerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.paypal" svgIcon="card-paypal" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
          <mat-icon *ngIf="customerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.unionpay" svgIcon="card-unionpay" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
          <mat-icon *ngIf="customerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.verve" svgIcon="card-verve" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
          <mat-icon *ngIf="customerPaymentMethod?.accountType.toLowerCase() === _paymentAccountType.visa" svgIcon="card-visa" class="custom-mat-icon ico-card ico-size ico-std"></mat-icon>
        </span>
        <span class="label-card-number-new">
          <p>Ending in {{customerPaymentMethod?.accountNumber}}</p>
          <p>{{customerPaymentMethod?.accountName}}</p>
        </span>
        <span class="label-card-exp">
          Exp. {{customerPaymentMethod?.expDate}}
        </span>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-hint *ngIf="_showsGateway && _selectedCustomerPaymentMethod && _selectedCustomerPaymentMethod !== '0'" class="mat-hint custom-mat-hint hint-gateway-new">Payment Gateway: <b>{{_selectedCustomerPaymentMethod?.companyGateway.displayName}}</b></mat-hint>
  <mat-hint *ngIf="_showsBillingAddress &&  _selectedCustomerPaymentMethod && _selectedCustomerPaymentMethod !== '0' && _selectedCustomerPaymentMethod?.customerAddressBook"
    class="mat-hint custom-mat-hint hint-gateway-new">Billing Address: <b>{{_selectedCustomerPaymentMethod?.customerAddressBook.contactName}},{{_selectedCustomerPaymentMethod?.customerAddressBook.street1}}{{_selectedCustomerPaymentMethod?.customerAddressBook?.street2 ? ','+ _selectedCustomerPaymentMethod?.customerAddressBook?.street2:''}}, {{_selectedCustomerPaymentMethod?.customerAddressBook.city}}, {{_selectedCustomerPaymentMethod?.customerAddressBook.state}}, {{_selectedCustomerPaymentMethod?.customerAddressBook.zip}}, {{_selectedCustomerPaymentMethod?.customerAddressBook?.country.alpha3Code}}</b>
  </mat-hint>
  <mat-hint *ngIf="_showsBillingAddress && _selectedCustomerPaymentMethod && _selectedCustomerPaymentMethod !== '0' && !_selectedCustomerPaymentMethod?.customerAddressBook"
    class="mat-hint custom-mat-hint hint-gateway-new">Billing Address: <b>{{_selectedCustomerPaymentMethod?.billingAddress.street1}}{{_selectedCustomerPaymentMethod?.billingAddress?.street2 ? ','+ _selectedCustomerPaymentMethod?.billingAddress?.street2:''}}, {{_selectedCustomerPaymentMethod?.billingAddress.city}}, {{_selectedCustomerPaymentMethod?.billingAddress.state}}, {{_selectedCustomerPaymentMethod?.billingAddress.zip}}, {{_selectedCustomerPaymentMethod?.billingAddress?.country.alpha3Code}}</b>
  </mat-hint>
</div>

