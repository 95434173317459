import { GatewayUrls } from 'src/app/app.data';
import { AuthConfig } from '../app/_shared/interfaces/auth0.interface';
import { datadogRum } from '@datadog/browser-rum'

export const environment = {
  name: 'PROD',
  production: true,
  APIEndpoint: 'https://api.rebillia.com',
  APIVersion: '/internal',
  urlAuthorizeAccept: GatewayUrls.urlAuthorizeAcceptProd,
  urlBraintreeClient: GatewayUrls.urlBraintreeClientProd,
  urlBraintreeHostedFields: GatewayUrls.urlBraintreeHostedFieldsProd,
  urlBraintreeDataCollector: GatewayUrls.urlBraintreeDataCollectorProd,
  urlStripe: GatewayUrls.urlStripeProd,
  urlSquare: GatewayUrls.urlSquareProd,
  urlUsaepay: GatewayUrls.urlUsaepayProd,
  urlAuthorizeAcceptSandbox: GatewayUrls.urlAuthorizeAccept,
  urlBraintreeClientSandbox: GatewayUrls.urlBraintreeClient,
  urlBraintreeHostedFieldsSandbox: GatewayUrls.urlBraintreeHostedFields,
  urlBraintreeDataCollectorSandbox: GatewayUrls.urlBraintreeDataCollector,
  urlStripeSandbox: GatewayUrls.urlStripe,
  urlSquareSandbox: GatewayUrls.urlSquare,
  urlUsaepaySandbox: GatewayUrls.urlUsaepay,
  urlCybersourceHostedFields: GatewayUrls.urlCybersourceHostedFieldsProd,
  urlCybersourceHostedFieldsSandbox: GatewayUrls.urlCybersourceHostedFields,
};

export const AUTH_CONFIG_DESKTOP: AuthConfig = {
  audience: 'https://api.rebillia.com/',
  clientID: '3UtKwiIZw1RPLxbDpHfthQi9Ohq4Ld4f',
  connection: 'Rebillia',
  domain: 'auth.rebillia.com',
  callbackURL: 'https://admin.rebillia.com/callback',
  responseType: 'token id_token',
  scope: 'openid profile email'
};

export const EXCHANGE_RATE = {
  url: 'https://openexchangerates.org/api/latest.json',
  token: 'Token ddac58a9801b4eb7ab98fd9499cfeecc',
};

datadogRum.init({
  applicationId: '68a48a80-15d6-4fa7-95ce-0c46fbdfe8e5',
  clientToken: 'pubdb430cb56b277a0428f4ef567023f33b',
  site: 'datadoghq.com',
  service:'rebillia-ui-prod',
  version: '1.0.0',
  env: 'prod',
  sampleRate: 100,
  sessionReplaySampleRate: 20,
  allowedTracingOrigins: ["https://admin.rebillia.com"],
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel:'mask-user-input'
});

datadogRum.startSessionReplayRecording();
