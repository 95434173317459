import { Address, POST_AddressInterface } from './address.model';
import { Currency } from './currency.model';
import { Deserializable } from '../interfaces/deserialize.interface';
import { Industry } from './industries.model';
import { PaymentMethod } from './payment-method.model';
import { Timezone } from './timezone.model';
import { GatewayStatus, GatewayType } from 'src/app/app.data';
import { CustomerPaymentSource } from './customer.model';
import { Country } from './country.model';

export class Company {
    constructor(
        public id?: number,
        public name?: string,
        public website?: string,
        public email?: string,
        public isologo?: string,
        public address1?: string,
        public address2?: string,
        public city?: string,
        public state?: string,
        public zip?: string,
        public countryId?: number,
        public industryId?: number,
        public workerSize?: number,
        public taxId?: string,
        public vatNum?: string,
        public phoneNum?: string,
        public phoneExt?: string,
        public status?: string,
        public supportPin?: string,
        public timezone?: number,
        public planId?: number,
        public estimatedAnnualRevenue?: number
    ){}
}

export class CompanyBilling implements Deserializable<CompanyBilling>{
    constructor(
        public planId?: number,
        public lastInvoice?: CompanyLastInvoice,
        public metric?: CompanyMetrics,
        public paymentMethod?: Array<PaymentMethod>,
        public paymentMethodDefault?: number
    ) {}

    deserialize(input: any): CompanyBilling {
        Object.assign(this, input);
        return this;
    }
}

export class CompanyCurrency implements Deserializable<CompanyCurrency>{
  constructor(
    public companyGateway?: Array<CompanyGateway>,
    public conversionRate?: string,
    public createdAt?: string,
    public currency?: Currency,
    public fixedRate?: boolean,
    public id?: number,
    public iso3?: string,
    public symbol?: string,
    public updatedAt?: string
  ){}

  deserialize(input: any): CompanyCurrency {
      Object.assign(this, input);
      return this;
  }
}

export class SuggestedCustomerMerge implements Deserializable<SuggestedCustomerMerge>{
  constructor(
    public id: number,
    public first_name?: string,
    public last_name?: string,
    public email?: string,
    public num_subscriptions?: number,
    public num_invoices?: number,
    public num_transactions?: number,
    public num_payment_methods?: number,
    public checked?: boolean,
  ){}

  deserialize(input: any): SuggestedCustomerMerge {
    Object.assign(this, input);
    return this;
  }
}

export class CompanyCurrencyGateway implements Deserializable<CompanyCurrencyGateway>{
  constructor(
    public id?: number,
    public keyName?: string,
    public name?: string,
    public status?: string,
    public isDefault?: boolean,
    public credentialsLastUpdate?: string,
    public dateAdded?: string
​ ){}

  deserialize(input: any): CompanyCurrencyGateway {
    Object.assign(this, input);
    return this;
  }
}

export class CompanyGateway implements Deserializable<CompanyGateway>{
    constructor(
      public accountId?: string,
      public createdAt?: string,
      public customerCards: Array<CustomerPaymentSource> = [],
      public displayName?: string,
      public id?: number,
      public internalGatewayId?: number,
      public internalGatewayCode?: string,
      public isDefault?: boolean,
      public keyName?: string,
      public name?: string,
      public status?: string,
      public type?: GatewayType,
      public updatedAt?: string
    ) { }

    deserialize(input: any): CompanyGateway {
        Object.assign(this, input);
        return this;
    }
}

export class CompanyInvite implements Deserializable<CompanyInvite>{
    constructor(
        public email?: string,
        public role?: string,
        public status?: string,
        public token?: string,
        public createdAt?: number
    ) { }

    deserialize(input: any): CompanyInvite {
        Object.assign(this, input);
        return this;
    }
}

export class CompanyLastInvoice implements Deserializable<CompanyLastInvoice> {
    constructor(
        public id?: number,
        public amount?: string,
        public status?: string,
        public invoiceNumber?: string,
        public invoiceDate?: string,
        public balance?: number,
        public dueDate?: string
    ){}

    deserialize(input: any): CompanyLastInvoice {
        Object.assign(this, input);
        return this;
    }
}

export class CompanyMetrics implements Deserializable<CompanyMetrics> {
    constructor(
        public balance?: number,
        public totalInvoiceBalance?: number,
        public creditBalance?: number,
        public hasPastDueInvoice?: number,
        public redemption?: string
    ){}

    deserialize(input: any): CompanyMetrics {
        Object.assign(this, input);
        return this;
    }
}

export class CompanyProfile implements Deserializable<CompanyProfile>{
    constructor(
        public id?: number,
        public name?: string,
        public phoneNum?: string,
        public phoneExt?: string,
        public email?: string,
        public website?: string,
        public address?: Address,
        public timezone?: Timezone,
        public taxId?: string,
        public industry?: Industry,
        public estimatedAnnualRevenue?: string,
        public workerSize?: string,
        public type?: string,
        public status?: string,
        public cancelledAt?: Date,
    ) {}

    deserialize(dataJSON: any): CompanyProfile {
        Object.assign(this, dataJSON);
        return this;
    }
}

export class CompanySettings implements Deserializable<CompanySettings>{
    constructor(
        public code?: string,
        public defaultValue?: string,
        public label?: string,
        public type?: string,
        public value?: string
    ) { }

    deserialize(input: any): CompanySettings {
        Object.assign(this, input);
        return this;
    }
}

export class CompanySMTPSettings implements Deserializable<CompanySMTPSettings> {
    smtpServerAddress: string
    smtpUserName: string
    smtpPassword: string
    smtpPortNumber: string
    smtpEncryptionType: string

    deserialize(input: any): CompanySMTPSettings {
        Object.assign(this, input);
        return this;
    }
}

export class CompanyTimezone implements Deserializable<CompanyTimezone>{
  constructor(
    public id?: number,
    public isdst?: boolean,
    public offSet?: number,
    public text?: string,
    public value?: string
  ){}

  deserialize(input: any): CompanyTimezone {
      Object.assign(this, input);
      return this;
  }
}

export class CompanyUser implements Deserializable<CompanyUser>{
    constructor(
        public id?: number,
        public picture?: string,
        public email?: string,
        public fullName?: string,
        public role?: string
    ){}

    deserialize(input: any): CompanyUser {
        Object.assign(this, input);
        return this;
    }
}

export class CustomerGroup implements Deserializable<CustomerGroup>{
    constructor(
    public id?: number,
    public name?: string,
    ) { }

    deserialize(input: any): CustomerGroup {
        Object.assign(this, input);
        return this;
    }
}
export interface CustomerGroupInfo {
    productId?: number,
    productName?: string,
    productRatePlanId?: number,
    productRatePlanName?: string,
    initial?: number,
    initialGroupName?: string,
    terminate?: number,
    terminateGroupName?: string
}

export class CompanyInfo implements Deserializable<CompanyInfo>{
    constructor(
        public city?: string,
        public country?: Country,
        public createdAt?: string,
        public email?: string,
        public estimatedAnnualRevenue?: number,
        public id?: number,
        public industry?: Industry,
        public isologo?: string,
        public name?: string,
        public phoneNum?: string,
        public phoneExt?: string,
        public plan?:Plan,
        public state?: string,
        public status?: string,
        public street1?: string,
        public street2?: string,
        public supportPin?: string,
        public taxId?: string,
        public updatedAt?:string,
        public website?: string,
        public workerSize?: number,
        public zip?: string,
    ){}
    deserialize(input: any): CompanyInfo {
        Object.assign(this, input);
        return this;
    }
}

export interface PUT_CompanyGatewayStatusInterface {
  status: GatewayStatus,
}

export class PUT_CompanyProfileInterface {
    constructor(
        public city?: string,
        public countryId?: number,
        public email?: string,
        public estimatedAnnualRevenue?: string,
        public id?: number,
        public industryId?: number,
        public name?: string,
        public phoneExt?: string,
        public phoneNum?: string,
        public street1?: string,
        public street2?: string,
        public state?: string,
        public taxId?: string,
        public website?: string,
        public workerSize?: string,
        public zip?: string
    ) {}
}

export class Post_AdditionalCurrencyInterface {
    constructor(
        public currencyId?: number,
        public conversionRate?: string,
        public fixedRate?: boolean

    ){}
}
export interface POST_Company {
  city?: string,
  countryId?: number,
  currencyId?: number,
  email?: string,
  estimatedAnnualRevenue?: number,
  industryId?: number,
  name?: string,
  phoneNum?: string,
  phoneExt?: string,
  state?: string,
  street1?: string,
  street2?: string,
  timezoneId?: number,
  website?: string,
  workerSize?: number,
  zip?: string
}
export interface Post_CustomerGroupInfo {
  productRatePlanId?: number,
  initial?: number,
  terminate?: number
}

export class Put_AdditionalCurrencyInterface {
    constructor(
        public conversionRate?: string,
        public fixedRate?: boolean
    ){}
}


export interface SalesChannelDataDashboard {
    integrationName: string,
    displayName: string,
    customerCount?: string,
    subscriptionCount?: string,
    invoiceCount?: string,
    invoiceAmount?: string
}

export class Plan implements Deserializable<Plan> {
  constructor(
    public description?: string,
    public name?: string,
    public pricingConcepts: Array<PricingConcept> = []
  ){}

  deserialize(input: any): Plan {
  Object.assign(this, input);
    return this;
  }
}

export class PricingConcept implements Deserializable<PricingConcept> {
  constructor(
    public description?: string,
    public frequency?: string,
    public keyName?: string,
    public name?: string,
    public overrideQuantity?: string,
    public price?:string,
    public quantity?: number,
    public status?:string
  ){}

  deserialize(input: any): PricingConcept {
    Object.assign(this, input);
    return this;
  }
}


export interface Post_ActivateCompany {

  type: string,
  accountType: string,
  accountKind: string,
  accountName: string,
  paymentMethod: Post_PaymentMethod,
  billingAddress: POST_AddressInterface,
}

export interface Post_PaymentMethod {
  nonce: string
}
