//angular
import { Pipe, PipeTransform } from '@angular/core';
//others
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(advancedFormat)
dayjs.extend(timezone);
dayjs.extend(utc);
//app data
import { DateFormatValues } from 'src/app/app.data';
//app models
import { Company, CompanyTimezone } from '../models/company.model';
import { DatePipe } from '@angular/common';
import { DataSharingService } from 'src/app/_core/services';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      (('00' + Math.floor(value - minutes * 60)).slice(-2)) +
      (' ' + (minutes >= 1 ? 'min': 'sec'))
    );
  }
}

/**
 * 25 OCT 2023 Deprecated use dateTz for converion in timezone.
 */
@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  transform(_date: Date | string, _format: string = DateFormatValues.MMM_DD_YYYY, _timezone: CompanyTimezone = null): string {
    let dateFormated = _timezone ? dayjs(_date,_timezone.value).utcOffset(_timezone.offSet).format(_format) : dayjs(_date).format(_format);
    return dateFormated.toString();
  }
}

@Pipe({
  name: 'formatDateLong'
})
export class FormatDateLongPipe implements PipeTransform {
  transform(_date: Date | string, format: string = DateFormatValues.MMM_DD_YYYY): string {
    let date = new Date(_date);
    let dateFormated = new Date(date.getTime() + date.getTimezoneOffset());
    return dateFormated.toLocaleString('en-US', {
      day: '2-digit', // numeric, 2-digit
      year: 'numeric', // numeric, 2-digit
      month: 'short', // numeric, 2-digit, long, short, narrow
    });
  }
}

@Pipe({
  name: 'formatFullTime'
})
export class FormatFullTime implements PipeTransform {
  transform(_date: Date | string, _format: string = DateFormatValues.HH_mm , _timezone: any = CompanyTimezone): string {
    let timeFormated = dayjs(_date).format(_format);
    return timeFormated.toString();
  }
}

@Pipe({
  name: 'formatDateTime'
})
export class FormatDateTimePipe implements PipeTransform {
  transform(_date: Date | string, format: string = DateFormatValues.ddd_DD_MMM_YYYY_HH_mm_ss): string {
    let date = new Date(_date);
    let dateFormated = new Date(date.getTime());
    return dateFormated.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  }
}

@Pipe({
  name: 'dateTz'
})
export class SetTimezonePipe implements PipeTransform {
  utcOffset: string;
  dateFormat: string;
  timezoneSub: Subscription;
  companySettingsTimezone: any;
  displayDateFormat: any;

  constructor( private _shareData: DataSharingService) {
    // This part needs optimization, its called many times
    const {companySettingsTimezone, displayDateFormat} = this._shareData.getDateConfig();
    this.companySettingsTimezone = companySettingsTimezone;
    this.displayDateFormat = displayDateFormat;

  }


  transform(value: any, format?: any, timezone?:any): any {
    const offsetOfTimezone =  dayjs.utc(value).tz(this.companySettingsTimezone.value).utcOffset() / 60 ;
    const datePipeTimezone = offsetOfTimezone > 0 ? `+${offsetOfTimezone}00`: `${offsetOfTimezone}00`
    const datePipe = new DatePipe('en-US', datePipeTimezone === "000" ? null : datePipeTimezone);
    if(!format || format == 'mediumDate'){
      const dayJSPipe = {
        'YYYY-MM-DD': 'YYYY-MM-dd',
        'MMM DD, YYYY': 'MMM dd, YYYY',
        'ddd DD MMM, YYYY': 'EEE dd MMM, YYYY',
        'dddd DD MMM, YYYY': 'EEEE dd MMM, YYYY'
      }
      format = dayJSPipe[this.displayDateFormat];
    }
    if(format == 'longTime') {
      format =  'longTime';
    }
    value = datePipe.transform(value, format);
    return value;
  }
}


