// angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// app
import { CallbackComponent } from './modules/login/callback/callback.component';
import { ForbiddenComponent } from './_shared/pages/forbidden/forbidden.component';
import { PageNotFoundComponent } from './_shared/pages/pagenotfound/pagenotfound.component';
import { UnauthorizedComponent } from './_shared/pages/unauthorized/unauthorized.component';
import { RestrictionResolver } from './_shared/services/permission/restriction.resolver';
import { LayoutBaseNewComponent } from './modules/main/layout-new.component';

const AppRoutes: Routes = [
  //account
  {
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
    path: 'account',
    pathMatch: 'prefix'
  },
  //callback
  {
    path: 'callback',
    component: CallbackComponent
  },
  //forbidden
  {
      path: 'forbidden',
      component: ForbiddenComponent
  },
  //login
  {
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
    path: 'login',
    pathMatch: 'prefix'
  },
  //main
  {
    component: LayoutBaseNewComponent,
    loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),
    path: 'main',
    pathMatch: 'prefix',
    resolve: {
      config: RestrictionResolver
    }
  },
  //setup
  {
    loadChildren: () => import('./modules/setup/setup.module').then(m => m.SetupModule),
    path: 'setup',
    pathMatch: 'prefix'
  },
  //unauthorized
  {
      path: 'unauthorized',
      component: UnauthorizedComponent
  },
  //default
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'main/dashboard'
  },
  //not found
  {
      path: '**',
      component: PageNotFoundComponent
  }
];

@NgModule({
    exports: [
        RouterModule
    ],
    imports: [
        RouterModule.forRoot(AppRoutes, { scrollPositionRestoration: 'enabled', enableTracing: false })
    ]
})

export class AppRouterModule { }
