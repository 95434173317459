<div *ngIf="!_skeleton.skeletonGridDataLoader">
  <mat-list-item *ngFor="let fake of _skeleton.generateFake(numberOfRows)" class="custom-mat-list-item grid-row">
    <div class="grid-row-inner" *ngIf="includeInnerGrid">
      <!-- bulk -->
      <div *ngIf="gridBulkSection" [class]="'cell-wrapper col-bulk'">
        <div class="bulk-pulse-indicator">
          <div class="bulk-pulse-inner"></div>
        </div>
      </div>

      <div class="cell-wrapper col-first">
        <div class="cell flex-order order-0">
          <label matLine>
            <skeleton-loader [theme]="{'width': '80px'}"></skeleton-loader>
          </label>
        </div>
      </div>

      <div class="cell-wrapper col-info">
        <div *ngFor="let fake of _skeleton.generateFake(numberOfColumns)" class="cell flex-order">
          <label matLine>
            <skeleton-loader [theme]="{'width': '80px'}"></skeleton-loader>
          </label>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!includeInnerGrid">
      <!-- bulk -->
      <div *ngIf="gridBulkSection" [class]="'cell-wrapper col-bulk'">
        <div class="bulk-pulse-indicator">
          <div class="bulk-pulse-inner"></div>
        </div>
      </div>

      <div class="cell-wrapper col-first">
        <div class="cell flex-order order-0">
          <label matLine>
            <skeleton-loader [theme]="{'width': '80px'}"></skeleton-loader>
          </label>
        </div>
      </div>

      <div class="cell-wrapper col-info">
        <div *ngFor="let fake of _skeleton.generateFake(numberOfColumns)" class="cell flex-order">
          <label matLine>
            <skeleton-loader [theme]="{'width': '80px'}"></skeleton-loader>
          </label>
        </div>
      </div>
    </ng-container>
  </mat-list-item>
</div>
