import { BillingRunStatus, FactoringDimension, PaymentRunStatus } from 'src/app/app.data';
import { Currency } from './currency.model';
import { Deserializable } from '../interfaces/deserialize.interface';

export interface AnalyticsTotals {
  customers?: number;
  products?: number;
  ratePlans?: number;
  subscriptions?: number;
  subscribers?: number,
}

export class Checkout implements Deserializable<Checkout> {
  constructor(
    public name?: string,
    public website?: string,
    public phoneNum?: string,
    public phoneExt?: string,
    public countryId?: number,
    public planId?: number,
    public workerSize?: number,
    public paymentMethod?: [any]
  ){}

  deserialize(input: any): Checkout {
    Object.assign(this, input);
    return this;
  }
}

export class GatewayDefault implements Deserializable<GatewayDefault>{
  constructor(
    public gatewayData?: any,
    public gatewayId?: any,
    public gatewaySettings?: any,
    public name?: string,
    public setting?: Object
  ){}

  deserialize(input: any): GatewayDefault {
    Object.assign(this, input);
    return this;
  }
}

export class Settings implements Deserializable<Settings>{
  constructor(
    public id?: number,
    public name?: string,
    public value?: string,
    public type?: string
  ){}
  deserialize(input: any): Settings {
    Object.assign(this, input);
    return this;
  }
}

export class InternalNotificationSettingsResult implements Deserializable<InternalNotificationSettingsResult>{
  constructor(
    public isBillRunReportEnabled?: boolean,
    public isIntegrationRunReportEnabled?: boolean,
    public isPayRunReportEnabled?: boolean,
    public isRetryRunReportEnabled?: boolean,
  ){}
  deserialize(input: any): InternalNotificationSettingsResult {
    Object.assign(this, input);
    return this;
  }
}

export class PaginatorResult implements Deserializable<PaginatorResult>{
  constructor(
    public recordsPerPage?: number,
  ){}
  deserialize(input: any): PaginatorResult {
    Object.assign(this, input);
    return this;
  }
}

export class RblScopeKeyResult implements Deserializable<RblScopeKeyResult>{
  constructor(
    public id: number,
    public keyName: string,
    public type: string,
    public createdAt?: Date,
    public updatedAt?: Date,
  ){}
  deserialize(input: any): RblScopeKeyResult {
    Object.assign(this, input);
    return this;
  }
}

export class ExperimentalFeaturesResult implements Deserializable<ExperimentalFeaturesResult>{
  constructor(
    public id: number,
    public keyName: string,
    public type: string,
    public overrideId?: number,
    public enabled?: number,
    public customEnabled?: number,
  ){}
  deserialize(input: any): ExperimentalFeaturesResult {
    Object.assign(this, input);
    return this;
  }
}

export class SettingsDateFormat implements Deserializable<SettingsDateFormat>{
  constructor(
    public displayDateFormat?: string,
    public dstCorrection?: string,
    public extendedDisplayDateFormat?: string,
    public timezone?: number,
  ){}
  deserialize(input: any): SettingsDateFormat {
    Object.assign(this, input);
    return this;
  }
}

export class SettingsMetrics implements Deserializable<SettingsMetrics>{
  constructor(
    public decimalPlaces?: string,
    public decimalToken?: string,
    public factoringDimension?: FactoringDimension,
    public lengthMeasurement?: string,
    public thousandsToken?: string,
    public weightMeasurement?: string,
  ){}
  deserialize(input: any): SettingsMetrics {
    Object.assign(this, input);
    return this;
  }
}

export class GatewaysFields implements Deserializable<Settings>{
  constructor(
    public key: string,
    public label: string
  ){}
  deserialize(input: any): GatewaysFields {
    Object.assign(this, input);
    return this;
  }
}

export class SystemGateway implements Deserializable<SystemGateway>{

  constructor(
    public ach?: GatewayDefault,
    public card?: GatewayDefault
  ){}

  deserialize(input: any): SystemGateway{
    Object.assign(this, input);
    return this;
  }
}

export class SystemMessage implements Deserializable<SystemMessage>{

  constructor(
    public id?: number,
    public message?: string,
    public status?: string,
    public type?: string,
    public level?: number,
    public createdAt?: Date,
    public updatedAt?: Date,
    public hideDismiss?: any
  ) { }

  deserialize(input: any): SystemGateway {
    Object.assign(this, input);
    return this;
  }
}

export class BillingRunOperation implements Deserializable<BillingRunOperation> {
  constructor(
  public amount?: number,
  public completedOn?: Date,
  public createdAt?: Date,
  public executedOn?: Date,
  public id?: number,
  public invoiceCount?: number,
  public limit?: number,
  public repeatType?: string,
  public status?: BillingRunStatus,
  public subscriptionCount?: number,
  public targetDate?: Date,
  public type?: string,
  public updatedAt?: Date
  ) { }

  deserialize(input: any): BillingRunOperation {
    Object.assign(this, input);
    return this;
  }
}

export class PaymentRunOperation implements Deserializable<PaymentRunOperation> {
  constructor(
    public completedOn?: Date,
    public createdAt?: Date,
    public declinedAmount?: number,
    public errorAmount?: number,
    public executedOn?: Date,
    public id?: number,
    public invoiceCount?: number,
    public paidAmount?: number,
    public repeatType?: string,
    public retryAction?: string,
    public retryCount?: string,
    public retryTargetDate?: Date,
    public retryTimeOfDate?: Date,
    public status?: PaymentRunStatus,
    public targetDate?: Date,
    public type?: string,
    public subscriptionCount?: number,
    public updatedAt?: Date
  ) { }

  deserialize(input: any): PaymentRunOperation {
    Object.assign(this, input);
    return this;
  }
}

export class BillingRunInfo implements Deserializable<BillingRunInfo> {
  constructor(
    public invoiceId ?: number,
    public description ?: string,
    public status?: BillingRunStatus,
    public subscriptionId?: number,
  ) { }

  deserialize(input: any): BillingRunInfo {
    Object.assign(this, input);
    return this;
  }
}

export class PaymentRunInfo implements Deserializable<PaymentRunInfo> {
  constructor(
    public amount?: string,
    public createdAt?: string,
    public currency?: Currency,
    public customerPaymentSourceId?: number,
    public id?: number,
    public invoiceId?: number,
    public kind?: string,
    public parentId?: number,
    public paymentAccountName?: string,
    public paymentAccountNumber?: string,
    public paymentExpDate?: string,
    public paymentType?: string,
    public transactionId?: string,
    public paymentMethod?: string,
    public status?: string,
    public type?: string,
    public updatedAt?: string
  ) { }

  deserialize(input: any): PaymentRunInfo {
    Object.assign(this, input);
    return this;
  }
}

export class Put_StoreSettingsInterface {
  constructor(
    public decimalPlaces?: string,
    public decimalToken?: string,
    public displayDateFormat?: string,
    public dstCorrection?: boolean,
    public extendedDisplayDateFormat?: string,
    public factoringDimension?: string,
    public lengthMeasurement?: number,
    public thousandsToken?: string,
    public timezone?: string,
    public weightMeasurement?: number,
    public recordsPerPage?: number,
  ){}
}
