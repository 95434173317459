import { DataSharingService } from './data.sharing.service';
import { GridService } from './grid.service';
import { LoaderService } from './loader.service';
import { RestService } from './rest.service';
import { SchedulerService } from './scheduler.service';
import { SkeletonLoaderService } from './skeleton-loader.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';
import { UtilsService } from './utils.service';
import { AppService } from './app.service';
import { ScrollService } from './scroll.service';
import { ErrorService } from './error.service';

export const SERVICES: any[] = [
  DataSharingService,
  GridService,
  LoaderService,
  RestService,
  SchedulerService,
  SkeletonLoaderService,
  StorageService,
  UserService,
  UtilsService,
  AppService,
  ScrollService,
  ErrorService
];

export * from './data.sharing.service';
export * from './grid.service';
export * from './loader.service';
export * from './rest.service';
export * from './skeleton-loader.service';
export * from './storage.service';
export * from './user.service';
export * from './utils.service';
export * from './app.service';
export * from './scroll.service';
