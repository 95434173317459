//angular
import { Injectable } from '@angular/core';
//others
import { RestResponse } from 'src/app/_shared/models';
//rxJs
import { Subject, BehaviorSubject, timer } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { SharedGlobalService } from './api/shared-global.service';
import { HttpParamsInterface } from 'src/app/_shared/interfaces/http-params.interface';

@Injectable()
export class NotificationService {
  private _status: number = 0;
  private _messageStatus = new Subject<any>();
  public changeMessageStatus = this._messageStatus.asObservable();
  private load$ = new BehaviorSubject('');
  private NOTIFICATIONS_INTERVAL: number = 30000;
  private NOTIFICATIONS_COUNT_INTERVAL: number = 120000;

  constructor(
    private sharedGlobalService: SharedGlobalService,
  ) {}

  set status(value) {
    this._status = value;
    this._messageStatus.next(value);
  }

  getNotificationsStream() {
    const httpParams: HttpParamsInterface = {
      status: 'unread',
      orderBy: 'createdAt',
      sortBy: 'ASC',
    }

    return this.load$.pipe(
      switchMap(_ => timer(0, this.NOTIFICATIONS_INTERVAL).pipe(
        concatMap(_ => this.sharedGlobalService.getNotifications(httpParams)),
        map((res: RestResponse) => res.data),
      ))
    );
  }

  getNotificationsCountStream() {
    const httpParams: HttpParamsInterface = {
      status: 'unread',
      orderBy: 'createdAt',
      sortBy: 'ASC',
    }

    return this.load$.pipe(
      switchMap(_ => timer(0, this.NOTIFICATIONS_COUNT_INTERVAL).pipe(
        concatMap(_ => this.sharedGlobalService.getNotifications(httpParams)),
        map((res: any) => res.data.length),
      ))
    );
  }

  refreshNotificationsCountStreamData() {
    this.load$.next('');
  }

  showMessage() {
    this.status = 1;
  }

  hideMessage() {
    this.status = 0;
  }


}
