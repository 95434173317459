//angular
import { Component, Inject, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
//rxjs operators
import { debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
//app
import { AppAnimations } from 'src/app/_shared/animations';
import { ExternalProductIntegration } from 'src/app/_shared/models/product.model';
import { ProductCatalogModel, ExternalProduct, ExternalProductOption } from 'src/app/_shared/models/product.model';
import { ExternalDetail } from 'src/app/_shared/models/subscriptions.model';
import { CompanyIntegrationModel, RestResponse } from 'src/app/_shared/models';
import { LoaderService, UtilsService } from 'src/app/_core/services';
import { SharedIntegrationService } from 'src/app/_core/services/api/shared-integration.service';
import {AdjustersTypes, IntegrationStatus, PriceTypes, responseCode, VisibleState} from 'src/app/app.data';
import { KeyName } from 'src/app/app.keynames';
import { ProductService } from '../../products/_services/api/product.service';
import { MetaService } from 'src/app/_core/services/meta.service';
import { _MatTableDataSource } from '@angular/material/table';
import { ValidatorService } from 'src/app/_shared/validators/validator.service';
import { Unsubscriber } from 'src/app/_shared/unsubscriber/unsubscriber';

@Component({
  animations: [AppAnimations],
  selector: 'app-dialog-link-rateplan-charge',
  templateUrl: './link-rateplan-charge.component.html',
  styleUrls: ['./link-rateplan-charge.component.scss']
})

export class DialogLinkRateplanChargeComponent implements OnInit, OnDestroy {
  addUseProductsOptionsCheckbox: boolean = false;
  autocompleteIsLoading: boolean = false;
  calulatedVariantPrice:string = '';
  checkedUseProductsOptions: boolean = false;
  checkedUseVariantsCalculatedPrice: boolean = false;
  collectionOfCompanyIntegration: Array<CompanyIntegrationModel> = [];
  collectionOfProductFiltered: Array<ExternalProductIntegration> = [];
  collectionOfProductOptions: Array<ExternalProductOption> = [];
  collectionOfVariants: Array<any> = [];
  disabledProductsOptionsCheckbox: boolean = false;
  expressionChangedAfterItHasBeenCheckedMessages: any = {};
  form: FormGroup;
  formSubmitted: boolean = false;
  integrationId: number;
  isProductReady: boolean = false;
  options: any;
  receivedExternalDetail: Array<ExternalDetail> = [];
  selectedExternalProduct: ExternalProduct;
  selectedVariant: any;
  statusForProductFiltered: Array<VisibleState> = [];
  variant: any;
  linkThisToAllChargesId = false;

  private subs = new Unsubscriber();

  @Input() _visibleState = VisibleState;

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _dialogRef: MatDialogRef<DialogLinkRateplanChargeComponent>,
    private _fb: FormBuilder,
    private _loader: LoaderService,
    public _meta: MetaService,
    private _iconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
    private sharedIntegrationService: SharedIntegrationService,
    private productService: ProductService,
    private ValidatorService: ValidatorService,
    private utils: UtilsService
  ) {
    this._iconRegistry.addSvgIcon('ico-info', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-help-faq.svg'));
    this._iconRegistry.addSvgIcon('ico-check-01', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-check-01.svg'));
    this._iconRegistry.addSvgIcon('ico-check', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-check-01.svg'));
    this._iconRegistry.addSvgIcon('ico-close', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-close-01.svg'));
    this._iconRegistry.addSvgIcon('ico-del-item', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-close-03.svg'));
    this._iconRegistry.addSvgIcon('ico-search', this._sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ico-search-01.svg'));
  }

  addOption(option, index) {
    let validations = [];

    if (option.required) {
      validations = [Validators.required];
    }

    if (!option.values) {
      validations.push(this.ValidatorService.emptySpace);
    }
    this.options.addControl(index, new FormControl('', validations));
  }

  autocompleteProductDisplayFn(product?: ProductCatalogModel): string | undefined {
    return product ? product.name : undefined;
  }

  getCompanyIntegrationsByChannelName(channelName: string) {
    this.subs.addSub = this.sharedIntegrationService.getCompanyIntegrationsByChannelName(channelName).subscribe(
      (res:RestResponse) => {
        this.collectionOfCompanyIntegration = (<Array<CompanyIntegrationModel>>res.data).filter(ci => ci.status == IntegrationStatus.active);
        if (this.integrationId) {
          this.form.get('companyIntegration').setValue(this.collectionOfCompanyIntegration.find(ci => ci.id == this.integrationId));
        }
      },
      (err:RestResponse) => {
        this._meta.showNotification(err);
      }
    );
  }

  checkForIntegrationMissingValues() {
    this.collectionOfProductOptions.forEach((productOption, index) => {
      // if is a dropdown
      if (productOption?.values) {
        for (let i = 0; i < this.receivedExternalDetail.length; i++) {
          const detail = this.receivedExternalDetail[i];
          if (!productOption.values.some(value => value.id === Number(detail.value_id))) {
            if (productOption.values.some(value => value.value === detail.value_name)) {
              this.expressionChangedAfterItHasBeenCheckedMessages[index] = `Previous value selected: "${detail.value_name}" is missing from integration`;
              this.form.get('options').get(`${index}`).setValue(null);
              this.options.get(`${index}`).markAsTouched();
              break;
            }
          } else {
            if (productOption.rebilliaOption) {
              this.form.get('options').get(`${index}`).disable();
            }
          }
        }
      // if is an input
      } else {
        let matchedDetail = this.receivedExternalDetail.find(detail => detail.option_id === productOption.id);
        if (!matchedDetail) {
          if (productOption.type === 'text') {
            let detail = this.receivedExternalDetail.find(detail => !detail.value_id);
            if (detail) {
              this.expressionChangedAfterItHasBeenCheckedMessages[index] = `Previous value: "${detail.value_name}" is missing from integration`;
              this.form.get('options').get(`${index}`).setValue(null);
              this.options.get(`${index}`).markAsTouched();
            }
          }
        }
      }
    });
  }

  convertToExternalDetailFormat(options) {
    let externalDetail = [];
    Object.entries(options).forEach(([key, option]) => {
      if (option) {
        let detail: any = {
          value_id: typeof option === 'string' ? null : Number(this.selectedExternalProduct.options[key].values.find(item => item.id == option)?.id),
          value_name: typeof option === 'string' ? option : this.selectedExternalProduct.options[key].values.find(item => item.id == option)?.value,
          option_id: Number(this.selectedExternalProduct.options[key].id),
          option_name: this.selectedExternalProduct.options[key].displayName,
        };
        externalDetail.push(detail);
      }
    });
    return externalDetail;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.setUpForm();

    if (this._data.fromProduct) {
      this.addUseProductsOptionsCheckbox = true;
      this.checkedUseProductsOptions = true;
    }

    if (this._data.integrationId) {
      this.integrationId = this._data.integrationId;
    }

    if (this._data.fromSubscription && this._data.isEdit) {
      this.receivedExternalDetail = this._data.externalRatePlanCharge.externalDetail.sort((a, b) => a.option_id - b.option_id);

      let urlParams = (this._data.subscriptionCompanyCurrency ? { companyCurrencyId: this._data.subscriptionCompanyCurrency.id} : null)

      this.subs.addSub = this.productService.searchProductByExternalProductId(this.integrationId, this._data.externalRatePlanCharge.externalId, urlParams).subscribe((res: RestResponse) => {
        this.collectionOfProductFiltered = <Array<any>>([res.data]);
        this.onSelectProductFiltered(this.collectionOfProductFiltered[0]);
        this.form.get('productName').setValue(this.collectionOfProductFiltered[0]);
        this.form.get('productName').disable();

        // set the number of input/dropdowns based on the product options
        this.collectionOfProductOptions.forEach((productOption, index) => {
          let matchedDetail = this.receivedExternalDetail.find(detail => detail.option_id === productOption.id);
          if (matchedDetail) {
            if (!matchedDetail.value_id) {
              this.options.get(`${index}`).setValue(matchedDetail.value_name);
            } else {
              this.options.get(`${index}`).setValue(Number(matchedDetail.value_id));
            }
          } else {
            // if there is a productOption that is optional or doesn't have a match with any external detail
            this.options.get(`${index}`).setValue('');
          }
        });

        this.searchForAVariant(this.receivedExternalDetail, this.collectionOfProductFiltered[0].variants);

        if (this.form.get('useSaleChannelData').value) {
          let priceType: string = PriceTypes.currentPrice;
          const calculatedPrice = this.utils.convertToNumber(this.variant?.calculated_price);
          if (Number(this.selectedExternalProduct?.price) !== calculatedPrice) {
            if (Number(this._data.charge?.price) === calculatedPrice) {
              priceType = PriceTypes.variant;
            }
          }
          this.form.get('priceType').setValue(priceType);
        }
        // this setTimeout is needed to solve ExpressionChangedAfterItHasBeenCheckedError issue
        setTimeout(() => {
          // this method is used to identify the missing values from the integration
          this.checkForIntegrationMissingValues();
        }, 100);
      },(error) => {
        this._loader.stopPageLoader();
        if (error.error && error.error.message === 'The resource was not found in Bigcommerce'){
          this._meta.showNotificationCustomMessage(responseCode.errorBadRequestCode, KeyName.error_bad_request_big_commerce);
        } else {
          this._meta.showNotification(error);
        }
        this.setAutocompleteProductControl();
        this.isProductReady = true;
      });
    } else {
      this.setAutocompleteProductControl();
    }

    this.getCompanyIntegrationsByChannelName(this._data.integrationKeyName);

    if(this._data.usageBased){
      this.form.get('quantity').disable();
      this.form.get('useSaleChannelData').setValue(false);
      this.form.get('useSaleChannelData').disable();
    }
  }

  onChangeProductOption() {
    this.searchForAVariant(this.convertToExternalDetailFormat((<FormGroup>this.form.get('options')).getRawValue()), this.collectionOfProductFiltered[0].variants);
  }

  onChangeUseProductsOptionsCheckbox() {
    this.checkedUseProductsOptions = !this.checkedUseProductsOptions;
    if (this.checkedUseProductsOptions) {
      this.form.controls.options.disable();
    } else {
      this.form.controls.options.enable();
    }
  }

  onChangeUseSaleChannelDataCheckbox() {
    if (this.form.get('useSaleChannelData').value) {
      if (!this.form.get('priceType')) {
        this.form.addControl('priceType', new FormControl(PriceTypes.currentPrice, [Validators.required]));
      }
      if (!this.form.get('quantity')) {
        this.form.addControl('quantity', new FormControl('1', [Validators.required, this.ValidatorService.positiveDecimalNumberIncludeZero]));
      }

    } else {
      if (!this.form.get('priceType')) {
        this.form.removeControl('priceType');
      }
      if (this.form.get('quantity')) {
        this.form.removeControl('quantity');
      }
    }

    // setTimeout(()=>{
    //   if(this._data.usageBased){
    //     this.form.get('quantity').disable();
    //     this.form.get('useSaleChannelData').setValue(false);
    //     this.form.get('useSaleChannelData').disable();
    //   }
    // })
  }

  onChangeUseVariantsCalculatedPriceCheckbox() {
    this.checkedUseVariantsCalculatedPrice = !this.checkedUseVariantsCalculatedPrice;
  }

  onCleanProductSearchInput(clearInput = true) {
    this.collectionOfProductFiltered = [];
    if(clearInput)
      this.form.controls.productName.setValue('');
    this.form.controls.options = this._fb.group({});
    this.form.controls.options.updateValueAndValidity();
    this.options = <FormGroup>this.form.controls.options;
    this.collectionOfProductOptions = [];
    this.selectedExternalProduct = null;
    this.isProductReady = false;
  }

  onClose(): void {
    this._dialogRef.close({ code: KeyName.form_invalid });
  }

  onCompanyIntegrationChange(_companyIntegration: CompanyIntegrationModel) {
    this.form.get('companyIntegration').setValue(_companyIntegration);
    this.integrationId = _companyIntegration.id;
    this.onCleanProductSearchInput();
  }

  onKeyUpInput() {
    this.searchForAVariant(this.convertToExternalDetailFormat((<FormGroup>this.form.get('options')).getRawValue()), this.collectionOfProductFiltered[0].variants);
  }

  onSelectProductFiltered(_product: ExternalProduct) {
    this.collectionOfProductOptions = [];
    this.selectedExternalProduct = _product;
    this.selectedExternalProduct.options = this.selectedExternalProduct.options.sort((a, b) => a.id - b.id);

    if (this._data.fromProduct) {
      if (this._data.externalId == this.selectedExternalProduct.id) {
        this.checkedUseProductsOptions = true;
        this.disabledProductsOptionsCheckbox = true;
      } else {
        this.checkedUseProductsOptions = true;
        this.disabledProductsOptionsCheckbox = false;
      }
    }

    this.selectedExternalProduct.options.forEach((option, index) => {
      this.collectionOfProductOptions.push(option);
      this.addOption(option, index);
    });

    this.collectionOfProductOptions = this.collectionOfProductOptions.sort((a, b) => a.id - b.id);

    if (this.collectionOfProductOptions.length > 0) {
      const min = 1231;
      const max = 23121331;
      this.collectionOfProductOptions.forEach((item) => {
        if (!item.required) {
          item.values.unshift({
            id: Math.floor(Math.random() * (max - min + 1)) + min,
            value: 'None'
          })
        }
      })
    }
    this.isProductReady = true;
  }

  onSubmit() {
    // next line is an workaround to form being invalid even if controls are valid.
    this.form.updateValueAndValidity();
    // seems like all are valid but values of options are empty.
    // updating form seems its working

    this.formSubmitted = !this.formSubmitted;
    let formValidation = this.form.invalid || this.form.get('options').invalid;
    if(this._data.integrationId  && this._data.integrationId != this.form.get('companyIntegration')?.value.id) {
      this._meta.showNotificationCustomMessage(responseCode.errorBadRequestCode, null, 'Cross Channel Subscriptions is not available in your plan');
      return
    }
    if (this.checkedUseProductsOptions) {
      this.form.controls.options.disable();
      formValidation = this.form.invalid;
    }

    if (formValidation) {
      this.formSubmitted = !this.formSubmitted;
      this.form.markAllAsTouched();
      this.form.markAsDirty();
      return;
    }

    let externalDetail = [];

    if (!this.checkedUseProductsOptions) {
      externalDetail = this.convertToExternalDetailFormat((<FormGroup>this.form.get('options')).getRawValue());

      if (this._data.fromProduct) {
        externalDetail.forEach(detail => {
          detail.rebillia_option = false;
        });
      }
    }

    if (externalDetail && externalDetail.length > 0) {
      externalDetail = externalDetail.filter((item) => item.value_name !== "None")
    }

    let res: any = {
      code: KeyName.form_submitted,
      selectedExternalProduct: this.selectedExternalProduct,
      externalDetail: externalDetail,
    };

    if (this._data.fromSubscription) {
      if (!this._data.isEdit) {
        res.integrationId = this.integrationId;
      }

      if (this.form.get('useSaleChannelData').value) {
        if (this.form.get('priceType').value === PriceTypes.variant) {
          res.price = Number(this.calulatedVariantPrice);
        } else if (this.form.get('priceType').value === PriceTypes.currentPrice) {
          res.price = Number(this._data.charge.price);
        } else {
          res.price = this.selectedExternalProduct.price;
        }
        res.quantity = Number(this.form.get('quantity').value);
      }

      res.useSaleChannelData = this.form.get('useSaleChannelData').value;
    }

    res.linkThisToAllChargesId = this.linkThisToAllChargesId;
    this._loader.startPageLoader();
    this._dialogRef.close(res);
  }

  searchForAVariant(options, collectionOfVariants) {
    if (this.form.get('options').valid) {
      this.calulatedVariantPrice = '0';
      this.variant = this.utils.findVariant(options, collectionOfVariants);

      const collectionOfOptions = this.utils.findOptionVariant(options, this.collectionOfProductFiltered[0].options);
      let sumOfAdjusterPrices = 0;

      const priceTotal = this.variant?.price ? this.utils.convertToNumber(this.variant?.price) : this.utils.convertToNumber(this.variant?.calculated_price);

      if(collectionOfOptions?.length > 0) {
        collectionOfOptions.forEach(element => {
          sumOfAdjusterPrices += element.adjusters?.adjusterPrice?.adjusterValue ?
          (
            element.adjusters?.adjusterPrice?.adjuster === AdjustersTypes.percentage ?
              this.calculatePercentageValue(this.utils.convertToNumber(element.adjusters?.adjusterPrice?.adjusterValue), priceTotal) :
              this.utils.convertToNumber(element.adjusters?.adjusterPrice?.adjusterValue)
          )
          : 0});
      }

      if (this.variant) {

        const hasPercentage = collectionOfOptions.some(element => element.adjusters?.adjusterPrice?.adjuster === AdjustersTypes.percentage);

        if(hasPercentage) {
          this.calulatedVariantPrice = this.utils.formatDecimalNumberToReceive(this.utils.convertToNumber(sumOfAdjusterPrices));
        } else {
          this.calulatedVariantPrice = this.utils.formatDecimalNumberToReceive(this.utils.convertToNumber(this.utils.formatDecimalNumberToReceive(this.variant?.calculated_price)) + sumOfAdjusterPrices);
        }

        if (this.form.get('priceType')) {
          this.form.get('priceType').setValue(PriceTypes.variant);
          this.form.updateValueAndValidity();
        }
      } else {
        if (this.form.get('priceType')) {
          this.form.get('priceType').setValue(PriceTypes.currentPrice);
          this.form.updateValueAndValidity();
        }
      }
    }
  }

  searchProduct(_value) {
    if (typeof(_value) === 'string' && _value !== '') {
      return this.productService.searchProductByExternalProductName(this.integrationId, _value)
        .pipe(
          finalize(
            () => (this.autocompleteIsLoading = false)
          ),
        )
    } else {
      this.autocompleteIsLoading = false;
    }
    return [];
  }

  setAutocompleteProductControl() {
    const queryField = this.form.controls.productName;
    queryField.valueChanges.pipe(
        debounceTime(300),
        tap(() => {
          this.autocompleteIsLoading = true;
        }),
        switchMap((value) => this.searchProduct(value))
      )
      .subscribe((res: RestResponse) => {
        this.collectionOfProductFiltered = <Array<any>>(res.data);
      },() => {
        this.setAutocompleteProductControl();
        this.isProductReady = true;
      });
  }

  setUpForm() {
    this.form = this._fb.group({
      companyIntegration: ['', [Validators.required]],
      productName: ['', [Validators.required]],
      options: this._fb.group({}),
    });

    this.options = <FormGroup>this.form.controls.options;

    if(this._data.fromSubscription) {
      this.form.addControl('useSaleChannelData', new FormControl(this._data.charge?.useSaleChannelData ? this._data.charge?.useSaleChannelData : false));

      if (this.form.get('useSaleChannelData').value) {
        if (this._data.isEdit) {
          if (!this.form.get('priceType')) {
            this.form.addControl('priceType', new FormControl('', [Validators.required]));
          }
          if (!this.form.get('quantity')) {
            this.form.addControl('quantity', new FormControl((this._data.charge?.quantity ? this._data.charge?.quantity : '1'), [Validators.required, this.ValidatorService.positiveDecimalNumberIncludeZero]));
          }
        } else {
          this.onChangeUseSaleChannelDataCheckbox();
        }
      }
    }
  }

  calculatePercentageValue(partialValue: number, totalValue: number) {
    return totalValue + ((partialValue * totalValue)/100);
  }
}
