//angular
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'element-button-secondary',
  styleUrls: ['./button-secondary.component.scss'],
  templateUrl: './button-secondary.component.html'
})
export class ButtonSecondaryComponent implements OnInit {

  @Input() text: string;

  constructor() { }

  ngOnInit(): void {
  }

}
