//angular
import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { Observable } from "rxjs";
import { OverlayContainer } from "@angular/cdk/overlay";
//app

import { Deserializable } from "../../_shared/interfaces/deserialize.interface";
import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { GridColumn } from "../../_shared/models/grid.model";
import {
  CompanyGateway,
  CompanyIntegrationModel,
  CompanyProfile,
  PaginatorResult,
  RestResponse
} from "src/app/_shared/models";
import { CompanySettingsKey, GatewayCode, IntegrationStatus, responseCode, SettingsType, VisibleState } from "src/app/app.data";
import { KeyName } from 'src/app/app.keynames';
import { DataSharingService } from "./data.sharing.service";
import { CompanyCurrency } from "src/app/_shared/models/company.model";
import { DialogMessageTimerComponent } from "src/app/modules/main/_shared/dialog/message-timer/message-timer.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { GatewayDefault, SettingsDateFormat, SettingsMetrics, SystemGateway } from 'src/app/_shared/models/system.model';
import { SharedGlobalService } from "./api/shared-global.service";
import { SharedIntegrationService } from "./api/shared-integration.service";
import { environment } from "src/environments/environment";

export class ColumnAttr implements Deserializable<ColumnAttr> {
  constructor(
    gridColumnId?: number,
    priority?: number,
    checked?: boolean,
    visible?: string,
  ) { }

  deserialize(input: any): ColumnAttr {
    Object.assign(this, input);
    return this;
  }
}

@Injectable()
export class MetaService {
  collectionOfMetaFields: Array<any> = [];
  private currentEnvironment: string = null;

  private renderer: Renderer2;

  private urlAuthorizeAccept: string = null;
  private urlBraintreeClient: string = null;
  private urlBraintreeHostedFields: string = null;
  private urlCybersourceHostedFields: string = null;
  private urlBraintreeDataCollector: string = null;
  private urlStripe: string = null;
  private urlSquare: string = null;
  private urlUsaepay: string = null;

  constructor(
    private _overlayContainer: OverlayContainer,
    private _notification: MatSnackBar,
    private _shareData: DataSharingService,
    private sharedGlobalService: SharedGlobalService,
    private sharedIntegrationService: SharedIntegrationService,
    rendererFactory: RendererFactory2
  ) {
    console.log(`meta.service was loaded`);
    this.renderer = rendererFactory.createRenderer(null, null);

  }

  public onChangeGridColumnsPreferences(_event: any, _column: GridColumn) {
    this.collectionOfMetaFields[_column.keyName].visible = (_event.checked ? VisibleState.isVisible : VisibleState.isHidden);
  }

  public onCloseGridColumnsPreferences(_collectionOfColumn: any) {
    let form: FormArray = new FormArray([]);

    Object.entries(_collectionOfColumn).forEach(([key, value]) => {
      let formGroup: FormGroup = new FormGroup({
        gridColumnId: new FormControl(''),
        checked: new FormControl(false),
        priority: new FormControl(0)
      })
      formGroup.controls.gridColumnId.setValue(value['gridColumnId']);
      formGroup.controls.checked.setValue((value['visible'] == VisibleState.isHidden ? false : true));
      formGroup.controls.priority.setValue(value['priority']);
      form.push(formGroup);
    })

    this.sharedGlobalService.createColumnsPreferences(form.value).subscribe(
      (res: RestResponse) => {},
      (err: RestResponse) => {
        console.log(`err. ${err}`);
      }
    )
  }

  public onMenuFilterClosed() {
    this._overlayContainer.getContainerElement().classList.remove('disable-backdrop-click');
  }

  public onMenuFilterOpened() {
    this._overlayContainer.getContainerElement().classList.add('disable-backdrop-click');
  }

  checkGatewaysScriptImported() {
      const isEnvironmentSandbox = (this.currentEnvironment === 'sandbox' ? true : false);

      this.urlAuthorizeAccept = isEnvironmentSandbox ? environment.urlAuthorizeAcceptSandbox : environment.urlAuthorizeAccept;
      this.urlBraintreeClient = isEnvironmentSandbox ? environment.urlBraintreeClientSandbox : environment.urlBraintreeClient;
      this.urlBraintreeHostedFields = isEnvironmentSandbox ? environment.urlBraintreeHostedFieldsSandbox : environment.urlBraintreeHostedFields;
      this.urlCybersourceHostedFields = isEnvironmentSandbox ? environment.urlCybersourceHostedFieldsSandbox : environment.urlCybersourceHostedFields;
      this.urlBraintreeDataCollector = isEnvironmentSandbox ? environment.urlBraintreeDataCollectorSandbox : environment.urlBraintreeDataCollector;
      this.urlStripe = isEnvironmentSandbox ? environment.urlStripeSandbox : environment.urlStripe;
      this.urlSquare = isEnvironmentSandbox ? environment.urlSquareSandbox : environment.urlSquare;
      this.urlUsaepay = isEnvironmentSandbox ? environment.urlUsaepaySandbox : environment.urlUsaepay;

      const collectionOfScripts = document.head.getElementsByTagName('script');
      let isAnyGateway = false;

      for (var i = (collectionOfScripts.length-1); i >= 0; i--) {
        if(collectionOfScripts[i].getAttribute('id') != 'a' &&
          (collectionOfScripts[i].src == this.urlAuthorizeAccept ||
          collectionOfScripts[i].src == this.urlBraintreeClient ||
          collectionOfScripts[i].src == this.urlBraintreeHostedFields ||
          collectionOfScripts[i].src == this.urlBraintreeDataCollector ||
          collectionOfScripts[i].src == this.urlStripe ||
          collectionOfScripts[i].src == this.urlSquare ||
          collectionOfScripts[i].src == this.urlCybersourceHostedFields ||
          collectionOfScripts[i].src == this.urlUsaepay)
          ) {
            isAnyGateway = true;
        }
      }

      if(!isAnyGateway) {
        this.setCompanyGateways();
      }
  }

  private createScript(urlGateWay: string, type?: string, charset?:string) {
    const script = this.renderer.createElement('script');
    script.src = urlGateWay;
    if(type) {
      script.type = type;
    }

    if(charset) {
      script.charset = charset;
    }

    return script;

  }

  isScriptAdded(environmentUrl:string) {
    const collectionOfScripts = document.head.getElementsByTagName('script');
    let returnValue = false;

    for (var i = (collectionOfScripts.length-1); i >= 0; i--) {

        if(collectionOfScripts[i].getAttribute('id') != 'a' &&
          (collectionOfScripts[i].src == environmentUrl)
        ){
          returnValue = true;
        }
    }
    return returnValue;
  }

  loadGatewayScript(gateWayName:string) {
    switch (gateWayName) {
      case GatewayCode.braintree:
        if(!this.isScriptAdded(this.urlBraintreeClient)) {
          this.renderer.appendChild(document.head, this.createScript(this.urlBraintreeClient));
        }
        if(!this.isScriptAdded(this.urlBraintreeHostedFields)) {
          this.renderer.appendChild(document.head, this.createScript(this.urlBraintreeHostedFields));
        }

        if(!this.isScriptAdded(this.urlBraintreeDataCollector)) {
          this.renderer.appendChild(document.head, this.createScript(this.urlBraintreeDataCollector));
        }
        break;
      case GatewayCode.authorize:
        if(!this.isScriptAdded(this.urlAuthorizeAccept)) {
          this.renderer.appendChild(document.head, this.createScript(this.urlAuthorizeAccept, 'text/javascript', 'utf-8'));
        }
        break;
      case GatewayCode.cybersource:
        if(!this.isScriptAdded(this.urlCybersourceHostedFields)) {
          this.renderer.appendChild(document.head, this.createScript(this.urlCybersourceHostedFields));
        }
        break;
      case GatewayCode.stripe:
        if(!this.isScriptAdded(this.urlStripe)) {
          this.renderer.appendChild(document.head, this.createScript(this.urlStripe));
        }
        break;
      case GatewayCode.square:
        if(!this.isScriptAdded(this.urlSquare)) {
          this.renderer.appendChild(document.head, this.createScript(this.urlSquare));
        }
        break;
      case GatewayCode.usaepay:
        if(!this.isScriptAdded(this.urlUsaepay)) {
          this.renderer.appendChild(document.head, this.createScript(this.urlUsaepay));
        }
        break;

    }
  }

  loadGatewayScriptCreateCompany(gateWayName:string) {
    switch (gateWayName) {
      case GatewayCode.braintree:
        if(!this.isScriptAdded(environment.urlBraintreeClient)) {
          this.renderer.appendChild(document.head, this.createScript(environment.urlBraintreeClient));
          this.urlBraintreeClient = environment.urlBraintreeClient;
        }
        if(!this.isScriptAdded(environment.urlBraintreeHostedFields)) {
          this.renderer.appendChild(document.head, this.createScript(environment.urlBraintreeHostedFields));
          this.urlBraintreeHostedFields = environment.urlBraintreeHostedFields;
        }

        if(!this.isScriptAdded(environment.urlBraintreeDataCollector)) {
          this.renderer.appendChild(document.head, this.createScript(environment.urlBraintreeDataCollector));
          this.urlBraintreeDataCollector = environment.urlBraintreeDataCollector;
        }
        break;
      case GatewayCode.cybersource:
        if(!this.isScriptAdded(environment.urlCybersourceHostedFields)) {
          this.renderer.appendChild(document.head, this.createScript(environment.urlCybersourceHostedFields));
          this.urlCybersourceHostedFields = environment.urlCybersourceHostedFields;
        }
        break;
      case GatewayCode.authorize:
        if(!this.isScriptAdded(environment.urlAuthorizeAccept)) {
          this.renderer.appendChild(document.head, this.createScript(environment.urlAuthorizeAccept, 'text/javascript', 'utf-8'));
          this.urlAuthorizeAccept = environment.urlAuthorizeAccept;
        }
        break;
      case GatewayCode.stripe:
        if(!this.isScriptAdded(environment.urlStripe)) {
          this.renderer.appendChild(document.head, this.createScript(environment.urlStripe));
          this.urlStripe = environment.urlStripe;
        }
        break;
      case GatewayCode.square:
        if(!this.isScriptAdded(environment.urlSquare)) {
          this.renderer.appendChild(document.head, this.createScript(environment.urlSquare));
          this.urlSquare = environment.urlSquare;
        }
        break;
      case GatewayCode.usaepay:
        if(!this.isScriptAdded(environment.urlUsaepay)) {
          this.renderer.appendChild(document.head, this.createScript(environment.urlUsaepay));
          this.urlUsaepay = environment.urlUsaepay;
        }
        break;
    }
  }

  public setCompanyCurrencyBase(){
    this.sharedGlobalService.getCompanyCurrenciesDefault().subscribe(
      (res:RestResponse)=>{
        let companyCurrencyBase: CompanyCurrency = new CompanyCurrency().deserialize(res.data);
        this._shareData.setCompanySettingsCurrencyBaseSource({currencyBase: companyCurrencyBase})
      },
      (err:RestResponse)=>{
        console.log(err);
      }
    )
  }

  public setCompanyGateways()
  {
    this.sharedGlobalService.getActiveCompanyGateways()
    .subscribe(
      (res: RestResponse) => {
        const collectionOfCompanyGateways = <Array<CompanyGateway>>res.data;
        let collectionOfNames:Array<string> =[];

        collectionOfCompanyGateways.forEach(
          _gateway => {
            const elementValue = collectionOfNames.some(nameElement => nameElement == _gateway.keyName);

            if(!elementValue) {
              collectionOfNames.push(_gateway.keyName);
            }
        });

        collectionOfNames.forEach(
          gateway => {
            this.loadGatewayScript(gateway);
        });
        // TODO REMOVE
        if(collectionOfNames.indexOf(GatewayCode.authorize) == -1){
          this.loadGatewayScript(GatewayCode.authorize);
        }
      },
      (err: RestResponse) => {
        this.showNotification(err);
      }
    )

  }

  public setCompanySettings() {
    this._shareData.setCompanySettingsEnvironment({ environment: 'production' });
    this._shareData.setCompanySettingsShippingSource({ shipping: '0' });
    this._shareData.setCompanySettingsTaxSource({ tax: '0' });
    this._shareData.setCompanySettingsTimezone({ timezone: '15' });

    this.sharedGlobalService.getCompanySettingsBySettingType(SettingsType.settings).subscribe(
      (res: RestResponse) => {
        this._shareData.setCompanySettingsShippingSource({shipping: Number(res.data[CompanySettingsKey.shipping])});
        this._shareData.setCompanySettingsEnvironment({environment: res.data[CompanySettingsKey.environment]});
        this.currentEnvironment = res.data[CompanySettingsKey.environment];
        if (Number(res.data[CompanySettingsKey.tax]) === 1) {
          this.sharedIntegrationService.getIntegrationByIntegrationType(CompanySettingsKey.tax).subscribe(
            (res: RestResponse) => {
              let collectionOfCompanyIntegration = <Array<CompanyIntegrationModel>>res.data
              collectionOfCompanyIntegration.forEach(_companyIntegration => {
                if (_companyIntegration.status == IntegrationStatus.active){
                  this._shareData.setCompanySettingsTaxSource({tax: _companyIntegration.id});
                }
              });
            },
            (err: RestResponse) => {
              console.log(err)
            }
          )
        } else {
          this._shareData.setCompanySettingsTaxSource({tax: Number(res.data[CompanySettingsKey.tax])});
        }

        this.checkGatewaysScriptImported();

      },
      (err: RestResponse) => {
        console.log(err);
      }
    )

    this.sharedGlobalService.getCompanySettingsBySettingType(SettingsType.paginator).subscribe(
      (res: RestResponse) => {
        if (res.data) {
          let companyPaginator = new PaginatorResult().deserialize(res.data);
          this._shareData.setCompanyTableRecordsPerPage({ recordsPerPage: companyPaginator.recordsPerPage ? Number(companyPaginator.recordsPerPage) : 25 });
        }
      },
      (err: RestResponse) => {
        console.log(err);
      }
    )

    this.sharedGlobalService.getEntityProfile().subscribe(
      (res: RestResponse) => {
        let company = new CompanyProfile().deserialize(res.data);
        this._shareData.setCompany({ companyId: company.id, companyName: company.name, type: company.type, status: company.status, cancelledAt: company.cancelledAt });
      },
      (err: RestResponse)=> {
        console.log(err);
      }
    );

    this.sharedGlobalService.getCompanySettingsBySettingType(SettingsType.dateFormat).subscribe(
      (resSettingDateformat: RestResponse) => {
        let companyDateFormat = new SettingsDateFormat().deserialize(resSettingDateformat.data);
        this._shareData.setDisplayDateFormat(companyDateFormat.displayDateFormat);
        this.sharedGlobalService.getTimeZones().subscribe(
          (res: RestResponse) => {
            let collectionOfTimezone = <Array<any>>res.data;
            let timezone = collectionOfTimezone.find(item=> item.id == resSettingDateformat.data[CompanySettingsKey.timezone])
            this._shareData.setCompanySettingsTimezone(timezone);
          },
          (err: RestResponse) => {
            console.log(err);
          }
        )

      },
      (err: RestResponse) => {
        console.log(err);
      }
    )

    this.sharedGlobalService.getCompanySettingsBySettingType(SettingsType.metrics).subscribe(
      (res: RestResponse) => {
        let companyMetrics = new SettingsMetrics().deserialize(res.data);
        let decimalPlaces = companyMetrics.decimalPlaces;
        let decimalToken = companyMetrics.decimalToken;
        let factoringDimension = companyMetrics.factoringDimension;
        let lengthMeasurement = companyMetrics.lengthMeasurement
        let thousandsToken = companyMetrics.thousandsToken;
        let weightMeasurement = companyMetrics.weightMeasurement
        this._shareData.setDecimalPlaces(decimalPlaces);
        this._shareData.setDecimalToken(decimalToken);
        this._shareData.setThousandsToken(thousandsToken);

        this._shareData.setFactoringDimension(factoringDimension);
        this._shareData.setLengthMeasurement(lengthMeasurement);
        this._shareData.setWeightMeasurement(weightMeasurement);

        this._shareData.setSettingsVariables({ decimalPlaces, decimalToken, factoringDimension, lengthMeasurement, thousandsToken, weightMeasurement});

      },
      (err: RestResponse) => {
        console.log(err);
      }
    )
  }

  public setDateFormat(date: Date, displayFormat: Object): string{
    if (displayFormat === 'YYYY-MM-DD') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }
    return date.toDateString();
  }

  public setGridColumns(_collectionOfColumn: Array<GridColumn>): Observable<any> {
    return new Observable((observer) => {
      this.collectionOfMetaFields = [] ;
      let position = 1;
      _collectionOfColumn.forEach(element => {
        this.collectionOfMetaFields[element.keyName] = new ColumnAttr().deserialize({});
        this.collectionOfMetaFields[element.keyName].gridColumnId = element.id;
        this.collectionOfMetaFields[element.keyName].checked = element.checked;
        this.collectionOfMetaFields[element.keyName].priority = position;
        this.collectionOfMetaFields[element.keyName].visible = (element.checked ? VisibleState.isVisible : VisibleState.isHidden);
        position++;
      });
      return observer.next(this.collectionOfMetaFields);
    })
  }

  public setSystemGateway() {
    this.sharedGlobalService.getSystemGateways().subscribe(
      (res: RestResponse) => {
        let resGateways = new SystemGateway().deserialize(res.data);
        const gatewayDefaultCC: GatewayDefault = new GatewayDefault().deserialize(resGateways.card);
        this.loadGatewayScriptCreateCompany(gatewayDefaultCC.gatewayData.keyName);
      },
      (err:RestResponse) => {
        this.showNotification(err);
      }
    );
  }

  // Use only for custom Front-end messages:
  //_customMessage: any message that We need to show instead of messages alloweded in app.keynames.ts
  //_templateLiterals contains the object to be shown in the notification message
  public showNotificationCustomMessage(_statusCode: any, _keyName: any, _customMessage?: string, _templateLiterals?: string[]) {
    const statusCode = _statusCode == responseCode.successCode ? ['custom-mat-dialog', 'success'] :
                      (_statusCode  == responseCode.successCodeWithError ?  ['custom-mat-dialog', 'success_with_error'] :
                      ['custom-mat-dialog', 'bad-request']);
    this._notification.openFromComponent(DialogMessageTimerComponent, {
      duration: 5000,
      panelClass: statusCode,
      data: {
        statusCode: _statusCode,
        keyName: _keyName,
        extraData: _templateLiterals ? _templateLiterals : null,
        customMessage: _customMessage ? _customMessage: null
      }
    });
  }

  public showNotification(response: RestResponse) {

    this._notification.openFromComponent(DialogMessageTimerComponent, {
      duration: 5000,
      panelClass: ((response?.code && response.code == responseCode.successCode ) ? ['custom-mat-dialog', 'success'] : ['custom-mat-dialog', 'bad-request']),
      data: {
        statusCode: (response?.code ? response.code : responseCode.errorBadRequestCode),
        keyName: response?.code == responseCode.successCode ? KeyName.request_success : response?.error?.code,
        customMessage:  response?.error?.message
      }
    });
  }

  public sortGridColumns(_collectionOfColumn: Array<GridColumn>) {
    let position = 1;
    _collectionOfColumn.forEach(element => {
      this.collectionOfMetaFields[element.keyName].priority = position;
      position++;
    });
  }

  public removeGatewayScripts() {
    const collectionOfScripts = document.head.getElementsByTagName('script');

    for (var i = (collectionOfScripts.length-1); i >= 0; i--) {

      if(collectionOfScripts[i].getAttribute('id') != 'a' &&
        (collectionOfScripts[i].src == this.urlAuthorizeAccept ||
          collectionOfScripts[i].src == this.urlBraintreeClient ||
          collectionOfScripts[i].src == this.urlBraintreeHostedFields ||
          collectionOfScripts[i].src == this.urlCybersourceHostedFields ||
          collectionOfScripts[i].src == this.urlBraintreeDataCollector ||
          collectionOfScripts[i].src == this.urlSquare ||
          collectionOfScripts[i].src == this.urlStripe)
        ) {
        collectionOfScripts[i].parentNode.removeChild(collectionOfScripts[i]);
      }
    }
  }

}
