import { Deserializable } from '../interfaces/deserialize.interface';

export interface AvalaraAddressInterface {
  boundaryLevel: string,
  city: string,
  country: string,
  id: number
  latitude: string,
  line1: string,
  line2: string,
  line3: string,
  longitude: string,
  postalCode: string,
  region: string,
  taxRegionId: number
  transactionId: number
}

export interface AvalaraLinesInterface {
  costInsuranceFreight: number,
  details: Array<any>,
  discountAmount: number,
  exemptAmount: number,
  exemptCertId: number,
  hsCode: string,
  id: number,
  isItemTaxable: boolean,
  lineAmount: number,
  lineNumber: string,
  nonPassthroughDetails: Array<any>,
  quantity: number,
  reportingDate: string,
  tax: number,
  taxCalculated: number,
  taxCode: string,
  taxCodeId: number,
  taxDate: string,
  taxIncluded: boolean
  taxableAmount: number,
  transactionId: number,
  vatCode: string,
  vatNumberTypeId: number,
}

export interface AvalaraSummaryInterface {
  country: string,
  exemption: number,
  jurisCode: string,
  jurisName: string,
  jurisType: string,
  nonTaxable: number,
  rate: number,
  rateType: string,
  region: string,
  stateAssignedNo: string,
  tax: number,
  taxAuthorityType: number,
  taxCalculated: number,
  taxName: string,
  taxSubType: string,
  taxType: string,
  taxable: number
}

export interface taxItemInterface {
    amount: string,
    quantity: string,
    itemCode: string,
    avalaraCode: string
}

export class AvalaraIntegrationInfo implements Deserializable<AvalaraIntegrationInfo>{
  constructor(
    public IsFein?: boolean,
    public accountId?: number,
    public companyCode?: string,
    public createdDate?: string,
    public createdUserId?: number,
    public defaultCountry?: string,
    public hasProfile?: boolean,
    public id?: number,
    public inProgress?: boolean,
    public isActive?: boolean,
    public isDefault?: boolean,
    public isReportingEntity?: boolean,
    public isTest?: boolean,
    public modifiedDate?: string,
    public modifiedUserId?: number,
    public name?: string,
    public roundingLevelId?: string,
    public taxpayerIdNumber?: string,
    public warningsEnabled?: boolean
   ){}

  deserialize(input: any): AvalaraIntegrationInfo {
    Object.assign(this, input);
    return this;
  }
}

export class AvalaraTaxRates implements Deserializable<AvalaraTaxRates>{
  constructor(
    public name?: string,
    public rate?: number,
    public type?: string
  ){}

  deserialize(input: any): AvalaraTaxRates {
    Object.assign(this, input);
    return this;
  }
}

export class AvalaraTaxRegion implements Deserializable<AvalaraTaxRegion> {
  constructor(
    public id?:	number,
    public name?:	string,
    public region?:	string,
    public effectiveDate?: string,
    public endDate?: string
  ){}

  deserialize(input: any): AvalaraTaxRegion {
    Object.assign(this, input);
    return this;
  }
}

export class AvalaraTaxRegionsByCompany implements Deserializable<AvalaraTaxRegionsByCompany> {
  constructor(
    public name?: string,
    public regionIso?: string,
    public regions?: Array<AvalaraTaxRegion>,
  ){}

  deserialize(input: any): AvalaraTaxRegionsByCompany {
    Object.assign(this, input);
    return this;
  }
}

export class AvalaraTaxRegionsByCountry implements Deserializable<AvalaraTaxRegionsByCountry> {

  constructor(
    public code?: string,
    public countryCode?: string,
    public localizedNames?: Array<any>,
    public name?: string,
    public streamlinedSalesTax?: boolean
  ) { }

  deserialize(input: any): AvalaraTaxRegionsByCountry {
    Object.assign(this, input);
    return this;
  }
}

export class AvalaraTaxResponse implements Deserializable<AvalaraTaxResponse> {
  constructor(
    public amount?: number,
    public tax?: number,
    public taxRates?: Array<AvalaraTaxRates>,
    public totalRate?: number
  ) { }

  deserialize(input: any): AvalaraTaxResponse {
    Object.assign(this, input);
    return this;
  }
}

export class BigcommerceProductModel implements Deserializable<BigcommerceProductModel> {
  constructor(
    public companyIntegration?: CompanyIntegrationModel,
    public directURL?: string,
    public id?: number,
    public image?: string,
    public modifierDisplayName?: string,
    public name?: string,
    public options?: Array<any>,
    public price?: string,
    public sku?: string,
    public url?: string,
    public variants?: Array<any>,
  ) {}

  deserialize(input: any): BigcommerceProductModel {
    Object.assign(this, input);
    return this;
  }
}

export class CompanyIntegrationModel implements Deserializable<CompanyIntegrationModel> {
  constructor(
    public connected?: boolean,
    public createdAt?: Date,
    public description?: string,
    public displayName?: string,
    public id?: number,
    public integrationKeyName?: string,
    public integrationKey?: Array<IntegrationKey>,
    public integrationSetting?: Array<IntegrationSettings>,
    public gblIntegration?: CompanyIntegrationModel,
    public logo?: string,
    public name?: string,
    public publicKey?: string,
    public status?: string,
    public updatedAt?: Date,
    public urlRedirect?: string,
  ) {}

  deserialize(input: any): CompanyIntegrationModel {
    Object.assign(this, input);
    return this;
  }
}

export class CompanyIntegrationSettings implements Deserializable<CompanyIntegrationSettings> {
  constructor(
    public integrationSettingId?: number,
    public value?: string,
  ) {}

  deserialize(input: any): CompanyIntegrationSettings {
    Object.assign(this, input);
    return this;
  }
}

export class IntegrationExternalStatuses implements Deserializable<IntegrationExternalStatuses> {
  constructor(
    public custom_label?: string,
    public id?: string,
    public name?: string,
    public order?: number,
    public system_label?: string,
    public system_description?: string
  ) {}

  deserialize(input: any): IntegrationExternalStatuses {
    Object.assign(this, input);
    return this;
  }
}

export class Integration implements Deserializable<Integration> {
  createdAt: string
  code: string
  displayName: string
  description: string
  id: number
  logo: string
  name: string
  status: string
  updatedAt: string

  deserialize(input: any): Integration {
      Object.assign(this, input);
      return this;
  }
}

export class IntegrationSettings implements Deserializable<IntegrationSettings> {
  constructor(
    public id?: number,
    public jsonValue?: string,
    public keyName?: string,
    public label?: string,
    public required?: boolean,
    public type?: string,
    public value?: any,
  ) {}

  deserialize(input: any): IntegrationSettings {
    Object.assign(this, input);
    return this;
  }
}

export class IntegrationKey implements Deserializable<IntegrationKey> {
  constructor(
    public id?: number,
    public keyName?: string,
    public label?: string,
    public required?: boolean,
    public type?: string,
    public visible?: boolean,
    public value?: string,
  ){}

  deserialize(input: any): IntegrationKey {
      Object.assign(this, input);
      return this;
  }
}

export class IntegrationRelationsModel implements Deserializable<IntegrationRelationsModel> {
  constructor(
    public code?: string,
    public companyIntegration ?: string,
    public integration ?: string,
    public message ?: string
  ) {}

  deserialize(input: any): IntegrationRelationsModel {
    Object.assign(this, input);
    return this;
  }
}

export class POST_CompanyIntegrationModel implements Deserializable<POST_CompanyIntegrationModel> {
  constructor(
    public integrationKeys?: Object,
    public displayName?: string,
    public status?: string,
  ) {}

  deserialize(input: any): POST_CompanyIntegrationModel {
    Object.assign(this, input);
    return this;
  }
}

export class PUT_CompanyIntegrationModel implements Deserializable<PUT_CompanyIntegrationModel> {
  constructor(
    public displayName?: string,
    public status?: string,
  ) {}

  deserialize(input: any): PUT_CompanyIntegrationModel {
    Object.assign(this, input);
    return this;
  }
}

export class ShipperHqServicesModel implements Deserializable<ShipperHqServicesModel>{
  constructor(
    public companyIntegrationId?: number,
    public integrationName?: string,
    public carrierName?: string,
    public serviceId?: string,
    public serviceName?: string,
    public carrierCode?: string,
    public carrierTitle?: string,
    public amount?: string,
  ){}

  deserialize(input: any): ShippingServiciesModel {
    Object.assign(this, input);
    return this;
  }
}

export class ShippingServiciesModel implements Deserializable<ShippingServiciesModel>{
  constructor(
    public companyIntegrationId?: number,
    public carrierName?: string,
    public serviceId?: string,
    public serviceName?: string
  ){}

  deserialize(input: any): ShippingServiciesModel {
    Object.assign(this, input);
    return this;
  }
}

export class ShippingRatesModel implements Deserializable<ShippingRatesModel>{
  constructor(
    public amount?: string,
    public companyIntegrationId?: number,
    public currency?: string,
    public integrationName?: string,
    public serviceId?: string,
    public serviceName?: string,
    public carrierName?: string
  ){}

  deserialize(input: any): ShippingRatesModel {
    Object.assign(this, input);
    return this;
  }
}

export class ShippingCalculateModel implements Deserializable<ShippingCalculateModel>{
  constructor(
    public street1?: string,
    public street2?: string,
    public street3?: string,
    public city?: string,
    public state?: string,
    public zip?: string,
    public country?: string,
    public residential?: boolean,
    public weight?: number,
    public itemCount?: number,
    public orderAmount?: number,
    public companyCurrencyId?: number,

  ){}

  deserialize(input: any): ShippingCalculateModel {
    Object.assign(this, input);
    return this;
  }
}

export class ShopifyProductModel implements Deserializable<ShopifyProductModel> {
  constructor(
    public id?: number,
    public image?: string,
    public modifierDisplayName?: string,
    public name?: string,
    public options?: Array<any>,
    public price?: string,
    public sku?: string,
    public variants?: Array<any>,
  ) {}

  deserialize(input: any): ShopifyProductModel {
    Object.assign(this, input);
    return this;
  }
}

export class TaxItem implements Deserializable<TaxItem> {
  constructor(
    public amount?: string,
    public quantity?: string,
    public itemCode?: string,
    public taxCode?: string
  ){}

  deserialize(input: any): TaxItem {
    Object.assign(this, input);
    return this;
  }
}

export class TaxTransactionResponse implements Deserializable<TaxTransactionResponse> {
  constructor(
    public address?: any,
    public customerCode?: string,
    public documentType?: string,
    public items?: Array<any>,
    public totalAmount?: number,
    public totalExempt?: number,
    public totalTaxCalculated?: number,
    public totalTaxable?: number,
  ){}

  deserialize(input: any): TaxTransactionResponse {
    Object.assign(this, input);
    return this;
  }
}

export interface BigcommerceProductImage {
  id: number;
  is_thumbnail: boolean;
  description: string;
  url_zoom: string;
  url_standard: string;
  url_thumbnail: string;
  url_tiny: string;
  date_modified: string;
}
