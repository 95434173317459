//angular
import { Component, Input, Output, ViewChild, ElementRef, AfterContentInit, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//app
import { AppAnimations } from '../animations';
import { CompanyGateway } from 'src/app/_shared/models/company.model';
import { Country, RestResponse, GatewayClientToken, Gateway, GatewayConfig, Address, POST_AddressInterface } from 'src/app/_shared/models';
import { CustomerAddressBook } from 'src/app/_shared/models/customer.model';
import { VisibleState, PaymentAccountKind, PaymentAccountType, PaymentSource, GatewayType, GatewayCode, responseCode, GatewayStatus } from 'src/app/app.data';
import { KeyName } from 'src/app/app.keynames';
import { ValidatorService } from 'src/app/_shared/validators/validator.service';
import { SharedGlobalService } from 'src/app/_core/services/api';
import { GatewayService } from 'src/app/_core/services/gateway.service';
import { MetaService } from 'src/app/_core/services/meta.service';
import { Unsubscriber } from 'src/app/_shared/unsubscriber/unsubscriber';
import { DataSharingService } from 'src/app/_core/services';

@Component({
  animations: [ AppAnimations ],
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html'
})

export class PaymentFormComponent implements OnInit, AfterContentInit, OnDestroy {
  collectionOfCompanyGateway: Array<Gateway> = [];
  collectionOfCountry: Array<Country> = [];
  form: FormGroup;
  hostedFieldsInstance: any;
  isGatewayHasError: boolean = false;
  isGatewaySelected: boolean = false;
  isPaymentSourceReady: boolean = false;
  selectedBillToAddress: any;
  selectedCompanyGateway: GatewayConfig;
  selectedPaymentSourceType: PaymentSource;
  statusForNewBillingAddress: Boolean = false;
  private subs = new Unsubscriber();

  @Input() _billingAddress?: Address;
  @Input() _collectionOfCustomerAddressBook?: Array<CustomerAddressBook> = [];
  @Input() _companyGateways?: Array<any>;
  @Input() _existCompanyId: boolean = true;
  @Input() _gatewayCode = GatewayCode;
  @Input() _gatewaySettings?: any = null;
  @Input() _isNewAddressMandatory?: boolean = false;
  @Input() _isPaymentGatewayVisible?: boolean = true;
  @Input() _visibleState = VisibleState;

  @Output() _emitPaymentForm: EventEmitter<any> = new EventEmitter<any>();

  //IDs General
  @ViewChild('cardHolderName', { static: true }) public _elemCardHolderName: ElementRef;
  cleaveCardNumOptions = { creditCard: true, delimiter: ' ', delimiterLazyShow: true};
  cleaveEXPMonthOptions = { date: true,datePattern: ['m','y']};
  cleaveCardCVVOptions = { numeral: true, numeralPositiveOnly: true, stripLeadingZeroes: false, numeralThousandsGroupStyle: 'none'};

  //IDs for Braintree
  @ViewChild('braintree_CardCVV', { static: true }) public _elemBraintreeCardCVV: ElementRef;
  @ViewChild('braintree_CardExp', { static: true }) public _elemBraintreeCardExp: ElementRef;
  @ViewChild('braintree_CardNumber', { static: true }) public _elemBraintreeCardNumber: ElementRef;
  @ViewChild('braintree_CardZip', { static: true }) public _elemBraintreeCardZip: ElementRef;
  //IDs for Square
  @ViewChild('square_CardCVV', { static: true }) public _elemSquareCardCVV: ElementRef;
  @ViewChild('square_CardExp', { static: true }) public _elemSquareCardExp: ElementRef;
  @ViewChild('square_CardNumber', { static: true }) public _elemSquareCardNumber: ElementRef;
  @ViewChild('square_CardZip', { static: true }) public _elemSquareCardZip: ElementRef;
  //IDs for Stripe
  @ViewChild('stripe_CardExp', { static: true }) public _elemStripeCardExp: ElementRef;
  @ViewChild('stripe_CardNumber', { static: true }) public _elemStripeCardNumber: ElementRef;
  @ViewChild('stripe_CardCVV', { static: true }) public _elemStripeCardCVV: ElementRef;
  client: any;
  paymentCard: any;

  constructor(
    private _fb: FormBuilder,
    public _gateway: GatewayService,
    public _meta: MetaService,
    private sharedGlobalService: SharedGlobalService,
    private _validatorService: ValidatorService,
    private _dataService: DataSharingService
  ) {
  }

  setUpForm() {
    this.form = this._fb.group({
      accountName: [''],
      accountType: [''],
      accountKind: [''],
      billingAddress: this._fb.group({
        city: ['', [Validators.required, this._validatorService.emptySpace, Validators.minLength(3), Validators.maxLength(255)]],
        contactName: ['', [Validators.required, this._validatorService.emptySpace, Validators.minLength(3), Validators.maxLength(255)]],
        country: ['', [Validators.required]],
        id: [''],
        street1: ['', [Validators.required, this._validatorService.emptySpace, Validators.minLength(3), Validators.maxLength(255)]],
        street2: ['', [this._validatorService.emptySpace, Validators.minLength(1), Validators.maxLength(255)]],
        state: ['', [Validators.required, this._validatorService.emptySpace, Validators.minLength(2), Validators.maxLength(255)]],
        zip: ['', [Validators.required, this._validatorService.emptySpace, Validators.minLength(3), Validators.maxLength(255)]],
      }),
      companyGatewayId: [''],
      paymentNonce: [''],
      type: [PaymentSource.creditCard],
      authorizeForm: this._fb.group({
        authorize_CardNumber: '',
        authorize_cardExp: '',
        authorize_CardCVV: '',
        authorize_CardZip: '',
      }),
      paypalForm: this._fb.group({
        paypal_CardNumber: '',
        paypal_cardExp: '',
        paypal_CardCVV: '',
        // paypal_CardZip: '',
      }),
      cybersourceForm: this._fb.group({
        cybersource_cardExp: ''
      })
    });
  }

  addAuthorizeValidators() {
    this.form.get('authorizeForm').get('authorize_CardNumber').setValidators([Validators.required, this._validatorService.emptySpace]);
    this.form.get('authorizeForm').get('authorize_CardNumber').updateValueAndValidity();
    this.form.get('authorizeForm').get('authorize_CardCVV').setValidators([Validators.required, this._validatorService.emptySpace]);
    this.form.get('authorizeForm').get('authorize_CardCVV').updateValueAndValidity();
    this.form.get('authorizeForm').get('authorize_cardExp').setValidators([Validators.required, this._validatorService.emptySpace]);
    this.form.get('authorizeForm').get('authorize_cardExp').updateValueAndValidity();
    this.form.get('authorizeForm').get('authorize_CardZip').setValidators([Validators.required, this._validatorService.emptySpace]);
    this.form.get('authorizeForm').get('authorize_CardZip').updateValueAndValidity();
    this.form.get('cybersourceForm').get('cybersource_cardExp').setValidators([]);
    this.form.get('cybersourceForm').get('cybersource_cardExp').updateValueAndValidity();
  }

  refreshPaypalValidators(optional = false) {
    ['paypal_CardNumber', 'paypal_CardCVV', 'paypal_cardExp'].forEach(key => {
      this.form.get('paypalForm').get(key).setValidators(!optional ? [Validators.required, this._validatorService.emptySpace] : []);
      this.form.get('paypalForm').get(key).updateValueAndValidity();
    })
  }

  addCybersourceValidators() {
    this.form.get('cybersourceForm').get('cybersource_cardExp').setValidators([Validators.required, this._validatorService.emptySpace]);
    this.form.get('cybersourceForm').get('cybersource_cardExp').updateValueAndValidity();
  }

  removeAuthorizeValidators() {
    this.form.get('authorizeForm').get('authorize_CardNumber').setValidators([]);
    this.form.get('authorizeForm').get('authorize_CardNumber').updateValueAndValidity();
    this.form.get('authorizeForm').get('authorize_CardCVV').setValidators([]);
    this.form.get('authorizeForm').get('authorize_CardCVV').updateValueAndValidity();
    this.form.get('authorizeForm').get('authorize_cardExp').setValidators([]);
    this.form.get('authorizeForm').get('authorize_cardExp').updateValueAndValidity();
    this.form.get('authorizeForm').get('authorize_CardZip').setValidators([]);
    this.form.get('authorizeForm').get('authorize_CardZip').updateValueAndValidity();
    if (this.selectedCompanyGateway?.keyName !== GatewayCode.cybersource ) {
      if (this.form.get('cybersourceForm')) {
        this.form.get('cybersourceForm').get('cybersource_cardExp').setValidators([]);
        this.form.get('cybersourceForm').get('cybersource_cardExp').updateValueAndValidity();
      }
    }
  }

  ngOnInit() {
    this.setUpForm();
  }

  async ngAfterContentInit() {
    const resCountries = await this.sharedGlobalService.getCountries().toPromise();
    if (resCountries.code === 200) {
      this.collectionOfCountry = <Array<Country>>resCountries.data;
      if (this._companyGateways && this._companyGateways.length >= 1) {
        this._companyGateways.forEach((_companyGateway: CompanyGateway) => {
          if (_companyGateway.type == GatewayType.thirdPartyPaymentProvider &&
              _companyGateway.status == GatewayStatus.active) {
            this.collectionOfCompanyGateway.push(_companyGateway);
          }
        })

        this.selectedCompanyGateway = this.collectionOfCompanyGateway[0];
        this.selectedPaymentSourceType = PaymentSource.creditCard;
        this.form.get('companyGatewayId').setValue(this.selectedCompanyGateway.id);
        this.form.get('type').setValue(this.selectedPaymentSourceType);

        if (this._collectionOfCustomerAddressBook.length > 0 && !this._isNewAddressMandatory){
          this.selectedBillToAddress = this._collectionOfCustomerAddressBook[0];
        } else {
          this.selectedBillToAddress = '0';
          // this.statusForNewBillingAddress = VisibleState.isVisible
          this.statusForNewBillingAddress = true;
        }

        this.setBillingAddress(this.selectedBillToAddress);

        if (!!this.selectedCompanyGateway) {
          this.isGatewaySelected = true;
          this.setCardPaymentForm();
        }

      } else {
        this.isGatewaySelected = false;
        this._meta.showNotificationCustomMessage(responseCode.errorBadRequestCode, KeyName.error_not_gateway_associated);
      }
    } else {
      this._meta.showNotificationCustomMessage(responseCode.errorBadRequestCode, KeyName.error_not_countries_found);
    }
  }

  onChangeBillToAddress(_event: any){
    this.selectedBillToAddress = _event.value;
    this.setBillingAddress(this.selectedBillToAddress);
    if (this.selectedCompanyGateway?.keyName === GatewayCode.square) {
      this.setCardPaymentForm();
    }
  }

  onGatewayChange(_gateway: Gateway) {
    this.selectedCompanyGateway = _gateway;
    debugger
    this.form.get('companyGatewayId').setValue(this.selectedCompanyGateway.id);
    this.isPaymentSourceReady = false;
    this.setCardPaymentForm();
  }

  // @Deprecated after changing this to autocomplete
  onSelectionChange(_country){
    this.form.get('billingAddress').get('country').setValue(_country.value);
  }

  processCardPaymentForm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.markAsDirty();
      this._emitPaymentForm.emit();
      return;
    }

    switch (this.selectedCompanyGateway.keyName) {
      case GatewayCode.authorize:
        this.subs.addSub = this._gateway.getClientToken(`${this.sharedGlobalService.getServiceUrlCompanyGateways()}/${this.selectedCompanyGateway.id}/client_token`, this._existCompanyId).subscribe(
          (res: RestResponse) => {
            let data: any = res.data;
            let apiLoginId = '';
            let publicKey = '';

            if (this._existCompanyId){
              apiLoginId = data.apiLoginId;
              publicKey = data.publicKey;
            } else {
              let card: any = data.card;
              let gatewaySettings: any = card.gatewaySettings;
              apiLoginId = gatewaySettings.apiLoginId;
              publicKey = gatewaySettings.publicKey;
            }

            let cardData = {
              cardNumber: this.form.get('authorizeForm').get('authorize_CardNumber').value,
              cardCode: this.form.get('authorizeForm').get('authorize_CardCVV').value,
              cardExp: this.form.get('authorizeForm').get('authorize_cardExp').value,
              cardZip: this.form.get('authorizeForm').get('authorize_CardZip').value,
              fullName: this._gateway._elemCardHolderName?.nativeElement?.value
            }
            this.subs.addSub = this._gateway.getNonceWithAuthorize(publicKey, apiLoginId, cardData).subscribe(
              (res: RestResponse) => {
                this.isGatewayHasError = false;
                this.form.get('paymentNonce').setValue(res['opaqueData'].dataValue);
                this.form.get('accountName').setValue(this._gateway._elemCardHolderName.nativeElement.value);
                this.form.get('accountType').setValue(PaymentAccountType.checking);
                this.form.get('accountKind').setValue(PaymentAccountKind.credit);
                this._emitPaymentForm.emit(this.form.value);
              },
              (err: any) => {
                this.isGatewayHasError = true;
                this.isPaymentSourceReady = true;
                this._emitPaymentForm.emit();
                this._meta.showNotificationCustomMessage(responseCode.errorBadRequestCode, '', err.message, null);
              }
            )
          },
          (err: RestResponse) => {
            this.isGatewayHasError = true;
            this.isPaymentSourceReady = true;
            if(err?.code) {
              this._meta.showNotification(err);
            } else {
              this._meta.showNotificationCustomMessage(responseCode.errorBadRequestCode, KeyName.form_invalid );
            }
          }
        );
        break;
      case GatewayCode.paypal:
        if (this.form.valid) {
          let paypalFormValue = this.form.get('paypalForm').value;
          const [month, year] = paypalFormValue.paypal_cardExp.split('/')
          
          let data = {
            type: 'card',
            accountType: 'checking',
            accountKind: 'credit',
            accountName: 'test',
            companyGatewayId: this.form.get('companyGatewayId').value,
            billingAddress: this.form.get('billingAddress').value,
            paymentNonce: {
              number: paypalFormValue.paypal_CardNumber.replace(/ /g,''),
              expirationDate: {
                expYear: '20' + year,
                expMonth: month
              },
              cvv: paypalFormValue.paypal_CardCVV,
            }
          };
        this._emitPaymentForm.emit(data);
        }
      break;
      case GatewayCode.braintree:
        this.subs.addSub = this._gateway.getNonceWithBraintree(this._gateway._elemCardHolderName.nativeElement.value).subscribe(
          (res:any) => {

            this.form.get('paymentNonce').setValue(res.nonce);
            this.form.get('accountName').setValue(this._gateway._elemCardHolderName.nativeElement.value);
            this.form.get('accountType').setValue(PaymentAccountType.checking);
            this.form.get('accountKind').setValue(res.type);

            if (this.form.valid) {
              let data;
              data = this.form.value;
              data.details = res.details;
              this._emitPaymentForm.emit(data);
            }
          },
          (err:any) => {
            this._emitPaymentForm.emit();
            this._meta.showNotificationCustomMessage(responseCode.errorBadRequestCode, '', err.message, null);
          }
        )
        break;
      case GatewayCode.square:
        this.subs.addSub = this._gateway.getNonceWithSquare().subscribe(
          (res: any) => {
            this.form.get('paymentNonce').setValue(res.nonce);
            this.form.get('accountName').setValue(this._gateway._elemCardHolderName.nativeElement.value);
            this.form.get('accountType').setValue(PaymentAccountType.checking);
            this.form.get('accountKind').setValue(PaymentAccountKind.credit);

            if (this.form.valid) {
              let data;
              data = this.form.value;
              data.details = {
                cardType: res.cardData?.card.brand,
                expirationMonth: res.cardData?.card.expMonth,
                expirationYear: res.cardData?.card.expYear,
                lastFour: res.cardData?.card.last4,
              };
              this._emitPaymentForm.emit(data);
            }
          },
          (err: any) => {
            this._emitPaymentForm.emit();
            this._meta.showNotificationCustomMessage(responseCode.errorBadRequestCode, '', err['errors'].message, null);
          }
        )
        break;
      case GatewayCode.stripe:
        let address: POST_AddressInterface = {
          countryId: null
        };

        if (this.selectedBillToAddress == '0') {
          let country: Country = new Country().deserialize(this.form.get('billingAddress').get('country').value);
          address.city = this.form.get('billingAddress').get('city').value;
          address.contactName = this.form.get('billingAddress').get('contactName').value;
          address.countryCode = country.alpha2Code;
          address.countryId = country.id;
          address.state = this.form.get('billingAddress').get('state').value;
          address.street1 = this.form.get('billingAddress').get('street1').value;
          address.street2 = this.form.get('billingAddress').get('street2').value;
          address.zip = this.form.get('billingAddress').get('zip').value;
        } else {
          address.city = this.selectedBillToAddress.city
          address.contactName = this.selectedBillToAddress.contactName;
          address.countryCode = this.selectedBillToAddress.countryCode;
          address.countryId = this.selectedBillToAddress.countryId;
          address.state = this.selectedBillToAddress.state;
          address.street1 = this.selectedBillToAddress.street1;
          address.street2 = this.selectedBillToAddress.street2;
          address.zip = this.selectedBillToAddress.zip;
        }
        this._dataService.currentCustomer.subscribe((({customerId})=>{


        this.subs.addSub = this._gateway.getNonceWithStripe(this._gateway._elemCardHolderName.nativeElement.value, address, customerId, this.selectedCompanyGateway.id).subscribe(
          (res:any) => {

            this.form.get('accountName').setValue(this._gateway._elemCardHolderName.nativeElement.value);
            // this.form.get('accountKind').setValue(res.token.card.funding);
            this.form.get('accountType').setValue(PaymentAccountType.checking);
            this.form.get('paymentNonce').setValue(res.id);
            if (this.form.valid) {
              let data;
              data = this.form.value;
              data.billingAddress = this.form.get('billingAddress').value;
              // data.details = {
              //   cardType: res.token.card.brand,
              //   expirationMonth: res.token.card.exp_month,
              //   expirationYear: res.token.card.exp_year,
              //   lastFour: res.token.card.last4,
              // };
              this._emitPaymentForm.emit(data);
            }
          },
          (err:any) => {
            this._emitPaymentForm.emit();
            this._meta.showNotificationCustomMessage(responseCode.errorBadRequestCode, '', err.message, null);
          }
        )
      }))
        break;
      case GatewayCode.usaepay:
      this.subs.addSub = this._gateway.getNonceWithUsaepay().subscribe(
        (result: any) => {
          this.form.get('accountName').setValue(this._gateway._elemCardHolderName.nativeElement.value);
          // this.form.get('accountKind').setValue(res.token.card.funding);
          this.form.get('accountType').setValue(PaymentAccountType.checking);
          this.form.get('paymentNonce').setValue(result.key);
          if (this.form.valid) {
            let data;
            data = this.form.value;
            data.billingAddress = this.form.get('billingAddress').value;
            // data.details = {
            //   cardType: res.token.card.brand,
            //   expirationMonth: res.token.card.exp_month,
            //   expirationYear: res.token.card.exp_year,
            //   lastFour: res.token.card.last4,
            // };
            this._emitPaymentForm.emit(data);
          } else {
            this._emitPaymentForm.emit();
          }

        })
      break;
      case GatewayCode.cybersource:
        if (!this.form.get('cybersourceForm').get('cybersource_cardExp').value) {
          return;
        }

        const dateResult = this.form.get('cybersourceForm').get('cybersource_cardExp').value.split('/')
        if (!dateResult[0] || !(dateResult[1] && Number(dateResult[1]) >= Number(new Date().getFullYear().toString().substr(-2)))) {
          this._meta.showNotificationCustomMessage(responseCode.errorBadRequestCode, '', 'Sorry boss! There is an error with your expiration date. Please try again!', null);
          return;
        }
        let cardData = {
          expirationMonth: dateResult[0],
          expirationYear: `20${dateResult[1]}`,
        }
        this.subs.addSub = this._gateway.getNonceWithCybersource(cardData).subscribe(
          (result: any) => {
            this.form.get('accountName').setValue(this._gateway._elemCardHolderName.nativeElement.value);
            //this.form.get('accountType').setValue(PaymentAccountType.checking);
            this.form.get('paymentNonce').setValue(result);
            if (this.form.valid) {
              let data;
              data = this.form.value;
              data.billingAddress = this.form.get('billingAddress').value;
              this._emitPaymentForm.emit(data);
            } else {
              this._emitPaymentForm.emit();
            }

          })
        break;

      default:
        this._meta.showNotificationCustomMessage(responseCode.errorBadRequestCode, KeyName.error_gateway_doesnt_belong_rebillia_gateway, null, [this.selectedCompanyGateway.keyName]);
        break;
    }
  }

  setBillingAddress(_value: any){
    let billingAddress = <FormGroup>this.form.get('billingAddress');
    if (_value !== '0') {
      let address = new CustomerAddressBook().deserialize(_value);
      billingAddress.get('city').setValue(address.city);
      billingAddress.get('contactName').setValue(address.contactName);
      billingAddress.get('country').setValue(address.country);
      billingAddress.get('id').setValue(address.id);
      billingAddress.get('state').setValue(address.state);
      billingAddress.get('street1').setValue(address.street1);
      billingAddress.get('street2').setValue(address.street2);
      billingAddress.get('zip').setValue(address.zip);
      billingAddress.get('id').setValue(address.id);
      // this.statusForNewBillingAddress = VisibleState.isHidden;
      this.statusForNewBillingAddress = false;
    } else {
      // USA
      let defaultCountry = this.collectionOfCountry.filter(country => country.id == 240)[0];

      billingAddress.get('city').setValue('');
      billingAddress.get('contactName').setValue('');
      billingAddress.get('country').setValue(defaultCountry);
      billingAddress.get('id').setValue('');
      billingAddress.get('state').setValue('');
      billingAddress.get('street1').setValue('');
      billingAddress.get('street2').setValue('');
      billingAddress.get('zip').setValue('');
      // this.statusForNewBillingAddress = VisibleState.isVisible;
      this.statusForNewBillingAddress = true;
    }
  }

  setCardPaymentForm() {
    this._elemCardHolderName.nativeElement.value = '';
    this._gateway._elemCardHolderName = this._elemCardHolderName;
    switch (this.selectedCompanyGateway.keyName) {
      case GatewayCode.authorize:
        this.addAuthorizeValidators();
        this.refreshPaypalValidators(true);
        this.isGatewayHasError = false;
        this.isPaymentSourceReady = true;
        break;
      case GatewayCode.paypal:
        this.removeAuthorizeValidators();
        this.refreshPaypalValidators();
        this.isGatewayHasError = false;
        this.isPaymentSourceReady = true;
        break;
      case GatewayCode.braintree:
        this.removeAuthorizeValidators();
        this.refreshPaypalValidators(true);
        this._gateway._elemCardNumber = this._elemBraintreeCardNumber;
        this._gateway._elemCardExp = this._elemBraintreeCardExp;
        this._gateway._elemCardCVV = this._elemBraintreeCardCVV;
        this._gateway._elemCardZip = this._elemBraintreeCardZip;
        if (!this._gatewaySettings) {
          this.subs.addSub = this._gateway.getClientToken(`${this.sharedGlobalService.getServiceUrlCompanyGateways()}/${this.selectedCompanyGateway.id}/client_token`,this._existCompanyId).subscribe(
            (res: RestResponse) => {
              let publicKey = res.data['publicKey'];
              this.subs.addSub = this._gateway.doTokenizeCardFormWithBraintree(publicKey).subscribe(
                (res:any) => {
                  this.hostedFieldsInstance = res;
                  this.isGatewayHasError = false;
                  this.isPaymentSourceReady = true;
                },
                (err:any) => {
                  this._meta.showNotificationCustomMessage(responseCode.errorBadRequestCode, '', err.message, null);
                  this.isGatewayHasError = true;
                  this.isPaymentSourceReady = true;
                }
              );
            },
            (err:RestResponse) => {
              this.isGatewayHasError = true;
              this.isPaymentSourceReady = true;
              this._meta.showNotification(err);
            }
          );
        } else {
          this.subs.addSub = this._gateway.doTokenizeCardFormWithBraintree(this._gatewaySettings.publicKey).subscribe(
            (res:any) => {
              this.hostedFieldsInstance = res;
              this.isPaymentSourceReady = true;
            },
            (err:any) => {
              this._meta.showNotification(err);
              this.isPaymentSourceReady = true;
            }
          );
        }
        break;
      case GatewayCode.square:
        this.removeAuthorizeValidators();
        this.refreshPaypalValidators(true);
        this._gateway._elemCardNumber = this._elemSquareCardNumber;
        this._gateway._elemCardExp = this._elemSquareCardExp;
        this._gateway._elemCardCVV = this._elemSquareCardCVV;
        this._gateway._elemCardZip = this._elemSquareCardZip;
        if (!this._gatewaySettings) {
          this.subs.addSub = this._gateway.getClientToken(`${this.sharedGlobalService.getServiceUrlCompanyGateways()}/${this.selectedCompanyGateway.id}/client_token`,this._existCompanyId).subscribe(
            (res: RestResponse) => {
              let publicKey = res.data['publicKey'];
              this.subs.addSub = this._gateway.doTokenizeCardFormWithSquare(publicKey, this.selectedBillToAddress.zip).subscribe(
                (res: any) => {
                  this.isPaymentSourceReady = true;
                },
                (err: any) => {
                  this._meta.showNotification(err);
                  this.isPaymentSourceReady = true;
                }
              );
            },
            (err: RestResponse) => {
              this.isPaymentSourceReady = true;
              this._meta.showNotification(err);
            }
          );
        } else {
          this.subs.addSub = this._gateway.doTokenizeCardFormWithSquare(this._gatewaySettings.publicKey, this.selectedBillToAddress.zip).subscribe(
            (res: any) => {
              this.isGatewayHasError = false;
              this.isPaymentSourceReady = true;
            },
            (err: any) => {
              this._meta.showNotification(err);
              this.isGatewayHasError = true;
              this.isPaymentSourceReady = true;
            }
          );
        }
        break;
      case GatewayCode.stripe:
        this.removeAuthorizeValidators();
        this.refreshPaypalValidators(true);
        this._gateway._elemCardNumber = this._elemStripeCardNumber;
        this._gateway._elemCardExp = this._elemStripeCardExp;
        this._gateway._elemCardCVV = this._elemStripeCardCVV;
        if (!this._gatewaySettings) {
          this.subs.addSub = this._gateway.getClientToken(`${this.sharedGlobalService.getServiceUrlCompanyGateways()}/${this.selectedCompanyGateway.id}/client_token`, this._existCompanyId).subscribe(
            (res: RestResponse) => {
              let data = new GatewayClientToken().deserialize(res.data);
              this.subs.addSub = this._gateway.doTokenizeCardFormWithStripe(data.publicKey).subscribe(
                (res: any) => {
                  this.isGatewayHasError = false;
                  this.isPaymentSourceReady = true;
                },
                (err: any) => {
                  this._meta.showNotification(err);
                  this.isGatewayHasError = true;
                  this.isPaymentSourceReady = true;
                }
              );
            },
            (err: RestResponse) => {
              this._meta.showNotification(err);
              this.isGatewayHasError = true;
              this.isPaymentSourceReady = true;
            }
          );
        } else {
          this.subs.addSub = this._gateway.doTokenizeCardFormWithStripe(this._gatewaySettings.publicKey).subscribe(
            (res: any) => {
              this.isGatewayHasError = false;
              this.isPaymentSourceReady = true;
            },
            (err: any) => {
              this.isGatewayHasError = true;
              this.isPaymentSourceReady = true;
              this._meta.showNotification(err);
            }
          );
        }
        break;
      case GatewayCode.usaepay:
        this.removeAuthorizeValidators();
        this.refreshPaypalValidators(true);
        if (!this._gatewaySettings) {
          this.subs.addSub = this._gateway.getClientToken(`${this.sharedGlobalService.getServiceUrlCompanyGateways()}/${this.selectedCompanyGateway.id}/client_token`, this._existCompanyId).subscribe(
            (res: RestResponse) => {
              let data = new GatewayClientToken().deserialize(res.data);
              this.subs.addSub = this._gateway.doTokenizeCardFormWithUsaepay(data.publicKey).subscribe(
                (res: any) => {
                  this.isGatewayHasError = false;
                  this.isPaymentSourceReady = true;
                },
                (err: any) => {
                  this._meta.showNotification(err);
                  this.isGatewayHasError = true;
                  this.isPaymentSourceReady = true;
                }
              );
            },
            (err: RestResponse) => {
              this._meta.showNotification(err);
              this.isGatewayHasError = true;
              this.isPaymentSourceReady = true;
            }
          );
        } else {
          this.subs.addSub = this._gateway.doTokenizeCardFormWithUsaepay(this._gatewaySettings.publicKey).subscribe(
            (res: any) => {
              this.isGatewayHasError = false;
              this.isPaymentSourceReady = true;
            },
            (err: any) => {
              this.isGatewayHasError = true;
              this.isPaymentSourceReady = true;
              this._meta.showNotification(err);
            }
          );
        }
        break;
      case GatewayCode.cybersource:
        this.addCybersourceValidators();
        this.removeAuthorizeValidators();
        this.refreshPaypalValidators(true);
        if (!this._gatewaySettings) {
          this.subs.addSub = this._gateway.getClientToken(`${this.sharedGlobalService.getServiceUrlCompanyGateways()}/${this.selectedCompanyGateway.id}/client_token`, this._existCompanyId).subscribe(
            (res: RestResponse) => {
              let data = new GatewayClientToken().deserialize(res.data);
              this.subs.addSub = this._gateway.doTokenizeCardFormWithCybersource(data.publicKey).subscribe(
                (res: any) => {
                  this.isGatewayHasError = false;
                  this.isPaymentSourceReady = true;
                },
                (err: any) => {
                  console.log('erro re',err)
                  this._meta.showNotification(err);
                  this.isGatewayHasError = true;
                  this.isPaymentSourceReady = true;
                }
              );
            },
            (err: RestResponse) => {
              this._meta.showNotification(err);
              this.isGatewayHasError = true;
              this.isPaymentSourceReady = true;
            }
          );
        } else {
          this.subs.addSub = this._gateway.doTokenizeCardFormWithCybersource(this._gatewaySettings.publicKey).subscribe(
            (res: any) => {
              this.isGatewayHasError = false;
              this.isPaymentSourceReady = true;
            },
            (err: any) => {
              this.isGatewayHasError = true;
              this.isPaymentSourceReady = true;
              this._meta.showNotification(err);
            }
          );
        }
        break;
      default:
        this.removeAuthorizeValidators();
        this.refreshPaypalValidators(true);
        this.isGatewayHasError = true;
        this.isPaymentSourceReady = true;
        break;
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
