import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subscription, timer} from 'rxjs';
import { CompanySettingsKey } from 'src/app/app.data';
import { DataSharingService, RestService, StorageService } from 'src/app/_core/services';
import { HttpParamsInterface } from 'src/app/_shared/interfaces/http-params.interface';
import { Post_ActivateCompany, Post_AdditionalCurrencyInterface, Put_AdditionalCurrencyInterface, PUT_CompanyGatewayStatusInterface, PUT_CompanyProfileInterface, RestResponse, ShippingCalculateModel } from 'src/app/_shared/models';
import { environment } from 'src/environments/environment';
import { HttpParamService } from './http-param.service';
import {concatMap, map, switchMap} from "rxjs/operators";
import {Post_CompanyPlanScope} from "../../../_shared/models/rbl-plan.model";

const APIEndpoint: string = environment.APIEndpoint;
const APIVersion: string = environment.APIVersion;

const APIResourceCompanies = '/companies';
const APIResourceCostumers: string = '/customers';
const APIResourceCostumersImport: string = '/import';
const APIResourceCustomerListDuplicates: string = '/list/duplicates';
const APIResourceCustomerList: string = '/list/information/manual-duplicates';
const APIResourceCountries: string = '/countries';
const APIResourceCurrencies: string = '/currencies';
const APIResourceDefault: string = '/default';
const APIResourceGateways: string = '/gateways';
const APIResourceGlobals: string = '/globals';
const APIResourceSettingsByType: string = '/settings/types';
const APIResourceRblScopeKeyByType: string = '/rbl-scope-key';
const APIResourceExperimentalFeatures: string = '/experimental-features';
const APIResourceSaveRblScopeKeyByType: string = '/changetype';
const APIResourceTimezones: string = '/timezones';
const APIResourceIndustries: string = '/industries';
const APIResourceCompanyProfile = '/profile';
const APIResourceCompanySelectedPlanScope = '/selected-plan-scope';
const APIResourceCompanyUpdateOrCreatePlanScope = '/update-or-create-company-plan-scope';
const APIResourceAllPlanScopes = '/all/plan-scopes';
const APIResourceCurrencyDefault = '/currencies/default';
const OXR_BASE_URL = 'https://openexchangerates.org/api';
const APIResourceSystem: string = '/system';
const APIResourceGateway = '/gateway';
const APIResourceUsers: string = '/users';
const APIResourceFilters: string = '/filters';
const APIResourceIntegrations: string = '/integrations';
const APIExportQueue: string = '/export-queue/';

const APIResourceGrids: string = '/grids';
const APIResourceUsersMe: string = '/users/me';
const APIResourceSettings: string = '/settings';
const APIResourceDeactivate: string = '/deactivate';
const APIResourceOrders: string = '/orders';
const APIResourceImport: string = '/import';
const APIScope: string = '/scope';

const APIResourceCheckout = '/checkout';

const APIResourceInbox: string = '/inbox';

const APIResourceAnalytics: string = '/analytics';
const APIResourceSalesChannel: string = '/sales-channel';
const APIResourceTotals: string = '/totals';
const APIResourceInvoices: string ='/invoices';

const APIResourceInvites = '/invites';

const APIResourceBilling = '/billing';
const APIResourcePaymentMethods = '/paymentmethod'; //REVCODEREB: cambiar esta ruta en la api y luego en el front

const APIResourceShipping: string = '/shipping';
const APIResourceShippingCalculate: string = '/shipping/calculate';
const APIResourceShippingServices: string = '/shipping/services';
const APIResourceShipperHQServices: string = '/shipping/shipperhq-services';
const APIResourceTaxNewTransaction: string = '/tax/new-transaction';
const APIResourceRoles: string = '/roles';

@Injectable({
  providedIn: 'root'
})
export class SharedGlobalService {

  private subscription: Subscription;
  private serviceUrl_CompanyGateways: string;
  private serviceUrl_CompanyCurrencies: string;
  private serviceUrl_CompanyCurrenciesDefault: string;
  private serviceUrl_Countries: string;
  private serviceUrl_CustomersImport: string;
  private serviceUrl_MergeCustomers: string;
  private serviceUrl_CustomersSuggestedMerge: string;
  private serviceUrl_CustomersListForMerge: string;
  private serviceUrl_CompanySettingsByType: string;
  private serviceUrl_RblScopeKeyByType: string;
  private serviceUrl_ExperimentalFeatures: string;
  private serviceUrl_SaveRblScopeKeyByType: string;
  private serviceUrl_Timezones: string;
  private serviceUrl_Industries: string;
  private serviceUrl_EntityProfile: string;
  private serviceUrl_Gateways: string;
  private serviceUrl_EntityCurrencyDefault: string;
  private serviceUrl_Currencies: string;
  private serviceUrl_ExchangeAllRate: string;
  private serviceUrl_SystemGateway: string;
  private serviceUrl_Grids: string;

  private serviceUrl_Export: string;
  private serviceUrl_Filters: string;
  private serviceUrl_Integrations: string;
  private serviceUrl_Costumers: string;
  private serviceUrl_OrdersImport: string;
  private serviceUrl_Scope: string;

  private serviceUrl_UserMe: string;

  private serviceUrl_EntitySettings: string;

  private serviceUrl_EntityDeactivate: string;

  private serviceUrl_EntityCurrencies: string;

  private serviceUrl_Checkout: string;

  private serviceUrl_CompanyInbox: string;

  private serviceUrl_CompanyRequest: string;
  private serviceUrl_PurchaseHistoryUpsCapital: string;

  //only one use
  private serviceUrl_AnalyticsSalesChannel: string;

  //only one use
  private serviceUrl_AnalyticsTotals: string;

  //only one use
  private serviceUrl_AnalyticsInvoices: string;

  //only one use
  private serviceUrl_UserLog: string;

  private serviceUrl_EntityUsersInvites: string;
  private serviceUrl_EntityUsers: string;
  private serviceUrl_InternalUsers: string;

  //only one use
  private serviceUrl_EntityPaymentMethod: string;

  //only one use
  private serviceUrl_Shipping: string;
  private serviceUrl_ShippingCalculate: string;
  private serviceUrl_ShippingServices: string;
  private serviceUrl_ShipperHQServices: string;

  private serviceUrl_TaxCreateTransaction: string;
  private serviceUrl_PlanScope: string;
  private serviceUrl_SelectedPlanScope: string;
  private serviceUrl_UpdateOrCreateCompanyPlanScope: string;
  private serviceUrl_UserCompanies: string;
  private serviceUrl_UserRoles: string;
  private serviceUrl_UserPendingActivationCompanies: string;
  private load$ = new BehaviorSubject('');
  private COMPANY_USER_EXPORT_NOTIFICATION_INTERVAL: number = 20000;

  constructor(
    private httpParamService: HttpParamService,
    private _shareData: DataSharingService,
    private _storage: StorageService,
    private _rest: RestService
  ) {

      let entityId: string;

      this.subscription = this._shareData.currentCompany.subscribe(data => {
        if (data.companyId){
          entityId = `/${data.companyId}`;
        } else {
          entityId = `/${this._storage.readLocalStorage('entityId')}`;
        }

        // [GET][PUT][DELETE] CURRENCY
        // COMPANY CURRENCIES
        // /companies/{{companyId}}/currencies
        this.serviceUrl_CompanyCurrencies = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceCurrencies;

        // Import
        // /companies/{{companyId}}/customers/import
        this.serviceUrl_CustomersImport = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceCostumers + APIResourceCostumersImport;

        //merge customers
        this.serviceUrl_MergeCustomers = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceCostumers;

        // Merge
        // /companies/{{companyId}}/customers/list/duplicates
        this.serviceUrl_CustomersSuggestedMerge = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceCostumers + APIResourceCustomerListDuplicates;


        this.serviceUrl_CustomersListForMerge = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceCostumers + APIResourceCustomerList;

        // COMPANY CURRENCIES DEFAULT
        // [GET]
        // /companies/{{companyId}}/currencies/default
        this.serviceUrl_CompanyCurrenciesDefault = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceCurrencies + APIResourceDefault;


        // COMPANY SETTINGS BY TYPE
        // /companies/{{companyId}}/settings/type
        this.serviceUrl_CompanySettingsByType = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceSettingsByType;

        // /companies/{{companyId}}/rbl-scope-key
        this.serviceUrl_RblScopeKeyByType = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceRblScopeKeyByType;

        this.serviceUrl_SaveRblScopeKeyByType = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceRblScopeKeyByType + APIResourceSaveRblScopeKeyByType;

        // /companies/{{companyId}}/experimental-features
        this.serviceUrl_ExperimentalFeatures = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceExperimentalFeatures;

        // Timezones
        // /globals/timezones
        this.serviceUrl_Timezones = APIEndpoint + APIResourceGlobals + APIResourceTimezones;

        // Industries
        // /globals/industries
        this.serviceUrl_Industries = APIEndpoint + APIResourceGlobals + APIResourceIndustries;

        // COMPANY
        // [GET][PUT] Company Profile
        // https://devapi.rebillia.com/internal/companies/{{companyId}}/profile
        this.serviceUrl_EntityProfile = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceCompanyProfile;

        // COMPANY
        // Selected PlanScope
        // /internal/companies/{{companyId}}/selected-plan-scope
        this.serviceUrl_SelectedPlanScope = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceCompanySelectedPlanScope;

        // COMPANY
        // Selected PlanScope
        // /internal/companies/{{companyId}}/update-or-create-company-plan-scope
        this.serviceUrl_UpdateOrCreateCompanyPlanScope = APIEndpoint + APIVersion + APIResourceCompanies + entityId;

        // COMPANY
        // Selected PlanScope
        // /internal/companies/all/plan-scopes
        this.serviceUrl_PlanScope = APIEndpoint + APIVersion + APIResourceCompanies + APIResourceAllPlanScopes;

        // CURRENCY BASE (Default)
        // https://devapi.rebillia.com/internal/companies/{{companyId}}/currencies/default
        this.serviceUrl_EntityCurrencyDefault = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceCurrencyDefault;

        // Gateways
        // /globals/gateways
        this.serviceUrl_Gateways = APIEndpoint + APIResourceGlobals + APIResourceGateways;

        // Currencies
        // /globals/currencies
        this.serviceUrl_Currencies = APIEndpoint + APIResourceGlobals + APIResourceCurrencies;

        // Countries
        // /globals/countries
        this.serviceUrl_Countries = APIEndpoint + APIResourceGlobals + APIResourceCountries;

        // Exchange Rates
        // https://openexchangerates.org/api/latest.json
        this.serviceUrl_ExchangeAllRate = OXR_BASE_URL + "/latest.json";

        // Company Customers
        this.serviceUrl_Costumers = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceCostumers

        this.serviceUrl_Export = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIExportQueue

         // INTEGRATION
        // /companies/{{companyId}}/intergration
        this.serviceUrl_Integrations = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceIntegrations;

        // FILTERS
        // /companies/{{companyId}}/filters
        this.serviceUrl_Filters = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceFilters;

        // USER GRID COLUMNS PREFERENCES
        // /users/me/settings/grids
        this.serviceUrl_Grids = APIEndpoint + APIVersion + APIResourceUsersMe + APIResourceSettings + APIResourceGrids;

        // ORDERS
        // /companies/{{companyId}}/orders/import
        this.serviceUrl_OrdersImport = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceOrders + APIResourceImport;

        // SCOPE
        // /companies/{{companyId}}/scope
        this.serviceUrl_Scope = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIScope;

        this.serviceUrl_PurchaseHistoryUpsCapital = APIEndpoint + APIVersion + APIResourceCompanies + entityId + '/shipping/ups/shipping-capital';
        // ACCOUNT SETTING
        // [GET][PUT] User profile
        // /users/me
        this.serviceUrl_UserMe = APIEndpoint + APIVersion + APIResourceUsers + '/me';

        //User companies
        //{{host}}/{{version}}/users/companies
        this.serviceUrl_UserCompanies = APIEndpoint + APIVersion + APIResourceUsers + APIResourceCompanies;

        // COMPANY REQUEST
        //{{host}}/{{version}}/company-request/
        this.serviceUrl_UserPendingActivationCompanies = APIEndpoint + APIVersion + '/company-request';

        // USER LOG
        // /companies/{{companyId}}/logs
        this.serviceUrl_UserLog = APIEndpoint + APIVersion + APIResourceCompanies;

        // COMPANY SETTINGS
        // https://devapi.rebillia.com/internal/companies/{{companyId}}/settings
        this.serviceUrl_EntitySettings = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceSettings;

           // COMPANY SETTINGS
        // https://devapi.rebillia.com/internal/companies/{{companyId}}/deactivate
        this.serviceUrl_EntityDeactivate = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceDeactivate;


        // [GET][PUT][DELETE] CURRENCY
        // COMPANY CURRENCIES
        // https://devapi.rebillia.com/internal/companies/{{companyId}}/currencies
        this.serviceUrl_EntityCurrencies = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceCurrencies;

        // [POST] Post checkout
        //ONLY ONE USE
        this.serviceUrl_Checkout = APIEndpoint + APIVersion + APIResourceSystem + APIResourceCheckout;

        // [GET] Company Inbox
        // /companies/{{companyId}}/inbox
        this.serviceUrl_CompanyInbox = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceInbox;


        // ANALYTICS - SUBSCRIPTION BY SALES CHANNEL
        // /companies/{{companyId}}/analytics/subscriptions-by-sales-channel
        this.serviceUrl_AnalyticsSalesChannel = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceAnalytics + APIResourceSalesChannel;

        // ANALYTICS - GET TOTALS
        // /companies/{{companyId}}/analytics/totals
        this.serviceUrl_AnalyticsTotals = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceAnalytics + APIResourceTotals;

        // ANALYTICS - PAYMENT ACTIVITY
        // /companies/{{companyId}}/analytics/payment-activity
        this.serviceUrl_AnalyticsInvoices = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceAnalytics + APIResourceInvoices;

        // [POST][DELETE] USERS/INVITES
        // https://devapi.rebillia.com/internal/companies/{{companyId}}/users/invites
        this.serviceUrl_EntityUsersInvites = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceUsers + APIResourceInvites;

        // [GET] USERS
        // https://devapi.rebillia.com/internal/companies/{{companyId}}/users
        this.serviceUrl_EntityUsers = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceUsers;

        // https://devapi.rebillia.com/internal/customers
        this.serviceUrl_InternalUsers = APIEndpoint + APIVersion + APIResourceCostumers;

        // BILLING
        // Payment Methods
        // https://devapi.rebillia.com/internal/companies/{{companyId}}/billing/paymentmethod
        this.serviceUrl_EntityPaymentMethod = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceBilling + APIResourcePaymentMethods;

        // SHIPPING
        // /companies/{{companyId}}/shipping/calculate
        this.serviceUrl_ShippingCalculate = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceShippingCalculate;

        // /companies/{{companyId}}/shipping/services
        this.serviceUrl_ShippingServices = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceShippingServices;

        // /companies/{{companyId}}/shipping/shipperhq-services
        this.serviceUrl_ShipperHQServices = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceShipperHQServices;

        // /companies/{{companyId}}/shipping
        this.serviceUrl_Shipping = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceShipping;

        // TAX NEW TRANSACTION
        // /companies/{{companyId}}/tax/new-transaction
        this.serviceUrl_TaxCreateTransaction = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceTaxNewTransaction;

        // User Roles
        // /globals/gateways
        this.serviceUrl_UserRoles = APIEndpoint + APIResourceGlobals + APIResourceRoles;

        // Payment Gateways
        // /companies/{{companyId}}/gateways
        this.serviceUrl_CompanyGateways = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceGateways;

        // [GET][PUT][DELETE] CURRENCY
        // COMPANY CURRENCIES
        // /companies/{{companyId}}/currencies
        this.serviceUrl_CompanyCurrencies = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceCurrencies;

        // Import
        // /companies/{{companyId}}/customers/import
        this.serviceUrl_CustomersImport = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceCostumers + APIResourceCostumersImport;

     // COMPANY CURRENCIES DEFAULT
        // [GET]
        // /companies/{{companyId}}/currencies/default
        this.serviceUrl_CompanyCurrenciesDefault = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceCurrencies + APIResourceDefault;


        // COMPANY SETTINGS BY TYPE
        // /companies/{{companyId}}/settings/type
        this.serviceUrl_CompanySettingsByType = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceSettingsByType;

        // Timezones
        // /globals/timezones
        this.serviceUrl_Timezones = APIEndpoint + APIResourceGlobals + APIResourceTimezones;

        // Industries
        // /globals/industries
        this.serviceUrl_Industries = APIEndpoint + APIResourceGlobals + APIResourceIndustries;

        // COMPANY
        // [GET][PUT] Company Profile
        // https://devapi.rebillia.com/internal/companies/{{companyId}}/profile
        this.serviceUrl_EntityProfile = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceCompanyProfile;

        // CURRENCY BASE (Default)
        // https://devapi.rebillia.com/internal/companies/{{companyId}}/currencies/default
        this.serviceUrl_EntityCurrencyDefault = APIEndpoint + APIVersion + APIResourceCompanies + entityId + APIResourceCurrencyDefault;

        // Gateways
        // /globals/gateways
        this.serviceUrl_Gateways = APIEndpoint + APIResourceGlobals + APIResourceGateways;

        this.serviceUrl_CompanyRequest = APIEndpoint + APIVersion;

        // Currencies
        // /globals/currencies
        this.serviceUrl_Currencies = APIEndpoint + APIResourceGlobals + APIResourceCurrencies;

        // Countries
        // /globals/countries
        this.serviceUrl_Countries = APIEndpoint + APIResourceGlobals + APIResourceCountries;

        // Get Gateway Default
        // /system/gateway
        this.serviceUrl_SystemGateway = APIEndpoint + APIVersion + APIResourceSystem + APIResourceGateway;

        // Exchange Rates
        // https://openexchangerates.org/api/latest.json
        this.serviceUrl_ExchangeAllRate = OXR_BASE_URL + "/latest.json";
    });
  }

  public getServiceUrlCompanyGateways(): string {
    return this.serviceUrl_CompanyGateways;
  }

  public getServiceUrlSystemGateways(): string {
    return this.serviceUrl_SystemGateway;
  }

  public importFromBigCommerce(companyIntegrationId: number, _bodyParams: any): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_CustomersImport}/${companyIntegrationId}`, _bodyParams);
  }

  public mergeCustomers(mainCustomerId: number, _bodyParams: any): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_MergeCustomers}/action/${mainCustomerId}/merge`, _bodyParams);
  }

  public customerSetupStripeIntent(companyGatewayId: any, customerId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_CompanyGateways}/${companyGatewayId}${APIResourceCostumers}/${customerId}/setup_intent`);
  }

  public getCustomerSuggestedDuplicates() {
    return this._rest.get(this.serviceUrl_CustomersSuggestedMerge);
  }

  public getCustomerListForMerge() {
    return this._rest.get(this.serviceUrl_CustomersListForMerge);
  }

  //companygateway
  public getActiveCompanyGateways(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_CompanyGateways}?status=active`);
  }

  //companyCurrencies
  public getCompanyCurrencies(): Observable<RestResponse> {
    return this._rest.get(this.serviceUrl_CompanyCurrencies);
  }

  //countries
  public getCountries(): Observable<RestResponse> {
    return this._rest.get(this.serviceUrl_Countries);
  }

  //company currency
  public getCompanyCurrencyDefault(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_EntitySettings}/${CompanySettingsKey.currencyDefault}`);
  }

    //company currency
    public deactivateCompany(): Observable<RestResponse> {
      return this._rest.put(`${this.serviceUrl_EntityDeactivate}`, {});
    }

  //entity currency
  public getEntityCurrencies(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_EntityCurrencies}`);
  }

  //get currencydefault
  public getCompanyCurrenciesDefault(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_CompanyCurrenciesDefault}`);
  }

  //company gateways by companycurrencyId
  public getCompanyGatewayByCompanyCurrencyId(companyCurrencyId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_CompanyCurrencies}/${companyCurrencyId}`);
  }

  public getCompanySettingsBySettingType(settingType: string): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_CompanySettingsByType}/${settingType}`);
  }

  public getSettingsBySettingTypeAndCompanyId(companyId: number, settingType: string) : Observable<RestResponse> {
    return this._rest.get(APIEndpoint + APIVersion + APIResourceCompanies + '/' + companyId + APIResourceSettingsByType + '/' + settingType);
  }

  public getRblScopeKeyTypes(bodyParams = {}): Observable<RestResponse> {
    return this._rest.post(this.serviceUrl_RblScopeKeyByType, bodyParams);
  }

  public saveRblScopeKeyTypes(bodyParams = []): Observable<RestResponse> {
    return this._rest.put(this.serviceUrl_SaveRblScopeKeyByType, bodyParams);
  }

  public getExperimentalFeatures(): Observable<RestResponse> {
    return this._rest.get(this.serviceUrl_ExperimentalFeatures);
  }

  public saveExperimentalFeatures(bodyParams = []): Observable<RestResponse> {
    return this._rest.put(this.serviceUrl_ExperimentalFeatures, bodyParams);
  }

  public getTimeZones(): Observable<RestResponse> {
    return this._rest.get(this.serviceUrl_Timezones);
  }

  public getIndustries(): Observable<RestResponse> {
    return this._rest.get(this.serviceUrl_Industries);
  }

  public getEntityProfile(): Observable<RestResponse> {
    return this._rest.get(this.serviceUrl_EntityProfile);
  }

  public updateEntityProfile( bodyParams: PUT_CompanyProfileInterface): Observable<any> {
    return this._rest.put(`${this.serviceUrl_EntityProfile}`, bodyParams);
  }

  public testGatewayCredentials(gatewayId: number) {
    return this._rest.get(`${this.serviceUrl_CompanyGateways}/${gatewayId}/test`);
  }

  public reconnectCompanyGateway(gatewayId: number, gatewayEnvironment: string) {
    return this._rest.get(`${this.serviceUrl_CompanyGateways}/${gatewayId}/oauth-reconnect/${gatewayEnvironment}`);
  }

  //companygateway
  public getCompanyGateways(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_CompanyGateways}`);
  }

  //Global gateways
  public getGlobalGateways(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Gateways}`);
  }

  public deleteCompanyGateway(gatewayId: number): Observable<RestResponse> {
    return this._rest.delete(`${this.serviceUrl_CompanyGateways}/${gatewayId}`);
  }

  public updateCompanyGatewayStatus(gatewayId:number, bodyParams: PUT_CompanyGatewayStatusInterface): Observable<any> {
    return this._rest.put(`${this.serviceUrl_CompanyGateways}/${gatewayId}/update-status`, bodyParams);
  }

  public getUserRoles(): Observable<RestResponse> {
    return this._rest.get(this.serviceUrl_UserRoles)
  }

  public getGlobalGatewaysByGatewayId(gatewayId:number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Gateways}/${gatewayId}?environment=sandbox`);
  }

  public getCompanyCurrencyBase(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_EntityCurrencyDefault}`);
  }

  public getGlobalCurrencies(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Currencies}`);
  }

  public createBaseCurrency(bodyParams: any): Observable<any> {
    return this._rest.post(`${this.serviceUrl_EntityCurrencyDefault}`, bodyParams);
  }

  public createAdditionalCrrency(bodyParams: Post_AdditionalCurrencyInterface): Observable<any> {
    return this._rest.post(`${this.serviceUrl_CompanyCurrencies}`, bodyParams);
  }

  public updateCompanyCurrencyByCompanyCurrencyId(companyCurrencyId: number, bodyParams: Put_AdditionalCurrencyInterface): Observable<any> {
    return this._rest.put(`${this.serviceUrl_EntityCurrencies}/${companyCurrencyId}`, bodyParams);
  }

  public getExchangeRate(): Observable<any> {
    return this._rest.getExchangeRate(this.serviceUrl_ExchangeAllRate);
  }

  public getSystemGateways(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_SystemGateway}`);
  }

  //filters
  public getFilters(sectionId: string): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Filters}?section=${sectionId}`);
  }

  //integrations
  public getIntegrations(type: string): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Integrations}?type=${type}`);
  }

 //integrations customers
  public getIntegrationCustomers(companyIntegrationId: string, email: string): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Integrations}/${companyIntegrationId}/customers?email=${email}`);
  }
  //customers
  public storeExternalCustomer(customerId: any, data: any): Observable<RestResponse> {
    return this._rest.put(`${this.serviceUrl_Costumers}/${customerId}`, data);
  }

   //customers
   public getExternalCustomer(customerId: any): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Costumers}/${customerId}/externalcustomer`);
  }

  public getFilterAttributesBySectionId(sectionId: string): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Filters}/fields?section=${sectionId}`);
  }

  public deleteCustomFilter(_filterId: number): Observable<RestResponse> {
    return this._rest.delete(`${this.serviceUrl_Filters}/${_filterId}`);
  }

  public createFilter(bodyParams: any): Observable<any> {
    return this._rest.post(this.serviceUrl_Filters, bodyParams);
  }

  //grid columns
  public getGridColumns(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Grids}/externalOrders`);
  }

  public getImportedOrders(pathParams?: HttpParamsInterface): Observable<RestResponse> {
    let params = pathParams ? this.httpParamService.buildPathParams(pathParams) : null;
    return this._rest.get(`${this.serviceUrl_OrdersImport}`, { params: params });
  }

  public getGridColumnsByColumnsParams(gridColumnsParam: string): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Grids}/${gridColumnsParam}`);
  }

  public createColumnsPreferences(bodyParams: any): Observable<RestResponse> {
    return this._rest.post(this.serviceUrl_Grids, bodyParams);
  }

  public getActiveGridFilters(gridFiltersParam: string): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_Filters}?section=${gridFiltersParam}&status=active`);
  }

  public getCurrentUserProfile(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_UserMe}`, []);
  }

  public updateUserProfile(bodyParams): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_UserMe}`, bodyParams);
  }

  public doCheckout(bodyParams: any): Observable<RestResponse> {
    return this._rest.post(this.serviceUrl_Checkout, bodyParams);
  }

  //notifications
  public getNotifications(pathParams?: HttpParamsInterface): Observable<RestResponse> {
    let params = pathParams ? this.httpParamService.buildPathParams(pathParams) : null;
    return this._rest.get(`${this.serviceUrl_CompanyInbox}`, { params: params });
  }

  public getExportNotifications(pathParams?: HttpParamsInterface): Observable<RestResponse> {
    let params = pathParams ? this.httpParamService.buildPathParams(pathParams) : null;
    return this._rest.get(`${this.serviceUrl_CompanyInbox}/export/notification-list`, { params: params });
  }

  public getExportNotificationsStream(pathParams?: HttpParamsInterface) {
    return this.load$.pipe(
      switchMap(_ => timer(0, this.COMPANY_USER_EXPORT_NOTIFICATION_INTERVAL).pipe(
        concatMap(_ => this.getExportNotifications(pathParams)),
        map((res: RestResponse) => res),
      ))
    );
  }

  public updateNotificationStatus(notificationId: number): Observable<any> {
    return this._rest.put(`${this.serviceUrl_CompanyInbox}/${notificationId}/read`);
  }

  //notifications channels
  public getNotificationsChannels(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_AnalyticsSalesChannel}`);
  }

  public getAnalyicsTotals(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_AnalyticsTotals}`);
  }

  public getPaymentActivity(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_AnalyticsInvoices}`);
  }

  public createLogs(userId: number, bodyParams: any): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_UserLog}/${userId}/logs`, bodyParams);
  }

  public cancelInvite(userEmail: string): Observable<RestResponse> {
    return this._rest.delete(`${this.serviceUrl_EntityUsersInvites}/${userEmail}`);
  }

  public cancelInternalInvite(customerId: number, userEmail: string): Observable<RestResponse> {
    return this._rest.delete(`${this.serviceUrl_InternalUsers}/${customerId}/internal/user/setting/${userEmail}`);
  }

  public getUsers(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_EntityUsers}`);
  }

  public getInternalCompanyUsers(customerId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_InternalUsers}/${customerId}/internal/user/setting/all/company-users`);
  }

  public getInternalInvites(customerId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_InternalUsers}/${customerId}/internal/user/setting/all/invites`);
  }

  public getInvites(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_EntityUsersInvites}`);
  }

  public changeLevelAccess(userId: number, roleId: number): Observable<RestResponse> {
    return this._rest.put(`${this.serviceUrl_EntityUsers}/${userId}/${roleId}`);
  }

  public changeInternalLevelAccess(customerId: number, userId: number, roleId: number): Observable<RestResponse> {
    return this._rest.put(`${this.serviceUrl_InternalUsers}/${customerId}/internal/user/setting/update/user-role/${roleId}`, { userId: userId });
  }

  public revokeInternalAccess(customerId: number, userId: number): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_InternalUsers}/${customerId}/internal/user/setting/revoke/user`, { userId: userId });
  }

  public revokeAccess(userId: number): Observable<RestResponse> {
    return this._rest.delete(`${this.serviceUrl_EntityUsers}/${userId}`);
  }


  public sendInvite(bodyParams: any): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_EntityUsersInvites}`,bodyParams);
  }

  public sendInternalInvite(customerId: number, bodyParams: any): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_InternalUsers}/${customerId}/internal/user/setting/invite`, bodyParams);
  }

  public resendInvite(userEmail: string): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_EntityUsersInvites}/${userEmail}/resend`);
  }

  public resendInternalInvite(customerId: number, userEmail: string): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_InternalUsers}/${customerId}/internal/user/setting/${userEmail}/resend`);
  }

  public createPaymentSource(bodyParams: any): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_EntityPaymentMethod}`, bodyParams);
  }

  //
  public getShippingServices(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_ShippingServices}`);
  }

  public getShipperHQServices(bodyParams: any): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_ShipperHQServices}`, bodyParams);
  }

  public createTaxTransaction(bodyParams: any): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_TaxCreateTransaction}`, bodyParams);
  }

  public createShippingByServiceId(companyIntegrationId:number, serviceId: string, bodyParams: ShippingCalculateModel): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_Shipping}/${companyIntegrationId}/services/${serviceId}`, bodyParams);
  }

  public calculateShipping(bodyParams: ShippingCalculateModel): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_ShippingCalculate}`, bodyParams);
  }
  public exportCSV(bodyParams){
    return this._rest.put(`${this.serviceUrl_Export}`, bodyParams);
  }

  public getUserCompanies(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_UserCompanies}`, []);
  }

  public createCompanyRequest(bodyParams:any): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_CompanyRequest}/company-request`, bodyParams);
  }

  public getPendingActivationCompanies(): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_UserPendingActivationCompanies}`, []);
  }

  public getCompanyPendingActivationInfo(companyId:number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_CompanyRequest}/company-request/${companyId}`,{}, true);
  }

  public getCurrentCompanySelectedPlanScope(customerId: number): Observable<RestResponse> {
    return this._rest.get(`${this.serviceUrl_SelectedPlanScope}/${customerId}`,{}, true);
  }

  public getAllPlanScopes(): Observable<RestResponse> {
    return this._rest.get(this.serviceUrl_PlanScope,{}, true);
  }

  public createOrUpdateCompanyPlanScope(rblPlanId: number, customerId: number, bodyParams: Post_CompanyPlanScope): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_UpdateOrCreateCompanyPlanScope}/customer/${customerId}${APIResourceCompanyUpdateOrCreatePlanScope}/${rblPlanId}`,bodyParams);
  }

  public activateCompany(companyId:number, bodyParams: Post_ActivateCompany): Observable<RestResponse> {
    return this._rest.post(`${this.serviceUrl_CompanyRequest}/company-request/${companyId}/activate`,bodyParams);
  }

  public getCompanyScope(){
    return this._rest.get(`${this.serviceUrl_Scope}`);
  }

  public getShippingProtectionPurchaseHistory(pathParams: HttpParamsInterface) : Observable<RestResponse> {
    let params = this.httpParamService.buildPathParams(pathParams);
    return this._rest.get(`${this.serviceUrl_PurchaseHistoryUpsCapital}`, { params: params });
  }
}
