<div class="dialog-wrapper">
  <div class="body">
    <div class="bulk-records-info">
      <div class="bulk-records-inner">
        <label class="label-bulk-records">{{bulkRecords}}</label>
      </div>
      <h1 class="label-bulk-section">{{_data.sectionName}} selected</h1>
    </div> 
    <div class="bulk-records-actions" *ngIf="_data.collectionOfActions.length > 0">
      <div *ngFor="let action of _data.collectionOfActions">
        <button *ngIf="action === 'publish'" mat-icon-button (click)="doAction('publish')" disableRipple="true" class="custom-mat-icon-button button-action">
          <mat-icon svgIcon="ico-check" class="custom-mat-icon ico-size ico-1xs"></mat-icon>
          Publish
        </button>
        <button *ngIf="action === 'enable'" mat-icon-button (click)="doAction('publish')" disableRipple="true" class="custom-mat-icon-button button-action">
          <mat-icon svgIcon="ico-check" class="custom-mat-icon ico-size ico-1xs"></mat-icon>
          Enable
        </button>

        <button *ngIf="action === 'disable'" mat-icon-button (click)="doAction('disable')" disableRipple="true" class="custom-mat-icon-button button-action">
          <mat-icon svgIcon="ico-disable" class="custom-mat-icon ico-size ico-1xs"></mat-icon>
          Disable
        </button>
        <button *ngIf="action === 'duplicate'" mat-icon-button disableRipple="true" class="custom-mat-icon-button button-action">
          <mat-icon svgIcon="ico-copy" class="custom-mat-icon ico-size ico-1xs"></mat-icon>
          Duplicate
        </button>
        <!-- <button  *ngIf="action === 'export'" mat-icon-button disableRipple="true" class="custom-mat-icon-button button-action">
          <mat-icon svgIcon="ico-export" class="custom-mat-icon ico-size ico-1xs"></mat-icon>
          Export
        </button> -->
        <button  *ngIf="action === 'archive'" mat-icon-button disableRipple="true" class="custom-mat-icon-button button-action">
          <mat-icon svgIcon="ico-archive" class="custom-mat-icon ico-size ico-1xs"></mat-icon>
          Archive
        </button>
        <button  *ngIf="action === 'delete'" mat-icon-button disableRipple="true" class="custom-mat-icon-button button-action">
          <mat-icon svgIcon="ico-delete" class="custom-mat-icon ico-size ico-1xs"></mat-icon>
          Delete
        </button>
        <button  *ngIf="action === 'status'" mat-icon-button disableRipple="true" class="custom-mat-icon-button button-action">
          <mat-icon svgIcon="ico-switch" class="custom-mat-icon ico-size ico-1xs"></mat-icon>
          Status
        </button>
      </div>
        <button mat-icon-button (click)="onClose()" disableRipple="true"
          class="custom-mat-icon-button button-action button-close">
          <mat-icon svgIcon="ico-close" class="custom-mat-icon ico-size ico-1xs"></mat-icon>
        </button>
    </div>
  </div>
</div>
